import styled from "styled-components";
import * as T from "@zendeskgarden/react-typography";
import {
  ComponentProps,
  JSX,
  JSXElementConstructor,
  ReactElement,
} from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Component = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;
type BaseProps<Base, Tag extends Component> = Omit<Base, "tag"> & {
  tag?: Tag;
} & ComponentProps<Tag>;

export type EllipsisProps<Tag extends Component> = BaseProps<
  T.IEllipsisProps,
  Tag
>;

// @ts-expect-error, review later
export const Ellipsis: <Tag extends Component>(
  p: EllipsisProps<Tag>,
) => ReactElement = T.Ellipsis;

export type TypographySMProps<Tag extends Component> = BaseProps<
  ComponentProps<typeof T.SM>,
  Tag
>;

// @ts-expect-error, review later
export const TypographySM: <Tag extends Component>(
  p: TypographySMProps<Tag>,
) => ReactElement = T.SM;

export type TypographyMDProps<Tag extends Component> = BaseProps<
  ComponentProps<typeof T.MD>,
  Tag
>;

// @ts-expect-error, review later
export const TypographyMD: <Tag extends Component>(
  p: TypographyMDProps<Tag>,
) => ReactElement = T.MD;

export type TypographyLGProps<Tag extends Component> = BaseProps<
  ComponentProps<typeof T.LG>,
  Tag
>;

// @ts-expect-error, review later
export const TypographyLG: <Tag extends Component>(
  p: TypographyLGProps<Tag>,
) => ReactElement = T.LG;

export type TypographyXLProps<Tag extends Component> = BaseProps<
  ComponentProps<typeof T.XL>,
  Tag
>;

// @ts-expect-error, review later
export const TypographyXL: <Tag extends Component>(
  p: TypographyXLProps<Tag>,
) => ReactElement = T.XL;

export type TypographyXXLProps<Tag extends Component> = BaseProps<
  ComponentProps<typeof T.XXL>,
  Tag
>;

// @ts-expect-error, review later
export const TypographyXXL: <Tag extends Component>(
  p: TypographyXXLProps<Tag>,
) => ReactElement = T.XXL;

export const DisabledMd = styled(TypographyMD)`
  color: ${(props) => props.theme.palette.grey[600]};
  font-style: italic;
`;
