import { silentUnreachableError } from "utils/exceptions";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

import * as DataTypes from "./states/DataTypes";
import * as Customers from "./states/Customers";
import * as Suppliers from "./states/Suppliers";
import * as Repositories from "./states/Repositories";
import * as InventoryItems from "./states/InventoryItems";
import * as ItemMovements from "./states/ItemMovements";
import * as RepositoryMovements from "./states/RepositoryMovements";
import * as Stocks from "./states/Stocks";
import * as Transactions from "./states/Transactions";
import { pickingOrderState } from "./utils";

export function reducer(s: State.State, a: Actions.Actions): State.State {
  if (DataTypes.isState(s.payload.subState)) {
    if (DataTypes.isActions(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: DataTypes.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (Customers.isState(s.payload.subState)) {
    if (Customers.isActions(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Customers.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (Suppliers.isState(s.payload.subState)) {
    if (Suppliers.isActions(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Suppliers.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (Repositories.isState(s.payload.subState)) {
    if (Repositories.isActions(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Repositories.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (InventoryItems.isState(s.payload.subState)) {
    if (InventoryItems.isActions(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: InventoryItems.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (ItemMovements.isState(s.payload.subState)) {
    if (ItemMovements.isActions(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: ItemMovements.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (RepositoryMovements.isState(s.payload.subState)) {
    if (RepositoryMovements.isActions(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: RepositoryMovements.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (Stocks.isState(s.payload.subState)) {
    if (Stocks.isActions(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Stocks.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (Transactions.isState(s.payload.subState)) {
    if (Transactions.isActions(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Transactions.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (pickingOrderState.isState(s.payload.subState)) {
    if (pickingOrderState.isActions(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: pickingOrderState.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  switch (a.type) {
    case "Ready:DataManager:LoadFail": {
      return pipe(
        s,
        O.fromPredicate(State.isLoading),
        O.map((s) => State.loadError(s.payload)),
        O.getOrElse(() => s),
      );
    }

    case "Ready:DataManager:LoadSuccess": {
      return pipe(
        s,
        O.fromPredicate(State.isLoading),
        O.map((s) =>
          State.ready({
            ...s.payload,
            dataTypes: a.payload.dataTypes,
          }),
        ),
        O.getOrElse(() => s),
      );
    }
  }

  /*
  This is a hack have a type safe reducer
   */
  if (DataTypes.isActions(a)) return s;
  if (Customers.isActions(a)) return s;
  if (Suppliers.isActions(a)) return s;
  if (Repositories.isActions(a)) return s;
  if (InventoryItems.isActions(a)) return s;
  if (ItemMovements.isActions(a)) return s;
  if (RepositoryMovements.isActions(a)) return s;
  if (Stocks.isActions(a)) return s;
  if (Transactions.isActions(a)) return s;
  if (pickingOrderState.isActions(a)) return s;

  silentUnreachableError(a);
  return s;
}
