import { ItemMovement } from "types/src/ItemMovements/ItemMovement";
import { toDate } from "types/src/date/ISODate";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { NoEmptyString } from "types/src/NoEmptyString";
import {
  RepositoryMovement,
  RepositoryMovementId,
} from "types/src/RepositoryMovements/RepositoryMovement";
import {
  RepositoryFragmentFragment,
  RepositoryMovementFragmentFragment,
} from "../generated/graphql";
import { repositoryFragmentToRepository } from "./Repositories";

export function repositoryMovementFragmentToRepositoryMovement(
  fragment: RepositoryMovementFragmentFragment,
): RepositoryMovement {
  return {
    id: fragment.id as RepositoryMovementId,
    fields: (fragment.data ?? {}) as ItemMovement["fields"],
    createdAt: toDate(fragment.createdAt),
    updatedAt: pipe(
      fragment.updatedAt,
      O.fromNullable,
      O.map(toDate),
      O.getOrElseW(() => undefined),
    ),
    collectionId: fragment.collectionID as ItemMovement["collectionId"],
    orderId: fragment.orderID as ItemMovement["orderId"],
    dataTypeId: fragment.dataTypeID as ItemMovement["dataTypeId"],
    executed: fragment.executed ?? false,
    executedAt: pipe(
      fragment.executedAt,
      O.fromNullable,
      O.map(toDate),
      O.getOrElseW(() => undefined),
    ),
    handler: fragment.handler as NoEmptyString,
    repository: repositoryFragmentToRepository(fragment.repository),
    from: repositoryFragmentToRepository(
      fragment.from as RepositoryFragmentFragment,
    ),
    to: repositoryFragmentToRepository(fragment.to),
  };
}
