import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError } from "ds";
import { Client as OpenAIClient } from "open-ai-ds";
import { getDataTypes } from "ds/DataTypes";
import { flow } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { isOneOf } from "utils/isOneOf";
import { Epic, mergeByGuard } from "../../../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

import * as DataTypes from "./states/DataTypes";
import * as Customers from "./states/Customers";
import * as Suppliers from "./states/Suppliers";
import * as Repositories from "./states/Repositories";
import * as InventoryItems from "./states/InventoryItems";
import * as ItemMovements from "./states/ItemMovements";
import * as RepositoryMovements from "./states/RepositoryMovements";
import * as Stocks from "./states/Stocks";
import * as Transactions from "./states/Transactions";
import { pickingOrderState } from "./utils";

const loadingEpic: Epic<
  Actions.Actions,
  State.Loading | State.LoadError,
  {
    pyckAdminClient$: Observable<Client>;
    openAIClient$: Observable<OpenAIClient>;
  }
> = (state$, { pyckAdminClient$ }) =>
  state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isLoading),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([, client]) => {
      return from(getDataTypes(client, {})).pipe(
        map(
          flow(
            E.map((v) => Actions.loadSuccess({ dataTypes: v.items })),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.loadFail({ type: "unknown" }),
            ),
          ),
        ),
        catchError(() => of(Actions.loadFail({ type: "unknown" }))),
      );
    }),
  );

const subStatesEpic: Epic<
  Actions.Actions,
  State.Ready,
  {
    pyckAdminClient$: Observable<Client>;
    openAIClient$: Observable<OpenAIClient>;
  }
> = (state$, ds$) => {
  return mergeByGuard([
    [DataTypes.isState, DataTypes.epic],
    [Customers.isState, Customers.epic],
    [Suppliers.isState, Suppliers.epic],
    [Repositories.isState, Repositories.epic],
    [InventoryItems.isState, InventoryItems.epic],
    [ItemMovements.isState, ItemMovements.epic],
    [RepositoryMovements.isState, RepositoryMovements.epic],
    [Stocks.isState, Stocks.epic],
    [Transactions.isState, Transactions.epic],
    [pickingOrderState.isState, pickingOrderState.epic],
  ])(state$.pipe(map((s) => s.payload.subState)), ds$);
};

export const epic = mergeByGuard([
  [isOneOf([State.isLoading, State.isLoadError]), loadingEpic],
  [State.isReady, subStatesEpic],
]);
