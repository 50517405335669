import { DataTypeId } from "types/src/DataType/DataType";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import { NoEmptyArr } from "types/src/NoEmptyArr";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import * as PickingOrderItem from "../../PickingOrderItem/types/State";
import { NewItemId } from "./NewItemId";

export type State<P extends string, Id extends string> =
  | Ready<P, Id>
  | Valid<P, Id>;

export const isState = <P extends string, Id extends string>(p: P) => {
  const _isReady = isReady<P, Id>(p);
  const _isValid = isValid<P, Id>(p);

  return strictGuard((s: State<P, Id>): s is State<P, Id> => {
    if (_isReady(s) || _isValid(s)) return true;

    silentUnreachableError(s);
    return false;
  });
};

// region Ready
export interface ReadyPayload<P extends string, Id extends string> {
  dataTypes: NoEmptyArr<{
    title: string;
    id: DataTypeId;
    schema: DataTypeSchema;
    isDefault: boolean;
  }>;
  items: Record<
    Id | NewItemId,
    PickingOrderItem.State<`${P}:item:${NewItemId | Id}`>
  >;
}

export interface Ready<P extends string, Id extends string> {
  type: "Ready";
  payload: ReadyPayload<P, Id>;
}

export const ready =
  <P extends string, Id extends string>(p: P) =>
  (payload: Ready<P, Id>["payload"]): Ready<P, Id> => ({
    type: "Ready",
    payload,
  });

export const isReady =
  <P extends string, Id extends string>(p: P) =>
  (s: State<P, Id>): s is Ready<P, Id> =>
    s.type === "Ready";
// endregion

// region Valid
export interface ValidPayload<P extends string, Id extends string>
  extends ReadyPayload<P, Id> {
  items: Record<
    Id | NewItemId,
    PickingOrderItem.Valid<`${P}:item:${NewItemId | Id}`>
  >;
}

export interface Valid<P extends string, Id extends string> {
  type: "Valid";
  payload: ValidPayload<P, Id>;
}

export const valid =
  <P extends string, Id extends string>(p: P) =>
  (payload: Valid<P, Id>["payload"]): Valid<P, Id> => ({
    type: "Valid",
    payload,
  });

export const isValid =
  <P extends string, Id extends string>(p: P) =>
  (s: State<P, Id>): s is Valid<P, Id> =>
    s.type === "Valid";
// endregion

export const init = <P extends string, Id extends string>(p: P) =>
  ready<P, Id>(p);
