import { AdvancedFilters as FiltersSidebar } from "ui/layouts/Filters/AdvancedFilters";
import { RootState, useSelector } from "state-manager";
import { ReactElement, ReactNode } from "react";

export interface AdvancedFiltersProps {
  open$: (s: RootState) => boolean;
  onClose: () => void;
  onClear: () => void;
  onApply: () => void;
  children: ReactNode;
}

export function AdvancedFilters(p: AdvancedFiltersProps): ReactElement {
  const isOpen = useSelector(p.open$);

  return (
    <FiltersSidebar
      isOpen={isOpen}
      onClose={p.onClose}
      onApply={p.onApply}
      onClear={p.onClear}
    >
      {p.children}
    </FiltersSidebar>
  );
}
