import {
  debounceTime,
  distinctUntilKeyChanged,
  filter,
  map,
  merge,
  switchMap,
} from "rxjs";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { Epic } from "../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

export function epicCreator<P extends string, E, T>(
  p: P,
): Epic<
  Actions.Actions<P, E, T>,
  State.State<P, E, T>,
  { get: (s: string | undefined) => Promise<E.Either<E, T[]>> }
> {
  return (state$, { get }) => {
    const submitQuery$ = state$.pipe(
      filter(State.isIdle(p)),
      filter((s) => O.isSome(s.payload.query)),
      debounceTime(500),
      map(Actions.submitQuery(p)),
    );
    const search$ = state$.pipe(
      filter(State.isSearching(p)),
      switchMap((s) =>
        get(O.toUndefined(s.payload.query)).then(
          flow(
            E.map(Actions.searchSuccess(p)),
            E.getOrElseW(Actions.searchError(p)),
          ),
        ),
      ),
    );
    const idle$ = state$.pipe(
      filter(State.isIdle(p)),
      distinctUntilKeyChanged("type"),
      switchMap((s) =>
        get(O.toUndefined(s.payload.query)).then(
          flow(
            E.map(Actions.searchSuccess(p)),
            E.getOrElseW(Actions.searchError(p)),
          ),
        ),
      ),
    );

    return merge(idle$, search$, submitQuery$);
  };
}
