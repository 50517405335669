import { Selector, useSelector } from "state-manager";
import { Value, isInvalid } from "types/src/FormValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Input as InputComponent, InputNumberProps } from "ui/components/Input";
import { flow } from "fp-ts/function";

export interface InputProps
  extends Omit<InputNumberProps, "value" | "onChange"> {
  value: Selector<Value<unknown, NoEmptyString, string | undefined>>;
  onChange: (value: string) => void;
}

export function Input(p: InputProps) {
  const { value: v, onChange, ...other } = p;
  const isError = useSelector(flow(v, isInvalid));
  const value = useSelector(flow(v, (v) => v.value));

  return (
    <InputComponent
      {...other}
      value={value}
      onChange={(v) => onChange(v.target.value)}
      validation={isError ? "error" : undefined}
    />
  );
}
