import * as Filters from "./types/Filters";

import * as StateModule from "./types/State";
import * as ActionsModule from "./types/Actions";
import * as ExitsModule from "./types/Exits";

import { reducer } from "./reducer";
import { epic } from "./epic";

export const createState = <P extends string>(p: P) => ({
  isState: StateModule.isState(p),
  isActions: ActionsModule.isActions(p),
  actions: {
    loadSuccess: {
      create: ActionsModule.loadSuccess(p),
      is: ActionsModule.isLoadSuccess(p),
    },
    fetchSuccess: {
      create: ActionsModule.fetchSuccess(p),
      is: ActionsModule.isFetchSuccess(p),
    },
    loadFail: {
      create: ActionsModule.loadFail(p),
      is: ActionsModule.isLoadFail(p),
    },
    setPage: {
      create: ActionsModule.setPage(p),
      is: ActionsModule.isSetPage(p),
    },
    orderBy: {
      create: ActionsModule.orderBy(p),
      is: ActionsModule.isOrderBy(p),
    },
    select: {
      create: ActionsModule.select(p),
      is: ActionsModule.isSelect(p),
    },
    selectAll: {
      create: ActionsModule.selectAll(p),
      is: ActionsModule.isSelectAll(p),
    },
    removeItem: {
      create: ActionsModule.removeItem(p),
      is: ActionsModule.isRemoveItem(p),
    },
    removeBulk: {
      create: ActionsModule.removeBulk(p),
      is: ActionsModule.isRemoveBulk(p),
    },
    removeConfirm: {
      create: ActionsModule.removeConfirm(p),
      is: ActionsModule.isRemoveConfirm(p),
    },
    removeDecline: {
      create: ActionsModule.removeDecline(p),
      is: ActionsModule.isRemoveDecline(p),
    },
    removeSuccess: {
      create: ActionsModule.removeSuccess(p),
      is: ActionsModule.isRemoveSuccess(p),
    },
    removeFail: {
      create: ActionsModule.removeFail(p),
      is: ActionsModule.isRemoveFail(p),
    },
    setCreatedAtFilter: {
      create: ActionsModule.setCreatedAtFilter(p),
      is: ActionsModule.isSetCreatedAtFilter(p),
    },
    setUpdatedAtFilter: {
      create: ActionsModule.setUpdatedAtFilter(p),
      is: ActionsModule.isSetUpdatedAtFilter(p),
    },
    setSearchFilter: {
      create: ActionsModule.setSearchFilter(p),
      is: ActionsModule.isSetSearchFilter(p),
    },
    setIdFilter: {
      create: ActionsModule.setIdFilter(p),
      is: ActionsModule.isSetIdFilter(p),
    },
    setStatusFilter: {
      create: ActionsModule.setStatusFilter(p),
      is: ActionsModule.isSetStatusFilter(p),
    },
    submitFilters: {
      create: ActionsModule.submitFilters(p),
      is: ActionsModule.isSubmitFilters(p),
    },
    openAdvancedFilters: {
      create: ActionsModule.openAdvancedFilters(p),
      is: ActionsModule.isOpenAdvancedFilters(p),
    },
    closeAdvancedFilters: {
      create: ActionsModule.closeAdvancedFilters(p),
      is: ActionsModule.isCloseAdvancedFilters(p),
    },
    clearFilters: {
      create: ActionsModule.clearFilters(p),
      is: ActionsModule.isClearFilters(p),
    },
    create: {
      create: ActionsModule.create(p),
      is: ActionsModule.isCreate(p),
    },
  },
  states: {
    loading: {
      create: StateModule.loading(p),
      is: StateModule.isLoading(p),
    },
    loadError: {
      create: StateModule.loadError(p),
      is: StateModule.isLoadError(p),
    },
    ready: {
      create: StateModule.ready(p),
      is: StateModule.isReady(p),
    },
    fetching: {
      create: StateModule.fetching(p),
      is: StateModule.isFetching(p),
    },
  },
  exists: {
    create: { is: ExitsModule.isCreate(p) },
    edit: { is: ExitsModule.isEdit(p) },
  },
  reducer: reducer(p),
  epic: epic(p),
  init: StateModule.init(p),
  filters: {
    isEmpty: Filters.isEmpty,
  },
});

export type State<P extends string> = StateModule.State<P>;
export type Actions<P extends string> = ActionsModule.Actions<P>;
