import { User } from "types";
import { catchError, from, map, NEVER, of, switchMap } from "rxjs";
import { unreachableError } from "utils/exceptions";
import * as O from "fp-ts/Option";
import { Epic } from "../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

interface Deps {
  getUser(): Promise<O.Option<User>>;
  signIn(): Promise<User>;
  signInConfirm(): Promise<void>;
}

export const epic: Epic<Actions.Actions, State.State, Deps> = (
  state$,
  deps,
) => {
  return state$.pipe(
    switchMap((state) => {
      if (state.type === "Auth:Login") return NEVER;
      if (state.type === "Auth:AuthError") return NEVER;

      if (state.type === "Auth:Idle") {
        return from(deps.getUser()).pipe(
          catchError(() => of(O.none)),
          map(O.map(Actions.userPreloadSuccess)),
          map(O.getOrElse<Actions.Actions>(Actions.userPreloadFail)),
        );
      }

      if (state.type === "Auth:Authentication") {
        return from(deps.signIn()).pipe(
          map((user) => Actions.signInSuccess(user)),
          catchError(() => of(Actions.signInFail())),
        );
      }

      if (state.type === "Auth:AuthConfirmation") {
        deps.signInConfirm();
        return NEVER;
      }

      unreachableError(state);
      return NEVER;
    }),
  );
};
