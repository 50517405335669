import { silentUnreachableError } from "utils/exceptions";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { isOneOf } from "utils/isOneOf";
import * as NoEmptyString from "types/src/NoEmptyString";
import * as Create from "../Create";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { FiltersMonoid } from "./types/Filters";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): State.State | Create.State {
  switch (a.type) {
    case "Ready:DataManager:Suppliers:ListingAll:LoadFail": {
      if (State.isLoading(s)) return State.loadError(s.payload);
      if (State.isFetching(s)) return State.ready(s.payload);

      return s;
    }
    case "Ready:DataManager:Suppliers:ListingAll:LoadSuccess": {
      if (State.isLoading(s)) {
        return State.ready({
          ...s.payload,
          total: a.payload.total,
          items: a.payload.items.map((i) => ({
            id: i.id,
            createdAt: i.createdAt,
            updatedAt: O.fromNullable(i.updatedAt),
            selected: false,
            removeState: "none",
            dataType: pipe(
              a.payload.dataTypes.find((d) => d.id === i.dataTypeId),
              O.fromNullable,
              O.map((d) => ({ id: d.id, name: d.name })),
            ),
          })),
          dataTypes: a.payload.dataTypes,
          pageInfo: a.payload.pageInfo,
          advancedFiltersState: "closed",
        });
      }

      return s;
    }
    case "Ready:DataManager:Suppliers:ListingAll:FetchSuccess": {
      if (State.isFetching(s))
        return State.ready({
          ...s.payload,
          total: a.payload.total,
          items: a.payload.items.map((i) => ({
            id: i.id,
            createdAt: i.createdAt,
            updatedAt: O.fromNullable(i.updatedAt),
            selected:
              s.payload.items.find((si) => si.id === i.id)?.selected ?? false,
            removeState: "none",
            dataType: pipe(
              s.payload.dataTypes.find((d) => d.id === i.dataTypeId),
              O.fromNullable,
              O.map((d) => ({ id: d.id, name: d.name })),
            ),
          })),
          pageInfo: a.payload.pageInfo,
        });

      return s;
    }
    case "Ready:DataManager:Suppliers:ListingAll:SetPage": {
      if (State.isReady(s) || State.isFetching(s))
        return State.fetching({
          ...s.payload,
          page: a.payload,
        });

      return s;
    }
    case "Ready:DataManager:Suppliers:ListingAll:OrderBy": {
      if (State.isReady(s) || State.isFetching(s))
        return State.fetching({
          ...s.payload,
          page: "current",
          order: O.isNone(s.payload.order)
            ? O.of({ by: a.payload, direction: "asc" })
            : pipe(
                s.payload.order,
                O.chain((o) => {
                  if (o.by === a.payload) {
                    return pipe(
                      getNextDirection(O.of(o.direction)),
                      O.map((d) => ({ ...o, direction: d })),
                    );
                  } else {
                    return pipe(
                      O.of(a.payload),
                      O.map((by) => ({ by, direction: "asc" })),
                    );
                  }
                }),
              ),
        });

      return s;
    }
    case "Ready:DataManager:Suppliers:ListingAll:Select": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.filter((s) => s.payload.items.some((i) => i.id === a.payload)),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                items: s.payload.items.map((i) =>
                  i.id === a.payload ? { ...i, selected: !i.selected } : i,
                ),
              },
            }) as typeof s,
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:SelectAll": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) => {
          const allSelected = s.payload.items.every((i) => i.selected);
          return {
            ...s,
            payload: {
              ...s.payload,
              items: s.payload.items.map((i) => ({
                ...i,
                selected: !allSelected,
              })),
            },
          } as typeof s;
        }),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:RemoveItem": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) => s.payload.items.some((i) => i.id === a.payload)),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.id === a.payload ? { ...i, removeState: "confirmation" } : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:RemoveBulk": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) => s.payload.items.some((i) => i.selected)),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.selected
                ? {
                    ...i,
                    removeState: "confirmation",
                  }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:RemoveConfirm": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some((i) => i.removeState === "confirmation"),
        ),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.removeState === "confirmation"
                ? {
                    ...i,
                    removeState: "removing",
                  }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:RemoveDecline": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some((i) => i.removeState === "confirmation"),
        ),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.removeState !== "none" ? { ...i, removeState: "none" } : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:RemoveSuccess": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some(
            (i) => i.removeState === "removing" && a.payload.includes(i.id),
          ),
        ),
        O.map((s) =>
          State.fetching({
            ...s.payload,
            page: "current",
            items: s.payload.items.filter(
              (i) =>
                !(i.removeState === "removing" && a.payload.includes(i.id)),
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:RemoveFail": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some(
            (i) => i.removeState === "removing" && a.payload.includes(i.id),
          ),
        ),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              !(i.removeState === "removing" && a.payload.includes(i.id))
                ? {
                    ...i,
                    removeState: "none",
                  }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:SetUpdatedAtFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              updatedAt: a.payload,
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:SetCreatedAtFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              createdAt: a.payload,
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:SetSearchFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              search: NoEmptyString.fromString(a.payload),
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:SetDataTypesFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) => {
          const dataTypes = s.payload.dataTypes.map((dt) => dt.id);
          return State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              dataTypes: a.payload.filter((d) => dataTypes.includes(d)),
            },
          });
        }),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:SetIdFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              id: NoEmptyString.fromString(a.payload),
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:SetStatusFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              status: a.payload,
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:SubmitFilters": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.map((s) =>
          State.fetching({
            ...s.payload,
            page: "start",
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:ClearFilters": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.fetching({
            ...s.payload,
            page: "start",
            advancedFiltersState: "closed",
            filters: FiltersMonoid.empty,
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:OpenAdvancedFilters": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.filter((s) => s.payload.advancedFiltersState === "closed"),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                advancedFiltersState: "open",
              },
            }) as typeof s,
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:ListingAll:CloseAdvancedFilters": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.filter((s) => s.payload.advancedFiltersState === "open"),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                advancedFiltersState: "closed",
              },
            }) as typeof s,
        ),
        O.getOrElseW(() => s),
      );
    }
    default:
      silentUnreachableError(a);
      return s;
  }
}

function getNextDirection(
  d: O.Option<"asc" | "desc">,
): O.Option<"asc" | "desc"> {
  return O.isNone(d)
    ? O.some("asc")
    : pipe(
        d,
        O.chain((d) => (d === "asc" ? O.some("desc") : O.none)),
      );
}
