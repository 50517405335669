import { Supplier } from "types/src/Supplier/Supplier";
import { toDate } from "types/src/date/ISODate";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { SupplierFragmentFragment } from "../generated/graphql";

export function supplierFragmentToSupplier(
  supplierFragment: SupplierFragmentFragment,
): Supplier {
  return {
    id: supplierFragment.id as Supplier["id"],
    fields: (supplierFragment.data ?? {}) as Supplier["fields"],
    createdAt: toDate(supplierFragment.createdAt),
    updatedAt: pipe(
      supplierFragment.updatedAt,
      O.fromNullable,
      O.map(toDate),
      O.getOrElseW(() => undefined),
    ),
    deletedAt: pipe(
      supplierFragment.updatedAt,
      O.fromNullable,
      O.map(toDate),
      O.getOrElseW(() => undefined),
    ),
    dataTypeId: supplierFragment.dataTypeID as Supplier["dataTypeId"],
  };
}
