import { useTranslation } from "i18n";
import { TypographyMD } from "ui/components/Typography";
import { RootState } from "state-manager";
import * as Create from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Create";

export interface HeaderProps {
  selector: (s: RootState) => Create.State;
  dispatch: (a: Create.Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();

  return <TypographyMD>{t("Add new Data Type")}</TypographyMD>;
}
