import { RootState, useSelector } from "state-manager";
import {
  Combobox as ComboboxComponent,
  Field,
  Option,
} from "ui/components/Combobox";
import { TranslatedStr } from "types/src/TranslatedStr";
import { flow } from "fp-ts/function";

export interface ComboboxProps<T extends string> {
  value$: (s: RootState) => T | undefined;
  onChange: (value: T) => void;
  options: { value: T; label: TranslatedStr }[];
  validation?: "success" | "warning" | "error";
}

export function Combobox<T extends string>({
  value$,
  onChange,
  options,
}: ComboboxProps<T>) {
  const activeIndex = useSelector(
    flow(value$, (v) => options.findIndex((i) => i.value === v)),
  );

  return (
    <Field>
      <ComboboxComponent
        isAutocomplete
        isEditable={false}
        activeIndex={activeIndex}
        inputValue={options[activeIndex]?.label}
        onChange={({ selectionValue }) => {
          if (selectionValue !== undefined) {
            onChange(selectionValue as T);
          }
        }}
        validation={activeIndex === -1 ? "error" : undefined}
      >
        {options.map(({ value: v, label }, i) => (
          <Option key={i} value={v} label={label} />
        ))}
      </ComboboxComponent>
    </Field>
  );
}
