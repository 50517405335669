import { PageLayout } from "ui/layouts/PageLayout";
import { MainSidebar } from "@Containers/MainSidebar";
import { Selector } from "state-manager";
import { Actions, State } from "state-manager/states/Ready";
import { SecondarySidebar } from "@Containers/SecondarySidebar";
import * as ReadyContainer from "@Containers/Ready";

export interface ReadyProps {
  selector: Selector<State>;
  dispatch: (a: Actions) => void;
}

export function Ready({ selector, dispatch }: ReadyProps) {
  return (
    <PageLayout
      mainSidebar={<MainSidebar selector={selector} dispatch={dispatch} />}
      secondarySidebar={
        <SecondarySidebar selector={selector} dispatch={dispatch} />
      }
      header={<ReadyContainer.Header selector={selector} dispatch={dispatch} />}
      footer={<ReadyContainer.Footer selector={selector} dispatch={dispatch} />}
    >
      <ReadyContainer.Content selector={selector} dispatch={dispatch} />
    </PageLayout>
  );
}
