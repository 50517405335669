import { GetTransactionsVars } from "ds/Transactions";
import * as O from "fp-ts/Option";
import { fromDate } from "types/src/date/ISODate";
import { pipe } from "fp-ts/function";
import { PageInfo } from "types";
import { Transaction } from "types/src/Transactions/Transaction";
import { Loading, Fetching } from "./types/State";
import { Filters } from "./types/Filters";
import { Item } from "./types/Item";
import {
  itemSearchState,
  repositorySearchState,
  transactionSearchState,
} from "./utils";

function stateToTransactionQuery({
  filters,
  perPage,
  page,
  pageInfo,
}: {
  filters: Filters;
  perPage: number;
  page: "current" | "next" | "prev" | "start" | "end";
  pageInfo: PageInfo | undefined;
}): GetTransactionsVars {
  const where: GetTransactionsVars["where"] = {
    or: [
      { hasItemWith: [{ search: filters.search }] },
      { hasRepositoryWith: [{ name: filters.search }] },
    ],
    id: {
      eq: O.toUndefined(transactionSearchState.getValue(filters.id))?.id,
    },
    type: pipe(
      filters.type,
      O.fromNullable,
      O.map((v) => ({ eq: v })),
      O.toUndefined,
    ),
    quantity: {
      gte: filters.quantity?.[0],
      lte: filters.quantity?.[1],
    },
    hasItemWith: [
      {
        id: O.toUndefined(itemSearchState.getValue(filters.item))?.id,
      },
    ],
    hasRepositoryWith: [
      {
        id: O.toUndefined(repositorySearchState.getValue(filters.repository))
          ?.id,
      },
    ],
    createdAt: {
      gte: pipe(
        filters.createdAt?.[0],
        O.fromNullable,
        O.map(fromDate),
        O.toUndefined,
      ),
      lte: pipe(
        filters.createdAt?.[1],
        O.fromNullable,
        O.map(fromDate),
        O.toUndefined,
      ),
    },
  };

  switch (page) {
    case "start":
      return {
        first: perPage,
        where,
      };
    case "prev":
      return {
        last: perPage,
        before: pageInfo?.prevCursor,
        where,
      };
    case "next":
      return {
        first: perPage,
        after: pageInfo?.nextCursor,
        where,
      };
    case "end":
      return {
        last: perPage,
        where,
      };
    case "current":
      return {
        first: perPage,
        where,
      };
  }
}

export function loadingToTransactionsQuery(s: Loading): GetTransactionsVars {
  return stateToTransactionQuery({
    filters: s.payload.filters,
    perPage: s.payload.perPage,
    page: "start",
    pageInfo: undefined,
  });
}
export function fetchToTransactionsQuery(s: Fetching): GetTransactionsVars {
  return stateToTransactionQuery({
    filters: s.payload.filters,
    perPage: s.payload.perPage,
    page: s.payload.page,
    pageInfo: s.payload.pageInfo,
  });
}

export function transactionToItem(v: Transaction): Item {
  return {
    id: v.id,
    repository: {
      id: v.repository.id,
      name: v.repository.name,
    },
    item: {
      id: v.item.id,
    },
    createdAt: v.createdAt,
    quantity: v.quantity,
    type: v.type,
  };
}
