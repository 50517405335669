import { silentUnreachableError } from "utils/exceptions";
import * as FormValue from "types/src/FormValue";
import { isNoEmptyString } from "types/src/NoEmptyString";
import { Sku } from "types/src/Sku";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { createSchemaFieldsState } from "./utils";

export const reducer = <P extends string>(p: P) => {
  const schemaFields = createSchemaFieldsState(p);

  return (s: State.State<P>, a: Actions.Actions<P>): State.State<P> => {
    if (schemaFields.isActions(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          fields: schemaFields.reducer(s.payload.fields, a),
        },
      } as State.State<P>;
    }

    if (Actions.isSetDataType(p)(a)) {
      if (s.payload.dataTypeId === a.payload) return s;

      const dataType = s.payload.dataTypes.find((v) => v.id === a.payload);

      if (dataType === undefined) return s;

      return State.ready(p)({
        ...s.payload,
        dataTypeId: dataType.id,
        fields: schemaFields.states.init.create({
          schema: dataType.schema,
          values: {},
        }),
      });
    }

    if (Actions.isSetSku(p)(a)) {
      return State.ready(p)({
        ...s.payload,
        sku: FormValue.initial(a.payload),
      });
    }

    if (Actions.isSetQuantity(p)(a)) {
      return State.ready(p)({
        ...s.payload,
        quantity: FormValue.initial(a.payload),
      });
    }

    if (Actions.isSubmit(p)(a)) {
      const skuValue = s.payload.sku.value;
      const sku = !skuValue
        ? FormValue.invalid("required" as const, skuValue)
        : !isNoEmptyString(skuValue)
        ? FormValue.invalid("invalid" as const, skuValue)
        : FormValue.valid(skuValue as Sku);

      const qVal = s.payload.quantity.value;
      const quantity = !qVal
        ? FormValue.invalid("required" as const, qVal)
        : qVal <= 0
        ? FormValue.invalid("invalid" as const, qVal)
        : FormValue.valid(qVal);

      if (FormValue.isValid(sku) && FormValue.isValid(quantity))
        return State.valid(p)({
          ...s.payload,
          sku,
          quantity,
        });

      return State.ready(p)({
        ...s.payload,
        sku,
        quantity,
      });
    }

    silentUnreachableError(a);
    return s;
  };
};
