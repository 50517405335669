import { ReactElement, useCallback, useMemo } from "react";
import { RootState } from "state-manager";
import { flow } from "fp-ts/function";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { GetContructorType } from "types/src/Utils";
import { DeleteConfirmation } from "./DeleteConfirmation";
import { ListingHeader } from "./ListingHeader";
import { Table, TableProps } from "./Table";

const state = DataManager.pickingOrderState.subStates.listingAll;

const listingOrReady = {
  ready: state.states.ready,
  fetching: state.states.fetching,
};
const actions = state.actions;
type ListingOrReady = GetContructorType<typeof listingOrReady>;
type Actions = GetContructorType<typeof actions>;

export interface AllProps {
  selector$: (s: RootState) => ListingOrReady;
  dispatch: (a: Actions) => void;
}

export function All({ selector$, dispatch }: AllProps): ReactElement {
  const items$: TableProps["items$"] = useMemo(
    () => flow(selector$, (s) => s.payload.items),
    [selector$],
  );
  const orderBy$ = useMemo(
    () => flow(selector$, (s) => s.payload.order),
    [selector$],
  );

  const orderBy = useCallback(
    (v: "createdAt" | "updatedAt") => dispatch(actions.orderBy.create(v)),
    [dispatch],
  );
  const onSelect = useCallback(flow(actions.select.create, dispatch), [
    dispatch,
  ]);
  const onSelectAll = useCallback(flow(actions.selectAll.create, dispatch), [
    dispatch,
  ]);

  const onDelete = useCallback(flow(actions.removeItem.create, dispatch), [
    dispatch,
  ]);
  const onDeleteAll = useCallback(flow(actions.removeBulk.create, dispatch), [
    dispatch,
  ]);

  return (
    <>
      <ListingWrapper
        header={<ListingHeader selector$={selector$} dispatch={dispatch} />}
      >
        <Table
          items$={items$}
          orderBy$={orderBy$}
          orderBy={orderBy}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          onDeleteAll={onDeleteAll}
          onDelete={onDelete}
        />
      </ListingWrapper>
      <DeleteConfirmation selector$={selector$} dispatch={dispatch} />
    </>
  );
}
