import { isInvalid, Value } from "types/src/FormValue";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";
import { Number } from "@Containers/Form/Number";
import { Selector, useSelector } from "state-manager";
import { Field } from "ui/components/Field";
import { Label } from "ui/components/Label";
import * as O from "fp-ts/Option";

export interface QuantityInputProps {
  value$: Selector<Value<unknown, number, number | undefined>>;
  onChange: (value: O.Option<number>) => void;
}

export function QuantityInput(p: QuantityInputProps) {
  const { t } = useTranslation();
  const value$ = flow(p.value$, (v) => v.value);
  const isError = useSelector(flow(p.value$, isInvalid));

  return (
    <Field>
      <Label>{t("Quantity")}</Label>
      <Number
        value$={value$}
        onChange={flow(O.fromNullable, p.onChange)}
        validation={isError ? "error" : undefined}
        min={0}
      />
    </Field>
  );
}
