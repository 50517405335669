import { silentUnreachableError } from "utils/exceptions";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

export function reducer(
  state: State.State,
  action: Actions.Actions,
): State.State {
  switch (action.type) {
    case "Schema:Add":
    case "Schema:SetConfig":
    case "Schema:SetLabel":
    case "Schema:SetDesc":
    case "Schema:SetRequired":
    case "Schema:SetUnique":
    case "Schema:Remove": {
      return state;
    }
    default: {
      silentUnreachableError(action);
      return state;
    }
  }
}
