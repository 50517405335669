import { Footer as FooterLayout } from "ui/layouts/Create/Footer";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { GetContructorType } from "types/src/Utils";

const state = DataManager.pickingOrderState.subStates.create;

type State = GetContructorType<typeof state.states>;
type Actions = GetContructorType<typeof state.actions>;

export interface FooterProps {
  selector: Selector<State>;
  dispatch: (a: Actions) => void;
}

export function Footer({ selector, dispatch }: FooterProps) {
  const isSaving = useSelector(flow(selector, state.states.saving.is));

  return (
    <FooterLayout
      submit={{
        disabled: isSaving,
        onClick: () => dispatch(state.actions.submit.create()),
      }}
    />
  );
}
