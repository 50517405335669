import { useTranslation } from "i18n";
import { useCallback } from "react";
import { TranslatedStr } from "types/src/TranslatedStr";
import { DataTypeEntity } from "types/src/DataType/DataType";

export function useDataTypeEntityTitle(): (t: DataTypeEntity) => TranslatedStr {
  const { t } = useTranslation();

  return useCallback(
    (v: DataTypeEntity) => {
      switch (v) {
        case DataTypeEntity.Customer:
          return t("Customer");
        case DataTypeEntity.Delivery:
          return t("Delivery");
        case DataTypeEntity.Item:
          return t("Item");
        case DataTypeEntity.Order:
          return t("Order");
        case DataTypeEntity.Repository:
          return t("Repository");
        case DataTypeEntity.Supplier:
          return t("Supplier");
        case DataTypeEntity.Movement:
          return t("Movement");
        case DataTypeEntity.RepositoryPallet:
          return t("Repository Pallet");
        case DataTypeEntity.RepositoryBox:
          return t("Repository Box");
        case DataTypeEntity.ItemSet:
          return t("Item Set");
        case DataTypeEntity.CollectionMovement:
          return t("Collection Movement");
        case DataTypeEntity.Attachment:
          return t("Attachment");
      }
    },
    [t],
  );
}
