import { RootState } from "state-manager";
import { useMemo } from "react";
import * as Create from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Create";
import { DataTypeForm, DataTypeFormProps } from "@Containers/Form/DataTypeForm";
import * as DataTypesSchema from "state-manager/generic-states/Schema";
import { flow } from "fp-ts/function";
import { FormColumns } from "ui/layouts/FormColumns";
import { jsonSchemaToDataTypeSchema } from "ds/DataTypes";
import { DataTypeJsonEditor } from "@Containers/Form/DataTypeJsonEditor";

export interface ContentProps {
  selector: (s: RootState) => Create.State;
  dispatch: (a: Create.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const selectors = useMemo(() => {
    return {
      form: flow(selector, (s) => ({
        name: s.payload.name || "",
        description: s.payload.description || "",
        default: s.payload.default,
        entity: s.payload.entity,
        schema: s.payload.schema,
      })),
      jsonEditorSelector: flow(
        selector,
        (st) => st as typeof st,
        (s) => s.payload.schema.payload,
        (s) => JSON.stringify(s, null, 2),
      ),
    };
  }, [selector]);
  const dispatcher = useMemo<DataTypeFormProps["dispatch"]>(
    () => ({
      setEntity: flow(Create.setEntity, dispatch),
      setName: flow(Create.setName, dispatch),
      setDescription: flow(Create.setDescription, dispatch),
      setDefault: flow(Create.setDefault, dispatch),
      setConfig: flow(DataTypesSchema.setConfig, dispatch),
      remove: flow(DataTypesSchema.remove, dispatch),
    }),
    [],
  );

  return (
    <FormColumns>
      <DataTypeForm selector={selectors.form} dispatch={dispatcher} />
      <DataTypeJsonEditor
        value$={selectors.jsonEditorSelector}
        onChange={flow(
          (v) => JSON.parse(v ?? ""),
          jsonSchemaToDataTypeSchema,
          Create.resetSchema,
          dispatch,
        )}
        jsonSchema={{
          $schema: "http://json-schema.org/draft-07/schema#",
          title: "Core schema meta-schema",
          definitions: {
            schemaArray: {
              type: "array",
              minItems: 1,
              items: { $ref: "#" },
            },
            nonNegativeInteger: {
              type: "integer",
              minimum: 0,
            },
            nonNegativeIntegerDefault0: {
              allOf: [
                { $ref: "#/definitions/nonNegativeInteger" },
                { default: 0 },
              ],
            },
            simpleTypes: {
              enum: [
                "array",
                "boolean",
                "integer",
                "null",
                "number",
                "object",
                "string",
              ],
            },
            stringArray: {
              type: "array",
              items: { type: "string" },
              uniqueItems: true,
              default: [],
            },
          },
          type: ["object", "boolean"],
          properties: {
            $id: {
              type: "string",
              // format: "uri-reference",
            },
            $schema: {
              type: "string",
              // format: "uri",
            },
            $ref: {
              type: "string",
              // format: "uri-reference",
            },
            $comment: {
              type: "string",
            },
            title: {
              type: "string",
            },
            description: {
              type: "string",
            },
            default: true,
            readOnly: {
              type: "boolean",
              default: false,
            },
            writeOnly: {
              type: "boolean",
              default: false,
            },
            examples: {
              type: "array",
              items: true,
            },
            multipleOf: {
              type: "number",
              exclusiveMinimum: 0,
            },
            maximum: {
              type: "number",
            },
            exclusiveMaximum: {
              type: "number",
            },
            minimum: {
              type: "number",
            },
            exclusiveMinimum: {
              type: "number",
            },
            maxLength: { $ref: "#/definitions/nonNegativeInteger" },
            minLength: { $ref: "#/definitions/nonNegativeIntegerDefault0" },
            pattern: {
              type: "string",
              // format: "regex",
            },
            additionalItems: { $ref: "#" },
            items: {
              anyOf: [{ $ref: "#" }, { $ref: "#/definitions/schemaArray" }],
              default: true,
            },
            maxItems: { $ref: "#/definitions/nonNegativeInteger" },
            minItems: { $ref: "#/definitions/nonNegativeIntegerDefault0" },
            uniqueItems: {
              type: "boolean",
              default: false,
            },
            contains: { $ref: "#" },
            maxProperties: { $ref: "#/definitions/nonNegativeInteger" },
            minProperties: { $ref: "#/definitions/nonNegativeIntegerDefault0" },
            required: { $ref: "#/definitions/stringArray" },
            additionalProperties: { $ref: "#" },
            definitions: {
              type: "object",
              additionalProperties: { $ref: "#" },
              default: {},
            },
            properties: {
              type: "object",
              additionalProperties: { $ref: "#" },
              default: {},
            },
            patternProperties: {
              type: "object",
              additionalProperties: { $ref: "#" },
              // propertyNames: {
              //   format: "regex",
              // },
              default: {},
            },
            dependencies: {
              type: "object",
              additionalProperties: {
                anyOf: [{ $ref: "#" }, { $ref: "#/definitions/stringArray" }],
              },
            },
            propertyNames: { $ref: "#" },
            const: true,
            enum: {
              type: "array",
              items: true,
              minItems: 1,
              uniqueItems: true,
            },
            type: {
              anyOf: [
                { $ref: "#/definitions/simpleTypes" },
                {
                  type: "array",
                  items: { $ref: "#/definitions/simpleTypes" },
                  minItems: 1,
                  uniqueItems: true,
                },
              ],
            },
            format: { type: "string" },
            contentMediaType: { type: "string" },
            contentEncoding: { type: "string" },
            if: { $ref: "#" },
            then: { $ref: "#" },
            else: { $ref: "#" },
            allOf: { $ref: "#/definitions/schemaArray" },
            anyOf: { $ref: "#/definitions/schemaArray" },
            oneOf: { $ref: "#/definitions/schemaArray" },
            not: { $ref: "#" },
          },
          default: true,
        }}
      />
    </FormColumns>
  );
}
