import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import { GetPickingOrdersVars } from "ds/PickingOrder";
import { silentUnreachableError } from "utils/exceptions";
import { isT, mPipe } from "fp-utilities";
import { fromDate } from "types/src/date/ISODate";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import * as State from "./types/State";

const dateRangeToISOWhere = (v: [Date | undefined, Date | undefined]) => ({
  lte: mPipe(fromDate)(v[0]),
  gte: mPipe(fromDate)(v[1]),
});

export const getFetchVars = <P extends string>(p: P) => {
  const isLoading = State.isLoading(p);
  const isFetching = State.isFetching(p);

  return (s: State.Loading<P> | State.Fetching<P>): GetPickingOrdersVars => {
    const filters = s.payload.filters;
    const where: GetPickingOrdersVars["where"] = {
      createdAt: Fp.pipe(
        filters.createdAt,
        O.map(dateRangeToISOWhere),
        O.toUndefined,
      ),
      updatedAt: Fp.pipe(
        filters.updatedAt,
        O.map(dateRangeToISOWhere),
        O.toUndefined,
      ),
      id: Fp.pipe(
        filters.id,
        O.map((v) => ({ eq: v as unknown as PickingOrderId })),
        O.toUndefined,
      ),
      and: [
        {
          dataTypeId: {
            eq: s.payload.id,
          },
        },
        Fp.pipe(
          filters.search,
          O.map((v): GetPickingOrdersVars["where"] => ({
            or: [
              { data: { contains: [v] } },
              {
                hasItemsWith: [
                  {
                    sku: {
                      contains: v,
                    },
                    data: { contains: [v] },
                  },
                ],
              },
            ],
          })),
          O.toUndefined,
        ),
        Fp.pipe(
          filters.status,
          O.some,
          O.filter((v) => v !== "all"),
          O.map((v) => ({ hasItems: v === "withItems" })),
          O.toUndefined,
        ),
      ].filter(isT),
    };

    if (isLoading(s)) {
      return {
        first: s.payload.perPage,
        orderBy: O.toUndefined(s.payload.order),
        where,
      };
    }

    if (isFetching(s)) {
      switch (s.payload.page) {
        case "start":
          return {
            first: s.payload.perPage,
            orderBy: O.toUndefined(s.payload.order),
            where,
          };
        case "prev":
          return {
            last: s.payload.perPage,
            before: s.payload.pageInfo.prevCursor,
            orderBy: O.toUndefined(s.payload.order),
            where,
          };
        case "next":
          return {
            first: s.payload.perPage,
            after: s.payload.pageInfo.nextCursor,
            orderBy: O.toUndefined(s.payload.order),
            where,
          };
        case "end":
          return {
            last: s.payload.perPage,
            orderBy: O.toUndefined(s.payload.order),
            where,
          };
        case "current":
          return {
            first: s.payload.perPage,
            orderBy: O.toUndefined(s.payload.order),
            where,
          };
      }
    }

    silentUnreachableError(s);
    throw new Error("Unreachable");
  };
};
