import * as Rx from "rxjs";
import { Client, DsError, notFoundError } from "ds";
import { isOneOf } from "utils/isOneOf";
import { distinctUntilKeyChanged, switchMap } from "rxjs";
import { getCustomers } from "ds/Customers";
import * as E from "fp-ts/Either";
import { getDataTypes } from "ds/DataTypes";
import { flow } from "fp-ts/function";
import { getPickingOrder, updatePickingOrder } from "ds/PickingOrder";
import { isNoEmptyArr } from "types/src/NoEmptyArr";
import * as Obj from "utils/object";
import {
  createPickingOrderItem,
  updatePickingOrderItem,
} from "ds/PickingOrderItem";
import {
  PickingOrderItemId,
  PickingOrderItemUpdate,
} from "types/src/PickingOrder/PickingOrderItem";
import { difference } from "fp-ts/Array";
import { isT } from "fp-utilities";
import { DataTypeEntity } from "types/src/DataType/DataType";
import { Epic } from "../../../../../../../../types/RootEpic";
import { isNewItemId } from "../../../../../../../../generic-states/PickingOrderItems/types/NewItemId";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import {
  createCustomerSearchState,
  createSchemaFieldsState,
  createPickingOrderItemsState,
} from "./utils";

export const epic = <P extends string>(
  p: P,
): Epic<
  Actions.Actions<P>,
  State.State<P>,
  { pyckAdminClient$: Rx.Observable<Client> }
> => {
  const pickingOrderItemsState = createPickingOrderItemsState(p);
  const isLoading = State.isLoading(p);
  const isReady = State.isReady(p);
  const isSaving = State.isSaving(p);
  const schemaFieldsState = createSchemaFieldsState(p);
  const customerSearchState = createCustomerSearchState(p);
  const loadSuccess = Actions.loadSuccess(p);
  const loadFail = Actions.loadFail(p);
  const saveFail = Actions.saveFail(p);
  const saveSuccess = Actions.saveSuccess(p);

  return (state$, { pyckAdminClient$ }) => {
    const pickingOrderItems$ = pickingOrderItemsState.epic(
      state$.pipe(
        Rx.filter(isOneOf([isReady, isSaving])),
        Rx.map((s) => s.payload.items),
      ),
      {},
    );

    const loading$ = pyckAdminClient$.pipe(
      Rx.switchMap((client) =>
        state$.pipe(
          distinctUntilKeyChanged("type"),
          Rx.filter(isLoading),
          Rx.switchMap((s) => {
            return Rx.forkJoin({
              dataTypes: getDataTypes(client, {
                where: { entity: [DataTypeEntity.Order] },
              }),
              order: getPickingOrder(client, s.payload.id),
            }).pipe(
              Rx.map(
                flow((v) => {
                  if (E.isLeft(v.order)) return v.order;
                  if (E.isLeft(v.dataTypes)) return v.dataTypes;

                  const order = v.order.right;
                  const dataTypes = v.dataTypes.right.items.map((i) => ({
                    title: i.name,
                    id: i.id,
                    schema: i.schema,
                    isDefault: i.default,
                  }));
                  const dataType = dataTypes.find(
                    (i) => i.id === order.dataTypeId,
                  );

                  if (!dataType || !isNoEmptyArr(dataTypes))
                    return E.left(notFoundError());

                  return E.right(loadSuccess({ dataType, dataTypes, order }));
                }, E.getOrElse<DsError, Actions.Actions<P>>(loadFail)),
              ),
            );
          }),
        ),
      ),
    );

    const save$ = pyckAdminClient$.pipe(
      Rx.switchMap((client) =>
        state$.pipe(
          distinctUntilKeyChanged("type"),
          Rx.filter(isSaving),
          Rx.switchMap((s) => {
            const currentItems = s.payload._initial.items.map(
              (v): PickingOrderItemUpdate => ({
                id: v.id,
                sku: v.sku,
                fields: v.fields,
                quantity: v.quantity,
                dataTypeId: v.dataTypeId,
              }),
            );
            const currentItemsIds = currentItems.map((v) => v.id);

            const toAdd = Obj.entries(
              Obj.filter(
                (_, k) => isNewItemId(k),
                s.payload.items.payload.items,
              ),
            ).map(([id, v]) => {
              const state =
                pickingOrderItemsState.createPickingOrderItemState(id);
              return createPickingOrderItem(client, {
                dataTypeId: v.payload.dataTypeId,
                sku: v.payload.sku.value,
                quantity: v.payload.quantity.value,
                fields: state.schemaFieldsState.extractFieldsFromSchema(
                  v.payload.fields.payload.values,
                ),
                orderId: s.payload.id,
              });
            });
            const toUpdate = Obj.entries(
              Obj.filter(
                (_, k) => !isNewItemId(k),
                s.payload.items.payload.items,
              ),
            )
              .map(([id, v]): PickingOrderItemUpdate => {
                const state =
                  pickingOrderItemsState.createPickingOrderItemState(id);
                return {
                  id: id as PickingOrderItemId,
                  dataTypeId: v.payload.dataTypeId,
                  fields: state.schemaFieldsState.extractFieldsFromSchema(
                    v.payload.fields.payload.values,
                  ),
                  quantity: v.payload.quantity.value,
                  sku: v.payload.sku.value,
                };
              })
              .filter((v) => {
                const current = currentItems.find((a) => a.id === v.id);

                return !current || !Obj.isDeepEqual(v, current);
              })
              .map((v) => {
                return updatePickingOrderItem(client, v);
              });

            const removedItems = difference<PickingOrderItemId>({
              equals: (a, b) => a === b,
            })(
              currentItemsIds,
              Obj.keys(s.payload.items.payload.items) as PickingOrderItemId[],
            );

            return Rx.forkJoin({
              toAdd: Rx.from(Promise.all(toAdd)),
              toUpdate: Rx.from(Promise.all(toUpdate)),
            }).pipe(
              Rx.mergeMap((v) => {
                const addedItems = v.toAdd
                  .map(
                    flow(
                      E.map((v) => v.id),
                      E.getOrElseW(() => undefined),
                    ),
                  )
                  .filter(isT);

                return Rx.from(
                  updatePickingOrder(client, {
                    addedItems,
                    removedItems,
                    dataTypeId: s.payload._initial.dataTypeId,
                    id: s.payload._initial.id,
                    fields: schemaFieldsState.extractFieldsFromSchema(
                      s.payload.fields.payload.values,
                    ),
                  }),
                ).pipe(
                  Rx.map(
                    flow(
                      E.map(saveSuccess),
                      E.getOrElseW<DsError, Actions.Actions<P>>(saveFail),
                    ),
                  ),
                );
              }),
            );
          }),
        ),
      ),
    );

    const fieldsSchema$ = schemaFieldsState.epic(
      state$.pipe(
        Rx.filter(isOneOf([isReady, isSaving])),
        Rx.map((s) => s.payload.fields),
      ),
      pyckAdminClient$,
    );

    const customerSearch$ = pyckAdminClient$.pipe(
      switchMap((client) =>
        customerSearchState.epic(
          state$.pipe(
            Rx.filter(isOneOf([isReady, isSaving])),
            Rx.map((s) => s.payload.customer),
          ),
          {
            get: (q) => {
              return getCustomers(client, {
                where: { search: q },
              }).then(
                E.map((r) => r.items.map((v) => ({ title: v.id, id: v.id }))),
              );
            },
          },
        ),
      ),
    );

    return Rx.merge(
      loading$,
      save$,
      fieldsSchema$,
      customerSearch$,
      pickingOrderItems$,
    );
  };
};
