import { ButtonHTMLAttributes, forwardRef, ReactNode, Ref } from "react";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { OverflowButton } from "@zendeskgarden/react-tables";
import * as Dropdown from "@zendeskgarden/react-dropdowns";
import { TranslatedStr } from "types/src/TranslatedStr";
import { Cell, HeaderCell } from "@components/Table";

export interface ActionsCellAction {
  label: TranslatedStr;
  onClick: () => void;
}

export interface ActionsCellProps {
  className?: string;
  actions: ActionsCellAction[];
  label?: TranslatedStr;
}

export const ActionsCell = forwardRef<HTMLTableCellElement, ActionsCellProps>(
  (props, ref) => {
    return (
      <Cell ref={ref} className={props.className} hasOverflow>
        <OverflowMenu label={props.label}>
          {props.actions.map((a, i) => {
            return (
              <Dropdown.Item key={i} onClick={a.onClick} value={a.label}>
                {a.label}
              </Dropdown.Item>
            );
          })}
        </OverflowMenu>
      </Cell>
    );
  },
);

export const ActionsHeaderCell = forwardRef<
  HTMLTableCellElement,
  ActionsCellProps
>((props, ref) => {
  return (
    <HeaderCell ref={ref} className={props.className} hasOverflow>
      <OverflowMenu label={props.label}>
        {props.actions.map((a, i) => {
          return (
            <Dropdown.Item key={i} onClick={a.onClick} value={a.label}>
              {a.label}
            </Dropdown.Item>
          );
        })}
      </OverflowMenu>
    </HeaderCell>
  );
});

const TooltipOverflowButton = forwardRef(
  (
    props: ButtonHTMLAttributes<HTMLButtonElement>,
    ref: Ref<HTMLButtonElement>,
  ) =>
    props["aria-label"] ? (
      <Tooltip content={props["aria-label"]} placement="start">
        <OverflowButton ref={ref} {...props} />
      </Tooltip>
    ) : (
      <OverflowButton ref={ref} {...props} />
    ),
);

interface OverflowMenuProps {
  children: ReactNode;
  label?: TranslatedStr;
}
const OverflowMenu = ({ children, label }: OverflowMenuProps) => (
  <Dropdown.Dropdown>
    <Dropdown.Trigger>
      <TooltipOverflowButton aria-label={label} />
    </Dropdown.Trigger>
    <Dropdown.Menu
      placement="bottom-end"
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "viewport",
        },
        flip: {
          enabled: false,
        },
        offset: {
          fn: (data) => {
            /**
             * Ensure correct placement relative to trigger
             **/
            data.offsets.popper.top -= 2;

            return data;
          },
        },
      }}
    >
      {children}
    </Dropdown.Menu>
  </Dropdown.Dropdown>
);
