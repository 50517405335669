import { FiltersWrapper } from "ui/layouts/Listing/FiltersWrapper";
import { SearchInput } from "@Containers/Form/SearchInput";
import { RootState, useSelector } from "state-manager";
import { Dispatch, ReactElement } from "react";
import { flow } from "fp-ts/function";
import { FiltersButton } from "ui/layouts/Filters/FiltersButton";
import * as Listing from "state-manager/states/Ready/states/DataManager/states/Customers/states/Listing";
import * as O from "fp-ts/Option";
import { AdvancedFilters } from "./AdvancedFilters";
import { AdvancedFiltersForm } from "./AdvancedFiltersForm";

export interface FiltersProps {
  selector$: (s: RootState) => Listing.Ready | Listing.Fetching;
  dispatch: Dispatch<Listing.Actions>;
}

export function ListingHeader(p: FiltersProps): ReactElement {
  const search$ = flow(
    p.selector$,
    (s) => s.payload.filters.search,
    O.getOrElseW(() => ""),
  );
  const isOpen$ = flow(
    p.selector$,
    (s) => s.payload.advancedFiltersState === "open",
  );
  const filters$ = flow(p.selector$, (s) => s.payload.filters);

  return (
    <FiltersWrapper>
      <SearchInput
        value$={search$}
        onChange={flow(Listing.setSearchFilter, p.dispatch)}
      />
      <FiltersTrigger
        isSelected$={flow(
          p.selector$,
          (s) => !Listing.isFilterEmpty(s.payload.filters),
        )}
        onClear={flow(Listing.clearFilters, p.dispatch)}
        onClick={flow(Listing.openAdvancedFilters, p.dispatch)}
      />
      <AdvancedFilters
        open$={isOpen$}
        onClose={flow(Listing.closeAdvancedFilters, p.dispatch)}
        onClear={flow(Listing.clearFilters, p.dispatch)}
        onApply={flow(Listing.submitFilters, p.dispatch)}
      >
        <AdvancedFiltersForm dispatch={p.dispatch} selector$={filters$} />
      </AdvancedFilters>
    </FiltersWrapper>
  );
}

interface FiltersTriggerProps {
  isSelected$: (s: RootState) => boolean;
  onClear: () => void;
  onClick: () => void;
}
function FiltersTrigger(p: FiltersTriggerProps): ReactElement {
  const isSelected = useSelector(p.isSelected$);

  return (
    <FiltersButton
      isSelected={isSelected}
      onClick={p.onClick}
      onClear={p.onClear}
    />
  );
}
