import { Selector, useSelector } from "state-manager";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/Repositories/states/Edit";
import { flow, pipe } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import { NameInput } from "@Containers/Form/NameInput";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { RepositoryTypeSelect } from "@Containers/Form/predefined/RepositoryTypeSelect";
import { Toggle } from "@Containers/Form/Toggle";
import { useTranslation } from "i18n";
import { Field } from "ui/components/Field";
import * as O from "fp-ts/Option";
import { SearchCombobox } from "@Containers/Form/SearchCombobox";
import { TranslatedStr } from "types/src/TranslatedStr";
import { SchemaFieldsJsonEditor } from "@Containers/SchemaFieldsJsonEditor";

export interface ContentProps {
  selector: Selector<Edit.State>;
  dispatch: (a: Edit.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const { t } = useTranslation();
  const r = useSelector(
    flow(selector, (s) => {
      switch (s.type) {
        case "Ready:DataManager:Repositories:Edit:Loading":
        case "Ready:DataManager:Repositories:Edit:LoadError":
          return {
            type: "loading",
          } as const;
        case "Ready:DataManager:Repositories:Edit:Ready":
        case "Ready:DataManager:Repositories:Submitted":
        case "Ready:DataManager:Repositories:Edit:Saving": {
          const schemaSelector = flow(
            selector,
            (s) => s as Edit.Ready,
            (s) => s.payload.schema.payload,
          );
          const nameSelector = flow(
            selector,
            (s) => s as Edit.Ready,
            (s) => s.payload.name,
          );
          const typeSelector = flow(
            selector,
            (s) => s as Edit.Ready,
            (s) => s.payload.type,
          );
          const isVirtual$ = flow(
            selector,
            (s) => s as Edit.Ready,
            (s) => s.payload.isVirtual,
          );
          const parentSelector = flow(
            selector,
            (s) => s as Edit.Ready,
            (s) => s.payload.parent,
            (s) => ({
              validation: Edit.parentSearchState.states.itemSearchError.is(s)
                ? ("error" as const)
                : undefined,
              isLoading: Edit.parentSearchState.states.searching.is(s),
              value: pipe(
                s,
                O.fromPredicate(Edit.parentSearchState.states.selected.is),
                O.map((v) => v.payload.item),
              ),
              options: s.payload.items.map((i) => ({
                value: i,
                label: i?.name as TranslatedStr,
              })),
            }),
          );

          return {
            type: "ready",
            schemaSelector,
            nameSelector,
            typeSelector,
            isVirtual$,
            parentSelector,
          } as const;
        }

        default: {
          silentUnreachableError(s);
          return {
            type: "loading",
          } as const;
        }
      }
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <FormWrapper>
          <NameInput
            value$={r.nameSelector}
            onChange={(v) => dispatch(Edit.setName(v))}
          />
          <RepositoryTypeSelect
            value$={r.typeSelector}
            onChange={(v) => dispatch(Edit.setType(v))}
          />
          <SearchCombobox
            label={t("Parent")}
            selector={r.parentSelector}
            getId={(v) => v?.id ?? "unknown"}
            onChange={flow(
              O.toUndefined,
              Edit.parentSearchState.actions.selectItem.create,
              dispatch,
            )}
            onSearch={flow(
              Edit.parentSearchState.actions.setQuery.create,
              dispatch,
            )}
            clearable
          />
          <Field>
            <Toggle disabled value$={r.isVirtual$}>
              {t("Is virtual")}
            </Toggle>
          </Field>
          <SchemaFieldsJsonEditor
            value$={r.schemaSelector}
            onChange={flow(
              Edit.schemaFieldsState.actions.onChange.create,
              dispatch,
            )}
          />
        </FormWrapper>
      );
  }
}
