import { Cursor } from "types";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { isT } from "fp-utilities";
import { ISODate } from "types/src/date/ISODate";
import { StockId } from "types/src/Stocks/Stock";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { RepositoryId } from "types/src/Repositories/Repository";
import { UniqId } from "types/src/UniqId";
import { omitEmpties } from "utils/value";
import {
  GetStocksQueryVariables,
  StockOrderField,
  StockWhereInput,
} from "../generated/graphql";
import { toApiOrderDirection } from "../transformers/OrderDirection";
import {
  GetInventoryItemsWhere,
  getInventoryItemsWhereToApi,
} from "./InvetoryItems";
import { RepositoryWhere, repositoryWhereToApiWhere } from "./Repositories";
import { WhereEq, WhereOrd } from "./Where";

export interface GetStocksVarsWhere {
  and?: GetStocksVarsWhere[];
  createdAt?: WhereOrd<ISODate>;
  createdBy?: WhereEq<UniqId>;
  hasItem?: boolean;
  hasItemWith?: GetInventoryItemsWhere[];
  hasRepository?: boolean;
  hasRepositoryWith?: RepositoryWhere[];
  id?: WhereEq<StockId>;
  incomingStock?: WhereOrd<number>;
  itemId?: WhereEq<InventoryItemId>;
  movementId?: WhereEq<InventoryItemId>;
  not?: GetStocksVarsWhere;
  or?: GetStocksVarsWhere[];
  outgoingStock?: WhereOrd<number>;
  quantity?: WhereOrd<number>;
  repositoryId?: WhereEq<RepositoryId>;
  tenantId?: WhereEq<UniqId>;
  updatedAt?: WhereOrd<ISODate>;
  updatedBy?: WhereOrd<UniqId>;
  time?: ISODate;
}

export function getStocksVarsWhereToApiInput(
  vars: GetStocksVarsWhere,
): StockWhereInput | undefined {
  return omitEmpties({
    and: omitEmpties(vars.and?.map(getStocksVarsWhereToApiInput))?.filter(isT),
    createdAt: vars.createdAt?.eq,
    createdAtGT: vars.createdAt?.gt,
    createdAtGTE: vars.createdAt?.gte,
    createdAtIn: vars.createdAt?.in,
    createdAtLT: vars.createdAt?.lt,
    createdAtLTE: vars.createdAt?.lte,
    createdAtNEQ: vars.createdAt?.neq,
    createdAtNotIn: vars.createdAt?.notIn,
    createdBy: vars.createdBy?.eq,
    createdByIn: vars.createdBy?.in,
    createdByNEQ: vars.createdBy?.neq,
    createdByNotIn: vars.createdBy?.notIn,
    hasItem: vars.hasItem,
    hasItemWith: omitEmpties(
      vars.hasItemWith?.map(getInventoryItemsWhereToApi),
    )?.filter(isT),
    hasRepository: vars.hasRepository,
    hasRepositoryWith: omitEmpties(
      vars.hasRepositoryWith?.map(repositoryWhereToApiWhere),
    )?.filter(isT),
    id: vars.id?.eq,
    idIn: vars.id?.in,
    idNEQ: vars.id?.neq,
    idNotIn: vars.id?.notIn,
    incomingStock: vars.incomingStock?.eq,
    incomingStockGT: vars.incomingStock?.gt,
    incomingStockGTE: vars.incomingStock?.gte,
    incomingStockIn: vars.incomingStock?.in,
    incomingStockLT: vars.incomingStock?.lt,
    incomingStockLTE: vars.incomingStock?.lte,
    incomingStockNEQ: vars.incomingStock?.neq,
    incomingStockNotIn: vars.incomingStock?.notIn,
    itemID: vars.itemId?.eq,
    itemIDIn: vars.itemId?.in,
    itemIDNEQ: vars.itemId?.neq,
    itemIDNotIn: vars.itemId?.notIn,
    movementID: vars.movementId?.eq,
    movementIDIn: vars.movementId?.in,
    movementIDNEQ: vars.movementId?.neq,
    movementIDNotIn: vars.movementId?.notIn,
    not: pipe(
      vars.not,
      O.fromNullable,
      O.map(getStocksVarsWhereToApiInput),
      O.toUndefined,
    ),
    or: omitEmpties(vars.or?.map(getStocksVarsWhereToApiInput))?.filter(isT),
    outgoingStock: vars.outgoingStock?.eq,
    outgoingStockGT: vars.outgoingStock?.gt,
    outgoingStockGTE: vars.outgoingStock?.gte,
    outgoingStockIn: vars.outgoingStock?.in,
    outgoingStockLT: vars.outgoingStock?.lt,
    outgoingStockLTE: vars.outgoingStock?.lte,
    outgoingStockNEQ: vars.outgoingStock?.neq,
    outgoingStockNotIn: vars.outgoingStock?.notIn,
    quantity: vars.quantity?.eq,
    quantityGT: vars.quantity?.gt,
    quantityGTE: vars.quantity?.gte,
    quantityIn: vars.quantity?.in,
    quantityLT: vars.quantity?.lt,
    quantityLTE: vars.quantity?.lte,
    quantityNEQ: vars.quantity?.neq,
    quantityNotIn: vars.quantity?.notIn,
    repositoryID: vars.repositoryId?.eq,
    repositoryIDIn: vars.repositoryId?.in,
    repositoryIDNEQ: vars.repositoryId?.neq,
    repositoryIDNotIn: vars.repositoryId?.notIn,
    tenantID: vars.tenantId?.eq,
    tenantIDIn: vars.tenantId?.in,
    tenantIDNEQ: vars.tenantId?.neq,
    tenantIDNotIn: vars.tenantId?.notIn,
    updatedAt: vars.updatedAt?.eq,
    updatedAtGT: vars.updatedAt?.gt,
    updatedAtGTE: vars.updatedAt?.gte,
    updatedAtIn: vars.updatedAt?.in,
    updatedAtLT: vars.updatedAt?.lt,
    updatedAtLTE: vars.updatedAt?.lte,
    updatedAtNEQ: vars.updatedAt?.neq,
    updatedAtNotIn: vars.updatedAt?.notIn,
    updatedBy: vars.updatedBy?.eq,
    updatedByIn: vars.updatedBy?.in,
    updatedByNEQ: vars.updatedBy?.neq,
    updatedByNotIn: vars.updatedBy?.notIn,
    time: vars.time,
  });
}

export interface GetStocksVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: GetStocksVarsWhere;
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
}

export function getStocksVarsToApiVars(
  vars: GetStocksVars,
): GetStocksQueryVariables {
  return (
    omitEmpties({
      first: vars.first,
      last: vars.last,
      after: vars.after,
      before: vars.before,
      where: omitEmpties(
        pipe(
          vars.where,
          O.fromNullable,
          O.map(getStocksVarsWhereToApiInput),
          O.toNullable,
        ),
      ),
      orderBy: pipe(
        vars.orderBy,
        O.fromNullable,
        O.map((v) => ({
          field: pipe(
            v.by,
            (v) =>
              ({
                createdAt: StockOrderField.CreatedAt,
                updatedAt: StockOrderField.UpdatedAt,
              })[v],
          ),
          direction: pipe(v.direction, toApiOrderDirection),
        })),
        O.toUndefined,
      ),
    }) ?? {}
  );
}
