import { gql } from "@apollo/client";

export const repositoryFragment = gql`
  fragment RepositoryFragment on Repository {
    createdAt
    data
    dataTypeID
    deletedAt
    id
    name
    parentID
    type
    updatedAt
    virtualRepo
  }
`;
