import { ReactElement, useCallback, useMemo } from "react";
import * as Listing from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Listing";
import { RootState } from "state-manager";
import { flow } from "fp-ts/function";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import { ExecuteConfirmation } from "@Containers/Ready/DataManager/RepositoryMovements/Listing/components/ExecuteConfirmation";
import { ListingHeader } from "./ListingHeader";
import { DeleteConfirmation } from "./DeleteConfirmation";
import { Table, TableProps } from "./Table";

export interface AllProps {
  selector$: (s: RootState) => Listing.Ready | Listing.Fetching;
  dispatch: (a: Listing.Actions) => void;
}

export function All({ selector$, dispatch }: AllProps): ReactElement {
  const items$: TableProps["items$"] = useMemo(
    () => flow(selector$, (s) => s.payload.items),
    [selector$],
  );

  const onSelect = useCallback(flow(Listing.select, dispatch), [dispatch]);
  const onSelectAll = useCallback(flow(Listing.selectAll, dispatch), [
    dispatch,
  ]);

  const onDelete = useCallback(flow(Listing.removeItem, dispatch), [dispatch]);
  const onDeleteAll = useCallback(flow(Listing.removeBulk, dispatch), [
    dispatch,
  ]);

  const onExecute = useCallback(flow(Listing.executeItem, dispatch), [
    dispatch,
  ]);
  const onExecuteAll = useCallback(flow(Listing.executeBulk, dispatch), [
    dispatch,
  ]);

  return (
    <>
      <ListingWrapper
        header={<ListingHeader selector$={selector$} dispatch={dispatch} />}
      >
        <Table
          items$={items$}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          onDeleteAll={onDeleteAll}
          onDelete={onDelete}
          onExecute={onExecute}
          onExecuteAll={onExecuteAll}
        />
      </ListingWrapper>
      <DeleteConfirmation selector$={selector$} dispatch={dispatch} />
      <ExecuteConfirmation selector$={selector$} dispatch={dispatch} />
    </>
  );
}
