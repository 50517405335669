import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
body {
  padding: 0;
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.system};
}

a {
  color: ${(p) => p.theme.palette.blue[600]};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
}
`;
