import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import * as Fp from "fp-ts/function";
import { match } from "fp-utilities";
import { State } from "./types/State";
import { Actions } from "./types/Actions";
import {
  createPickingOrderState,
  editPickingOrderState,
  listingAllPickingOrderState,
  listingPickingOrderState,
} from "./utils";

export function reducer(s: State, a: Actions): State {
  if (createPickingOrderState.isActions(a)) {
    if (createPickingOrderState.isState(s))
      return Fp.pipe(
        createPickingOrderState.reducer(s, a),
        E.getOrElseW(
          match([
            createPickingOrderState.exits.list.is,
            (v) => listingPickingOrderState.init(v.payload.dataTypeId),
          ]),
        ),
      );
    return s;
  }

  if (listingAllPickingOrderState.isActions(a)) {
    if (listingAllPickingOrderState.isState(s))
      return Fp.pipe(
        listingAllPickingOrderState.reducer(s, a),
        E.getOrElseW(
          match([
            listingAllPickingOrderState.exits.edit.is,
            (v) => editPickingOrderState.init({ id: v.payload }),
          ]),
        ),
      );
    return s;
  }

  if (listingPickingOrderState.isActions(a)) {
    if (listingPickingOrderState.isState(s))
      return Fp.pipe(
        listingPickingOrderState.reducer(s, a),
        E.getOrElseW(
          match(
            [
              listingPickingOrderState.exists.create.is,
              (v) => createPickingOrderState.init({ dataTypeId: v.payload }),
            ],
            [
              listingPickingOrderState.exists.edit.is,
              (v) => editPickingOrderState.init({ id: v.payload }),
            ],
          ),
        ),
      );
    return s;
  }

  if (editPickingOrderState.isActions(a)) {
    if (editPickingOrderState.isState(s))
      return Fp.pipe(
        editPickingOrderState.reducer(s, a),
        E.getOrElseW(
          match([
            editPickingOrderState.exits.list.is,
            (v) => listingPickingOrderState.init(v.payload.dataTypeId),
          ]),
        ),
      );
    return s;
  }

  silentUnreachableError(a);
  return s;
}
