import { Selector, useSelector } from "state-manager";
import {
  Combobox as ComboboxComponent,
  Field,
  Option,
} from "ui/components/Combobox";
import { TranslatedStr } from "types/src/TranslatedStr";
import { shallowEqualArrays } from "shallow-equal";

export interface MultiComboboxProps<T extends string> {
  value$: Selector<T[]>;
  onChange: (value: T[]) => void;
  options: { value: T; label: TranslatedStr }[];
  placeholder?: TranslatedStr;
}

export function MultiCombobox<T extends string>({
  value$,
  onChange,
  options,
  placeholder,
}: MultiComboboxProps<T>) {
  const values = useSelector(value$, shallowEqualArrays);
  return (
    <Field>
      <ComboboxComponent
        isAutocomplete
        isMultiselectable
        isEditable={false}
        maxHeight="auto"
        placeholder={!values.length ? placeholder : undefined}
        onChange={({ selectionValue }) => {
          if (selectionValue !== undefined) {
            onChange(selectionValue as T[]);
          }
        }}
      >
        {options.map(({ value: v, label }, i) => (
          <Option
            key={i}
            value={v}
            label={label}
            isSelected={values.includes(v)}
          />
        ))}
      </ComboboxComponent>
    </Field>
  );
}
