import { Checkbox } from "@zendeskgarden/react-forms";
import { forwardRef, ReactElement } from "react";
import { Cell } from "@components/Table";
import { Field } from "@components/Field";
import { Label } from "@components/Label";

export interface CheckCellProps {
  value: boolean;
  onChange: () => void;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
}

export const CheckCell = forwardRef<HTMLTableCellElement, CheckCellProps>(
  ({ onChange, value, readonly, disabled }, ref): ReactElement => {
    return (
      <Cell isMinimum ref={ref}>
        <Field>
          <Checkbox
            readOnly={readonly}
            disabled={disabled}
            checked={value}
            onChange={onChange}
          >
            <Label hidden>+</Label>
          </Checkbox>
        </Field>
      </Cell>
    );
  },
);
