import { Selector, useSelector } from "state-manager";
import { ReactElement } from "react";
import { JsonEditor } from "ui/components/JsonEditor";

export interface DataTypeJsonEditorProps {
  value$: Selector<string | undefined>;
  onChange: (value: string | undefined) => void;
  jsonSchema: Record<string, unknown>;
}

export function DataTypeJsonEditor(p: DataTypeJsonEditorProps): ReactElement {
  const value = useSelector(p.value$, (a, b) => a === b);
  return (
    <JsonEditor content={value} onChange={p.onChange} schema={p.jsonSchema} />
  );
}
