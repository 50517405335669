import { isInvalid, Value } from "types/src/FormValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";
import { Input } from "@Containers/Form/Input";
import { Selector, useSelector } from "state-manager";
import { Field } from "ui/components/Field";
import { Label } from "ui/components/Label";

export interface SkuInputProps {
  value$: Selector<Value<unknown, NoEmptyString, string | undefined>>;
  onChange: (value: string) => void;
}
export function SkuInput(p: SkuInputProps) {
  const { t } = useTranslation();
  const value$ = flow(p.value$, (v) => v.value);
  const isError = useSelector(flow(p.value$, isInvalid));

  return (
    <Field>
      <Label>{t("Sku")}</Label>
      <Input
        value$={value$}
        onChange={p.onChange}
        validation={isError ? "error" : undefined}
      />
    </Field>
  );
}
