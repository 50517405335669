import { PageInfo } from "types";
import { DataType } from "types/src/DataType/DataType";
import { silentUnreachableError } from "utils/exceptions";
import * as O from "fp-ts/Option";
import { strictGuard } from "utils/strictGuard";
import { Item } from "./Item";
import { Filters, FiltersMonoid } from "./Filters";

// region State
export type State = Loading | LoadError | Ready | Fetching;

export const isState = strictGuard((s: State): s is State => {
  const type = (s as State).type;
  switch (type) {
    case "Ready:DataManager:Suppliers:ListingAll:Loading":
    case "Ready:DataManager:Suppliers:ListingAll:LoadError":
    case "Ready:DataManager:Suppliers:ListingAll:Ready":
    case "Ready:DataManager:Suppliers:ListingAll:Fetching":
      return true;
    default:
      silentUnreachableError(type);
      return false;
  }
});
// endregion

// region Loading
export interface LoadingPayload {
  perPage: number;
  order: O.Option<{
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  }>;
  filters: Filters;
}

export interface Loading {
  type: "Ready:DataManager:Suppliers:ListingAll:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Ready:DataManager:Suppliers:ListingAll:Loading",
  payload,
});

export const isLoading = (s: State): s is Loading =>
  s.type === "Ready:DataManager:Suppliers:ListingAll:Loading";
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Ready:DataManager:Suppliers:ListingAll:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Ready:DataManager:Suppliers:ListingAll:LoadError",
  payload,
});

export const isLoadError = (s: State): s is LoadError =>
  s.type === "Ready:DataManager:Suppliers:ListingAll:LoadError";
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  pageInfo: PageInfo;
  total: number;
  items: Item[];
  dataTypes: DataType[];
  advancedFiltersState: "open" | "closed";
}

export interface Ready {
  type: "Ready:DataManager:Suppliers:ListingAll:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:DataManager:Suppliers:ListingAll:Ready",
  payload,
});

export const isReady = (s: State): s is Ready =>
  s.type === "Ready:DataManager:Suppliers:ListingAll:Ready";
// endregion

// region Fetching
export interface FetchingPayload extends ReadyPayload {
  page: "current" | "start" | "prev" | "next" | "end";
}

export interface Fetching {
  type: "Ready:DataManager:Suppliers:ListingAll:Fetching";
  payload: FetchingPayload;
}

export const fetching = (payload: Fetching["payload"]): Fetching => ({
  type: "Ready:DataManager:Suppliers:ListingAll:Fetching",
  payload,
});

export const isFetching = (s: State): s is Fetching =>
  s.type === "Ready:DataManager:Suppliers:ListingAll:Fetching";
// endregion

export const init = (): Loading =>
  loading({
    perPage: 50,
    order: O.none,
    filters: FiltersMonoid.empty,
  });
