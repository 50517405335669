import { TranslatedStr } from "types/src/TranslatedStr";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";

export type Actions =
  | AddNotification
  | AddNotificationWithDesc
  | RemoveNotification
  | ClearNotifications
  | Lock
  | Unlock;

export const isActions = strictGuard((a: Actions): a is Actions => {
  switch (a.type) {
    case "Notifications:AddNotification":
    case "Notifications:AddNotificationWithDesc":
    case "Notifications:RemoveNotification":
    case "Notifications:ClearNotifications":
    case "Notifications:Lock":
    case "Notifications:Unlock":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});

// region AddNotification
export interface AddNotification {
  type: "Notifications:AddNotification";
  payload: {
    type: "success" | "info" | "warning" | "error";
    message: TranslatedStr;
  };
}

export const addNotification = (
  payload: AddNotification["payload"],
): AddNotification => ({
  type: "Notifications:AddNotification",
  payload,
});
// endregion

// region AddNotificationWithDesc
export interface AddNotificationWithDesc {
  type: "Notifications:AddNotificationWithDesc";
  payload: {
    type: "success" | "info" | "warning" | "error";
    message: TranslatedStr;
    description:
      | {
          type: "Notifications:text";
          text: TranslatedStr;
        }
      | {
          type: "Notifications:code";
          code: string;
        };
  };
}

export const addNotificationWithDesc = (
  payload: AddNotificationWithDesc["payload"],
): AddNotificationWithDesc => ({
  type: "Notifications:AddNotificationWithDesc",
  payload,
});
// endregion

// region RemoveNotification
export interface RemoveNotification {
  type: "Notifications:RemoveNotification";
  payload: number;
}

export const removeNotification = (
  payload: RemoveNotification["payload"],
): RemoveNotification => ({
  type: "Notifications:RemoveNotification",
  payload,
});
// endregion

// region ClearNotifications
export interface ClearNotifications {
  type: "Notifications:ClearNotifications";
}

export const clearNotifications = (): ClearNotifications => ({
  type: "Notifications:ClearNotifications",
});
// endregion

// region Lock
export interface Lock {
  type: "Notifications:Lock";
  payload: number;
}

export const lock = (payload: Lock["payload"]): Lock => ({
  type: "Notifications:Lock",
  payload,
});
// endregion

// region Unlock
export interface Unlock {
  type: "Notifications:Unlock";
  payload: number;
}

export const unlock = (payload: Unlock["payload"]): Unlock => ({
  type: "Notifications:Unlock",
  payload,
});
// endregion
