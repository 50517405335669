export enum Type {
  valid = "valid",
  invalid = "invalid",
  initial = "initial",
  verifying = "verifying",
}

// region Valid
export type Valid<T> = {
  __typeName: Type.valid;
  value: T;
};

export type ExtractValid<V extends AsyncValue<any, any, any>> =
  V extends Valid<any> ? V : never;

export type ExtractValidType<V extends AsyncValue<any, any, any>> =
  V extends Valid<infer T> ? T : never;

export const isValid = <T>(v: AsyncValue<unknown, T, unknown>): v is Valid<T> =>
  v.__typeName === Type.valid;

export const valid = <T>(value: T): Valid<T> => ({
  __typeName: Type.valid,
  value,
});

export const getValid = <T>(item: Valid<T>): T => item.value;
// endregion

// region Invalid
export type Invalid<E, T> = {
  __typeName: Type.invalid;
  value: T;
  error: E;
};

export type ExtractInvalid<V extends AsyncValue<any, any, any>> =
  V extends Invalid<any, infer T> ? T : never;

export type InvalidError<V extends AsyncValue<any, any, any>> =
  V extends Invalid<infer T, any> ? T : never;

export const isInvalid = <E, T2>(
  v: AsyncValue<E, any, T2>,
): v is Invalid<E, T2> => v.__typeName === Type.invalid;

export function invalid<E>(error: E): <T>(value: T) => Invalid<E, T>;
export function invalid<E, T>(error: E): (value: T) => Invalid<E, T>;
export function invalid<E, T>(error: E, value: T): Invalid<E, T>;
export function invalid<E, T>(
  ...args: [E] | [E, T]
): ((value: T) => Invalid<E, T>) | Invalid<E, T> {
  return args.length === 1
    ? (value: T) => ({ __typeName: Type.invalid, value, error: args[0] })
    : { __typeName: Type.invalid, value: args[1], error: args[0] };
}
// endregion

// region Initial
export type Initial<I> = {
  __typeName: Type.initial;
  value: I;
};

export type ExtractInitial<V extends AsyncValue<any, any, any>> =
  V extends Initial<infer T> ? T : never;

export const isInitial = <I>(v: Value<any, any, I>): v is Initial<I> =>
  v.__typeName === Type.initial;

export const initial = <I>(value: I): Initial<I> => ({
  __typeName: Type.initial,
  value,
});
// endregion

// region Verifying
export type Verifying<I> = {
  __typeName: Type.verifying;
  value: I;
};

export type VerifyingType<V extends AsyncValue<any, any, any>> =
  V extends Verifying<infer T> ? T : never;

export const isVerifying = <I>(v: AsyncValue<any, any, I>): v is Verifying<I> =>
  v.__typeName === Type.verifying;

export const verifying = <I>(value: I): Verifying<I> => ({
  __typeName: Type.verifying,
  value,
});
// endregion

// region Value
export type Value<E, T, T2> = Invalid<E, T2> | Valid<T> | Initial<T2>;

export type SubmittedAsyncValue<V extends AsyncValue<any, any, any>> =
  | Invalid<InvalidError<V>, ExtractInvalid<V>>
  | Verifying<ExtractInvalid<V>>
  | Valid<ExtractValidType<V>>;

export type SubmittedValue<V extends AsyncValue<any, any, any>> =
  | Invalid<InvalidError<V>, ExtractInvalid<V>>
  | Valid<ExtractValidType<V>>;
// endregion

// region AsyncValue
export type AsyncValue<E, T extends T2, T2> = Value<E, T, T2> | Verifying<T2>;
// endregion

//region Error
export const getError = <E>(item: Value<E, unknown, unknown>): E | undefined =>
  isInvalid(item) ? item.error : undefined;
//endregion

export type ExtractStructInitial<T extends {}> = {
  [k in keyof T]: T[k] extends AsyncValue<any, any, any>
    ? Initial<ExtractInitial<T[k]>>
    : T[k];
};

export type ExtractStructValid<T extends {}> = {
  [k in keyof T]: T[k] extends AsyncValue<any, any, any>
    ? ExtractValid<T[k]>
    : T[k];
};

export type ExtractStructInvalid<T extends {}> = {
  [k in keyof T]: T[k] extends AsyncValue<any, any, any>
    ? Invalid<InvalidError<T[k]>, ExtractInvalid<T[k]>>
    : T[k];
};

export type ExtractStructSubmitted<T extends {}> = {
  [k in keyof T]: T[k] extends AsyncValue<any, any, any>
    ? SubmittedValue<T[k]>
    : T[k];
};

export function map<A, B>(f: (v: A) => B, v: Valid<A>): Valid<B> {
  return valid(f(v.value));
}

export function mapLeft<A, B>(f: (v: A) => B, v: Initial<A>): Initial<B>;
export function mapLeft<A, B, E>(
  f: (v: A) => B,
  v: Invalid<E, A>,
): Invalid<E, B>;
export function mapLeft<A, B, E>(
  f: (v: A) => B,
  v: Initial<A> | Invalid<E, A>,
): Initial<B> | Invalid<E, B> {
  switch (v.__typeName) {
    case Type.initial:
      return initial(f(v.value));
    case Type.invalid:
      return invalid(v.error, f(v.value));
  }
}
