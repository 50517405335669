import { forwardRef } from "react";
import { useTranslation } from "i18n";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import styled from "styled-components";
import { TypographySM } from "@components/Typography";
import { Cell, CellProps } from "@components/Table";

export interface DateCellProps extends Omit<CellProps, "children"> {
  value: O.Option<Date>;
  className?: string;
}

export const DateCell = forwardRef<HTMLTableCellElement, DateCellProps>(
  ({ value, ...props }, ref) => {
    const { language } = useTranslation();
    return (
      <Cell ref={ref} {...props}>
        {pipe(
          value,
          O.map((v) => new Intl.DateTimeFormat(language).format(v)),
          O.map((v) => <Wrapper>{v}</Wrapper>),
          O.toUndefined,
        )}
      </Cell>
    );
  },
);

const Wrapper = styled(TypographySM).attrs({ tag: "span", isBold: true })`
  padding: 3px 5px;
  background: ${(p) => p.theme.palette.grey[200]};
  border-radius: 4px;
`;
