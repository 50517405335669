import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import { PageInfo } from "types";
import {
  itemSearchState,
  repositorySearchState,
  stockSearchState,
} from "../utils";
import { Filters } from "./Filters";
import { Item } from "./Item";

export type State = Loading | Ready | LoadError | Fetching;

export const isState = strictGuard((s: State): s is State => {
  if (isLoading(s)) return true;
  if (isReady(s)) return true;
  if (isLoadError(s)) return true;
  if (isFetching(s)) return true;

  silentUnreachableError(s);
  return false;
});

export const isLoaded = (s: State): s is Exclude<State, Loading | LoadError> =>
  !isLoading(s) && !isLoadError(s);

export const init = (): Loading =>
  loading({
    perPage: 50,
    filters: {
      search: undefined,
      id: stockSearchState.init(),
      repository: repositorySearchState.init(),
      item: itemSearchState.init(),
      latest: false,
    },
  });

// region Loading
export interface LoadingPayload {
  perPage: number;
  filters: Filters;
}

export interface Loading {
  type: "Ready:DataManager:Stocks:Listing:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Ready:DataManager:Stocks:Listing:Loading",
  payload,
});

export const isLoading = (s: State): s is Loading =>
  s.type === "Ready:DataManager:Stocks:Listing:Loading";
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Ready:DataManager:Stocks:Listing:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Ready:DataManager:Stocks:Listing:LoadError",
  payload,
});

export const isLoadError = (s: State): s is LoadError =>
  s.type === "Ready:DataManager:Stocks:Listing:LoadError";
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  total: number;
  pageInfo: PageInfo;
  items: Item[];
}

export interface Ready {
  type: "Ready:DataManager:Stocks:Listing:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:DataManager:Stocks:Listing:Ready",
  payload,
});

export const isReady = (s: State): s is Ready =>
  s.type === "Ready:DataManager:Stocks:Listing:Ready";
// endregion

// region Fetching
export interface FetchingPayload extends ReadyPayload {
  page: "current" | "start" | "prev" | "next" | "end";
}

export interface Fetching {
  type: "Ready:DataManager:Stocks:Listing:Fetching";
  payload: FetchingPayload;
}

export const fetching = (payload: Fetching["payload"]): Fetching => ({
  type: "Ready:DataManager:Stocks:Listing:Fetching",
  payload,
});

export const isFetching = (s: State): s is Fetching =>
  s.type === "Ready:DataManager:Stocks:Listing:Fetching";
// endregion
