import { filter, merge, Observable } from "rxjs";
import { Client } from "ds";
import { Client as OpenAIClient } from "open-ai-ds";
import { Epic } from "../../../../../../types/RootEpic";
import { Actions } from "./types/Actions";
import { State } from "./types/State";
import * as ListingAll from "./states/ListingAll";
import * as Listing from "./states/Listing";
import * as Create from "./states/Create";
import * as Edit from "./states/Edit";

export const epic: Epic<
  Actions,
  State,
  {
    pyckAdminClient$: Observable<Client>;
    openAIClient$: Observable<OpenAIClient>;
  }
> = (state$, ds$) => {
  const listingAll$ = ListingAll.epic(
    state$.pipe(filter(ListingAll.isState)),
    ds$,
  );
  const listing$ = Listing.epic(state$.pipe(filter(Listing.isState)), ds$);
  const create$ = Create.epic(state$.pipe(filter(Create.isState)), ds$);
  const edit$ = Edit.epic(state$.pipe(filter(Edit.isState)), ds$);

  return merge(listingAll$, listing$, create$, edit$);
};
