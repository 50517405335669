import { GetItemMovementsVars } from "ds/ItemMovements";
import * as O from "fp-ts/Option";
import * as State from "./types/State";

export function getFetchVars(
  s: State.Loading | State.Fetching,
): GetItemMovementsVars {
  const where: GetItemMovementsVars["where"] = {
    createdAt: O.toUndefined(s.payload.filters.createdAt),
    updatedAt: O.toUndefined(s.payload.filters.updatedAt),
    id: O.toUndefined(s.payload.filters.id),
    search: O.toUndefined(s.payload.filters.search),
    dataTypes: [s.payload.id],
  };

  switch (s.type) {
    case "Ready:DataManager:RepositoryMovements:Listing:Loading":
      return {
        first: s.payload.perPage,
        where,
      };
    case "Ready:DataManager:RepositoryMovements:Listing:Fetching": {
      switch (s.payload.page) {
        case "start":
          return {
            first: s.payload.perPage,
            where,
          };
        case "prev":
          return {
            last: s.payload.perPage,
            before: s.payload.pageInfo.prevCursor,
            where,
          };
        case "next":
          return {
            first: s.payload.perPage,
            after: s.payload.pageInfo.nextCursor,
            where,
          };
        case "end":
          return {
            last: s.payload.perPage,
            where,
          };
        case "current":
          return {
            first: s.payload.perPage,
            where,
          };
      }
    }
  }
}
