import { ReactElement } from "react";
import { Selector, useDispatch, useSelector } from "state-manager";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Footer as FooterLayout } from "ui/layouts/Footer";
import * as Listing from "state-manager/states/Ready/states/DataManager/states/ItemMovements/states/Listing";
import { Pagination } from "@Containers/Ready/DataManager/DataTypes/Listing/components/Pagination";
import { Button } from "ui/components/Button";

export interface FooterProps {
  selector: Selector<Listing.State>;
  dispatch: (a: Listing.Actions) => void;
}

export function Footer({ selector }: FooterProps): ReactElement | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const r = useSelector(
    flow(selector, (s: Listing.State) => {
      if (Listing.isLoading(s)) return { type: "null" } as const;
      if (Listing.isLoadError(s)) return { type: "null" } as const;

      if (Listing.isReady(s) || Listing.isFetching(s)) {
        return {
          type: "paginated",
          selector: flow(
            selector,
            (s) => s as Listing.Ready | Listing.Fetching,
            (s) => ({
              hasPrev: s.payload.pageInfo.hasPreviousPage,
              hasNext: s.payload.pageInfo.hasNextPage,
            }),
          ),
        } as const;
      }

      silentUnreachableError(s);
      return { type: "null" } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "null":
      return null;
    case "paginated":
      return (
        <FooterLayout>
          <Button isPrimary onClick={() => dispatch(Listing.create())}>
            {t("Add new")}
          </Button>
          <Pagination
            selector={r.selector}
            onChange={(v) => dispatch(Listing.setPage(v))}
          />
        </FooterLayout>
      );
  }
}
