import * as Create from "./states/Create";
import * as Edit from "./states/Edit";
import * as ListingAll from "./states/ListingAll";
import * as Listing from "./states/Listing";

export const createPickingOrderState = Create.createState(
  "Ready:DataManager:PickingOrder:Create",
);

export const editPickingOrderState = Edit.createState(
  "Ready:DataManager:PickingOrder:Edit",
);

export const listingAllPickingOrderState = ListingAll.createState(
  "Ready:DataManager:PickingOrder:ListingAll",
);
export const listingPickingOrderState = Listing.createState(
  "Ready:DataManager:PickingOrder:Listing",
);

export type CreateState = Create.State<"Ready:DataManager:PickingOrder:Create">;
export type CreateActions =
  Create.Actions<"Ready:DataManager:PickingOrder:Create">;

export type EditState = Edit.State<"Ready:DataManager:PickingOrder:Edit">;
export type EditActions = Edit.Actions<"Ready:DataManager:PickingOrder:Edit">;

export type ListingAllState =
  ListingAll.State<"Ready:DataManager:PickingOrder:ListingAll">;
export type ListingAllActions =
  ListingAll.Actions<"Ready:DataManager:PickingOrder:ListingAll">;

export type ListingState =
  Listing.State<"Ready:DataManager:PickingOrder:Listing">;
export type ListingActions =
  Listing.Actions<"Ready:DataManager:PickingOrder:Listing">;
