import { unreachableError } from "utils/exceptions";
import * as Listing from "./states/Listing";
import * as Single from "./states/Single";
import { State } from "./types/State";
import { Actions } from "./types/Actions";

export function reducer(s: State, a: Actions): State {
  if (Listing.isActions(a)) {
    if (Listing.isState(s)) {
      return Listing.reducer(s, a);
    }
    return s;
  }
  if (Single.isActions(a)) {
    if (Single.isState(s)) {
      return Single.reducer(s, a);
    }
    return s;
  }

  unreachableError(a);
  return a;
}
