import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";

// region Actions
import { DataTypeValue } from "types/src/DataType/DataTypeSchema";

export type Actions<P extends string> = OnChange<P>;

export const isActions = <P extends string>(p: P) =>
  strictGuard((a: Actions<P>): a is Actions<P> => {
    if (isOnChange(p)(a)) {
      return true;
    }

    silentUnreachableError(a);
    return false;
  });
// endregion

// region OnChange
export interface OnChange<P extends string> {
  type: `${P}:ItemSearch:OnChange`;
  payload: DataTypeValue | undefined;
}

export const onChange =
  <P extends string>(p: P) =>
  (payload: OnChange<P>["payload"]): OnChange<P> => ({
    type: `${p}:ItemSearch:OnChange`,
    payload,
  });

export const isOnChange =
  <P extends string>(p: P) =>
  (v: Actions<P>): v is OnChange<P> =>
    v.type === `${p}:ItemSearch:OnChange`;
// endregion
