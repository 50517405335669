import { RepositoryId } from "types/src/Repositories/Repository";
import {
  RepositoryMovement,
  RepositoryMovementId,
} from "types/src/RepositoryMovements/RepositoryMovement";

export interface ListingItem {
  id: RepositoryMovementId;
  orderId: string;
  collectionId: string;
  from: {
    id: RepositoryId;
    name: string;
  };
  to: {
    id: RepositoryId;
    name: string;
  };
  handler: string;
  executed: boolean;

  selected: boolean;
  actionState:
    | "none"
    | "removeConfirm"
    | "removing"
    | "executeConfirm"
    | "executing";
}

export function fromApiRepositoryMovement(i: RepositoryMovement): ListingItem {
  return {
    id: i.id,
    from: {
      id: i.from.id,
      name: i.from.name,
    },
    to: {
      id: i.to.id,
      name: i.to.name,
    },
    handler: i.handler,
    orderId: i.orderId,
    collectionId: i.collectionId,
    executed: i.executed,

    selected: false,
    actionState: "none",
  };
}
