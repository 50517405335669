import { gql } from "@apollo/client";

export const pickingOrderItemFragment = gql`
  fragment PickingOrderItemFragment on PickingOrderItem {
    id
    sku
    createdAt
    updatedAt
    dataTypeID
    data
    orderID
    quantity
  }
`;

export const pickingOrderFragment = gql`
  ${pickingOrderItemFragment}
  fragment PickingOrderFragment on PickingOrder {
    id
    createdAt
    updatedAt
    dataTypeID
    data
    customerID
    orderitems {
      ...PickingOrderItemFragment
    }
  }
`;
