import { FiltersWrapper } from "ui/layouts/Listing/FiltersWrapper";
import { ReactElement, ReactNode } from "react";
import { FiltersButton } from "ui/layouts/Filters/FiltersButton";
import { AdvancedFilters } from "ui/layouts/Filters/AdvancedFilters";
import { SearchInput } from "@Containers/Form/SearchInput";
import { RootState, useSelector } from "state-manager";
import { flow } from "fp-ts/function";

export interface FiltersProps {
  selector: (s: RootState) => {
    search: string;
    isOpen: boolean;
    hasFiltersEnabled: boolean;
  };
  dispatch: {
    openFilters: () => void;
    closeFilters: () => void;
    setSearch: (value: string) => void;
    onSave: () => void;
    onReset: () => void;
  };
  children?: ReactNode;
}

export function Filters({
  selector,
  dispatch,
  children,
}: FiltersProps): ReactElement {
  return (
    <FiltersWrapper>
      <SearchFilter
        value$={flow(selector, (s) => s.search)}
        dispatch={dispatch.setSearch}
      />
      <AdvancedFiltersTrigger
        selector={flow(selector, (s) => s.hasFiltersEnabled)}
        dispatch={{
          openFilters: dispatch.openFilters,
          clearFilters: dispatch.onReset,
        }}
      />
      <AdvancedFiltersForm
        selector={flow(selector, (s) => s.isOpen)}
        onClose={dispatch.closeFilters}
        onSave={dispatch.onSave}
        onReset={dispatch.onReset}
      >
        {children}
      </AdvancedFiltersForm>
    </FiltersWrapper>
  );
}

interface AdvancedFiltersTriggerProps {
  selector: (s: RootState) => boolean;
  dispatch: {
    openFilters: () => void;
    clearFilters: () => void;
  };
}
function AdvancedFiltersTrigger({
  selector,
  dispatch,
}: AdvancedFiltersTriggerProps): ReactElement {
  const isSelected = useSelector(selector);

  return (
    <FiltersButton
      isSelected={isSelected}
      onClick={dispatch.openFilters}
      onClear={dispatch.clearFilters}
    />
  );
}

interface SearchFilterProps {
  value$: (s: RootState) => string;
  dispatch: (value: string) => void;
}
function SearchFilter({ value$, dispatch }: SearchFilterProps) {
  return <SearchInput value$={value$} onChange={dispatch} />;
}

interface AdvancedFiltersFormProps {
  selector: (s: RootState) => boolean;
  onSave: () => void;
  onClose: () => void;
  onReset: () => void;
  children?: ReactNode;
}
function AdvancedFiltersForm({
  selector,
  onSave,
  onReset,
  onClose,
  children,
}: AdvancedFiltersFormProps): ReactElement {
  const isOpen = useSelector(selector);

  return (
    <AdvancedFilters
      isOpen={isOpen}
      onClose={onClose}
      onApply={onSave}
      onClear={onReset}
    >
      {children}
    </AdvancedFilters>
  );
}
