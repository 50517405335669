import { ReactElement, useCallback, useMemo } from "react";
import * as Listing from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/ListingAll";
import { RootState } from "state-manager";
import { flow } from "fp-ts/function";
import {
  SuppliersTable,
  SuppliersTableProps,
} from "@Containers/Ready/DataManager/Suppliers/ListingAll/components/SuppliersTable";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import { DeleteConfirmation } from "@Containers/Ready/DataManager/Suppliers/ListingAll/components/DeleteConfirmation";
import { ListingHeader } from "@Containers/Ready/DataManager/Suppliers/ListingAll/components/ListingHeader";

export interface AllSuppliersProps {
  selector$: (s: RootState) => Listing.Ready | Listing.Fetching;
  dispatch: (a: Listing.Actions) => void;
}

export function AllSuppliers({
  selector$,
  dispatch,
}: AllSuppliersProps): ReactElement {
  const items$: SuppliersTableProps["items$"] = useMemo(
    () => flow(selector$, (s) => s.payload.items),
    [selector$],
  );
  const orderBy$ = useMemo(
    () => flow(selector$, (s) => s.payload.order),
    [selector$],
  );

  const orderBy = useCallback(
    (v: "createdAt" | "updatedAt") => dispatch(Listing.orderBy(v)),
    [dispatch],
  );
  const onSelect = useCallback(flow(Listing.select, dispatch), [dispatch]);
  const onSelectAll = useCallback(flow(Listing.selectAll, dispatch), [
    dispatch,
  ]);

  const onDelete = useCallback(flow(Listing.removeItem, dispatch), [dispatch]);
  const onDeleteAll = useCallback(flow(Listing.removeBulk, dispatch), [
    dispatch,
  ]);

  return (
    <>
      <ListingWrapper
        header={<ListingHeader selector$={selector$} dispatch={dispatch} />}
      >
        <SuppliersTable
          items$={items$}
          orderBy$={orderBy$}
          orderBy={orderBy}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          onDeleteAll={onDeleteAll}
          onDelete={onDelete}
        />
      </ListingWrapper>
      <DeleteConfirmation selector$={selector$} dispatch={dispatch} />
    </>
  );
}
