import { silentUnreachableError } from "utils/exceptions";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { schemaFieldsState } from "./utils";

export function reducer(s: State.State, a: Actions.Actions): State.State {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return State.ready({
        id: s.payload.id,
        dataTypeId: s.payload.dataTypeId,
        schema: schemaFieldsState.reducer(s.payload.schema, a),
      });
    }

    return s;
  }

  switch (a.type) {
    case "Ready:DataManager:Suppliers:Edit:LoadFail": {
      if (State.isLoading(s)) {
        return State.loadError(s.payload);
      }

      return s;
    }
    case "Ready:DataManager:Suppliers:Edit:LoadSuccess": {
      if (State.isLoading(s)) {
        return State.ready({
          id: a.payload.item.id,
          dataTypeId: a.payload.item.dataTypeId,
          schema: schemaFieldsState.init({
            schema: a.payload.schema,
            values: a.payload.item.fields,
          }),
        });
      }

      return s;
    }
    case "Ready:DataManager:Suppliers:Edit:Submit": {
      if (State.isReady(s)) {
        return State.saving(s.payload);
      }

      return s;
    }
    case "Ready:DataManager:Suppliers:Edit:SaveError": {
      if (State.isSaving(s)) {
        return State.ready(s.payload);
      }

      return s;
    }
    case "Ready:DataManager:Suppliers:Edit:SaveSuccess": {
      if (State.isSaving(s)) {
        return State.ready(s.payload);
      }

      return s;
    }
    default: {
      silentUnreachableError(a);
      return s;
    }
  }
}
