import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { isOneOf } from "utils/isOneOf";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { CustomFields } from "./types/CustomFields";

export function reducer<T extends CustomFields>(
  s: State.State<T>,
  action: Actions.Actions<T>,
): State.State<T> {
  switch (action.type) {
    case "DataGenerator:Create": {
      if (State.isIdle<T>(s)) {
        return State.creating<T>({
          ...s.payload,
          isOpen: false,
        });
      }

      return s;
    }

    case "DataGenerator:ChangeAmount": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isIdle, State.isCreating])),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                amount: action.payload,
              },
            }) as typeof s,
        ),
        O.getOrElseW(() => s),
      );
    }

    case "DataGenerator:CreateFail": {
      if (State.isCreating<T>(s)) return State.idle<T>(s.payload);

      return s;
    }

    case "DataGenerator:CreateSuccess": {
      if (State.isCreating<T>(s))
        return State.success<T>({
          ...s.payload,
          resultData: {
            fields: action.payload.fields,
          },
        });

      return s;
    }
    case "DataGenerator:ToggleOpenSidebar": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isIdle, State.isCreating])),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                isOpen: !s.payload.isOpen,
              },
            }) as typeof s,
        ),
        O.getOrElseW(() => s),
      );
    }
  }
}
