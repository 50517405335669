import { JSONSchema7 } from "json-schema";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import {
  DataType,
  DataTypeEntity,
  DataTypeId,
} from "types/src/DataType/DataType";
import { jsonParse } from "utils/object";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/function";
import { toDate } from "types/src/date/ISODate";
import { TranslatedStr } from "types/src/TranslatedStr";
import {
  DataTypeEntity as ApiDataTypeEntity,
  DataTypeFragmentFragment,
} from "../generated/graphql";

export function dataTypeToJsonSchema(schema: DataTypeSchema): JSONSchema7 {
  return schema;
}

export function apiEntityToDataTypeEntity(
  entity: ApiDataTypeEntity,
): DataTypeEntity {
  switch (entity) {
    case ApiDataTypeEntity.Attachment:
      return DataTypeEntity.Attachment;
    case ApiDataTypeEntity.Customer:
      return DataTypeEntity.Customer;
    case ApiDataTypeEntity.Order:
      return DataTypeEntity.Order;
    case ApiDataTypeEntity.Delivery:
      return DataTypeEntity.Delivery;
    case ApiDataTypeEntity.Item:
      return DataTypeEntity.Item;
    case ApiDataTypeEntity.Repository:
      return DataTypeEntity.Repository;
    case ApiDataTypeEntity.Supplier:
      return DataTypeEntity.Supplier;
    case ApiDataTypeEntity.Movement:
      return DataTypeEntity.Movement;
    case ApiDataTypeEntity.CollectionMovement:
      return DataTypeEntity.CollectionMovement;
    case ApiDataTypeEntity.ItemSet:
      return DataTypeEntity.ItemSet;
    case ApiDataTypeEntity.RepositoryBox:
      return DataTypeEntity.RepositoryBox;
    case ApiDataTypeEntity.RepositoryPallet:
      return DataTypeEntity.RepositoryPallet;
  }
}

export function dataTypeEntityToApiEntity(
  entity: DataTypeEntity,
): ApiDataTypeEntity {
  switch (entity) {
    case DataTypeEntity.Attachment:
      return ApiDataTypeEntity.Attachment;
    case DataTypeEntity.Customer:
      return ApiDataTypeEntity.Customer;
    case DataTypeEntity.Order:
      return ApiDataTypeEntity.Order;
    case DataTypeEntity.Delivery:
      return ApiDataTypeEntity.Delivery;
    case DataTypeEntity.Item:
      return ApiDataTypeEntity.Item;
    case DataTypeEntity.Repository:
      return ApiDataTypeEntity.Repository;
    case DataTypeEntity.Supplier:
      return ApiDataTypeEntity.Supplier;
    case DataTypeEntity.Movement:
      return ApiDataTypeEntity.Movement;
    case DataTypeEntity.CollectionMovement:
      return ApiDataTypeEntity.CollectionMovement;
    case DataTypeEntity.ItemSet:
      return ApiDataTypeEntity.ItemSet;
    case DataTypeEntity.RepositoryBox:
      return ApiDataTypeEntity.RepositoryBox;
    case DataTypeEntity.RepositoryPallet:
      return ApiDataTypeEntity.RepositoryPallet;
  }
}

export function jsonSchemaToDataTypeSchema(
  schema: JSONSchema7,
): DataTypeSchema {
  return schema as DataTypeSchema;
}

export function dataTypeFragmentToDataType(
  fragment: DataTypeFragmentFragment,
): DataType {
  return {
    id: fragment.id as DataTypeId,
    name: fragment.name as TranslatedStr,
    description: fragment.description as TranslatedStr,
    entity: fragment.entity,
    default: fragment.default,
    schema: jsonSchemaToDataTypeSchema(
      jsonParse(fragment.jsonSchema) as JSONSchema7,
    ),
    createdAt: toDate(fragment.createdAt),
    updatedAt: pipe(fragment.updatedAt, O.fromNullable, O.map(toDate)),
    deletedAt: pipe(fragment.deletedAt, O.fromNullable, O.map(toDate)),
  };
}
