import { DataType } from "types/src/DataType/DataType";
import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import * as DataTypes from "../states/DataTypes";
import * as Customers from "../states/Customers";
import * as Suppliers from "../states/Suppliers";
import * as Repositories from "../states/Repositories";
import * as InventoryItems from "../states/InventoryItems";
import * as ItemMovements from "../states/ItemMovements";
import * as RepositoryMovements from "../states/RepositoryMovements";
import * as PickingOrder from "../states/PickingOrder";
import * as Stocks from "../states/Stocks";
import * as Transactions from "../states/Transactions";
import { pickingOrderState } from "../utils";

// region Actions
export type Actions =
  | LoadFail
  | LoadSuccess
  | DataTypes.Actions
  | Suppliers.Actions
  | Customers.Actions
  | Repositories.Actions
  | InventoryItems.Actions
  | ItemMovements.Actions
  | Stocks.Actions
  | Transactions.Actions
  | RepositoryMovements.Actions
  | PickingOrder.Actions;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (isLoadFail(a)) return true;
  if (isLoadSuccess(a)) return true;
  if (DataTypes.isActions(a)) return true;
  if (Customers.isActions(a)) return true;
  if (Suppliers.isActions(a)) return true;
  if (Repositories.isActions(a)) return true;
  if (InventoryItems.isActions(a)) return true;
  if (ItemMovements.isActions(a)) return true;
  if (RepositoryMovements.isActions(a)) return true;
  if (Stocks.isActions(a)) return true;
  if (Transactions.isActions(a)) return true;
  if (pickingOrderState.isActions(a)) return true;

  silentUnreachableError(a);
  return false;
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:LoadFail";
  payload: {
    type: "unknown";
  };
}

export const loadFail = (payload: LoadFail["payload"]): LoadFail => ({
  type: "Ready:DataManager:LoadFail",
  payload,
});

export const isLoadFail = (action: Actions): action is LoadFail =>
  action.type === "Ready:DataManager:LoadFail";
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:LoadSuccess";
  payload: {
    dataTypes: DataType[];
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:LoadSuccess",
  payload,
});

export const isLoadSuccess = (action: Actions): action is LoadSuccess =>
  action.type === "Ready:DataManager:LoadSuccess";
// endregion
