import { Eq } from "fp-ts/Eq";

export type Tuple<A, B> = [A, B];

export const TupleEq = <A, B>(a: Eq<A>, b: Eq<B>): Eq<Tuple<A, B>> => ({
  equals: (x, y) => a.equals(x[0], y[0]) && b.equals(x[1], y[1]),
});

export type SymmetricTuple<A> = [A, A];

export const symmetricTuple = <A>(a: A, b: A): SymmetricTuple<A> => [a, b];

export const SymmetricTuple = <A>(a: Eq<A>): Eq<SymmetricTuple<A>> =>
  TupleEq(a, a);
