import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import * as PickingOrder from "state-manager/states/Ready/states/DataManager/states/PickingOrder";

import * as DataManager from "state-manager/states/Ready/states/DataManager";
import * as ListingAllContainer from "./ListingAll";
import * as CreateContainer from "./Create";
import * as EditContainer from "./Edit";
import * as ListingContainer from "./Listing";

const states = DataManager.pickingOrderState.subStates;

export interface ContentProps {
  selector: Selector<PickingOrder.State>;
  dispatch: (a: PickingOrder.Actions) => void;
}

export function Content(p: ContentProps) {
  const r = useSelector(
    flow(p.selector, (s) => {
      if (states.listingAll.isState(s))
        return {
          type: "listing-all",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      if (states.listing.isState(s))
        return {
          type: "listing",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      if (states.create.isState(s))
        return {
          type: "create",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      if (states.edit.isState(s))
        return {
          type: "edit",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      silentUnreachableError(s);
      return {
        type: "unknown",
        selector: flow(p.selector, (st) => st as typeof s),
      } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "listing-all":
      return (
        <ListingAllContainer.Content
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "listing":
      return (
        <ListingContainer.Content selector={r.selector} dispatch={p.dispatch} />
      );
    case "create":
      return (
        <CreateContainer.Content selector={r.selector} dispatch={p.dispatch} />
      );
    case "edit":
      return (
        <EditContainer.Content selector={r.selector} dispatch={p.dispatch} />
      );
    case "unknown":
      return null;
  }
}
