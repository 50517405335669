import { Input } from "@zendeskgarden/react-forms";
import { ComponentProps } from "react";

export { Input, FauxInput, MediaInput } from "@zendeskgarden/react-forms";

export interface InputNumberProps
  extends Omit<ComponentProps<typeof Input>, "value"> {
  value?: number;
  onValueChange?: (value: number | undefined) => void;
}
export function NumberInput({
  onChange,
  onValueChange,
  ...props
}: InputNumberProps) {
  return (
    <Input
      {...props}
      onChange={(e) => {
        const value = e.target.value ? Number(e.target.value) : undefined;
        onChange?.(e);
        onValueChange?.(value);
      }}
      type="number"
    />
  );
}
