import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import { Item } from "./Item";

export type State = Ready;

export const isState = strictGuard((s: State): s is State => {
  if (isReady(s)) return true;

  silentUnreachableError(s);
  return false;
});

// region Ready
export interface ReadyPayload {
  counter: number;
  items: Item[];
}

export interface Ready {
  type: "Notifications:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Notifications:Ready",
  payload,
});

export const isReady = (a: State): a is Ready =>
  a.type === "Notifications:Ready";
// endregion

export const init = (): State => ready({ counter: 0, items: [] });
