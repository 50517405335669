import { gql } from "@apollo/client";

import { flow } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { isT } from "fp-utilities";

import {
  PickingOrderItemId,
  PickingOrderItem,
  PickingOrderItemCreate,
  PickingOrderItemUpdate,
} from "types/src/PickingOrder/PickingOrderItem";
import { omitEmpties } from "utils/value";
import type {
  CreatePickingOrderItemMutation,
  CreatePickingOrderItemMutationVariables,
  PickingOrderItemFragmentFragment,
  UpdatePickingOrderItemMutation,
  UpdatePickingOrderItemMutationVariables,
} from "../../generated/graphql";
import { Client, notFoundError } from "../../index";
import type { DsError } from "../../type/DsError";
import type { QueryResponse } from "../../type/QueryResponse";
import { pickingOrderItemFragment } from "../../fragments/PickingOrder";
import { pickingOrderItemFragmentToPickingOrderItem } from "../../transformers/PickingOrderItem";

export const createPickingOrderItem = (
  client: Client,
  vars: PickingOrderItemCreate,
): Promise<QueryResponse<PickingOrderItem>> => {
  const mutation = gql`
    ${pickingOrderItemFragment}
    mutation CreatePickingOrderItem($input: CreatePickingOrderItemInput!) {
      createPickingOrderItem(input: $input) {
        pickingOrderItem {
          ...PickingOrderItemFragment
        }
      }
    }
  `;

  return client
    .mutate<
      CreatePickingOrderItemMutation,
      CreatePickingOrderItemMutationVariables
    >({
      mutation,
      variables: {
        input: {
          data: vars.fields,
          sku: vars.sku,
          quantity: vars.quantity,
          orderID: vars.orderId,
          dataTypeID: vars.dataTypeId,
        },
      },
    })
    .then(
      flow(
        E.map((v) => v.createPickingOrderItem?.pickingOrderItem),
        E.filterOrElseW(isT, notFoundError),
        E.map(pickingOrderItemFragmentToPickingOrderItem),
      ),
    );
};

export const updatePickingOrderItem = (
  client: Client,
  vars: PickingOrderItemUpdate,
): Promise<QueryResponse<PickingOrderItem>> => {
  const mutation = gql`
    ${pickingOrderItemFragment}
    mutation UpdatePickingOrderItem(
      $id: ID!
      $input: UpdatePickingOrderItemInput!
    ) {
      updatePickingOrderItem(id: $id, input: $input) {
        pickingOrderItem {
          ...PickingOrderItemFragment
        }
      }
    }
  `;

  return client
    .mutate<
      UpdatePickingOrderItemMutation,
      UpdatePickingOrderItemMutationVariables
    >({
      mutation,
      variables: {
        id: vars.id,
        input: {
          dataTypeID: vars.dataTypeId,
          data: omitEmpties(vars.fields),
          sku: vars.sku,
          quantity: vars.quantity,
        },
      },
    })
    .then(
      flow(
        E.map((node) =>
          pickingOrderItemFragmentToPickingOrderItem(
            node.updatePickingOrderItem
              ?.pickingOrderItem as PickingOrderItemFragmentFragment,
          ),
        ),
      ),
    );
};

export const deletePickingOrderItems = (
  client: Client,
  ids: PickingOrderItemId[],
): Promise<E.Either<DsError, void>> => {
  const template = `
    data_picking_order_item_id: deletePickingOrderItem(id: "[PICKING_ORDER_ITEM_ID]") {
      deletedID
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template
      .replace("[PICKING_ORDER_ITEM_ID]", id)
      .replace("data_picking_order_item_id", `data_picking_order_item_${i}`),
  );
  const query = `
        mutation DeletePickingOrderItems {
            ${fakeMutation.join("\n")}
        }
      `;

  return client
    .mutate({
      mutation: gql(query),
    })
    .then(E.map(() => undefined));
};
