import * as Rx from "rxjs";
import { Epic } from "../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { createSchemaFieldsState } from "./utils";

export const epic = <P extends string>(
  p: P,
): Epic<Actions.Actions<P>, State.State<P>> => {
  const schemaFields = createSchemaFieldsState<P>(p);
  return (s$) => {
    return schemaFields.epic(
      s$.pipe(
        Rx.map((s) => s.payload.fields),
        Rx.filter(schemaFields.isState),
      ),
      {},
    );
  };
};
