import { silentUnreachableError } from "utils/exceptions";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { isOneOf } from "utils/isOneOf";
import * as NoEmptyString from "types/src/NoEmptyString";
import * as Create from "../Create";
import { fromApiItemMovement } from "../../types/ListingItem";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { FiltersMonoid } from "./types/Filters";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): State.State | Create.State {
  switch (a.type) {
    case "Ready:DataManager:ItemMovements:ListingAll:LoadFail": {
      if (State.isLoading(s)) return State.loadError(s.payload);
      if (State.isFetching(s)) return State.ready(s.payload);

      return s;
    }
    case "Ready:DataManager:ItemMovements:ListingAll:LoadSuccess": {
      if (State.isLoading(s)) {
        return State.ready({
          ...s.payload,
          total: a.payload.total,
          items: a.payload.items.map(fromApiItemMovement),
          dataTypes: a.payload.dataTypes,
          pageInfo: a.payload.pageInfo,
          advancedFiltersState: "closed",
        });
      }

      return s;
    }
    case "Ready:DataManager:ItemMovements:ListingAll:FetchSuccess": {
      if (State.isFetching(s))
        return State.ready({
          ...s.payload,
          total: a.payload.total,
          items: a.payload.items.map(fromApiItemMovement),
          pageInfo: a.payload.pageInfo,
        });

      return s;
    }
    case "Ready:DataManager:ItemMovements:ListingAll:SetPage": {
      if (State.isReady(s) || State.isFetching(s))
        return State.fetching({
          ...s.payload,
          page: a.payload,
        });

      return s;
    }
    case "Ready:DataManager:ItemMovements:ListingAll:Select": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.filter((s) => s.payload.items.some((i) => i.id === a.payload)),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                items: s.payload.items.map((i) =>
                  i.id === a.payload ? { ...i, selected: !i.selected } : i,
                ),
              },
            }) as typeof s,
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:SelectAll": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) => {
          const allSelected = s.payload.items.every((i) => i.selected);
          return {
            ...s,
            payload: {
              ...s.payload,
              items: s.payload.items.map((i) => ({
                ...i,
                selected: !allSelected,
              })),
            },
          } as typeof s;
        }),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:RemoveItem": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) => s.payload.items.some((i) => i.id === a.payload)),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.id === a.payload && i.actionState === "none"
                ? { ...i, actionState: "removeConfirm" }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:RemoveBulk": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) => s.payload.items.some((i) => i.selected)),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.selected && i.actionState === "none"
                ? {
                    ...i,
                    actionState: "removeConfirm",
                  }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:RemoveConfirm": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some((i) => i.actionState === "removeConfirm"),
        ),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.actionState === "removeConfirm"
                ? {
                    ...i,
                    actionState: "removing",
                  }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:RemoveDecline": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some((i) => i.actionState === "removeConfirm"),
        ),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.actionState === "removeConfirm"
                ? { ...i, actionState: "none" }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:RemoveSuccess": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some(
            (i) => i.actionState === "removing" && a.payload.includes(i.id),
          ),
        ),
        O.map((s) =>
          State.fetching({
            ...s.payload,
            page: "current",
            items: s.payload.items.filter(
              (i) =>
                !(i.actionState === "removing" && a.payload.includes(i.id)),
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:RemoveFail": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some(
            (i) => i.actionState === "removing" && a.payload.includes(i.id),
          ),
        ),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              !(i.actionState === "removing" && a.payload.includes(i.id))
                ? {
                    ...i,
                    actionState: "none",
                  }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:ExecuteItem": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) => s.payload.items.some((i) => i.id === a.payload)),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.id === a.payload && !i.executed && i.actionState === "none"
                ? { ...i, actionState: "executeConfirm" }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:ExecuteBulk": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) => s.payload.items.some((i) => i.selected)),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.selected && !i.executed && i.actionState === "none"
                ? {
                    ...i,
                    actionState: "executeConfirm",
                  }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:ExecuteConfirm": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some((i) => i.actionState === "executeConfirm"),
        ),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.actionState === "executeConfirm"
                ? {
                    ...i,
                    actionState: "executing",
                  }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:ExecuteDecline": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some((i) => i.actionState === "executeConfirm"),
        ),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.actionState === "executeConfirm"
                ? { ...i, actionState: "none" }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:ExecuteSuccess": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some(
            (i) => i.actionState === "executing" && a.payload.includes(i.id),
          ),
        ),
        O.map((s) =>
          State.fetching({
            ...s.payload,
            page: "current",
            items: s.payload.items.map((i) =>
              !(i.actionState === "executing" && a.payload.includes(i.id))
                ? { ...i, actionState: "none", executed: true }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:ExecuteFail": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some(
            (i) => i.actionState === "executing" && a.payload.includes(i.id),
          ),
        ),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              !(i.actionState === "executing" && a.payload.includes(i.id))
                ? {
                    ...i,
                    actionState: "none",
                  }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:SetUpdatedAtFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              updatedAt: a.payload,
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:SetCreatedAtFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              createdAt: a.payload,
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:SetSearchFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              search: NoEmptyString.fromString(a.payload),
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:SetDataTypesFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) => {
          const dataTypes = s.payload.dataTypes.map((dt) => dt.id);
          return State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              dataTypes: a.payload.filter((d) => dataTypes.includes(d)),
            },
          });
        }),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:SetIdFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              id: NoEmptyString.fromString(a.payload),
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:SetStatusFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              status: a.payload,
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:SetExecutedFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              executed: a.payload,
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:SubmitFilters": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.map((s) =>
          State.fetching({
            ...s.payload,
            page: "start",
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:ClearFilters": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.fetching({
            ...s.payload,
            page: "start",
            advancedFiltersState: "closed",
            filters: FiltersMonoid.empty,
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:OpenAdvancedFilters": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.filter((s) => s.payload.advancedFiltersState === "closed"),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                advancedFiltersState: "open",
              },
            }) as typeof s,
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:ListingAll:CloseAdvancedFilters": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.filter((s) => s.payload.advancedFiltersState === "open"),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                advancedFiltersState: "closed",
              },
            }) as typeof s,
        ),
        O.getOrElseW(() => s),
      );
    }
    default:
      silentUnreachableError(a);
      return s;
  }
}
