import { Selector, useSelector } from "state-manager";
import * as Single from "state-manager/states/Ready/states/DataManager/states/Stocks/states/Single";
import { ReactElement } from "react";
import { flow } from "fp-ts/function";
import { Loading } from "@Pages/Loading";
import { Form } from "./Form";

export interface ContentProps {
  selector: Selector<Single.State>;
}

export function Content(p: ContentProps): ReactElement | null {
  const data = useSelector(
    flow(p.selector, (s) => {
      if (Single.isLoading(s) || Single.isLoadError(s)) {
        return {
          type: "none",
        } as const;
      }

      if (Single.isReady(s)) {
        return {
          type: "ready",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;
      }

      return {
        type: "none",
      } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (data.type) {
    case "none":
      return <Loading />;
    case "ready":
      return <Form selector={data.selector} />;
  }
}
