import { IInputProps, MediaInput } from "@zendeskgarden/react-forms";
import { Datepicker, IDatepickerProps } from "@zendeskgarden/react-datepickers";
import { XCircleIcon } from "icons/X";
import styled from "styled-components";

export type {
  IDatepickerProps,
  IDatepickerRangeProps,
} from "@zendeskgarden/react-datepickers";
export { Datepicker, DatepickerRange } from "@zendeskgarden/react-datepickers";

export interface DatePickerProps extends Omit<IDatepickerProps, "onChange"> {
  onChange?: (value: Date | undefined) => void;
  validation?: IInputProps["validation"];
}

export function DatePickerInput(props: DatePickerProps) {
  return (
    <Datepicker {...props}>
      <MediaInput
        end={
          <ClearButton
            aria-hidden={!props.value}
            onClick={() => props.onChange?.(undefined)}
          >
            <Clear />
          </ClearButton>
        }
      />
    </Datepicker>
  );
}

const ClearButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;

  &[aria-hidden="true"] {
    display: none;
  }
`;

const Clear = styled(XCircleIcon)`
  color: ${(p) => p.theme.palette.grey[600]};
  &:hover {
    color: ${(p) => p.theme.palette.grey[800]};
  }
`;
