import { gql } from "@apollo/client";
import { flow } from "fp-ts/function";
import { RepositoryId, Repository } from "types/src/Repositories/Repository";
import * as E from "fp-ts/Either";
import { isT } from "fp-utilities";
import { Client } from "../../index";
import {
  GetRepositoriesQuery,
  GetRepositoriesQueryVariables,
} from "../../generated/graphql";
import { repositoryFragmentToRepository } from "../../transformers/Repositories";
import { repositoryFragment } from "../../fragments/Repository";
import {
  getPaginatedQueryResult,
  PaginatedQueryResult,
  QueryResponse,
} from "../../type/QueryResponse";
import { notFoundError } from "../../type/DsError";
import {
  GetRepositoryVars,
  repositoryWhereToApi,
} from "../../type/Repositories";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";

export type { GetRepositoryVars } from "../../type/Repositories";

export type GetRepositoriesQueryResult = PaginatedQueryResult<Repository>;

export function getRepositories(
  client: Client,
  vars: GetRepositoryVars,
): Promise<QueryResponse<GetRepositoriesQueryResult>> {
  const query = gql`
    ${repositoryFragment}
    ${pageInfoFragment}

    query GetRepositories(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: RepositoryWhereInput
      $orderBy: RepositoryOrder
    ) {
      repositories(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...RepositoryFragment
          }
        }
      }
    }
  `;

  return client
    .query<GetRepositoriesQuery, GetRepositoriesQueryVariables>({
      query,
      variables: repositoryWhereToApi(vars),
    })
    .then(
      E.map(
        flow(
          (r) => r.repositories,
          getPaginatedQueryResult(repositoryFragmentToRepository),
        ),
      ),
    );
}

export type GetRepositoryQueryResult = Repository;
export function getRepository(
  client: Client,
  id: RepositoryId,
): Promise<QueryResponse<GetRepositoryQueryResult>> {
  return getRepositories(client, { where: { id } }).then(
    flow(
      E.map((r) => r.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
  );
}
