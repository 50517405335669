import { Button } from "ui/components/Button";
import { RootState, useDispatch, useSelector } from "state-manager";
import { signIn } from "state-manager/states/Auth";
import { Login } from "ui/layouts/Login";
import { useEffect } from "react";
import { Close, Notification, Title, useToast } from "ui/components/Toast";
import { useTranslation } from "i18n";

export interface SignInProps {
  selector: (s: RootState) => "normal" | "loading" | "error";
}

export function LogIn({ selector }: SignInProps) {
  const dispatch = useDispatch();
  const state = useSelector(selector);
  const { t } = useTranslation();
  const { addToast } = useToast();
  const isError = state === "error";

  useEffect(() => {
    if (isError) {
      addToast(({ close }) => (
        <Notification type={"error"}>
          <Title>{t("Oops!!!")}</Title>
          {t("Something went wrong. Please try again.")}
          <Close onClick={close} />
        </Notification>
      ));
    }
  }, [isError]);

  return (
    <Login>
      <Button
        disabled={state === "loading"}
        onClick={() => dispatch(signIn())}
        isPrimary
      >
        {t("Sign in")}
      </Button>
    </Login>
  );
}
