import { silentUnreachableError } from "utils/exceptions";
import { State } from "./types/State";
import { Actions } from "./types/Actions";

export function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case "Ready:BPMNPreview:Actions":
      return state;
    default:
      silentUnreachableError(action.type);
      return state;
  }
}
