import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import { DataType } from "types/src/DataType/DataType";
import {
  Repo,
  RepoWithMeta,
  RequiredFields,
} from "types/src/Repositories/Repository";

export type State =
  | Loading
  | LoadError
  | Ready
  | CreateReposProcessing
  | CreatedSuccessfully
  | UpdateReposProcessing
  | UpdatedSuccessfully
  | DeleteReposProcessing
  | DeletedSuccessfully
  | ActiveDataType;

export const isState = strictGuard((s: State): s is State => {
  const type = (s as State).type;
  switch (type) {
    case "Ready:BuilderPreview:Loading":
    case "Ready:BuilderPreview:LoadError":
    case "Ready:BuilderPreview:Ready":
    case "Ready:BuilderPreview:CreatedSuccessfully":
    case "Ready:BuilderPreview:CreateReposProcessing":
    case "Ready:BuilderPreview:UpdatedSuccessfully":
    case "Ready:BuilderPreview:UpdateReposProcessing":
    case "Ready:BuilderPreview:DeleteReposProcessing":
    case "Ready:BuilderPreview:DeletedSuccessfully":
    case "Ready:BuilderPreview:ActiveDataType":
      return true;
    default:
      silentUnreachableError(type);
      return false;
  }
});

// region Loading
export interface LoadingPayload {}

export interface Loading {
  type: "Ready:BuilderPreview:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Ready:BuilderPreview:Loading",
  payload,
});

export const isLoading = (a: State): a is Loading => {
  return a.type === "Ready:BuilderPreview:Loading";
};
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Ready:BuilderPreview:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Ready:BuilderPreview:LoadError",
  payload,
});

export const isLoadError = (s: State): s is LoadError =>
  s.type === "Ready:BuilderPreview:LoadError";
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  // is needed to bind internal builder ids with server ids
  idsBinding: Record<string, Repo["id"]>;
  items: Repo[];
  dataTypes: DataType[];
  activeDataType: DataType["id"];
  refetchRepoId: string;
}

export interface Ready {
  type: "Ready:BuilderPreview:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:BuilderPreview:Ready",
  payload,
});

export const isReady = (s: State): s is Ready =>
  s.type === "Ready:BuilderPreview:Ready";
// endregion

// region Ready
export interface CreatedSuccessfullyPayload extends ReadyPayload {}

export interface CreatedSuccessfully {
  type: "Ready:BuilderPreview:CreatedSuccessfully";
  payload: CreatedSuccessfullyPayload;
}

export const createdSuccessfully = (
  payload: CreatedSuccessfully["payload"],
): CreatedSuccessfully => ({
  type: "Ready:BuilderPreview:CreatedSuccessfully",
  payload,
});

export const isCreatedSuccessfully = (s: State): s is CreatedSuccessfully =>
  s.type === "Ready:BuilderPreview:CreatedSuccessfully";
// endregion

// region CreateReposProcessing
export interface CreateReposProcessingPayload extends ReadyPayload {
  createItems: RepoWithMeta[];
}

export interface CreateReposProcessing {
  type: "Ready:BuilderPreview:CreateReposProcessing";
  payload: CreateReposProcessingPayload;
}

export const createReposProcessing = (
  payload: CreateReposProcessing["payload"],
): CreateReposProcessing => ({
  type: "Ready:BuilderPreview:CreateReposProcessing",
  payload,
});

export const isCreateReposProcessing = (s: State): s is CreateReposProcessing =>
  s.type === "Ready:BuilderPreview:CreateReposProcessing";
// endregion

// region CreateReposProcessing
export interface DeleteReposProcessingPayload extends ReadyPayload {
  itemsIds: Repo["id"][];
}

export interface DeleteReposProcessing {
  type: "Ready:BuilderPreview:DeleteReposProcessing";
  payload: DeleteReposProcessingPayload;
}

export const deleteReposProcessing = (
  payload: DeleteReposProcessing["payload"],
): DeleteReposProcessing => ({
  type: "Ready:BuilderPreview:DeleteReposProcessing",
  payload,
});

export const isDeleteReposProcessing = (s: State): s is DeleteReposProcessing =>
  s.type === "Ready:BuilderPreview:DeleteReposProcessing";
// endregion

export interface DeletedSuccessfullyPayload extends ReadyPayload {}

export interface DeletedSuccessfully {
  type: "Ready:BuilderPreview:DeletedSuccessfully";
  payload: DeletedSuccessfullyPayload;
}

export const deletedSuccessfully = (
  payload: DeletedSuccessfully["payload"],
): DeletedSuccessfully => ({
  type: "Ready:BuilderPreview:DeletedSuccessfully",
  payload,
});

export const isDeletedSuccessfully = (s: State): s is DeletedSuccessfully =>
  s.type === "Ready:BuilderPreview:DeletedSuccessfully";
// endregion

export interface UpdatedSuccessfullyPayload extends ReadyPayload {}

export interface UpdatedSuccessfully {
  type: "Ready:BuilderPreview:UpdatedSuccessfully";
  payload: UpdatedSuccessfullyPayload;
}

export const updatedSuccessfully = (
  payload: UpdatedSuccessfully["payload"],
): UpdatedSuccessfully => ({
  type: "Ready:BuilderPreview:UpdatedSuccessfully",
  payload,
});

export const isUpdatedSuccessfully = (s: State): s is UpdatedSuccessfully =>
  s.type === "Ready:BuilderPreview:UpdatedSuccessfully";
// endregion

// region CreateReposProcessing
export interface UpdateReposProcessingPayload extends ReadyPayload {
  updateItems: RequiredFields<Partial<RepoWithMeta>, "id">[];
}

export interface UpdateReposProcessing {
  type: "Ready:BuilderPreview:UpdateReposProcessing";
  payload: UpdateReposProcessingPayload;
}

export const updateReposProcessing = (
  payload: UpdateReposProcessing["payload"],
): UpdateReposProcessing => ({
  type: "Ready:BuilderPreview:UpdateReposProcessing",
  payload,
});

export const isUpdateReposProcessing = (s: State): s is UpdateReposProcessing =>
  s.type === "Ready:BuilderPreview:UpdateReposProcessing";

export interface ActiveDataTypePayload extends ReadyPayload {
  // changeDataTypeId: DataType["id"];
}

export interface ActiveDataType {
  type: "Ready:BuilderPreview:ActiveDataType";
  payload: ActiveDataTypePayload;
}

export const activeDataType = (
  payload: ActiveDataType["payload"],
): ActiveDataType => ({
  type: "Ready:BuilderPreview:ActiveDataType",
  payload,
});

export const isActiveDataType = (s: State): s is ActiveDataType =>
  s.type === "Ready:BuilderPreview:ActiveDataType";

export const init = (): Loading => loading({});
