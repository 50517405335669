import { unreachableError } from "utils/exceptions";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import * as Auth from "./states/Auth";
import * as Ready from "./states/Ready";
import * as Notifications from "./states/Notifications";

export function reducer(s: State.State, a: Actions.Actions): State.State {
  if (Auth.isAction(a)) {
    return Auth.isState(s.content)
      ? { ...s, content: Auth.reducer(s.content, a) }
      : s;
  }

  if (Ready.isActions(a)) {
    return Ready.isState(s.content)
      ? { ...s, content: Ready.reducer(s.content, a) }
      : s;
  }

  if (Notifications.isActions(a)) {
    return Notifications.isState(s.notifications)
      ? { ...s, notifications: Notifications.reducer(s.notifications, a) }
      : s;
  }

  unreachableError(a);
  return s;
}
