import { silentUnreachableError } from "utils/exceptions";
import {
  DataType,
  DataTypeEntity,
  DataTypeId,
} from "types/src/DataType/DataType";
import { strictGuard } from "utils/strictGuard";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import * as SchemaActions from "../../../../../../../../../generic-states/Schema/types/Actions";

export type Actions = FormActions | SchemaActions.Actions;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (SchemaActions.isActions(a)) return true;
  if (isFormActions(a)) return true;

  silentUnreachableError(a);
  return false;
});

// region FormActions
type FormActions =
  | LoadFail
  | LoadSuccess
  | SetName
  | SetDescription
  | SetDefault
  | SetEntity
  | ResetSchema
  | Submit
  | SaveSuccess
  | SaveFail
  | Remove
  | RemoveApprove
  | RemoveCancel
  | RemoveSuccess
  | RemoveFail;

function isFormActions(action: Actions): action is FormActions {
  const a = action as FormActions;

  switch (a.type) {
    case "Ready:DataManager:DataTypes:Edit:LoadFail":
    case "Ready:DataManager:DataTypes:Edit:LoadSuccess":
    case "Ready:DataManager:DataTypes:Edit:SetDefault":
    case "Ready:DataManager:DataTypes:Edit:SetDescription":
    case "Ready:DataManager:DataTypes:Edit:SetEntity":
    case "Ready:DataManager:DataTypes:Edit:SetName":
    case "Ready:DataManager:DataTypes:Edit:Submit":
    case "Ready:DataManager:DataTypes:Edit:Remove":
    case "Ready:DataManager:DataTypes:Edit:RemoveApprove":
    case "Ready:DataManager:DataTypes:Edit:RemoveCancel":
    case "Ready:DataManager:DataTypes:Edit:SaveFail":
    case "Ready:DataManager:DataTypes:Edit:SaveSuccess":
    case "Ready:DataManager:DataTypes:Edit:RemoveSuccess":
    case "Ready:DataManager:DataTypes:Edit:RemoveFail":
    case "Ready:DataManager:DataTypes:Edit:ResetSchema":
      return true;

    default:
      silentUnreachableError(a);
      return false;
  }
}
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:DataTypes:Edit:LoadFail";
  payload: {
    type: "not-found" | "error";
  };
}

export const loadFail = (payload: LoadFail["payload"]): LoadFail => ({
  type: "Ready:DataManager:DataTypes:Edit:LoadFail",
  payload,
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:DataTypes:Edit:LoadSuccess";
  payload: DataType;
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:DataTypes:Edit:LoadSuccess",
  payload,
});
// endregion

// region SetName
export interface SetName {
  type: "Ready:DataManager:DataTypes:Edit:SetName";
  payload: string;
}

export const setName = (payload: SetName["payload"]): SetName => ({
  type: "Ready:DataManager:DataTypes:Edit:SetName",
  payload,
});
// endregion

// region SetDescription
export interface SetDescription {
  type: "Ready:DataManager:DataTypes:Edit:SetDescription";
  payload: string;
}

export const setDescription = (
  payload: SetDescription["payload"],
): SetDescription => ({
  type: "Ready:DataManager:DataTypes:Edit:SetDescription",
  payload,
});
// endregion

// region SetDefault
export interface SetDefault {
  type: "Ready:DataManager:DataTypes:Edit:SetDefault";
  payload: boolean;
}

export const setDefault = (payload: SetDefault["payload"]): SetDefault => ({
  type: "Ready:DataManager:DataTypes:Edit:SetDefault",
  payload,
});
// endregion

// region SetEntity
export interface SetEntity {
  type: "Ready:DataManager:DataTypes:Edit:SetEntity";
  payload: DataTypeEntity;
}

export const setEntity = (payload: SetEntity["payload"]): SetEntity => ({
  type: "Ready:DataManager:DataTypes:Edit:SetEntity",
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: "Ready:DataManager:DataTypes:Edit:Submit";
}

export const submit = (): Submit => ({
  type: "Ready:DataManager:DataTypes:Edit:Submit",
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: "Ready:DataManager:DataTypes:Edit:SaveSuccess";
  payload: DataType;
}

export const saveSuccess = (payload: SaveSuccess["payload"]): SaveSuccess => ({
  type: "Ready:DataManager:DataTypes:Edit:SaveSuccess",
  payload,
});
// endregion

// region SaveFail
export interface SaveFail {
  type: "Ready:DataManager:DataTypes:Edit:SaveFail";
}

export const saveFail = (): SaveFail => ({
  type: "Ready:DataManager:DataTypes:Edit:SaveFail",
});
// endregion

// region Remove
export interface Remove {
  type: "Ready:DataManager:DataTypes:Edit:Remove";
}

export const remove = (): Remove => ({
  type: "Ready:DataManager:DataTypes:Edit:Remove",
});
// endregion

// region RemoveApprove
export interface RemoveApprove {
  type: "Ready:DataManager:DataTypes:Edit:RemoveApprove";
}

export const removeApprove = (): RemoveApprove => ({
  type: "Ready:DataManager:DataTypes:Edit:RemoveApprove",
});
// endregion

// region RemoveCancel
export interface RemoveCancel {
  type: "Ready:DataManager:DataTypes:Edit:RemoveCancel";
}

export const removeCancel = (): RemoveCancel => ({
  type: "Ready:DataManager:DataTypes:Edit:RemoveCancel",
});
// endregion

// region RemoveSuccess
export interface RemoveSuccess {
  type: "Ready:DataManager:DataTypes:Edit:RemoveSuccess";
  payload: DataTypeId;
}

export const removeSuccess = (
  payload: RemoveSuccess["payload"],
): RemoveSuccess => ({
  type: "Ready:DataManager:DataTypes:Edit:RemoveSuccess",
  payload,
});
// endregion

// region RemoveFail
export interface RemoveFail {
  type: "Ready:DataManager:DataTypes:Edit:RemoveFail";
}

export const removeFail = (): RemoveFail => ({
  type: "Ready:DataManager:DataTypes:Edit:RemoveFail",
});
// endregion

// region ResetSchema
export interface ResetSchema {
  type: "Ready:DataManager:DataTypes:Edit:ResetSchema";
  payload: DataTypeSchema;
}

export const resetSchema = (payload: ResetSchema["payload"]): ResetSchema => ({
  type: "Ready:DataManager:DataTypes:Edit:ResetSchema",
  payload,
});

export const isResetSchema = (s: Actions): s is ResetSchema =>
  s.type === "Ready:DataManager:DataTypes:Edit:ResetSchema";
// endregion
