import { Footer as FooterLayout } from "ui/layouts/Create/Footer";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import * as Create from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Create";

export interface FooterProps {
  selector: Selector<Create.State>;
  dispatch: (a: Create.Actions) => void;
}

export function Footer({ selector, dispatch }: FooterProps) {
  const isSaving = useSelector(flow(selector, Create.isSaving));

  return (
    <FooterLayout
      submit={{
        disabled: isSaving,
        onClick: () => dispatch(Create.submit()),
      }}
    />
  );
}
