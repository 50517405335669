import { Selector, useSelector } from "state-manager";
import { Input as InputComponent } from "ui/components/Input";

export interface InputProps {
  value$: Selector<string | undefined>;
  onChange: (value: string) => void;
  placeholder?: string;
  size?: number;
  readOnly?: boolean;
  validation?: "success" | "warning" | "error";
}

export function Input({
  value$,
  onChange,
  placeholder,
  size,
  validation,
  readOnly,
}: InputProps) {
  const value = useSelector(value$);

  return (
    <InputComponent
      placeholder={placeholder}
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      size={size}
      validation={validation}
      readOnly={readOnly}
    />
  );
}
