import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";

// region Actions
export interface Actions {
  type: "Ready:ZitadelPreview:Actions";
}

export const actions = (): Actions => ({
  type: "Ready:ZitadelPreview:Actions",
});

export const isActions = strictGuard((a: Actions): a is Actions => {
  switch (a.type) {
    case "Ready:ZitadelPreview:Actions":
      return true;
    default:
      silentUnreachableError(a.type);
      return false;
  }
});
// endregion
