import { RootState, useSelector } from "state-manager";
import { Toggle as ToggleComponent } from "ui/components/Toggle";
import { ReactNode } from "react";
import { Label } from "ui/components/Label";

export interface ToggleProps {
  disabled?: boolean;
  value$: (s: RootState) => boolean;
  onChange?: (value: boolean) => void;
  children: ReactNode;
}

export function Toggle({ value$, onChange, children, disabled }: ToggleProps) {
  const value = useSelector(value$);

  return (
    <ToggleComponent
      disabled={disabled}
      checked={value}
      onChange={() => onChange?.(!value)}
    >
      <Label>{children}</Label>
    </ToggleComponent>
  );
}
