import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";

import * as PickingOrder from "state-manager/states/Ready/states/DataManager/states/PickingOrder";
import * as ListingAllContainer from "./ListingAll";
import * as CreateContainer from "./Create";
import * as EditContainer from "./Edit";
import * as ListingContainer from "./Listing";

const state = DataManager.pickingOrderState;

export interface HeaderProps {
  selector: Selector<PickingOrder.State>;
  dispatch: (a: PickingOrder.Actions) => void;
}

export function Header(p: HeaderProps) {
  const r = useSelector(
    flow(p.selector, (s) => {
      if (state.subStates.listingAll.isState(s))
        return {
          type: "listing-all",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      if (state.subStates.listing.isState(s))
        return {
          type: "listing",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      if (state.subStates.create.isState(s))
        return {
          type: "create",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      if (state.subStates.edit.isState(s))
        return {
          type: "edit",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      silentUnreachableError(s);
      return {
        type: "unknown",
        selector: flow(p.selector, (st) => st as typeof s),
      } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "listing-all":
      return (
        <ListingAllContainer.Header
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "listing":
      return (
        <ListingContainer.Header selector={r.selector} dispatch={p.dispatch} />
      );
    case "create":
      return (
        <CreateContainer.Header selector={r.selector} dispatch={p.dispatch} />
      );
    case "edit":
      return (
        <EditContainer.Header selector={r.selector} dispatch={p.dispatch} />
      );
    case "unknown":
      return null;
  }
}
