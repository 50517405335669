import { useTranslation } from "i18n";
import { TypographyMD } from "ui/components/Typography";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Edit";
import { Selector } from "state-manager";

export interface HeaderProps {
  selector: Selector<Edit.State>;
  dispatch: (a: Edit.Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();

  return <TypographyMD>{t("Edit item")}</TypographyMD>;
}
