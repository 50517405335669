import { silentUnreachableError } from "utils/exceptions";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

export function reducer(s: State.State, a: Actions.Actions): State.State {
  switch (a.type) {
    case "Ready:DataManager:Transactions:Single:FetchSuccessAction":
      return State.ready({
        id: a.payload.id,
        inventoryItemId: a.payload.item.id,
        repositoryId: a.payload.repository.id,
        repositoryName: a.payload.repository.name,
        createdAt: a.payload.createdAt,
        quantity: a.payload.quantity,
        type: a.payload.type,
      });
    case "Ready:DataManager:Transactions:Single:FetchErrorAction":
      return State.loadError(s.payload);
    default:
      silentUnreachableError(a);
      return s;
  }
}
