import { Sheet } from "@zendeskgarden/react-chrome";
import { ReactNode } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { ThemeProvider } from "@components/ThemeProvider";

export { Sheet } from "@zendeskgarden/react-chrome";

export interface GlobalSheetProps {
  isOpen: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  onClose?: () => void;
}

export function GlobalSheet({
  children,
  isOpen,
  header,
  footer,
  onClose,
}: GlobalSheetProps) {
  return createPortal(
    <ThemeProvider>
      {isOpen && <Overlay onClick={onClose} />}
      <StyledSheet isOpen={isOpen} placement={"end"}>
        {header && (
          <Header>
            {header}
            <StyledSheet.Close onClick={onClose} />
          </Header>
        )}
        <Body>{children}</Body>
        {footer && <Footer>{footer}</Footer>}
      </StyledSheet>
    </ThemeProvider>,
    document.body,
  );
}

const StyledSheet = styled(Sheet)`
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
`;

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 9;
`;

const Header = styled(Sheet.Header)`
  height: 52px;
  padding: ${(p) => p.theme.space.md};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: start;
  color: ${(p) => p.theme.palette.grey[600]};
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[300]};
`;

const Body = styled(Sheet.Body)`
  flex: 1;
`;

const Footer = styled(Sheet.Footer)`
  height: 80px;
  padding: ${(p) => p.theme.space.md};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: start;
  color: ${(p) => p.theme.palette.grey[600]};
  border-top: 1px solid ${(p) => p.theme.palette.grey[300]};
`;
