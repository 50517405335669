import { silentUnreachableError } from "utils/exceptions";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

export const reducer =
  <P extends string>(p: P) =>
  (s: State.State<P>, a: Actions.Actions<P>): State.State<P> => {
    if (Actions.isOnChange(p)(a)) {
      return State.init(p)({
        ...s.payload,
        values: a.payload ?? {},
      });
    }

    silentUnreachableError(a);
    return s;
  };
