import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import * as Listing from "../states/Listing";
import * as Single from "../states/Single";

export type Actions = Listing.Actions | Single.Actions;

export const isActions = strictGuard((s: Actions): s is Actions => {
  if (Listing.isActions(s)) return true;
  if (Single.isActions(s)) return true;

  silentUnreachableError(s);
  return false;
});
