import { gql } from "@apollo/client";
import { flow } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { Transaction, TransactionId } from "types/src/Transactions/Transaction";
import { isT } from "fp-utilities";
import { Client, notFoundError, QueryResponse } from "../../index";
import { transactionFragment } from "../../fragments/Transaction";
import {
  GetTransactionsQuery,
  GetTransactionsQueryVariables,
} from "../../generated/graphql";
import {
  GetTransactionsVars,
  getTransactionsVarsToApiVars,
} from "../../type/Transactions";
import { transactionFragmentToTransaction } from "../../transformers/Transactions";
import {
  getPaginatedQueryResult,
  PaginatedQueryResponse,
} from "../../type/QueryResponse";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";

export type { GetTransactionsVars } from "../../type/Transactions";

export function getTransactions(
  client: Client,
  vars: GetTransactionsVars,
): Promise<PaginatedQueryResponse<Transaction>> {
  const query = gql`
    ${pageInfoFragment}
    ${transactionFragment}

    query GetTransactions(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: TransactionWhereInput
      $orderBy: TransactionOrder
    ) {
      transactions(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...TransactionFragment
          }
        }
      }
    }
  `;

  return client
    .query<GetTransactionsQuery, GetTransactionsQueryVariables>({
      query,
      variables: getTransactionsVarsToApiVars(vars),
    })
    .then(
      E.map(
        flow(
          (v) => v.transactions,
          getPaginatedQueryResult(transactionFragmentToTransaction),
        ),
      ),
    );
}

export function getTransaction(
  client: Client,
  id: TransactionId,
): Promise<QueryResponse<Transaction>> {
  return getTransactions(client, { where: { id: { eq: id } }, first: 1 }).then(
    flow(
      E.map((v) => v.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
  );
}
