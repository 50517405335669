import styled from "styled-components";
import { Spinner } from "@components/Spinner";

export function Loading() {
  return (
    <Wrapper>
      <Spinner size={"55px"} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
