import { ReactElement } from "react";
import { useTranslation } from "i18n";
import { TypographyMD } from "ui/components/Typography";
import { Selector } from "state-manager";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { GetContructorType } from "types/src/Utils";

const state = DataManager.pickingOrderState.subStates.listingAll;

type State = GetContructorType<typeof state.states>;
type Actions = GetContructorType<typeof state.actions>;

export interface HeaderProps {
  selector: Selector<State>;
  dispatch: (a: Actions) => void;
}

export function Header(p: HeaderProps): ReactElement {
  const { t } = useTranslation();
  return <TypographyMD>{t("Picking Orders")}</TypographyMD>;
}
