import { useCallback } from "react";
import { TranslatedStr } from "types/src/TranslatedStr";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/function";

export function useTranslation() {
  const t = useCallback(
    (key: string, options?: Record<string, string | number>): TranslatedStr => {
      return pipe(
        options,
        O.fromNullable,
        O.map(Object.entries),
        O.map((es) =>
          es.reduce((acc, [k, v]) => acc.replace(`{{${k}}}`, `${v}`), key),
        ),
        O.getOrElse(() => key),
      ) as TranslatedStr;
    },
    [],
  );
  const language = "en";
  return { t, language };
}
