import { silentUnreachableError } from "utils/exceptions";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import { isOneOf } from "utils/isOneOf";
import * as NoEmptyString from "types/src/NoEmptyString";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import * as Exits from "./types/Exits";
import { FiltersMonoid } from "./types/Filters";

export const reducer = <P extends string>(p: P) => {
  const fetching = State.fetching(p);
  const isFetching = State.isFetching(p);
  const isLoading = State.isLoading(p);
  const isReady = State.isReady(p);
  const loadError = State.loadError(p);
  const ready = State.ready(p);

  const isLoadFail = Actions.isLoadFail(p);
  const isLoadSuccess = Actions.isLoadSuccess(p);
  const isFetchSuccess = Actions.isFetchSuccess(p);
  const isSetPage = Actions.isSetPage(p);
  const isOrderBy = Actions.isOrderBy(p);
  const isSelect = Actions.isSelect(p);
  const isSelectAll = Actions.isSelectAll(p);
  const isRemoveItem = Actions.isRemoveItem(p);
  const isRemoveBulk = Actions.isRemoveBulk(p);
  const isRemoveConfirm = Actions.isRemoveConfirm(p);
  const isRemoveDecline = Actions.isRemoveDecline(p);
  const isRemoveSuccess = Actions.isRemoveSuccess(p);
  const isRemoveFail = Actions.isRemoveFail(p);
  const isSetUpdatedAtFilter = Actions.isSetUpdatedAtFilter(p);
  const isSetCreatedAtFilter = Actions.isSetCreatedAtFilter(p);
  const isSetSearchFilter = Actions.isSetSearchFilter(p);
  const isSetDataTypesFilter = Actions.isSetDataTypesFilter(p);
  const isSetIdFilter = Actions.isSetIdFilter(p);
  const isSetStatusFilter = Actions.isSetStatusFilter(p);
  const isSubmitFilters = Actions.isSubmitFilters(p);
  const isClearFilters = Actions.isClearFilters(p);
  const isOpenAdvancedFilters = Actions.isOpenAdvancedFilters(p);
  const isCloseAdvancedFilters = Actions.isCloseAdvancedFilters(p);

  return (
    s: State.State<P>,
    a: Actions.Actions<P>,
  ): E.Either<Exits.Exits<P>, State.State<P>> => {
    if (isLoadFail(a)) {
      if (isLoading(s)) return E.right(loadError(s.payload));
      if (isFetching(s)) return E.right(ready(s.payload));

      return E.right(s);
    }

    if (isLoadSuccess(a)) {
      if (isLoading(s)) {
        return E.right(
          ready({
            ...s.payload,
            total: a.payload.total,
            items: a.payload.items.map((i) => ({
              id: i.id,
              createdAt: i.createdAt,
              updatedAt: O.fromNullable(i.updatedAt),
              selected: false,
              removeState: "none",
              dataType: pipe(
                a.payload.dataTypes.find((d) => d.id === i.dataTypeId),
                O.fromNullable,
                O.map((d) => ({ id: d.id, name: d.name })),
              ),
            })),
            dataTypes: a.payload.dataTypes,
            pageInfo: a.payload.pageInfo,
            advancedFiltersState: "closed",
          }),
        );
      }

      return E.right(s);
    }

    if (isFetchSuccess(a)) {
      if (isFetching(s))
        return E.right(
          ready({
            ...s.payload,
            total: a.payload.total,
            items: a.payload.items.map((i) => ({
              id: i.id,
              createdAt: i.createdAt,
              updatedAt: O.fromNullable(i.updatedAt),
              selected:
                s.payload.items.find((si) => si.id === i.id)?.selected ?? false,
              removeState: "none",
              dataType: pipe(
                s.payload.dataTypes.find((d) => d.id === i.dataTypeId),
                O.fromNullable,
                O.map((d) => ({ id: d.id, name: d.name })),
              ),
            })),
            pageInfo: a.payload.pageInfo,
          }),
        );

      return E.right(s);
    }
    if (isSetPage(a)) {
      if (isReady(s) || isFetching(s))
        return E.right(
          fetching({
            ...s.payload,
            page: a.payload,
          }),
        );

      return E.right(s);
    }
    if (isOrderBy(a)) {
      if (isReady(s) || isFetching(s))
        return E.right(
          fetching({
            ...s.payload,
            page: "current",
            order: O.isNone(s.payload.order)
              ? O.of({ by: a.payload, direction: "asc" })
              : pipe(
                  s.payload.order,
                  O.chain((o) => {
                    if (o.by === a.payload) {
                      return pipe(
                        getNextDirection(O.of(o.direction)),
                        O.map((d) => ({ ...o, direction: d })),
                      );
                    } else {
                      return pipe(
                        O.of(a.payload),
                        O.map((by) => ({ by, direction: "asc" })),
                      );
                    }
                  }),
                ),
          }),
        );

      return E.right(s);
    }
    if (isSelect(a)) {
      return pipe(
        O.of(s),
        O.filter(isOneOf([isReady, isFetching])),
        O.filter((s) => s.payload.items.some((i) => i.id === a.payload)),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                items: s.payload.items.map((i) =>
                  i.id === a.payload ? { ...i, selected: !i.selected } : i,
                ),
              },
            }) as typeof s,
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isSelectAll(a)) {
      return pipe(
        O.of(s),
        O.filter(isOneOf([isReady, isFetching])),
        O.map((s) => {
          const allSelected = s.payload.items.every((i) => i.selected);
          return {
            ...s,
            payload: {
              ...s.payload,
              items: s.payload.items.map((i) => ({
                ...i,
                selected: !allSelected,
              })),
            },
          } as typeof s;
        }),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isRemoveItem(a)) {
      return pipe(
        O.of(s),
        O.filter(isReady),
        O.filter((s) => s.payload.items.some((i) => i.id === a.payload)),
        O.map((s) =>
          ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.id === a.payload ? { ...i, removeState: "confirmation" } : i,
            ),
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isRemoveBulk(a)) {
      return pipe(
        O.of(s),
        O.filter(isReady),
        O.filter((s) => s.payload.items.some((i) => i.selected)),
        O.map((s) =>
          ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.selected
                ? {
                    ...i,
                    removeState: "confirmation",
                  }
                : i,
            ),
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isRemoveConfirm(a)) {
      return pipe(
        O.of(s),
        O.filter(isReady),
        O.filter((s) =>
          s.payload.items.some((i) => i.removeState === "confirmation"),
        ),
        O.map((s) =>
          ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.removeState === "confirmation"
                ? {
                    ...i,
                    removeState: "removing",
                  }
                : i,
            ),
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isRemoveDecline(a)) {
      return pipe(
        O.of(s),
        O.filter(isReady),
        O.filter((s) =>
          s.payload.items.some((i) => i.removeState === "confirmation"),
        ),
        O.map((s) =>
          ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.removeState !== "none" ? { ...i, removeState: "none" } : i,
            ),
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isRemoveSuccess(a)) {
      return pipe(
        O.of(s),
        O.filter(isReady),
        O.filter((s) =>
          s.payload.items.some(
            (i) => i.removeState === "removing" && a.payload.includes(i.id),
          ),
        ),
        O.map((s) =>
          fetching({
            ...s.payload,
            page: "current",
            items: s.payload.items.filter(
              (i) =>
                !(i.removeState === "removing" && a.payload.includes(i.id)),
            ),
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isRemoveFail(a)) {
      return pipe(
        O.of(s),
        O.filter(isReady),
        O.filter((s) =>
          s.payload.items.some(
            (i) => i.removeState === "removing" && a.payload.includes(i.id),
          ),
        ),
        O.map((s) =>
          ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              !(i.removeState === "removing" && a.payload.includes(i.id))
                ? {
                    ...i,
                    removeState: "none",
                  }
                : i,
            ),
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isSetUpdatedAtFilter(a)) {
      return pipe(
        O.of(s),
        O.filter(isOneOf([isReady, isFetching])),
        O.map((s) =>
          ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              updatedAt: a.payload,
            },
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isSetCreatedAtFilter(a)) {
      return pipe(
        O.of(s),
        O.filter(isOneOf([isReady, isFetching])),
        O.map((s) =>
          ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              createdAt: a.payload,
            },
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isSetSearchFilter(a)) {
      return pipe(
        O.of(s),
        O.filter(isOneOf([isReady, isFetching])),
        O.map((s) =>
          ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              search: NoEmptyString.fromString(a.payload),
            },
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isSetDataTypesFilter(a)) {
      return pipe(
        O.of(s),
        O.filter(isOneOf([isReady, isFetching])),
        O.map((s) => {
          const dataTypes = s.payload.dataTypes.map((dt) => dt.id);
          return ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              dataTypes: a.payload.filter((d) => dataTypes.includes(d)),
            },
          });
        }),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isSetIdFilter(a)) {
      return pipe(
        O.of(s),
        O.filter(isOneOf([isReady, isFetching])),
        O.map((s) =>
          ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              id: NoEmptyString.fromString(a.payload),
            },
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isSetStatusFilter(a)) {
      return pipe(
        O.of(s),
        O.filter(isOneOf([isReady, isFetching])),
        O.map((s) =>
          ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              status: a.payload,
            },
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isSubmitFilters(a)) {
      return pipe(
        O.of(s),
        O.filter(isReady),
        O.map((s) =>
          fetching({
            ...s.payload,
            page: "start",
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isClearFilters(a)) {
      return pipe(
        O.of(s),
        O.filter(isOneOf([isReady, isFetching])),
        O.map((s) =>
          fetching({
            ...s.payload,
            page: "start",
            advancedFiltersState: "closed",
            filters: FiltersMonoid.empty,
          }),
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isOpenAdvancedFilters(a)) {
      return pipe(
        O.of(s),
        O.filter(isOneOf([isReady, isFetching])),
        O.filter((s) => s.payload.advancedFiltersState === "closed"),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                advancedFiltersState: "open",
              },
            }) as typeof s,
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }
    if (isCloseAdvancedFilters(a)) {
      return pipe(
        O.of(s),
        O.filter(isOneOf([isReady, isFetching])),
        O.filter((s) => s.payload.advancedFiltersState === "open"),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                advancedFiltersState: "closed",
              },
            }) as typeof s,
        ),
        O.map(E.right),
        O.getOrElseW(() => E.right(s)),
      );
    }

    silentUnreachableError(a);
    return E.right(s);
  };
};

function getNextDirection(
  d: O.Option<"asc" | "desc">,
): O.Option<"asc" | "desc"> {
  return O.isNone(d)
    ? O.some("asc")
    : pipe(
        d,
        O.chain((d) => (d === "asc" ? O.some("desc") : O.none)),
      );
}
