import { gql } from "@apollo/client";
import { repositoryFragment } from "./Repository";
import { inventoryItemFragment } from "./InventoryItem";

export const stockFragment = gql`
  ${repositoryFragment}
  ${inventoryItemFragment}

  fragment StockFragment on Stock {
    id
    createdAt
    updatedAt
    incomingStock
    movementID
    outgoingStock
    quantity
    repository {
      ...RepositoryFragment
    }
    item {
      ...InventoryItemFragment
    }
  }
`;
