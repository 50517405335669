import type { Eq } from "fp-ts/Eq";

export type RepositoryType = "dynamic" | "static";

export const eq: Eq<RepositoryType> = {
  equals: (a, b) => a === b,
};

export const repositoryTypes: RepositoryType[] = Object.values({
  dynamic: "dynamic",
  static: "static",
} satisfies { [k in RepositoryType]: k });
