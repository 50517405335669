import { silentUnreachableError } from "utils/exceptions";
import { DataTypeId } from "types/src/DataType/DataType";
import * as FormValue from "types/src/FormValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Repository } from "types/src/Repositories/Repository";
import { strictGuard } from "utils/strictGuard";
import { SearchItem, SearchItemValid } from "../../../types/SearchItem";
import { SchemaFieldsState } from "../utils";

// region State
export type State = Loading | LoadError | Ready | Saving;

export const isState = strictGuard((s: State): s is State => {
  const type = s.type as State["type"];

  switch (type) {
    case "Ready:DataManager:RepositoryMovements:Create:Loading":
    case "Ready:DataManager:RepositoryMovements:Create:LoadError":
    case "Ready:DataManager:RepositoryMovements:Create:Ready":
    case "Ready:DataManager:RepositoryMovements:Create:Saving":
      return true;
    default:
      silentUnreachableError(type);
      return false;
  }
});
// endregion

// region Loading
export interface LoadingPayload {
  dataTypeId: DataTypeId;
}

export interface Loading {
  type: "Ready:DataManager:RepositoryMovements:Create:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Ready:DataManager:RepositoryMovements:Create:Loading",
  payload,
});

export const isLoading = (a: State): a is Loading =>
  a.type === "Ready:DataManager:RepositoryMovements:Create:Loading";
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Ready:DataManager:RepositoryMovements:Create:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Ready:DataManager:RepositoryMovements:Create:LoadError",
  payload,
});

export const isLoadError = (a: State): a is LoadError =>
  a.type === "Ready:DataManager:RepositoryMovements:Create:LoadError";
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  dataTypeId: DataTypeId;
  schema: SchemaFieldsState;
  handler: FormValue.Value<"required", NoEmptyString, string | undefined>;
  item: FormValue.AsyncValue<
    "required",
    SearchItemValid<Repository>,
    SearchItem<Repository>
  >;
  from: FormValue.AsyncValue<
    "required",
    SearchItemValid<Repository>,
    SearchItem<Repository>
  >;
  to: FormValue.AsyncValue<
    "required",
    SearchItemValid<Repository>,
    SearchItem<Repository>
  >;
}

export interface Ready {
  type: "Ready:DataManager:RepositoryMovements:Create:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:DataManager:RepositoryMovements:Create:Ready",
  payload,
});

export const isReady = (a: State): a is Ready =>
  a.type === "Ready:DataManager:RepositoryMovements:Create:Ready";
// endregion

// region Saving
export interface SavingPayload
  extends FormValue.ExtractStructValid<ReadyPayload> {}

export interface Saving {
  type: "Ready:DataManager:RepositoryMovements:Create:Saving";
  payload: SavingPayload;
}

export const saving = (payload: Saving["payload"]): Saving => ({
  type: "Ready:DataManager:RepositoryMovements:Create:Saving",
  payload,
});

export const isSaving = (a: State): a is Saving =>
  a.type === "Ready:DataManager:RepositoryMovements:Create:Saving";
// endregion

export function isLoaded(a: State): a is Exclude<State, Loading | LoadError> {
  return !isLoading(a) && !isLoadError(a);
}

export const isEditable = isReady;

export const init = loading;
