import {
  Body,
  Cell,
  Head,
  HeaderCell,
  HeaderRow,
  Row,
  SortableCell,
  Table,
} from "ui/components/Table";
import { RootState, useSelector } from "state-manager";
import { SupplierId } from "types/src/Supplier/Supplier";
import { DataTypeId } from "types/src/DataType/DataType";
import { useTranslation } from "i18n";
import { flow, pipe } from "fp-ts/function";
import { shallowEqualArrays, shallowEqualObjects } from "shallow-equal";
import { ReactElement, useMemo } from "react";
import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import { Link } from "@Router/Link";
import { CheckHeadCell } from "ui/layouts/Listing/cell/CheckHeadCell";
import { CheckCell } from "ui/layouts/Listing/cell/CheckCell";
import {
  ActionsCell,
  ActionsHeaderCell,
} from "ui/layouts/Listing/cell/ActionsCell";
import { CopyTooltip } from "ui/components/CopyTooltip";
import { DateCell } from "ui/layouts/Listing/cell/DateCell";
import { routes } from "@/router";

interface Item {
  id: SupplierId;
  createdAt: Date;
  updatedAt: O.Option<Date>;
  dataType: O.Option<{ id: DataTypeId; name: string }>;
  selected: boolean;
}

export interface SuppliersTableProps {
  orderBy$: (
    s: RootState,
  ) => Option<{ by: "createdAt" | "updatedAt"; direction: "asc" | "desc" }>;
  items$: (s: RootState) => Item[];
  orderBy: (v: "createdAt" | "updatedAt") => void;
  onSelect: (id: SupplierId) => void;
  onDelete: (id: SupplierId) => void;
  onSelectAll: () => void;
  onDeleteAll: () => void;
}

export function SuppliersTable({
  items$,
  orderBy$,
  orderBy,
  onSelect,
  onSelectAll,
  onDelete,
  onDeleteAll,
}: SuppliersTableProps): ReactElement {
  const selected$ = useMemo(
    () =>
      flow(
        items$,
        (s) => s.map((s) => s.selected),
        (s) =>
          s.every(Boolean)
            ? "checked"
            : s.some(Boolean)
            ? "indeterminate"
            : "unchecked",
      ),
    [items$],
  );

  return (
    <Table>
      <Head isSticky>
        <Header
          selected$={selected$}
          orderBy$={orderBy$}
          orderBy={orderBy}
          onSelect={onSelectAll}
          onDelete={onDeleteAll}
        />
      </Head>
      <Content items$={items$} onSelect={onSelect} onDelete={onDelete} />
    </Table>
  );
}

interface HeaderProps {
  selected$: (s: RootState) => "checked" | "unchecked" | "indeterminate";
  orderBy$: (s: RootState) => Option<{
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  }>;
  orderBy: (v: "createdAt" | "updatedAt") => void;
  onSelect: () => void;
  onDelete: () => void;
}
function Header({
  orderBy,
  orderBy$,
  selected$,
  onSelect,
  onDelete,
}: HeaderProps): ReactElement {
  const { t } = useTranslation();
  const selected = useSelector(selected$);
  const sortByCreatedAt = useSelector(
    flow(
      orderBy$,
      flow(
        O.filter((o) => o.by === "createdAt"),
        O.map((o) => o.direction),
        O.toUndefined,
      ),
    ),
  );
  const sortByUpdatedAt = useSelector(
    flow(
      orderBy$,
      flow(
        O.filter((o) => o.by === "updatedAt"),
        O.map((o) => o.direction),
        O.toUndefined,
      ),
    ),
  );

  return (
    <HeaderRow>
      <CheckHeadCell value={selected} onChange={onSelect} />
      <HeaderCell>{t("Id")}</HeaderCell>
      <HeaderCell>{t("DataType")}</HeaderCell>
      <SortableCell sort={sortByCreatedAt} onClick={() => orderBy("createdAt")}>
        {t("Created")}
      </SortableCell>
      <SortableCell sort={sortByUpdatedAt} onClick={() => orderBy("updatedAt")}>
        {t("Updated")}
      </SortableCell>
      <ActionsHeaderCell
        actions={[
          {
            label: t("Delete"),
            onClick: onDelete,
          },
        ]}
      />
    </HeaderRow>
  );
}

interface ContentProps {
  items$: (s: RootState) => Item[];
  onSelect: (id: SupplierId) => void;
  onDelete: (id: SupplierId) => void;
}
function Content({ items$, onSelect, onDelete }: ContentProps): ReactElement {
  const items = useSelector(
    flow(items$, (vs) => {
      return vs.map((v) => ({
        key: v.id,
        item$: flow(items$, (s) => s.find((d) => d.id === v.id) as Item),
      }));
    }),
    (a, b) =>
      shallowEqualArrays(
        a.map((v) => v.key),
        b.map((v) => v.key),
      ),
  );

  return (
    <Body>
      {items.map(({ key, item$ }) => (
        <ItemRow
          key={key}
          item$={item$}
          onSelect={onSelect}
          onDelete={onDelete}
        />
      ))}
    </Body>
  );
}

interface ItemRowProps {
  item$: (s: RootState) => Item;
  onSelect: (id: SupplierId) => void;
  onDelete: (id: SupplierId) => void;
}
function ItemRow({ item$, onSelect, onDelete }: ItemRowProps) {
  const { t } = useTranslation();
  const { id, createdAt, updatedAt, dataType, selected } = useSelector(
    flow(item$, (c) => ({
      id: c.id,
      createdAt: c.createdAt,
      updatedAt: c.updatedAt,
      dataType: c.dataType,
      selected: c.selected,
    })),
    (a, b) => {
      return (
        a.id === b.id &&
        a.selected === b.selected &&
        O.getEq<object>({ equals: shallowEqualObjects }).equals(
          a.dataType,
          b.dataType,
        ) &&
        a.createdAt.getTime() === b.createdAt.getTime() &&
        O.getEq<Date>({ equals: (a, b) => a.getTime() === b.getTime() }).equals(
          a.updatedAt,
          b.updatedAt,
        )
      );
    },
  );

  return (
    <Row>
      <CheckCell value={selected} onChange={() => onSelect(id)} />
      <Cell $ellipsis>
        <CopyTooltip text={id}>
          <Link to={routes["/suppliers/edit/:id"].create({ id })}>{id}</Link>
        </CopyTooltip>
      </Cell>
      <Cell $ellipsis>
        {pipe(
          dataType,
          O.map(({ id, name }) => (
            <Link to={routes["/data-types/edit/:id"].create({ id })}>
              {name}
            </Link>
          )),
          O.toUndefined,
        )}
      </Cell>
      <DateCell value={O.of(createdAt)} />
      <DateCell value={updatedAt} />
      <ActionsCell
        actions={[
          {
            label: t("Delete"),
            onClick: () => onDelete(id),
          },
        ]}
      />
    </Row>
  );
}
