import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import * as Listing from "../states/Listing";
import * as Single from "../states/Single";

export type State = Listing.State | Single.State;

export const isState = strictGuard((s: State): s is State => {
  if (Listing.isState(s)) return true;
  if (Single.isState(s)) return true;

  silentUnreachableError(s);
  return false;
});
