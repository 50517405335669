import { OrderDirection } from "../generated/graphql";

export function toApiOrderDirection(v: "asc" | "desc"): OrderDirection {
  switch (v) {
    case "asc":
      return OrderDirection.Asc;
    case "desc":
      return OrderDirection.Desc;
  }
}
