import { PickingOrder } from "types/src/PickingOrder/PickingOrder";
import { toDate } from "types/src/date/ISODate";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import {
  PickingOrderItem,
  PickingOrderItemCreateWithoutOrder,
  PickingOrderItemId,
} from "types/src/PickingOrder/PickingOrderItem";
import { Sku } from "types/src/Sku";
import { omitEmpties } from "utils/value";
import {
  CreatePickingOrderItemsInput,
  PickingOrderItemFragmentFragment,
} from "../generated/graphql";

export function pickingOrderItemFragmentToPickingOrderItem(
  p: PickingOrderItemFragmentFragment,
): PickingOrderItem {
  return {
    id: p.id as PickingOrderItemId,
    fields: (p.data ?? {}) as PickingOrder["fields"],
    createdAt: toDate(p.createdAt),
    updatedAt: pipe(p.updatedAt, O.fromNullable, O.map(toDate), O.toUndefined),
    dataTypeId: p.dataTypeID as PickingOrder["dataTypeId"],
    itemId: undefined,
    orderId: p.orderID as PickingOrderItem["orderId"],
    sku: p.sku as Sku,
    quantity: p.quantity,
  };
}

export function pickingOrderItemCreateWithoutOrderToCreatePickingOrderItemsInput(
  v: PickingOrderItemCreateWithoutOrder,
): CreatePickingOrderItemsInput {
  return {
    data: omitEmpties(v.fields),
    sku: v.sku,
    dataTypeID: v.dataTypeId,
    quantity: v.quantity,
  };
}
