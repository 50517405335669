import { ReactElement } from "react";
import { RootState, useSelector } from "state-manager";
import { CursorPagination } from "ui/components/Pagination";
import { useTranslation } from "i18n";
import { shallowEqualObjects } from "shallow-equal";

export interface PaginationProps {
  selector: (s: RootState) => {
    hasPrev: boolean;
    hasNext: boolean;
  };
  onChange: (page: "start" | "prev" | "next" | "end") => void;
}

export function Pagination({
  selector,
  onChange,
}: PaginationProps): ReactElement | null {
  const { t } = useTranslation();
  const { hasPrev, hasNext } = useSelector(selector, shallowEqualObjects);

  return (
    <CursorPagination aria-label="Cursor pagination">
      <CursorPagination.First
        onClick={() => onChange("start")}
        disabled={!hasPrev}
      >
        {t("First")}
      </CursorPagination.First>
      <CursorPagination.Previous
        onClick={() => onChange("prev")}
        disabled={!hasPrev}
      >
        {t("Previous")}
      </CursorPagination.Previous>
      <CursorPagination.Next
        onClick={() => onChange("next")}
        disabled={!hasNext}
      >
        {t("Next")}
      </CursorPagination.Next>
      <CursorPagination.Last
        onClick={() => onChange("end")}
        disabled={!hasNext}
      >
        {t("Last")}
      </CursorPagination.Last>
    </CursorPagination>
  );
}
