import OpenAI from "openai";

export function getClient(apiKey: string, organization: string) {
  const openai = new OpenAI({
    apiKey,
    organization,
    dangerouslyAllowBrowser: true,
  });

  return openai;
}

export function generateFields(
  client: OpenAI,
  v: {
    fieldsSchema: string;
    data?: Record<string, "string" | "number" | "undefined">;
    options: {
      amount: number;
      responseExample: string;
      templateContext: string;
    };
  },
) {
  return client.chat.completions.create({
    // is much slower. Request took up to 5 sec
    // model: "gpt-4-turbo-preview",
    model: "gpt-3.5-turbo-1106",
    n: 1,
    response_format: { type: "json_object" },
    messages: [
      {
        role: "system",
        content: `Act like a Data Analyst,I need a data field generator, you will parse the JSON schema I send to you and generate dummy data based on it, in the process, you should  ensure that you generate data for ${v.options.templateContext} and pay extra attention for "label"  property in JSON schema and generate dummy data according to them, please copy the object you generate ${v.options.amount} times, input the final result in a JSON format which contain field "data" which contain an array of objects without extra properties, , here is an example: ${v.options.responseExample}`,
      },
      {
        role: "system",
        content: "Fill all fields u can find even not required ones",
      },
      {
        role: "user",
        content: v.fieldsSchema,
      },
    ],
    temperature: 0.7,
    max_tokens: 4096,
    top_p: 0.5,
    frequency_penalty: 0,
    presence_penalty: 0,
  });
}

export type Client = OpenAI;
