import { AdjustIcon } from "icons/Adjust";
import { useTranslation } from "i18n";
import styled from "styled-components";
import { XIcon } from "icons/X";
import { Button, ButtonProps } from "@components/Button";

export interface FiltersButtonProps extends Omit<ButtonProps, "children"> {
  isSelected: boolean;
  onClear?: () => void;
}

export function FiltersButton(props: FiltersButtonProps) {
  const { t } = useTranslation();
  return (
    <SButton {...props}>
      {props.isSelected && (
        <Status
          onClickCapture={(e) => {
            e.stopPropagation();
            props.onClear?.();
          }}
        >
          <XIcon />
        </Status>
      )}
      <Button.StartIcon
        onClick={(e) => {
          e.stopPropagation();
          props.onClear?.();
        }}
      >
        <AdjustIcon />
      </Button.StartIcon>
      {t("Filter")}
    </SButton>
  );
}

const SButton = styled(Button)`
  position: relative;
  overflow: visible;
`;

const Status = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 1;
  border: 2px solid ${({ theme }) => theme.palette.white};
  background: ${({ theme }) => theme.palette.green["500"]};
  border-radius: 50%;
  width: 10px;
  height: 10px;

  svg {
    width: 6px;
    height: 6px;
    fill: ${({ theme }) => theme.palette.white};
  }
`;
