import { useTranslation } from "i18n";
import { TypographyMD } from "ui/components/Typography";
import { Selector } from "state-manager";
import * as ZitadelPreview from "state-manager/states/Ready/states/ZitadelPreview";

export interface HeaderProps {
  selector: Selector<ZitadelPreview.State>;
  dispatch: (a: ZitadelPreview.Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();

  return <TypographyMD>{t("Welcome to the Zitadel App")}</TypographyMD>;
}
