import { ReactNode } from "react";
import { ThemeProvider as StyledProvider } from "@zendeskgarden/react-theming";

export interface ThemeProviderProps {
  children?: ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  return <StyledProvider>{children}</StyledProvider>;
}
