import { PageInfo } from "types";
import {
  InventoryItem,
  InventoryItemId,
} from "types/src/InventoryItems/InventoryItem";
import { silentUnreachableError } from "utils/exceptions";
import { Option } from "fp-ts/Option";
import { DataType } from "types/src/DataType/DataType";
import { strictGuard } from "utils/strictGuard";
import * as DataGenerator from "../../../../../../../../../generic-states/data-genetator";

// region Actions
export type Actions =
  | LoadSuccess
  | FetchSuccess
  | LoadFail
  | SetPage
  | OrderBy
  | Select
  | SelectAll
  | RemoveItem
  | RemoveBulk
  | RemoveConfirm
  | RemoveDecline
  | RemoveSuccess
  | RemoveFail
  | SetCreatedAtFilter
  | SetUpdatedAtFilter
  | SetSearchFilter
  | SetIdFilter
  | SubmitFilters
  | OpenAdvancedFilters
  | CloseAdvancedFilters
  | ClearFilters
  | Create
  | GenerateSuccess
  | DataGenerator.Actions;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (DataGenerator.isActions(a)) return true;

  switch (a.type) {
    case "Ready:DataManager:InventoryItems:Listing:LoadSuccess":
    case "Ready:DataManager:InventoryItems:Listing:GenerateSuccess":
    case "Ready:DataManager:InventoryItems:Listing:FetchSuccess":
    case "Ready:DataManager:InventoryItems:Listing:LoadFail":
    case "Ready:DataManager:InventoryItems:Listing:SetPage":
    case "Ready:DataManager:InventoryItems:Listing:Select":
    case "Ready:DataManager:InventoryItems:Listing:SelectAll":
    case "Ready:DataManager:InventoryItems:Listing:OrderBy":
    case "Ready:DataManager:InventoryItems:Listing:RemoveBulk":
    case "Ready:DataManager:InventoryItems:Listing:RemoveConfirm":
    case "Ready:DataManager:InventoryItems:Listing:RemoveDecline":
    case "Ready:DataManager:InventoryItems:Listing:RemoveFail":
    case "Ready:DataManager:InventoryItems:Listing:RemoveItem":
    case "Ready:DataManager:InventoryItems:Listing:RemoveSuccess":
    case "Ready:DataManager:InventoryItems:Listing:SetIdFilter":
    case "Ready:DataManager:InventoryItems:Listing:SetCreatedAtFilter":
    case "Ready:DataManager:InventoryItems:Listing:SetUpdatedAtFilter":
    case "Ready:DataManager:InventoryItems:Listing:SetSearchFilter":
    case "Ready:DataManager:InventoryItems:Listing:SubmitFilters":
    case "Ready:DataManager:InventoryItems:Listing:CloseAdvancedFilters":
    case "Ready:DataManager:InventoryItems:Listing:OpenAdvancedFilters":
    case "Ready:DataManager:InventoryItems:Listing:ClearFilters":
    case "Ready:DataManager:InventoryItems:Listing:Create":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:InventoryItems:Listing:LoadSuccess";
  payload: {
    pageInfo: PageInfo;
    total: number;
    items: InventoryItem[];
    dataType: DataType;
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:InventoryItems:Listing:LoadSuccess",
  payload,
});
// endregion

// region GenerateSuccess
export interface GenerateSuccess {
  type: "Ready:DataManager:InventoryItems:Listing:GenerateSuccess";
}

export const generateSuccess = (): GenerateSuccess => ({
  type: "Ready:DataManager:InventoryItems:Listing:GenerateSuccess",
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:InventoryItems:Listing:LoadFail";
  payload: {
    type: undefined;
  };
}

export const loadFail = (payload: LoadFail["payload"]): LoadFail => ({
  type: "Ready:DataManager:InventoryItems:Listing:LoadFail",
  payload,
});
// endregion

// region FetchSuccess
export interface FetchSuccess {
  type: "Ready:DataManager:InventoryItems:Listing:FetchSuccess";
  payload: {
    pageInfo: PageInfo;
    total: number;
    items: InventoryItem[];
  };
}

export const fetchSuccess = (
  payload: FetchSuccess["payload"],
): FetchSuccess => ({
  type: "Ready:DataManager:InventoryItems:Listing:FetchSuccess",
  payload,
});
// endregion

// region SetPage
export interface SetPage {
  type: "Ready:DataManager:InventoryItems:Listing:SetPage";
  payload: "start" | "prev" | "next" | "end";
}

export const setPage = (payload: SetPage["payload"]): SetPage => ({
  type: "Ready:DataManager:InventoryItems:Listing:SetPage",
  payload,
});
// endregion

// region OrderBy
export interface OrderBy {
  type: "Ready:DataManager:InventoryItems:Listing:OrderBy";
  payload: "createdAt" | "updatedAt";
}

export const orderBy = (payload: OrderBy["payload"]): OrderBy => ({
  type: "Ready:DataManager:InventoryItems:Listing:OrderBy",
  payload,
});
// endregion

// region Select
export interface Select {
  type: "Ready:DataManager:InventoryItems:Listing:Select";
  payload: InventoryItemId;
}

export const select = (payload: Select["payload"]): Select => ({
  type: "Ready:DataManager:InventoryItems:Listing:Select",
  payload,
});
// endregion

// region SelectAll
export interface SelectAll {
  type: "Ready:DataManager:InventoryItems:Listing:SelectAll";
}

export const selectAll = (): SelectAll => ({
  type: "Ready:DataManager:InventoryItems:Listing:SelectAll",
});
// endregion

// region RemoveItem
export interface RemoveItem {
  type: "Ready:DataManager:InventoryItems:Listing:RemoveItem";
  payload: InventoryItemId;
}

export const removeItem = (payload: RemoveItem["payload"]): RemoveItem => ({
  type: "Ready:DataManager:InventoryItems:Listing:RemoveItem",
  payload,
});
// endregion

// region RemoveBulk
export interface RemoveBulk {
  type: "Ready:DataManager:InventoryItems:Listing:RemoveBulk";
}

export const removeBulk = (): RemoveBulk => ({
  type: "Ready:DataManager:InventoryItems:Listing:RemoveBulk",
});
// endregion

// region RemoveConfirm
export interface RemoveConfirm {
  type: "Ready:DataManager:InventoryItems:Listing:RemoveConfirm";
}

export const removeConfirm = (): RemoveConfirm => ({
  type: "Ready:DataManager:InventoryItems:Listing:RemoveConfirm",
});
// endregion

// region RemoveDecline
export interface RemoveDecline {
  type: "Ready:DataManager:InventoryItems:Listing:RemoveDecline";
}

export const removeDecline = (): RemoveDecline => ({
  type: "Ready:DataManager:InventoryItems:Listing:RemoveDecline",
});
// endregion

// region RemoveSuccess
export interface RemoveSuccess {
  type: "Ready:DataManager:InventoryItems:Listing:RemoveSuccess";
  payload: InventoryItemId[];
}

export const removeSuccess = (
  payload: RemoveSuccess["payload"],
): RemoveSuccess => ({
  type: "Ready:DataManager:InventoryItems:Listing:RemoveSuccess",
  payload,
});
// endregion

// region RemoveFail
export interface RemoveFail {
  type: "Ready:DataManager:InventoryItems:Listing:RemoveFail";
  payload: InventoryItemId[];
}

export const removeFail = (payload: RemoveFail["payload"]): RemoveFail => ({
  type: "Ready:DataManager:InventoryItems:Listing:RemoveFail",
  payload,
});
// endregion

// region SetCreatedAtFilter
export interface SetCreatedAtFilter {
  type: "Ready:DataManager:InventoryItems:Listing:SetCreatedAtFilter";
  payload: Option<[Date | undefined, Date | undefined]>;
}

export const setCreatedAtFilter = (
  payload: SetCreatedAtFilter["payload"],
): SetCreatedAtFilter => ({
  type: "Ready:DataManager:InventoryItems:Listing:SetCreatedAtFilter",
  payload,
});
// endregion

// region SetUpdatedAtFilter
export interface SetUpdatedAtFilter {
  type: "Ready:DataManager:InventoryItems:Listing:SetUpdatedAtFilter";
  payload: Option<[Date | undefined, Date | undefined]>;
}

export const setUpdatedAtFilter = (
  payload: SetUpdatedAtFilter["payload"],
): SetUpdatedAtFilter => ({
  type: "Ready:DataManager:InventoryItems:Listing:SetUpdatedAtFilter",
  payload,
});
// endregion

// region SetSearchFilter
export interface SetSearchFilter {
  type: "Ready:DataManager:InventoryItems:Listing:SetSearchFilter";
  payload: string;
}

export const setSearchFilter = (
  payload: SetSearchFilter["payload"],
): SetSearchFilter => ({
  type: "Ready:DataManager:InventoryItems:Listing:SetSearchFilter",
  payload,
});
// endregion

// region SetIdFilter
export interface SetIdFilter {
  type: "Ready:DataManager:InventoryItems:Listing:SetIdFilter";
  payload: string;
}

export const setIdFilter = (payload: SetIdFilter["payload"]): SetIdFilter => ({
  type: "Ready:DataManager:InventoryItems:Listing:SetIdFilter",
  payload,
});
// endregion

// region SubmitFilters
export interface SubmitFilters {
  type: "Ready:DataManager:InventoryItems:Listing:SubmitFilters";
}

export const submitFilters = (): SubmitFilters => ({
  type: "Ready:DataManager:InventoryItems:Listing:SubmitFilters",
});
// endregion

// region OpenAdvancedFilters
export interface OpenAdvancedFilters {
  type: "Ready:DataManager:InventoryItems:Listing:OpenAdvancedFilters";
}

export const openAdvancedFilters = (): OpenAdvancedFilters => ({
  type: "Ready:DataManager:InventoryItems:Listing:OpenAdvancedFilters",
});
// endregion

// region CloseAdvancedFilters
export interface CloseAdvancedFilters {
  type: "Ready:DataManager:InventoryItems:Listing:CloseAdvancedFilters";
}

export const closeAdvancedFilters = (): CloseAdvancedFilters => ({
  type: "Ready:DataManager:InventoryItems:Listing:CloseAdvancedFilters",
});
// endregion

// region ClearFilters
export interface ClearFilters {
  type: "Ready:DataManager:InventoryItems:Listing:ClearFilters";
}

export const clearFilters = (): ClearFilters => ({
  type: "Ready:DataManager:InventoryItems:Listing:ClearFilters",
});
// endregion

// region Create
export interface Create {
  type: "Ready:DataManager:InventoryItems:Listing:Create";
}

export const create = (): Create => ({
  type: "Ready:DataManager:InventoryItems:Listing:Create",
});
// endregion
