import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import {
  createPickingOrderState,
  listingAllPickingOrderState,
  ListingAllState,
  ListingState,
  listingPickingOrderState,
  CreateState,
  EditState,
  editPickingOrderState,
} from "../utils";

export type State = CreateState | ListingAllState | ListingState | EditState;

export const isState = strictGuard((s: State): s is State => {
  if (createPickingOrderState.isState(s)) return true;
  if (listingAllPickingOrderState.isState(s)) return true;
  if (listingPickingOrderState.isState(s)) return true;
  if (editPickingOrderState.isState(s)) return true;

  silentUnreachableError(s);
  return false;
});
