import { Field } from "ui/components/Field";
import { Label } from "ui/components/Label";
import { TranslatedStr } from "types/src/TranslatedStr";
import { DatePickerInput, InputProps } from "@Containers/Form/DatePickerInput";

interface FilterInputProps extends InputProps {
  label: TranslatedStr;
}
export function FilterDatePicker({ label, ...p }: FilterInputProps) {
  return (
    <Field>
      <Label>{label}</Label>
      <DatePickerInput {...p} />
    </Field>
  );
}
