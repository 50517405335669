import { GetSuppliersVars } from "ds/Suppliers";
import * as O from "fp-ts/Option";
import * as NonEmptyArray from "fp-ts/NonEmptyArray";
import * as State from "./types/State";

export function getFetchVars(
  s: State.Loading | State.Fetching,
): GetSuppliersVars {
  const where: GetSuppliersVars["where"] = {
    createdAt: O.toUndefined(s.payload.filters.createdAt),
    updatedAt: O.toUndefined(s.payload.filters.updatedAt),
    id: O.toUndefined(s.payload.filters.id),
    search: O.toUndefined(s.payload.filters.search),
    dataTypes: O.toUndefined(
      NonEmptyArray.fromArray(s.payload.filters.dataTypes),
    ),
    orphans: {
      all: undefined,
      active: false,
      orphan: true,
    }[s.payload.filters.status],
  };

  switch (s.type) {
    case "Ready:DataManager:Suppliers:ListingAll:Loading":
      return {
        first: s.payload.perPage,
        orderBy: O.toUndefined(s.payload.order),
        where,
      };
    case "Ready:DataManager:Suppliers:ListingAll:Fetching": {
      switch (s.payload.page) {
        case "start":
          return {
            first: s.payload.perPage,
            orderBy: O.toUndefined(s.payload.order),
            where,
          };
        case "prev":
          return {
            last: s.payload.perPage,
            before: s.payload.pageInfo.prevCursor,
            orderBy: O.toUndefined(s.payload.order),
            where,
          };
        case "next":
          return {
            first: s.payload.perPage,
            after: s.payload.pageInfo.nextCursor,
            orderBy: O.toUndefined(s.payload.order),
            where,
          };
        case "end":
          return {
            last: s.payload.perPage,
            orderBy: O.toUndefined(s.payload.order),
            where,
          };
        case "current":
          return {
            first: s.payload.perPage,
            orderBy: O.toUndefined(s.payload.order),
            where,
          };
      }
    }
  }
}
