import { Stock } from "types/src/Stocks/Stock";
import { StockFragmentFragment } from "../generated/graphql";
import { repositoryFragmentToRepository } from "./Repositories";
import { inventoryItemFragmentToInventoryItem } from "./InventoryItems";

export function stockFragmentToStock(v: StockFragmentFragment): Stock {
  return {
    id: v.id as Stock["id"],
    createdAt: new Date(v.createdAt),
    updatedAt: v.updatedAt ? new Date(v.updatedAt) : undefined,
    incomingStock: v.incomingStock,
    movementID: v.movementID,
    outgoingStock: v.outgoingStock,
    quantity: v.quantity,
    repository: repositoryFragmentToRepository(v.repository),
    item: inventoryItemFragmentToInventoryItem(v.item),
  };
}
