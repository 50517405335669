export interface WhereEq<T> {
  eq?: T;
  neq?: T;
  in?: T[];
  notIn?: T[];
}

export type ApiWhereEq<T, K extends string> = {
  [k in K | `${K}NEQ` | `${K}In` | `${K}NotIn`]?: k extends K | `${K}NEQ`
    ? T
    : T[];
};

export const whereEq = <K extends string>(
  k: K,
): (<T>(v: WhereEq<T>) => ApiWhereEq<T, K>) => {
  return <T>(v: WhereEq<T>) =>
    ({
      [k]: v.eq,
      [`${k}NEQ`]: v.neq,
      [`${k}In`]: v.in,
      [`${k}NotIn`]: v.notIn,
    }) as ApiWhereEq<T, K>;
};

export interface WhereOrd<T> extends WhereEq<T> {
  gt?: T;
  gte?: T;
  lt?: T;
  lte?: T;
}

export type ApiWhereOrd<T, K extends string> = ApiWhereEq<T, K> & {
  [k in `${K}GT` | `${K}GTE` | `${K}LT` | `${K}LE`]?: T;
};

export const whereOrd = <K extends string>(
  k: K,
): (<T>(v: WhereOrd<T>) => ApiWhereOrd<T, K>) => {
  return <T>(v: WhereOrd<T>) =>
    ({
      ...whereEq(k)(v),
      [`${k}GT`]: v.gt,
      [`${k}GTE`]: v.gte,
      [`${k}LT`]: v.lt,
      [`${k}LE`]: v.lte,
    }) as ApiWhereOrd<T, K>;
};

export function combineWhere<
  A extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  B extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
>(a: A, b: B): A & B;
export function combineWhere<
  A extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  B extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  C extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
>(a: A, b: B, c: C): A & B & C;
export function combineWhere<
  A extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  B extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  C extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  D extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
>(a: A, b: C, d: D): A & B & C & D;
export function combineWhere<
  A extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  B extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  C extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  D extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  E extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
>(a: A, b: C, d: D, e: E): A & B & C & D & E;
export function combineWhere<
  A extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  B extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  C extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  D extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  E extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  F extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
>(a: A, b: C, d: D, e: E, f: F): A & B & C & D & E & F;
export function combineWhere<
  A extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  B extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  C extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  D extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  E extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  F extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
  G extends ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>,
>(a: A, b: C, d: D, e: E, f: F, g: G): A & B & C & D & E & F & G;
export function combineWhere<
  T extends Array<ApiWhereEq<unknown, string> | ApiWhereOrd<unknown, string>>,
>(...t: T): ApiWhereEq<unknown, string> & ApiWhereOrd<unknown, string> {
  return t.reduce((acc, v) => Object.assign(acc, v));
}

export interface WhereData {
  eq?: string[];
  hasKey?: string;
  in?: string[];
  contains?: string[];
}
