import { RootState, useSelector } from "state-manager";
import * as State from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Listing";
import { ReactElement } from "react";
import { Pagination } from "@Containers/Ready/DataManager/DataTypes/Listing/components/Pagination";
import { silentUnreachableError } from "utils/exceptions";
import { Footer as FooterLayout } from "ui/layouts/Footer";
import { Button } from "ui/components/Button";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";

export interface FooterProps {
  selector: (s: RootState) => State.State;
  dispatch: (a: State.Actions) => void;
}

export function Footer({
  selector,
  dispatch,
}: FooterProps): ReactElement | null {
  const { t } = useTranslation();
  const r = useSelector(
    flow(selector, (s: State.State) => {
      if (State.isLoading(s)) return { type: "null" } as const;
      if (State.isLoadError(s)) return { type: "null" } as const;

      if (State.isReady(s) || State.isFetching(s)) {
        return {
          type: "paginated",
          selector: flow(
            selector,
            (s) => s as State.Ready | State.Fetching,
            (s) => ({
              hasPrev: s.payload.pageInfo.hasPreviousPage,
              hasNext: s.payload.pageInfo.hasNextPage,
            }),
          ),
        } as const;
      }

      silentUnreachableError(s);
      return { type: "null" } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "null":
      return null;
    case "paginated":
      return (
        <FooterLayout>
          <Button isPrimary onClick={() => dispatch(State.create())}>
            {t("Add new")}
          </Button>
          <Pagination
            selector={r.selector}
            onChange={(v) => dispatch(State.fetch(v))}
          />
        </FooterLayout>
      );
  }
}
