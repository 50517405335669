import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import { DataTypeId } from "types/src/DataType/DataType";
import { CustomerId } from "types/src/Customers/Customer";
import { SupplierId } from "types/src/Supplier/Supplier";
import { RepositoryId } from "types/src/Repositories/Repository";
import { ItemMovementId } from "types/src/ItemMovements/ItemMovement";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { RepositoryMovementId } from "types/src/RepositoryMovements/RepositoryMovement";
import { StockId } from "types/src/Stocks/Stock";
import { TransactionId } from "types/src/Transactions/Transaction";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";

export type State =
  | Idle
  | Login
  | AuthConfirmation
  | Authentication
  | AuthError;

export const isState = strictGuard((s: State): s is State => {
  if (isIdle(s)) return true;
  if (isLogin(s)) return true;
  if (isAuthConfirmation(s)) return true;
  if (isAuthentication(s)) return true;
  if (isAuthError(s)) return true;

  silentUnreachableError(s);
  return false;
});

// region Idle
export interface IdlePayload {
  goTo:
    | { type: "data-types:listing" }
    | { type: "data-types:create" }
    | { type: "data-types:edit"; id: DataTypeId }
    | { type: "customers" }
    | { type: "customers:data-type"; id: DataTypeId }
    | { type: "customers:create"; dataTypeId: DataTypeId }
    | { type: "customers:edit"; id: CustomerId }
    | { type: "suppliers" }
    | { type: "suppliers:data-type"; id: DataTypeId }
    | { type: "suppliers:create"; dataTypeId: DataTypeId }
    | { type: "warehouse-builder" }
    | { type: "bpmn-builder" }
    | { type: "zitadel-app" }
    | { type: "graphql-sandbox" }
    | { type: "suppliers:edit"; id: SupplierId }
    | { type: "repositories" }
    | { type: "repositories:data-type"; id: DataTypeId }
    | { type: "repositories:create"; dataTypeId: DataTypeId }
    | { type: "repositories:edit"; id: RepositoryId }
    | { type: "inventoryItems" }
    | { type: "inventoryItems:data-type"; id: DataTypeId }
    | { type: "inventoryItems:create"; dataTypeId: DataTypeId }
    | { type: "inventoryItems:edit"; id: InventoryItemId }
    | { type: "itemMovements" }
    | { type: "itemMovements:data-type"; id: DataTypeId }
    | { type: "itemMovements:create"; id: DataTypeId }
    | { type: "itemMovements:edit"; id: ItemMovementId }
    | { type: "repositoryMovements" }
    | { type: "repositoryMovements:data-type"; id: DataTypeId }
    | { type: "repositoryMovements:create"; id: DataTypeId }
    | { type: "repositoryMovements:edit"; id: RepositoryMovementId }
    | { type: "pickingOrders" }
    | { type: "pickingOrders:data-type"; id: DataTypeId }
    | { type: "pickingOrders:create"; id: DataTypeId }
    | { type: "pickingOrders:edit"; id: PickingOrderId }
    | { type: "stocks" }
    | { type: "stocks:single"; id: StockId }
    | { type: "transactions" }
    | { type: "transactions:single"; id: TransactionId };
}

export interface Idle {
  type: "Auth:Idle";
  payload: IdlePayload;
}

export const idle = (payload: Idle["payload"]): Idle => ({
  type: "Auth:Idle",
  payload,
});

export const isIdle = (s: State): s is Idle => s.type === "Auth:Idle";
// endregion

// region Login
export interface LoginPayload extends IdlePayload {}

export interface Login {
  type: "Auth:Login";
  payload: LoginPayload;
}

export const login = (payload: Login["payload"]): Login => ({
  type: "Auth:Login",
  payload,
});

export const isLogin = (s: State): s is Login => s.type === "Auth:Login";
// endregion

// region AuthConfirmation
export interface AuthConfirmationPayload {}

export interface AuthConfirmation {
  type: "Auth:AuthConfirmation";
  payload: AuthConfirmationPayload;
}

export const authConfirmation = (
  payload: AuthConfirmation["payload"],
): AuthConfirmation => ({
  type: "Auth:AuthConfirmation",
  payload,
});

export const isAuthConfirmation = (s: State): s is AuthConfirmation =>
  s.type === "Auth:AuthConfirmation";
// endregion

// region Authentication
export interface AuthenticationPayload extends LoginPayload {}

export interface Authentication {
  type: "Auth:Authentication";
  payload: AuthenticationPayload;
}

export const authentication = (
  payload: Authentication["payload"],
): Authentication => ({
  type: "Auth:Authentication",
  payload,
});

export const isAuthentication = (s: State): s is Authentication =>
  s.type === "Auth:Authentication";
// endregion

// region AuthError
export interface AuthErrorPayload extends LoginPayload {}

export interface AuthError {
  type: "Auth:AuthError";
  payload: AuthErrorPayload;
}

export const authError = (payload: AuthError["payload"]): AuthError => ({
  type: "Auth:AuthError",
  payload,
});

export const isAuthError = (s: State): s is AuthError =>
  s.type === "Auth:AuthError";
// endregion

export const init = idle;
