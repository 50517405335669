import * as StateModule from "./types/State";
import * as ActionsModule from "./types/Actions";
import { reducer } from "./reducer";
import { epic } from "./epic";
import { createPickingOrderItemState } from "./utils";

export const createState = <P extends string, Id extends string>(p: P) => {
  return {
    isState: StateModule.isState<P, Id>(p),
    isActions: ActionsModule.isActions<P, Id>(p),
    reducer: reducer<P, Id>(p),
    epic: epic<P, Id>(p),
    states: {
      ready: {
        create: StateModule.ready<P, Id>(p),
        is: StateModule.isReady<P, Id>(p),
      },
      valid: {
        create: StateModule.valid<P, Id>(p),
        is: StateModule.isValid<P, Id>(p),
      },
    },
    init: StateModule.init<P, Id>(p),
    actions: {
      addNew: {
        create: ActionsModule.addNew<P>(p),
        is: ActionsModule.isAddNew<P, Id>(p),
      },
      remove: {
        create: ActionsModule.remove<P, Id>(p),
        is: ActionsModule.isRemove<P, Id>(p),
      },
      submit: {
        create: ActionsModule.submit<P>(p),
        is: ActionsModule.isSubmit<P, Id>(p),
      },
    },
    createPickingOrderItemState: createPickingOrderItemState<P, Id>(p),
  };
};

export type { State } from "./types/State";
export type { Actions } from "./types/Actions";

export type StateConstructor<P extends string, Id extends string> = ReturnType<
  typeof createState<P, Id>
>;

export type { NewItemId } from "./types/NewItemId";
