import { Selector, useSelector } from "state-manager";
import * as Ready from "state-manager/states/Ready";
import { isSigningOut } from "state-manager/states/Ready";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import * as BuilderPreview from "state-manager/states/Ready/states/BuilderPreview";
import * as BPMNPreview from "state-manager/states/Ready/states/BPMNPreview";
import * as ZitadelPreview from "state-manager/states/Ready/states/ZitadelPreview";
import * as DataManagerComponent from "@Containers/Ready/DataManager";
import * as BuilderPreviewComponent from "@Containers/Ready/BuilderPreview";
import * as BPMNPreviewComponent from "@Containers/Ready/BPMNPreview";
import * as ZitadelPreviewComponent from "@Containers/Ready/ZitadelPreview";
import * as SandboxPreview from "state-manager/states/Ready/states/SandboxPreview";
import * as SandboxPreviewComponent from "@Containers/Ready/SandboxPreview";

import { silentUnreachableError } from "utils/exceptions";
import { ReactElement } from "react";
import { Loading } from "ui/layouts/Loading";
import * as E from "fp-ts/Either";

import { flow } from "fp-ts/function";

export interface ReadyContentProps {
  selector: Selector<Ready.State>;
  dispatch: (a: Ready.Actions) => void;
}

export function Content({
  selector,
  dispatch,
}: ReadyContentProps): ReactElement {
  const r = useSelector(
    flow(
      selector,
      (s) => s.payload.subState,
      (s) => {
        if (isSigningOut(s)) return { type: "loading" } as const;
        if (DataManager.isState(s)) {
          return {
            type: "data-manager",
            selector: flow(
              selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (BuilderPreview.isState(s)) {
          return {
            type: "builder-preview",
            selector: flow(
              selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (BPMNPreview.isState(s)) {
          return {
            type: "bpmn-preview",
            selector: flow(
              selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (SandboxPreview.isState(s)) {
          return {
            type: "sandbox-preview",
            selector: flow(
              selector,
              (s) => s.payload.user,
              E.map((u) => u.accessToken),
              E.getOrElseW(() => undefined),
            ),
          } as const;
        }
        if (ZitadelPreview.isState(s)) {
          return {
            type: "zitadel-preview",
            selector: flow(
              selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }

        silentUnreachableError(s);
        return { type: "loading" } as const;
      },
    ),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "data-manager":
      return (
        <DataManagerComponent.Content
          selector={r.selector}
          dispatch={dispatch}
        />
      );
    case "builder-preview":
      return (
        <BuilderPreviewComponent.Content
          selector={r.selector}
          dispatch={dispatch}
        />
      );
    case "bpmn-preview":
      return (
        <BPMNPreviewComponent.Content
          selector={r.selector}
          dispatch={dispatch}
        />
      );
    case "sandbox-preview":
      return <SandboxPreviewComponent.Content selector={r.selector} />;
    case "zitadel-preview":
      return (
        <ZitadelPreviewComponent.Content
          selector={r.selector}
          dispatch={dispatch}
        />
      );
  }
}
