import { Selector, useSelector } from "state-manager";
import { ReactElement } from "react";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { Field } from "ui/components/Field";
import { Label } from "ui/components/Label";
import { Input } from "@Containers/Form/Input";
import { flow } from "fp-ts/function";
import { useTranslation } from "i18n";
import { Link } from "@Router/Link";
import * as Single from "../../../../../../../../packages/state-manager/src/states/Ready/states/DataManager/states/Stocks/states/Single";
import { routes } from "@/router";

export interface FormProps {
  selector: Selector<Single.Ready>;
}

export function Form(p: FormProps): ReactElement {
  const { t } = useTranslation();
  const itemId = useSelector(
    flow(p.selector, (s) => s.payload.inventoryItemId),
  );
  const repositoryId = useSelector(
    flow(p.selector, (s) => s.payload.repositoryId),
  );
  const repositoryName = useSelector(
    flow(p.selector, (s) => s.payload.repositoryName),
  );

  return (
    <FormWrapper>
      <Field>
        <Label>{t("Stock Id")}</Label>
        <Input
          value$={flow(p.selector, (v) => v.payload.id)}
          onChange={() => {}}
          readOnly
        />
      </Field>
      <Field>
        <Label>{t("Incoming stock")}</Label>
        <Input
          value$={flow(p.selector, (v) => v.payload.incoming.toString())}
          onChange={() => {}}
          readOnly
        />
      </Field>
      <Field>
        <Label>{t("Quantity")}</Label>
        <Input
          value$={flow(p.selector, (v) => v.payload.quantity.toString())}
          onChange={() => {}}
          readOnly
        />
      </Field>
      <Field>
        <Label>{t("Outgoing stock")}</Label>
        <Input
          value$={flow(p.selector, (v) => v.payload.outgoing.toString())}
          onChange={() => {}}
          readOnly
        />
      </Field>
      <Field>
        <Label>
          {t("Item")}{" "}
          <Link to={routes["/inventory-items/edit/:id"].create({ id: itemId })}>
            {itemId}
          </Link>
        </Label>
      </Field>
      <Field>
        <Label>
          {t("Repository")}{" "}
          <Link
            to={routes["/repositories/edit/:id"].create({ id: repositoryId })}
          >
            {repositoryName}
          </Link>
        </Label>
      </Field>
    </FormWrapper>
  );
}
