import styled, { css } from "styled-components";
import * as Table from "@zendeskgarden/react-tables";

export {
  Body,
  Head,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
  SortableCell,
} from "@zendeskgarden/react-tables";

export type { ICellProps as CellProps } from "@zendeskgarden/react-tables";

export const Cell = styled(Table.Cell).attrs<{ $ellipsis?: boolean }>((p) => ({
  $ellipsis: p.$ellipsis,
}))`
  ${(props) =>
    props.$ellipsis
      ? css`
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        `
      : ""}
`;
