import { PageInfo } from "types";
import { DataType } from "types/src/DataType/DataType";
import { silentUnreachableError } from "utils/exceptions";
import { Option } from "fp-ts/Option";
import { strictGuard } from "utils/strictGuard";
import {
  PickingOrder,
  PickingOrderId,
} from "types/src/PickingOrder/PickingOrder";

// region Actions
export type Actions<P extends string> =
  | LoadSuccess<P>
  | FetchSuccess<P>
  | LoadFail<P>
  | SetPage<P>
  | OrderBy<P>
  | Select<P>
  | SelectAll<P>
  | RemoveItem<P>
  | RemoveBulk<P>
  | RemoveConfirm<P>
  | RemoveDecline<P>
  | RemoveSuccess<P>
  | RemoveFail<P>
  | SetCreatedAtFilter<P>
  | SetUpdatedAtFilter<P>
  | SetSearchFilter<P>
  | SetIdFilter<P>
  | SetStatusFilter<P>
  | SubmitFilters<P>
  | OpenAdvancedFilters<P>
  | CloseAdvancedFilters<P>
  | ClearFilters<P>
  | Create<P>;

export const isActions = <P extends string>(p: P) => {
  const _isLoadSuccess = isLoadSuccess(p);
  const _isFetchSuccess = isFetchSuccess(p);
  const _isLoadFail = isLoadFail(p);
  const _isSetPage = isSetPage(p);
  const _isOrderBy = isOrderBy(p);
  const _isSelect = isSelect(p);
  const _isSelectAll = isSelectAll(p);
  const _isRemoveItem = isRemoveItem(p);
  const _isRemoveBulk = isRemoveBulk(p);
  const _isRemoveConfirm = isRemoveConfirm(p);
  const _isRemoveDecline = isRemoveDecline(p);
  const _isRemoveSuccess = isRemoveSuccess(p);
  const _isRemoveFail = isRemoveFail(p);
  const _isSetCreatedAtFilter = isSetCreatedAtFilter(p);
  const _isSetUpdatedAtFilter = isSetUpdatedAtFilter(p);
  const _isSetSearchFilter = isSetSearchFilter(p);
  const _isSetIdFilter = isSetIdFilter(p);
  const _isSetStatusFilter = isSetStatusFilter(p);
  const _isSubmitFilters = isSubmitFilters(p);
  const _isOpenAdvancedFilters = isOpenAdvancedFilters(p);
  const _isCloseAdvancedFilters = isCloseAdvancedFilters(p);
  const _isClearFilters = isClearFilters(p);
  const _isCreate = isCreate(p);

  return strictGuard((a: Actions<P>): a is Actions<P> => {
    if (
      _isLoadSuccess(a) ||
      _isFetchSuccess(a) ||
      _isLoadFail(a) ||
      _isSetPage(a) ||
      _isOrderBy(a) ||
      _isSelect(a) ||
      _isSelectAll(a) ||
      _isRemoveItem(a) ||
      _isRemoveBulk(a) ||
      _isRemoveConfirm(a) ||
      _isRemoveDecline(a) ||
      _isRemoveSuccess(a) ||
      _isRemoveFail(a) ||
      _isSetCreatedAtFilter(a) ||
      _isSetUpdatedAtFilter(a) ||
      _isSetSearchFilter(a) ||
      _isSetIdFilter(a) ||
      _isSetStatusFilter(a) ||
      _isSubmitFilters(a) ||
      _isOpenAdvancedFilters(a) ||
      _isCloseAdvancedFilters(a) ||
      _isClearFilters(a) ||
      _isCreate(a)
    )
      return true;

    silentUnreachableError(a);
    return false;
  });
};
// endregion

// region LoadSuccess
export const isLoadSuccess =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is LoadSuccess<P> =>
    a.type === `${p}:LoadSuccess`;
export interface LoadSuccess<P extends string> {
  type: `${P}:LoadSuccess`;
  payload: {
    pageInfo: PageInfo;
    total: number;
    items: PickingOrder[];
    dataTypes: DataType[];
  };
}

export const loadSuccess =
  <P extends string>(p: P) =>
  (payload: LoadSuccess<P>["payload"]): LoadSuccess<P> => ({
    type: `${p}:LoadSuccess`,
    payload,
  });
// endregion

// region LoadFail
export const isLoadFail =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is LoadFail<P> =>
    a.type === `${p}:LoadFail`;
export interface LoadFail<P extends string> {
  type: `${P}:LoadFail`;
  payload: {
    type: undefined;
  };
}

export const loadFail =
  <P extends string>(p: P) =>
  (payload: LoadFail<P>["payload"]): LoadFail<P> => ({
    type: `${p}:LoadFail`,
    payload,
  });
// endregion

// region FetchSuccess
export const isFetchSuccess =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is FetchSuccess<P> =>
    a.type === `${p}:FetchSuccess`;
export interface FetchSuccess<P extends string> {
  type: `${P}:FetchSuccess`;
  payload: {
    pageInfo: PageInfo;
    total: number;
    items: PickingOrder[];
  };
}

export const fetchSuccess =
  <P extends string>(p: P) =>
  (payload: FetchSuccess<P>["payload"]): FetchSuccess<P> => ({
    type: `${p}:FetchSuccess`,
    payload,
  });
// endregion

// region SetPage
export const isSetPage =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is SetPage<P> =>
    a.type === `${p}:SetPage`;
export interface SetPage<P extends string> {
  type: `${P}:SetPage`;
  payload: "start" | "prev" | "next" | "end";
}

export const setPage =
  <P extends string>(p: P) =>
  (payload: SetPage<P>["payload"]): SetPage<P> => ({
    type: `${p}:SetPage`,
    payload,
  });
// endregion

// region OrderBy
export const isOrderBy =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is OrderBy<P> =>
    a.type === `${p}:OrderBy`;
export interface OrderBy<P extends string> {
  type: `${P}:OrderBy`;
  payload: "createdAt" | "updatedAt";
}

export const orderBy =
  <P extends string>(p: P) =>
  (payload: OrderBy<P>["payload"]): OrderBy<P> => ({
    type: `${p}:OrderBy`,
    payload,
  });
// endregion

// region Select
export const isSelect =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is Select<P> =>
    a.type === `${p}:Select`;
export interface Select<P extends string> {
  type: `${P}:Select`;
  payload: PickingOrderId;
}

export const select =
  <P extends string>(p: P) =>
  (payload: Select<P>["payload"]): Select<P> => ({
    type: `${p}:Select`,
    payload,
  });
// endregion

// region SelectAll
export const isSelectAll =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is SelectAll<P> =>
    a.type === `${p}:SelectAll`;
export interface SelectAll<P extends string> {
  type: `${P}:SelectAll`;
}

export const selectAll =
  <P extends string>(p: P) =>
  (): SelectAll<P> => ({
    type: `${p}:SelectAll`,
  });
// endregion

// region RemoveItem
export const isRemoveItem =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is RemoveItem<P> =>
    a.type === `${p}:RemoveItem`;
export interface RemoveItem<P extends string> {
  type: `${P}:RemoveItem`;
  payload: PickingOrderId;
}

export const removeItem =
  <P extends string>(p: P) =>
  (payload: RemoveItem<P>["payload"]): RemoveItem<P> => ({
    type: `${p}:RemoveItem`,
    payload,
  });
// endregion

// region RemoveBulk
export const isRemoveBulk =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is RemoveBulk<P> =>
    a.type === `${p}:RemoveBulk`;
export interface RemoveBulk<P extends string> {
  type: `${P}:RemoveBulk`;
}

export const removeBulk =
  <P extends string>(p: P) =>
  (): RemoveBulk<P> => ({
    type: `${p}:RemoveBulk`,
  });
// endregion

// region RemoveConfirm
export const isRemoveConfirm =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is RemoveConfirm<P> =>
    a.type === `${p}:RemoveConfirm`;
export interface RemoveConfirm<P extends string> {
  type: `${P}:RemoveConfirm`;
}

export const removeConfirm =
  <P extends string>(p: P) =>
  (): RemoveConfirm<P> => ({
    type: `${p}:RemoveConfirm`,
  });
// endregion

// region RemoveDecline
export const isRemoveDecline =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is RemoveDecline<P> =>
    a.type === `${p}:RemoveDecline`;
export interface RemoveDecline<P extends string> {
  type: `${P}:RemoveDecline`;
}

export const removeDecline =
  <P extends string>(p: P) =>
  (): RemoveDecline<P> => ({
    type: `${p}:RemoveDecline`,
  });
// endregion

// region RemoveSuccess
export const isRemoveSuccess =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is RemoveSuccess<P> =>
    a.type === `${p}:RemoveSuccess`;
export interface RemoveSuccess<P extends string> {
  type: `${P}:RemoveSuccess`;
  payload: PickingOrderId[];
}

export const removeSuccess =
  <P extends string>(p: P) =>
  (payload: RemoveSuccess<P>["payload"]): RemoveSuccess<P> => ({
    type: `${p}:RemoveSuccess`,
    payload,
  });
// endregion

// region RemoveFail
export const isRemoveFail =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is RemoveFail<P> =>
    a.type === `${p}:RemoveFail`;
export interface RemoveFail<P extends string> {
  type: `${P}:RemoveFail`;
  payload: PickingOrderId[];
}

export const removeFail =
  <P extends string>(p: P) =>
  (payload: RemoveFail<P>["payload"]): RemoveFail<P> => ({
    type: `${p}:RemoveFail`,
    payload,
  });
// endregion

// region SetCreatedAtFilter
export const isSetCreatedAtFilter =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is SetCreatedAtFilter<P> =>
    a.type === `${p}:SetCreatedAtFilter`;
export interface SetCreatedAtFilter<P extends string> {
  type: `${P}:SetCreatedAtFilter`;
  payload: Option<[Date | undefined, Date | undefined]>;
}

export const setCreatedAtFilter =
  <P extends string>(p: P) =>
  (payload: SetCreatedAtFilter<P>["payload"]): SetCreatedAtFilter<P> => ({
    type: `${p}:SetCreatedAtFilter`,
    payload,
  });
// endregion

// region SetUpdatedAtFilter
export const isSetUpdatedAtFilter =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is SetUpdatedAtFilter<P> =>
    a.type === `${p}:SetUpdatedAtFilter`;
export interface SetUpdatedAtFilter<P extends string> {
  type: `${P}:SetUpdatedAtFilter`;
  payload: Option<[Date | undefined, Date | undefined]>;
}

export const setUpdatedAtFilter =
  <P extends string>(p: P) =>
  (payload: SetUpdatedAtFilter<P>["payload"]): SetUpdatedAtFilter<P> => ({
    type: `${p}:SetUpdatedAtFilter`,
    payload,
  });
// endregion

// region SetSearchFilter
export const isSetSearchFilter =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is SetSearchFilter<P> =>
    a.type === `${p}:SetSearchFilter`;
export interface SetSearchFilter<P extends string> {
  type: `${P}:SetSearchFilter`;
  payload: string;
}

export const setSearchFilter =
  <P extends string>(p: P) =>
  (payload: SetSearchFilter<P>["payload"]): SetSearchFilter<P> => ({
    type: `${p}:SetSearchFilter`,
    payload,
  });
// endregion

// region SetIdFilter
export const isSetIdFilter =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is SetIdFilter<P> =>
    a.type === `${p}:SetIdFilter`;
export interface SetIdFilter<P extends string> {
  type: `${P}:SetIdFilter`;
  payload: string;
}

export const setIdFilter =
  <P extends string>(p: P) =>
  (payload: SetIdFilter<P>["payload"]): SetIdFilter<P> => ({
    type: `${p}:SetIdFilter`,
    payload,
  });
// endregion

// region SetStatusFilter
export const isSetStatusFilter =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is SetStatusFilter<P> =>
    a.type === `${p}:SetStatusFilter`;
export interface SetStatusFilter<P extends string> {
  type: `${P}:SetStatusFilter`;
  payload: "withItems" | "noItems" | "all";
}

export const setStatusFilter =
  <P extends string>(p: P) =>
  (payload: SetStatusFilter<P>["payload"]): SetStatusFilter<P> => ({
    type: `${p}:SetStatusFilter`,
    payload,
  });
// endregion

// region SubmitFilters
export const isSubmitFilters =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is SubmitFilters<P> =>
    a.type === `${p}:SubmitFilters`;
export interface SubmitFilters<P extends string> {
  type: `${P}:SubmitFilters`;
}

export const submitFilters =
  <P extends string>(p: P) =>
  (): SubmitFilters<P> => ({
    type: `${p}:SubmitFilters`,
  });
// endregion

// region OpenAdvancedFilters
export const isOpenAdvancedFilters =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is OpenAdvancedFilters<P> =>
    a.type === `${p}:OpenAdvancedFilters`;
export interface OpenAdvancedFilters<P extends string> {
  type: `${P}:OpenAdvancedFilters`;
}

export const openAdvancedFilters =
  <P extends string>(p: P) =>
  (): OpenAdvancedFilters<P> => ({
    type: `${p}:OpenAdvancedFilters`,
  });
// endregion

// region CloseAdvancedFilters
export const isCloseAdvancedFilters =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is CloseAdvancedFilters<P> =>
    a.type === `${p}:CloseAdvancedFilters`;
export interface CloseAdvancedFilters<P extends string> {
  type: `${P}:CloseAdvancedFilters`;
}

export const closeAdvancedFilters =
  <P extends string>(p: P) =>
  (): CloseAdvancedFilters<P> => ({
    type: `${p}:CloseAdvancedFilters`,
  });
// endregion

// region ClearFilters
export const isClearFilters =
  <P extends string>(p: P) =>
  (a: Actions<P>): a is ClearFilters<P> =>
    a.type === `${p}:ClearFilters`;
export interface ClearFilters<P extends string> {
  type: `${P}:ClearFilters`;
}

export const clearFilters =
  <P extends string>(p: P) =>
  (): ClearFilters<P> => ({
    type: `${p}:ClearFilters`,
  });
// endregion

// region Create
export interface Create<P extends string> {
  type: `${P}:Create`;
}

export const create =
  <P extends string>(p: P) =>
  (): Create<P> => ({
    type: `${p}:Create`,
  });

export const isCreate =
  <P extends string>(p: P) =>
  (s: Actions<P>): s is Create<P> =>
    s.type === `${p}:Create`;
// endregion
