import { DataTypeId } from "types/src/DataType/DataType";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import * as SchemaFields from "../../SchemaFields/types/Actions";

export type Actions<P extends string> =
  | SchemaFields.Actions<`${P}:Fields`>
  | SetDataType<P>
  | SetSku<P>
  | SetQuantity<P>
  | Submit<P>;

export const isActions = <P extends string>(p: P) => {
  const isSchemaFieldsActins = SchemaFields.isActions(`${p}:Fields`);

  return strictGuard((a: Actions<P>): a is Actions<P> => {
    if (
      isSchemaFieldsActins(a) ||
      isSetDataType(p)(a) ||
      isSetSku(p)(a) ||
      isSetQuantity(p)(a) ||
      isSubmit(p)(a)
    )
      return true;

    silentUnreachableError(a);
    return false;
  });
};

// region SetDataType
export interface SetDataType<P extends string> {
  type: `${P}:SetDataType`;
  payload: DataTypeId;
}

export const setDataType =
  <P extends string>(p: P) =>
  (payload: SetDataType<P>["payload"]): SetDataType<P> => ({
    type: `${p}:SetDataType`,
    payload,
  });

export const isSetDataType =
  <P extends string>(p: P) =>
  (s: Actions<P>): s is SetDataType<P> =>
    s.type === `${p}:SetDataType`;
// endregion

// region SetSku
export interface SetSku<P extends string> {
  type: `${P}:SetSku`;
  payload: string | undefined;
}

export const setSku =
  <P extends string>(p: P) =>
  (payload: SetSku<P>["payload"]): SetSku<P> => ({
    type: `${p}:SetSku`,
    payload,
  });

export const isSetSku =
  <P extends string>(p: P) =>
  (s: Actions<P>): s is SetSku<P> =>
    s.type === `${p}:SetSku`;
// endregion

// region SetQuantity
export interface SetQuantity<P extends string> {
  type: `${P}:SetQuantity`;
  payload: number | undefined;
}

export const setQuantity =
  <P extends string>(p: P) =>
  (payload: SetQuantity<P>["payload"]): SetQuantity<P> => ({
    type: `${p}:SetQuantity`,
    payload,
  });

export const isSetQuantity =
  <P extends string>(p: P) =>
  (s: Actions<P>): s is SetQuantity<P> =>
    s.type === `${p}:SetQuantity`;
// endregion

// region Submit
export interface Submit<P extends string> {
  type: `${P}:Submit`;
}

export const submit =
  <P extends string>(p: P) =>
  (): Submit<P> => ({
    type: `${p}:Submit`,
  });

export const isSubmit =
  <P extends string>(p: P) =>
  (s: Actions<P>): s is Submit<P> =>
    s.type === `${p}:Submit`;
// endregion
