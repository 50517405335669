import { ReactElement } from "react";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "ui/layouts/Loading";
import { All } from "@Containers/Ready/DataManager/Customers/ListingAll/components/All";
import * as ListingAll from "state-manager/states/Ready/states/DataManager/states/Customers/states/ListingAll";

export interface ContentProps {
  selector: Selector<ListingAll.State>;
  dispatch: (a: ListingAll.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps): ReactElement {
  const r = useSelector(
    flow(selector, (s) => {
      if (ListingAll.isLoading(s)) return { type: "loading" } as const;
      if (ListingAll.isReady(s) || ListingAll.isFetching(s)) {
        return {
          type: "items",
          header: flow(selector, (st) => st as typeof s),
          items: flow(selector, (st) => st as typeof s),
        } as const;
      }
      if (ListingAll.isLoadError(s)) return { type: "error" } as const;

      silentUnreachableError(s);
      return { type: "loading" } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "items":
      return <All selector$={r.items} dispatch={dispatch} />;
    case "error":
      return <div>Error</div>;
  }
}
