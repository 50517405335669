import { RootState, useSelector } from "state-manager";
import { Textarea as TextareaComponent } from "ui/components/Textarea";

export interface TextareaProps {
  value$: (s: RootState) => string;
  onChange: (value: string) => void;
  rows?: number;
  placeholder?: string;
}

export function Textarea({
  value$,
  onChange,
  rows,
  placeholder,
}: TextareaProps) {
  const value = useSelector(value$);

  return (
    <TextareaComponent
      placeholder={placeholder}
      rows={rows}
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
