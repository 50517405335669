import { DsError } from "ds";
import { DataTypeId } from "types/src/DataType/DataType";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import { PickingOrder } from "types/src/PickingOrder/PickingOrder";
import { NoEmptyArr } from "types/src/NoEmptyArr";
import {
  CustomerSearchActions,
  createCustomerSearchState,
  PickingOrderItemsActions,
  SchemaFieldsActions,
  createSchemaFieldsState,
  createPickingOrderItemsState,
} from "../utils";

export type Actions<P extends string> =
  | LoadFail<P>
  | LoadSuccess<P>
  | Submit<P>
  | SaveFail<P>
  | SaveSuccess<P>
  | CustomerSearchActions<P>
  | SchemaFieldsActions<P>
  | PickingOrderItemsActions<P>;

export const isActions = <P extends string>(p: P) => {
  const schemaFieldsState = createSchemaFieldsState(p);
  const customerSearchState = createCustomerSearchState(p);
  const pickingOrderItemsState = createPickingOrderItemsState(p);
  const _isLoadFail = isLoadFail(p);
  const _isLoadSuccess = isLoadSuccess(p);
  const _isSubmit = isSubmit(p);
  const _isSaveFail = isSaveFail(p);
  const _isSaveSuccess = isSaveSuccess(p);

  return strictGuard((a: Actions<P>): a is Actions<P> => {
    if (
      schemaFieldsState.isActions(a) ||
      _isLoadFail(a) ||
      _isLoadSuccess(a) ||
      _isSubmit(a) ||
      _isSaveFail(a) ||
      _isSaveSuccess(a) ||
      customerSearchState.isActions(a) ||
      pickingOrderItemsState.isActions(a)
    ) {
      return true;
    }

    silentUnreachableError(a);
    return false;
  });
};

// region LoadFail
export interface LoadFail<P extends string> {
  type: `${P}:LoadFail`;
  payload: DsError;
}

export const loadFail =
  <P extends string>(p: P) =>
  (payload: LoadFail<P>["payload"]): LoadFail<P> => ({
    type: `${p}:LoadFail`,
    payload,
  });

export const isLoadFail =
  <P extends string>(p: P) =>
  (s: Actions<P>): s is LoadFail<P> =>
    s.type === `${p}:LoadFail`;
// endregion

// region LoadSuccess
export interface LoadSuccess<P extends string> {
  type: `${P}:LoadSuccess`;
  payload: {
    dataType: {
      title: string;
      id: DataTypeId;
      schema: DataTypeSchema;
    };
    dataTypes: NoEmptyArr<{
      title: string;
      id: DataTypeId;
      schema: DataTypeSchema;
      isDefault: boolean;
    }>;
  };
}

export const loadSuccess =
  <P extends string>(p: P) =>
  (payload: LoadSuccess<P>["payload"]): LoadSuccess<P> => ({
    type: `${p}:LoadSuccess`,
    payload,
  });

export const isLoadSuccess =
  <P extends string>(p: P) =>
  (s: Actions<P>): s is LoadSuccess<P> =>
    s.type === `${p}:LoadSuccess`;
// endregion

// region Submit
export interface Submit<P extends string> {
  type: `${P}:Submit`;
}

export const submit =
  <P extends string>(p: P) =>
  (): Submit<P> => ({
    type: `${p}:Submit`,
  });

export const isSubmit =
  <P extends string>(p: P) =>
  (s: Actions<P>): s is Submit<P> =>
    s.type === `${p}:Submit`;
// endregion

// region SaveFail
export interface SaveFail<P extends string> {
  type: `${P}:SaveFail`;
  payload: DsError;
}

export const saveFail =
  <P extends string>(p: P) =>
  (payload: SaveFail<P>["payload"]): SaveFail<P> => ({
    type: `${p}:SaveFail`,
    payload,
  });

export const isSaveFail =
  <P extends string>(p: P) =>
  (s: Actions<P>): s is SaveFail<P> =>
    s.type === `${p}:SaveFail`;
// endregion

// region SaveSuccess
export interface SaveSuccess<P extends string> {
  type: `${P}:SaveSuccess`;
  payload: PickingOrder;
}

export const saveSuccess =
  <P extends string>(p: P) =>
  (payload: SaveSuccess<P>["payload"]): SaveSuccess<P> => ({
    type: `${p}:SaveSuccess`,
    payload,
  });

export const isSaveSuccess =
  <P extends string>(p: P) =>
  (s: Actions<P>): s is SaveSuccess<P> =>
    s.type === `${p}:SaveSuccess`;
// endregion
