import { CustomerId } from "types/src/Customers/Customer";
import { DsError } from "ds";
import type { GetGuardType } from "types/src/Utils";
import * as SchemaFields from "../../../../../../../../generic-states/SchemaFields";
import * as PickingOrderItems from "../../../../../../../../generic-states/PickingOrderItems";
import * as ItemSearch from "../../../../../../../../generic-states/ItemSearch";
import type { ItemId } from "./types/State";

export const createSchemaFieldsState = <P extends string>(p: P) =>
  SchemaFields.createState(`${p}:SchemaFields`);

export type SchemaFieldsActionsMap<P extends string> = {
  [k in keyof ReturnType<
    typeof createSchemaFieldsState<P>
  >["actions"]]: ReturnType<
    ReturnType<typeof createSchemaFieldsState<P>>["actions"][k]["create"]
  >;
};
export type SchemaFieldsActions<P extends string> =
  SchemaFieldsActionsMap<P>[keyof SchemaFieldsActionsMap<P>];

export type SchemaFieldsStatesMap<P extends string> = {
  [k in keyof ReturnType<
    typeof createSchemaFieldsState<P>
  >["states"]]: ReturnType<
    ReturnType<typeof createSchemaFieldsState<P>>["states"][k]["create"]
  >;
};
export type SchemaFieldsState<P extends string> =
  SchemaFieldsStatesMap<P>[keyof SchemaFieldsStatesMap<P>];

export const createPickingOrderItemsState = <P extends string>(p: P) =>
  PickingOrderItems.createState<`${P}:items`, ItemId>(`${p}:items`);

export type PickingOrderItemsStatesMap<P extends string> = {
  [k in keyof ReturnType<
    typeof createPickingOrderItemsState<P>
  >["states"]]: ReturnType<
    ReturnType<typeof createPickingOrderItemsState<P>>["states"][k]["create"]
  >;
};

export type PickingOrderItemsState<P extends string> =
  PickingOrderItemsStatesMap<P>[keyof PickingOrderItemsStatesMap<P>];

export type PickingOrderItemsActionsMap<P extends string> = {
  [k in keyof ReturnType<
    typeof createPickingOrderItemsState<P>
  >["actions"]]: ReturnType<
    ReturnType<typeof createPickingOrderItemsState<P>>["actions"][k]["create"]
  >;
};

export type PickingOrderItemsActions<P extends string> = GetGuardType<
  ReturnType<typeof createPickingOrderItemsState<P>>["isActions"]
>;

export const createCustomerSearchState = <P extends string>(p: P) =>
  ItemSearch.createState<
    `${P}:Customer`,
    DsError,
    { id: CustomerId; title: string }
  >(`${p}:Customer`, (a, b) => a.id === b.id);

export type CustomerSearchStatesMap<P extends string> = {
  [k in keyof ReturnType<
    typeof createCustomerSearchState<P>
  >["states"]]: ReturnType<
    ReturnType<typeof createCustomerSearchState<P>>["states"][k]["create"]
  >;
};
export type CustomerSearchState<P extends string> =
  CustomerSearchStatesMap<P>[keyof CustomerSearchStatesMap<P>];
export type CustomerSearchActions<P extends string> = ReturnType<
  ReturnType<typeof createCustomerSearchState<P>>["actions"][keyof ReturnType<
    typeof createCustomerSearchState<P>
  >["actions"]]["create"]
>;
