import { Selector, useSelector } from "state-manager";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Edit";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import { SkuInput } from "@Containers/Form/predefined/SkuInput";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { SchemaFieldsJsonEditor } from "@Containers/SchemaFieldsJsonEditor";

export interface ContentProps {
  selector: Selector<Edit.State>;
  dispatch: (a: Edit.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const r = useSelector(
    flow(selector, (s) => {
      switch (s.type) {
        case "Ready:DataManager:InventoryItems:Edit:Loading":
        case "Ready:DataManager:InventoryItems:Edit:LoadError":
          return {
            type: "loading",
          } as const;
        case "Ready:DataManager:InventoryItems:Edit:Ready":
        case "Ready:DataManager:InventoryItems:Edit:Saving": {
          return {
            type: "ready",
            schemaSelector: flow(
              selector,
              (s) => s as Edit.Ready,
              (s) => s.payload.schema.payload,
            ),
            skuSelector: flow(
              selector,
              (s) => s as Edit.Ready,
              (s) => s.payload.sku,
            ),
          } as const;
        }

        default: {
          silentUnreachableError(s);
          return {
            type: "loading",
          } as const;
        }
      }
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <FormWrapper>
          <SkuInput
            value$={r.skuSelector}
            onChange={flow(Edit.setSku, dispatch)}
          />
          <SchemaFieldsJsonEditor
            value$={r.schemaSelector}
            onChange={flow(
              Edit.schemaFieldsState.actions.onChange.create,
              dispatch,
            )}
          />
        </FormWrapper>
      );
  }
}
