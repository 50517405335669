import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import { NoEmptyString, NoEmptyStringEq } from "types/src/NoEmptyString";
import { Eq } from "fp-ts/Eq";
import * as Arr from "fp-ts/Array";
import * as Str from "fp-ts/string";
import { DataTypeId } from "types/src/DataType/DataType";
import { Semigroup } from "fp-ts/Semigroup";
import { Monoid } from "fp-ts/Monoid";

type DateRange = [Date | undefined, Date | undefined];

export interface Filters {
  createdAt: Option<DateRange>;
  updatedAt: Option<DateRange>;
  id: Option<NoEmptyString>;
  name: Option<NoEmptyString>;
  search: Option<NoEmptyString>;
  dataTypes: DataTypeId[];
  status: "all" | "active" | "orphan";
}

export const FiltersEq: Eq<Filters> = {
  equals: (a, b) => {
    return (
      a === b ||
      (O.getEq(NoEmptyStringEq).equals(a.id, b.id) &&
        O.getEq(NoEmptyStringEq).equals(a.name, b.name) &&
        Arr.getEq(Str.Eq).equals(a.dataTypes, b.dataTypes) &&
        O.getEq(DateRangeEq).equals(a.createdAt, b.createdAt) &&
        O.getEq(DateRangeEq).equals(a.updatedAt, b.updatedAt) &&
        O.getEq(NoEmptyStringEq).equals(a.search, b.search) &&
        a.status === b.status)
    );
  },
};

const DateRangeEq: Eq<DateRange> = {
  equals: (a, b) => {
    return (
      a[0]?.getTime() === b[0]?.getTime() && a[1]?.getTime() === b[1]?.getTime()
    );
  },
};

export const FiltersSemigroup: Semigroup<Filters> = {
  concat: (a, b) => {
    return {
      id: O.isNone(a.id) ? b.id : a.id,
      name: O.isNone(a.name) ? b.name : a.name,
      search: O.isNone(a.search) ? b.search : a.search,
      dataTypes: Arr.getMonoid<DataTypeId>().concat(a.dataTypes, b.dataTypes),
      createdAt: O.isNone(a.createdAt) ? b.createdAt : a.createdAt,
      updatedAt: O.isNone(a.updatedAt) ? b.updatedAt : a.updatedAt,
      status: a.status === "all" ? b.status : a.status,
    };
  },
};

export const FiltersMonoid: Monoid<Filters> = {
  concat: FiltersSemigroup.concat,
  empty: {
    id: O.none,
    name: O.none,
    search: O.none,
    dataTypes: [],
    createdAt: O.none,
    updatedAt: O.none,
    status: "all",
  },
};

export const isEmpty = (filters: Filters): boolean =>
  FiltersEq.equals(filters, FiltersMonoid.empty);
