import { ReactElement } from "react";
import { TranslatedStr } from "types/src/TranslatedStr";
import { IInputGroupProps, InputGroup } from "@zendeskgarden/react-forms";
import { Button } from "@components/Button";

export interface PickerProps<T> extends Omit<IInputGroupProps, "onChange"> {
  value: T | undefined;
  onChange: (value: T | undefined) => void;
  options: { value: T; label: TranslatedStr }[];
  eq?: (a: T | undefined, b: T | undefined) => boolean;
}

export function Picker<T>({
  value,
  onChange,
  options,
  eq = (a, b) => a === b,
  ...props
}: PickerProps<T>): ReactElement {
  return (
    <InputGroup {...props}>
      {options.map(({ value: v, label }, i) => (
        <Button
          focusInset
          isSelected={eq(v, value)}
          key={i}
          onClick={() => (v === value ? onChange(undefined) : onChange(v))}
        >
          {label}
        </Button>
      ))}
    </InputGroup>
  );
}
