import { RootState, useSelector } from "state-manager";
import { MediaInput as InputComponent } from "ui/components/Input";
import { SearchIcon } from "../../../../../packages/icons/src/Search";

export interface SearchInputProps {
  value$: (s: RootState) => string;
  onChange: (value: string) => void;
}

export function SearchInput({ value$, onChange }: SearchInputProps) {
  const value = useSelector(value$);

  return (
    <InputComponent
      start={<SearchIcon />}
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
