import { silentUnreachableError } from "utils/exceptions";
import { Repo } from "types/src/Repositories/Repository";
import { DataType } from "types/src/DataType/DataType";
import { v4 as uuidv4 } from "uuid";
import * as State from "./types/State";
import { Actions } from "./types/Actions";

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case "Ready:BuilderPreview:LoadFail": {
      if (State.isLoading(s)) return State.loadError(s.payload);

      return s;
    }
    case "Ready:BuilderPreview:ChangeActiveDataTypeFail": {
      if (State.isLoading(s)) return State.loadError(s.payload);

      return s;
    }
    case "Ready:BuilderPreview:LoadSuccess": {
      if (State.isLoading(s)) {
        const idsBinding: Record<string, Repo["id"]> = {};
        const items = a.payload.items.map((v) => {
          idsBinding[v.id] = v.id;
          return {
            id: v.id,
            name: v.name,
            parentId: v.parentId,
            data: v.data,
          };
        });

        return State.ready({
          ...s.payload,
          dataTypes: a.payload.dataTypes,
          activeDataType:
            a.payload.dataTypes.find((v) => v.default)?.id ??
            (a.payload.dataTypes[0]?.id as DataType["id"]),
          items,
          idsBinding,
          refetchRepoId: uuidv4(),
        });
      }

      return s;
    }
    case "Ready:BuilderPreview:ChangeActiveDataTypeSuccess": {
      if (State.isActiveDataType(s)) {
        const idsBinding: Record<string, Repo["id"]> = {};
        const items = a.payload.items.map((v) => {
          idsBinding[v.id] = v.id;
          return {
            id: v.id,
            name: v.name,
            parentId: v.parentId,
            data: v.data,
          };
        });

        return State.ready({
          ...s.payload,
          dataTypes: a.payload.dataTypes,
          activeDataType: s.payload.activeDataType,
          items,
          idsBinding,
          refetchRepoId: uuidv4(),
        });
      }

      return s;
    }
    case "Ready:BuilderPreview:CreateRepos": {
      if (State.isReady(s)) {
        return State.createReposProcessing({
          createItems: a.payload.items,
          items: s.payload.items,
          dataTypes: s.payload.dataTypes,
          activeDataType: s.payload.activeDataType,
          idsBinding: s.payload.idsBinding,
          refetchRepoId: s.payload.refetchRepoId,
        });
      }

      return s;
    }
    case "Ready:BuilderPreview:CreatedReposSuccess": {
      if (State.isCreateReposProcessing(s)) {
        return State.ready({
          items: [...s.payload.items, ...Object.values(a.payload.items)],
          dataTypes: s.payload.dataTypes,
          activeDataType: s.payload.activeDataType,
          idsBinding: { ...s.payload.idsBinding, ...a.payload.idsBinding },
          refetchRepoId: s.payload.refetchRepoId,
        });
      }

      return s;
    }

    case "Ready:BuilderPreview:CreatedReposFail": {
      if (State.isCreateReposProcessing(s)) {
        return State.ready({
          items: s.payload.items,
          dataTypes: s.payload.dataTypes,
          activeDataType: s.payload.activeDataType,
          idsBinding: s.payload.idsBinding,
          refetchRepoId: uuidv4(),
        });
      }
      return s;
    }
    case "Ready:BuilderPreview:UpdateRepos": {
      if (State.isReady(s)) {
        return State.updateReposProcessing({
          updateItems: a.payload.items,
          items: s.payload.items,
          dataTypes: s.payload.dataTypes,
          activeDataType: s.payload.activeDataType,
          idsBinding: s.payload.idsBinding,
          refetchRepoId: s.payload.refetchRepoId,
        });
      }

      return s;
    }

    case "Ready:BuilderPreview:UpdatedReposSuccess": {
      if (State.isUpdateReposProcessing(s)) {
        return State.ready({
          items: [...s.payload.items, ...Object.values(a.payload.items)],
          dataTypes: s.payload.dataTypes,
          activeDataType: s.payload.activeDataType,
          idsBinding: s.payload.idsBinding,
          refetchRepoId: s.payload.refetchRepoId,
        });
      }

      return s;
    }

    case "Ready:BuilderPreview:UpdatedReposFail": {
      if (State.isUpdateReposProcessing(s)) {
        return State.ready({
          items: s.payload.items,
          dataTypes: s.payload.dataTypes,
          activeDataType: s.payload.activeDataType,
          idsBinding: s.payload.idsBinding,
          refetchRepoId: uuidv4(),
        });
      }
      return s;
    }

    case "Ready:BuilderPreview:DeleteRepos": {
      if (State.isReady(s)) {
        return State.deleteReposProcessing({
          itemsIds: a.payload.itemsIds,
          items: s.payload.items,
          dataTypes: s.payload.dataTypes,
          activeDataType: s.payload.activeDataType,
          idsBinding: s.payload.idsBinding,
          refetchRepoId: s.payload.refetchRepoId,
        });
      }

      return s;
    }
    case "Ready:BuilderPreview:DeleteReposSuccess": {
      if (State.isDeleteReposProcessing(s)) {
        return State.ready({
          items: s.payload.items.filter(
            (v) => !s.payload.itemsIds.includes(v.id),
          ),
          dataTypes: s.payload.dataTypes,
          activeDataType: s.payload.activeDataType,
          idsBinding: s.payload.idsBinding,
          refetchRepoId: s.payload.refetchRepoId,
        });
      }

      return s;
    }
    case "Ready:BuilderPreview:DeleteReposFail": {
      if (State.isDeleteReposProcessing(s)) {
        return State.ready({
          items: s.payload.items,
          dataTypes: s.payload.dataTypes,
          activeDataType: s.payload.activeDataType,
          idsBinding: s.payload.idsBinding,
          refetchRepoId: uuidv4(),
        });
      }
      return s;
    }
    case "Ready:BuilderPreview:ChangeActiveDataType": {
      if (State.isReady(s)) {
        return State.activeDataType({
          items: s.payload.items,
          dataTypes: s.payload.dataTypes,
          activeDataType: a.payload,
          idsBinding: s.payload.idsBinding,
          refetchRepoId: s.payload.refetchRepoId,
        });
      }

      return s;
    }
    default:
      silentUnreachableError(a);
      return s;
  }
}
