import { DsErrorCode } from "./DsErrorCode";

type DsErrorBase<T extends DsErrorCode, P = ""> = P extends ""
  ? { code: T }
  : { code: T; payload: P };

export type UnauthorizedError = DsErrorBase<"UNAUTHORIZED">;
export const isUnauthorizedError = (e: DsError): e is UnauthorizedError =>
  e.code === "UNAUTHORIZED";
export const unauthorizedError = (): UnauthorizedError => ({
  code: "UNAUTHORIZED",
});

export type NotFoundError = DsErrorBase<"NOT_FOUND">;
export const isNotFoundError = (e: DsError): e is NotFoundError =>
  e.code === "NOT_FOUND";
export const notFoundError = (): NotFoundError => ({ code: "NOT_FOUND" });

export type UnknownError = DsErrorBase<"UNKNOWN">;
export const isUnknownError = (e: DsError): e is UnknownError =>
  e.code === "UNKNOWN";
export const unknownError = (): UnknownError => ({ code: "UNKNOWN" });

export type ServerError = DsErrorBase<
  "SERVER",
  {
    message: string;
    data: string;
  }
>;
export const isServerError = (e: DsError): e is ServerError =>
  e.code === "SERVER";
export const serverError = (message: string, data: string): ServerError => ({
  code: "SERVER",
  payload: { message, data },
});

export type DsError =
  | UnauthorizedError
  | NotFoundError
  | UnknownError
  | ServerError;
