import { distinctUntilChanged, from, map, Observable, switchMap } from "rxjs";
import { Client } from "ds";
import { getTransaction } from "ds/Transactions";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { dsErrorNotification } from "../../../../../../../Notifications/epic";
import { Epic } from "../../../../../../../../types/RootEpic";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export const epic: Epic<
  Actions.Actions,
  State.State,
  {
    pyckAdminClient$: Observable<Client>;
  }
> = (state$, { pyckAdminClient$ }) => {
  return state$.pipe(
    map((s) => s.payload.id),
    distinctUntilChanged(),
    switchMap((id) =>
      pyckAdminClient$.pipe(
        switchMap((client) =>
          from(getTransaction(client, id)).pipe(
            dsErrorNotification(
              flow(
                E.map((v) => Actions.fetchSuccessAction(v)),
                E.getOrElseW(Actions.fetchErrorAction),
              ),
            ),
          ),
        ),
      ),
    ),
  );
};
