import { InventoryItem } from "types/src/InventoryItems/InventoryItem";
import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import * as DataGenerator from "../../../../../../../../../generic-states/data-genetator";
import { SchemaFieldsActions, schemaFieldsState } from "../utils";

// region Actions
export type Actions =
  | LoadSuccess
  | LoadFail
  | Submit
  | SaveError
  | SaveSuccess
  | SchemaFieldsActions
  | SetSku
  | DataGenerator.Actions;

export const isActions = strictGuard((action: Actions): action is Actions => {
  const a = action as Actions;

  if (schemaFieldsState.isActions(a) || DataGenerator.isActions(a)) return true;

  switch (a.type) {
    case "Ready:DataManager:InventoryItems:Create:LoadFail":
    case "Ready:DataManager:InventoryItems:Create:LoadSuccess":
    case "Ready:DataManager:InventoryItems:Create:SaveError":
    case "Ready:DataManager:InventoryItems:Create:SaveSuccess":
    case "Ready:DataManager:InventoryItems:Create:SetSku":
    case "Ready:DataManager:InventoryItems:Create:Submit":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:InventoryItems:Create:LoadFail";
}

export const loadFail = (): LoadFail => ({
  type: "Ready:DataManager:InventoryItems:Create:LoadFail",
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:InventoryItems:Create:LoadSuccess";
  payload: DataTypeSchema;
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:InventoryItems:Create:LoadSuccess",
  payload,
});
// endregion

// region SetSku
export interface SetSku {
  type: "Ready:DataManager:InventoryItems:Create:SetSku";
  payload: string | undefined;
}

export const setSku = (payload: SetSku["payload"]): SetSku => ({
  type: "Ready:DataManager:InventoryItems:Create:SetSku",
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: "Ready:DataManager:InventoryItems:Create:Submit";
}

export const submit = (): Submit => ({
  type: "Ready:DataManager:InventoryItems:Create:Submit",
});
// endregion

// region SaveError
export interface SaveError {
  type: "Ready:DataManager:InventoryItems:Create:SaveError";
}

export const saveError = (): SaveError => ({
  type: "Ready:DataManager:InventoryItems:Create:SaveError",
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: "Ready:DataManager:InventoryItems:Create:SaveSuccess";
  payload: InventoryItem;
}

export const saveSuccess = (payload: SaveSuccess["payload"]): SaveSuccess => ({
  type: "Ready:DataManager:InventoryItems:Create:SaveSuccess",
  payload,
});
// endregion
