import { Selector, useSelector } from "state-manager";
import { ReactElement } from "react";
import { flow } from "fp-ts/function";
import { useTranslation } from "i18n";
import { SubNavItem, SubNavItemText } from "ui/layouts/PageLayout";
import { isShallowEqual } from "utils/object";
import {
  OrganizationId,
  State,
  changeOrganizationId,
} from "state-manager/states/Ready";

export interface SidebarProps {
  selector: Selector<State["payload"]>;
  dispatch: (action: OrganizationId) => void;
}

export function Sidebar(p: SidebarProps): ReactElement {
  const { t } = useTranslation();
  const organizations = useSelector(
    flow(p.selector, (s) => s.organizations),
    isShallowEqual,
  );
  const orgId = useSelector(
    flow(p.selector, (s) => s.orgId),
    isShallowEqual,
  );

  return (
    <>
      {organizations.ids.map((id) => {
        const entry = organizations.entities[id];
        if (!entry) return null;

        return (
          <SubNavItem
            key={id}
            onClick={() => p.dispatch(changeOrganizationId(id))}
            isCurrent={orgId === id}
          >
            <SubNavItemText>{entry.link}</SubNavItemText>
            <SubNavItemText>({entry.type})</SubNavItemText>
          </SubNavItem>
        );
      })}
    </>
  );
}
