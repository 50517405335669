import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import * as Create from "../states/Create";
import * as Edit from "../states/Edit";
import * as Listing from "../states/Listing";

export type Actions = Listing.Actions | Create.Actions | Edit.Actions;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (Listing.isActions(a)) return true;
  if (Create.isActions(a)) return true;
  if (Edit.isActions(a)) return true;

  silentUnreachableError(a);
  return false;
});
