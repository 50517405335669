import { Option } from "fp-ts/Option";
import { TranslatedStr } from "../TranslatedStr";
import { UniqId } from "../UniqId";
import { DataTypeSchema } from "./DataTypeSchema";

// region DataTypeId
declare const _dataTypeId: unique symbol;

export type DataTypeId = UniqId & { [_dataTypeId]: "DataTypeId" };
// endregion

export enum DataTypeEntity {
  Attachment = "attachment",
  CollectionMovement = "collection_movement",
  Customer = "customer",
  Delivery = "delivery",
  Item = "item",
  ItemSet = "item_set",
  Movement = "movement",
  Order = "order",
  Repository = "repository",
  RepositoryBox = "repository_box",
  RepositoryPallet = "repository_pallet",
  Supplier = "supplier",
}

export const dataTypeEntities: DataTypeEntity[] = Object.values(DataTypeEntity);

export interface DataType {
  id: DataTypeId;
  name: TranslatedStr;
  description: TranslatedStr;
  entity: DataTypeEntity;
  schema: DataTypeSchema;
  default: boolean;
  createdAt: Date;
  updatedAt: Option<Date>;
  deletedAt: Option<Date>;
}
