import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import { Value } from "./Value";

// region State
export type State<P extends string> = Init<P>;

export const isState = <P extends string>(p: P) =>
  strictGuard((s: State<P>): s is State<P> => {
    if (isInit(p)(s)) {
      return true;
    }

    silentUnreachableError(s);
    return false;
  });
// endregion

// region Init
export interface InitPayload {
  schema: DataTypeSchema;
  values: Value;
}

export interface Init<P extends string> {
  type: `${P}:Init`;
  payload: InitPayload;
}

export const init =
  <P extends string>(p: P) =>
  (payload: Init<P>["payload"]): Init<P> => ({
    type: `${p}:Init`,
    payload,
  });

export const isInit =
  <P extends string>(p: P) =>
  (a: State<P>): a is Init<P> =>
    a.type === `${p}:Init`;
// endregion
