import { Observable, filter, from, map, switchMap, withLatestFrom } from "rxjs";
import { generateFields, Client as OpenAIClient } from "open-ai-ds";
import * as E from "fp-ts/Either";

import { Client } from "ds";

import { flow } from "fp-ts/lib/function";
import { Epic } from "../../types/RootEpic";
import { dsErrorNotification } from "../../states/Notifications/epic";
import * as Actions from "./types/Actions";
import { State, isCreating } from "./types/State";
import { CustomFieldsValue } from "./types/CustomFields";

export const epic: Epic<
  Actions.Actions,
  State,
  {
    pyckAdminClient$: Observable<Client>;
    openAIClient$: Observable<OpenAIClient>;
  }
> = (state$, { openAIClient$ }) => {
  const openAICreate$ = state$.pipe(
    filter(isCreating),
    withLatestFrom(openAIClient$),
    switchMap(([state, client]) => {
      return from(
        generateFields(client, {
          fieldsSchema: state.payload.fieldsSchema,
          options: {
            amount: state.payload.amount,
            responseExample: state.payload.responseExample,
            templateContext: state.payload.templateContext,
          },
        }),
      ).pipe(
        map((v) => {
          if (v.choices[0]?.finish_reason === "length") {
            return E.left({
              code: "SERVER" as const,
              payload: {
                message:
                  "Too many fields to generate. Try to reduce the amount",
                data: "",
              },
            });
          }

          const fields = JSON.parse(v.choices[0]?.message.content ?? "[]")
            .data as CustomFieldsValue[] | undefined;

          const isArrOfObj = (fields: unknown[]) =>
            fields.every(
              (field) =>
                field instanceof Object && field.constructor === Object,
            );

          if (!fields || !Array.isArray(fields) || !isArrOfObj(fields)) {
            return E.left({
              code: "SERVER" as const,
              payload: {
                message: "Failed to generate fields",
                data: "",
              },
            });
          }

          return E.right({ fields });
        }),
        dsErrorNotification(
          flow(E.map(Actions.createSuccess), E.getOrElseW(Actions.createFail)),
        ),
      );
    }),
  );

  return openAICreate$;
};
