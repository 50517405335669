import { NEVER, Observable, interval, switchMap } from "rxjs";
import { Client } from "ds";
import { getDataTypes } from "ds/DataTypes";
import { Epic } from "../../../../types/RootEpic";
import { State } from "./types/State";
import { Actions } from "./types/Actions";

export const epic: Epic<
  Actions,
  State,
  { pyckAdminClient$: Observable<Client> }
> = (_, { pyckAdminClient$: client$ }) => {
  return client$.pipe(
    switchMap((client) =>
      interval(5000).pipe(
        switchMap((n) => getDataTypes(client, { where: { name: String(n) } })),
      ),
    ),

    switchMap(() => NEVER),
  );
};
