import * as InventoryItems from "state-manager/states/Ready/states/DataManager/states/InventoryItems";
import * as ListingAll from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/ListingAll";
import * as Listing from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Listing";
import * as Create from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Create";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Edit";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";

import * as ListingAllContainer from "./ListingAll";
import * as CreateContainer from "./Create";
import * as EditContainer from "./Edit";
import * as ListingContainer from "./Listing";

export interface FooterProps {
  selector: Selector<InventoryItems.State>;
  dispatch: (a: InventoryItems.Actions) => void;
}

export function Footer(p: FooterProps) {
  const r = useSelector(
    flow(p.selector, (s) => {
      if (ListingAll.isState(s))
        return {
          type: "listing-all",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      if (Listing.isState(s))
        return {
          type: "listing",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      if (Create.isState(s))
        return {
          type: "create",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      if (Edit.isState(s))
        return {
          type: "edit",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      silentUnreachableError(s);
      return {
        type: "unknown",
        selector: flow(p.selector, (st) => st as typeof s),
      } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "listing-all":
      return (
        <ListingAllContainer.Footer
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "listing":
      return (
        <ListingContainer.Footer selector={r.selector} dispatch={p.dispatch} />
      );
    case "create":
      return (
        <CreateContainer.Footer selector={r.selector} dispatch={p.dispatch} />
      );
    case "edit":
      return (
        <EditContainer.Footer selector={r.selector} dispatch={p.dispatch} />
      );
    case "unknown":
      return null;
  }
}
