import { Selector, useSelector } from "state-manager";
import { NumberInput as InputComponent } from "ui/components/Input";

export interface InputProps {
  placeholder?: string;
  value$: Selector<number | undefined>;
  onChange: (value: number | undefined) => void;
  min?: number;
  max?: number;
  validation?: "success" | "warning" | "error";
}

export function Number({
  value$,
  onChange,
  placeholder,
  min,
  max,
  validation,
}: InputProps) {
  const value = useSelector(value$);

  return (
    <InputComponent
      value={value}
      onValueChange={onChange}
      placeholder={placeholder}
      min={min}
      max={max}
      validation={validation}
    />
  );
}
