import { Button } from "ui/components/Button";
import { useTranslation } from "i18n";

export interface AddProps {
  onAdd: () => void;
}

export function Add(p: AddProps) {
  const { t } = useTranslation();

  return (
    <div>
      <Button isStretched isPrimary onClick={p.onAdd}>
        {t("Add New Item")}
      </Button>
    </div>
  );
}
