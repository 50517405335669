import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  forkJoin,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  scan,
  skip,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError } from "ds";
import { shallowEqualObjects } from "shallow-equal";
import * as Arr from "fp-ts/Array";
import * as Str from "fp-ts/string";
import { Eq } from "fp-ts/Eq";
import { getDataTypes } from "ds/DataTypes";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { deletePickingOrders, getPickingOrders } from "ds/PickingOrder";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import { DataTypeEntity } from "types/src/DataType/DataType";
import { Epic } from "../../../../../../../../types/RootEpic";
import { FiltersEq } from "./types/Filters";
import { getFetchVars } from "./transformers";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export const epic = <P extends string>(
  p: P,
): Epic<
  Actions.Actions<P>,
  State.State<P>,
  { pyckAdminClient$: Observable<Client> }
> => {
  const isReady = State.isReady(p);
  const isLoading = State.isLoading(p);
  const isFetching = State.isFetching(p);
  const loadSuccess = Actions.loadSuccess(p);
  const loadFail = Actions.loadFail(p);
  const fetchSuccess = Actions.fetchSuccess(p);
  const removeSuccess = Actions.removeSuccess(p);
  const removeFail = Actions.removeFail(p);
  const submitFilters = Actions.submitFilters(p);

  return (state$, { pyckAdminClient$: dep$ }) => {
    const loading$ = state$.pipe(
      filter(isLoading),
      map(getFetchVars(p)),
      distinctUntilChanged(shallowEqualObjects),
      withLatestFrom(dep$),
      switchMap(([vars, client]) => {
        return forkJoin({
          items: from(getPickingOrders(client, vars)),
          dataTypes: from(
            getDataTypes(client, {
              where: {
                entity: [DataTypeEntity.Order],
              },
            }),
          ),
        }).pipe(
          map(
            flow(
              (v) => {
                if (E.isLeft(v.items)) return v.items;
                if (E.isLeft(v.dataTypes)) return v.dataTypes;

                return E.right({
                  items: v.items.right,
                  dataTypes: v.dataTypes.right,
                });
              },
              E.map((r) =>
                loadSuccess({
                  dataTypes: r.dataTypes.items,
                  items: r.items.items,
                  total: r.items.totalCount,
                  pageInfo: r.items.pageInfo,
                }),
              ),
              E.getOrElse<DsError, Actions.Actions<P>>(() =>
                loadFail({ type: undefined }),
              ),
            ),
          ),
        );
      }),
    );

    const fetch$ = state$.pipe(
      filter(isFetching),
      map(getFetchVars(p)),
      distinctUntilChanged(shallowEqualObjects),
      withLatestFrom(dep$),
      switchMap(([vars, client]) => {
        return from(getPickingOrders(client, vars)).pipe(
          map(
            flow(
              E.map((r) =>
                fetchSuccess({
                  items: r.items,
                  total: r.totalCount,
                  pageInfo: r.pageInfo,
                }),
              ),
              E.getOrElse<DsError, Actions.Actions<P>>(() =>
                loadFail({ type: undefined }),
              ),
            ),
          ),
        );
      }),
    );

    const remove$ = state$.pipe(
      filter(isReady),
      map((s) =>
        s.payload.items
          .filter((i) => i.removeState === "removing")
          .map((i) => i.id),
      ),
      scan(
        ([pendingItems], removingItems) => {
          return [
            removingItems,
            Arr.difference(Str.Eq as Eq<PickingOrderId>)(pendingItems)(
              removingItems,
            ),
          ] as [PickingOrderId[], PickingOrderId[]];
        },
        [[], []] as [PickingOrderId[], PickingOrderId[]],
      ),
      map(([, toRemove]) => toRemove),
      filter((i) => i.length > 0),
      withLatestFrom(dep$),
      mergeMap(([toRemove, client]) => {
        return from(deletePickingOrders(client, toRemove)).pipe(
          map(
            flow(
              E.map(() => removeSuccess(toRemove)),
              E.getOrElse<DsError, Actions.Actions<P>>(() =>
                removeFail(toRemove),
              ),
            ),
          ),
          catchError(() => of(removeFail(toRemove))),
        );
      }),
    );

    const applyFilters$ = state$.pipe(
      filter(isReady),
      map((s) => s.payload.filters),
      distinctUntilChanged(FiltersEq.equals),
      skip(1),
      debounceTime(500),
      map(submitFilters),
    );

    return merge(loading$, fetch$, remove$, applyFilters$);
  };
};
