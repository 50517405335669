import { useTranslation } from "i18n";
import { TypographyMD } from "ui/components/Typography";
import { Selector } from "state-manager";
import * as BuilderPreview from "state-manager/states/Ready/states/BuilderPreview";

export interface HeaderProps {
  selector: Selector<BuilderPreview.State>;
  dispatch: (a: BuilderPreview.Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();

  return <TypographyMD>{t("Create new warehouse")}</TypographyMD>;
}
