import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";

export type Actions<P extends string, E, T> =
  | SetQuery<P>
  | SubmitQuery<P>
  | SearchError<P, E>
  | SearchSuccess<P, T>
  | SelectItem<P, T>
  | Clear<P>;

export const isActions = <P extends string, E, T>(p: P) =>
  strictGuard((a: Actions<P, E, T>): a is Actions<P, E, T> => {
    if (isSetQuery(p)(a)) return true;
    if (isSubmitQuery(p)(a)) return true;
    if (isSearchError(p)(a)) return true;
    if (isSearchSuccess(p)(a)) return true;
    if (isSelectItem(p)(a)) return true;
    if (isClear(p)(a)) return true;

    silentUnreachableError(a);
    return false;
  });

// region SetQuery
export interface SetQuery<P extends string> {
  type: `${P}:SetQuery`;
  payload: string;
}

export const setQuery =
  <P extends string>(p: P) =>
  (payload: SetQuery<P>["payload"]): SetQuery<P> => ({
    type: `${p}:SetQuery`,
    payload,
  });

export const isSetQuery =
  <P extends string>(p: P) =>
  <E, T>(a: Actions<P, E, T>): a is SetQuery<P> =>
    a.type === `${p}:SetQuery`;
// endregion

// region SubmitQuery
export interface SubmitQuery<P extends string> {
  type: `${P}:SubmitQuery`;
}

export const submitQuery =
  <P extends string>(p: P) =>
  (): SubmitQuery<P> => ({
    type: `${p}:SubmitQuery`,
  });

export const isSubmitQuery =
  <P extends string>(p: P) =>
  <E, T>(a: Actions<P, E, T>): a is SubmitQuery<P> =>
    a.type === `${p}:SubmitQuery`;
// endregion

// region SearchError
export interface SearchError<P extends string, E> {
  type: `${P}:SearchError`;
  payload: E;
}

export const searchError =
  <P extends string>(p: P) =>
  <E>(payload: SearchError<P, E>["payload"]): SearchError<P, E> => ({
    type: `${p}:SearchError`,
    payload,
  });

export const isSearchError =
  <P extends string>(p: P) =>
  <E, T>(a: Actions<P, E, T>): a is SearchError<P, E> =>
    a.type === `${p}:SearchError`;
// endregion

// region SearchSuccess
export interface SearchSuccess<P extends string, T> {
  type: `${P}:SearchSuccess`;
  payload: T[];
}

export const searchSuccess =
  <P extends string>(p: P) =>
  <T>(payload: SearchSuccess<P, T>["payload"]): SearchSuccess<P, T> => ({
    type: `${p}:SearchSuccess`,
    payload,
  });

export const isSearchSuccess =
  <P extends string>(p: P) =>
  <E, T>(a: Actions<P, E, T>): a is SearchSuccess<P, T> =>
    a.type === `${p}:SearchSuccess`;
// endregion

// region SelectItem
export interface SelectItem<P extends string, T> {
  type: `${P}:SelectItem`;
  payload: T;
}

export const selectItem =
  <P extends string>(p: P) =>
  <T>(payload: SelectItem<P, T>["payload"]): SelectItem<P, T> => ({
    type: `${p}:SelectItem`,
    payload,
  });

export const isSelectItem =
  <P extends string>(p: P) =>
  <E, T>(a: Actions<P, E, T>): a is SelectItem<P, T> =>
    a.type === `${p}:SelectItem`;
// endregion

// region Clear
export interface Clear<P extends string> {
  type: `${P}:Clear`;
}

export const clear =
  <P extends string>(p: P) =>
  (): Clear<P> => ({
    type: `${p}:Clear`,
  });

export const isClear =
  <P extends string>(p: P) =>
  <E, T>(a: Actions<P, E, T>): a is Clear<P> =>
    a.type === `${p}:Clear`;
// endregion
