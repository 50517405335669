import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import { Login, login } from "../Auth";
import * as Ready from "./types/State";
import * as Actions from "./types/Actions";
import * as DataManager from "./states/DataManager";
import * as BuilderPreview from "./states/BuilderPreview";
import * as BPMNPreview from "./states/BPMNPreview";
import * as ZitadelPreview from "./states/ZitadelPreview";
import * as SandboxPreview from "./states/SandboxPreview";
import * as DataTypesListing from "./states/DataManager/states/DataTypes/states/Listing";
import * as DataTypesCreate from "./states/DataManager/states/DataTypes/states/Create";
import * as DataTypesEdit from "./states/DataManager/states/DataTypes/states/Edit";

import * as CustomersListingAll from "./states/DataManager/states/Customers/states/ListingAll";
import * as CustomersListing from "./states/DataManager/states/Customers/states/Listing";
import * as CustomersCreate from "./states/DataManager/states/Customers/states/Create";
import * as CustomersEdit from "./states/DataManager/states/Customers/states/Edit";

import * as SuppliersListingAll from "./states/DataManager/states/Suppliers/states/ListingAll";
import * as SuppliersListing from "./states/DataManager/states/Suppliers/states/Listing";
import * as SuppliersCreate from "./states/DataManager/states/Suppliers/states/Create";
import * as SuppliersEdit from "./states/DataManager/states/Suppliers/states/Edit";

import * as RepositoriesListingAll from "./states/DataManager/states/Repositories/states/ListingAll";
import * as RepositoriesListing from "./states/DataManager/states/Repositories/states/Listing";
import * as RepositoriesCreate from "./states/DataManager/states/Repositories/states/Create";
import * as RepositoriesEdit from "./states/DataManager/states/Repositories/states/Edit";

import * as InventoryItemsListingAll from "./states/DataManager/states/InventoryItems/states/ListingAll";
import * as InventoryItemsListing from "./states/DataManager/states/InventoryItems/states/Listing";
import * as InventoryItemsCreate from "./states/DataManager/states/InventoryItems/states/Create";
import * as InventoryItemsEdit from "./states/DataManager/states/InventoryItems/states/Edit";

import * as ItemMovementsListingAll from "./states/DataManager/states/ItemMovements/states/ListingAll";
import * as ItemMovementsListing from "./states/DataManager/states/ItemMovements/states/Listing";
import * as ItemMovementsCreate from "./states/DataManager/states/ItemMovements/states/Create";
import * as ItemMovementsEdit from "./states/DataManager/states/ItemMovements/states/Edit";

import * as RepositoryMovementsListingAll from "./states/DataManager/states/RepositoryMovements/states/ListingAll";
import * as RepositoryMovementsListing from "./states/DataManager/states/RepositoryMovements/states/Listing";
import * as RepositoryMovementsCreate from "./states/DataManager/states/RepositoryMovements/states/Create";
import * as RepositoryMovementsEdit from "./states/DataManager/states/RepositoryMovements/states/Edit";

import * as StocksListing from "./states/DataManager/states/Stocks/states/Listing";
import * as StocksSingle from "./states/DataManager/states/Stocks/states/Single";

import * as TransactionsListing from "./states/DataManager/states/Transactions/states/Listing";
import * as TransactionsSingle from "./states/DataManager/states/Transactions/states/Single";
import { pickingOrderState } from "./states/DataManager/utils";

export function reducer(
  s: Ready.State,
  a: Actions.Actions,
): Ready.State | Login {
  if (DataManager.isActions(a)) {
    if (DataManager.isState(s.payload.subState)) {
      const subState = DataManager.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? Ready.state({
            user: s.payload.user,
            organizations: {
              isActive: s.payload.organizations.isActive,
              ids: s.payload.organizations.ids,
              entities: s.payload.organizations.entities,
            },
            orgId: s.payload.orgId,
            openAI: s.payload.openAI,
            subState,
          })
        : s;
    }
    return s;
  }

  if (BuilderPreview.isActions(a)) {
    if (BuilderPreview.isState(s.payload.subState)) {
      const subState = BuilderPreview.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? Ready.state({
            user: s.payload.user,
            organizations: {
              isActive: s.payload.organizations.isActive,
              ids: s.payload.organizations.ids,
              entities: s.payload.organizations.entities,
            },
            orgId: s.payload.orgId,
            openAI: s.payload.openAI,
            subState,
          })
        : s;
    }
    return s;
  }

  if (BPMNPreview.isActions(a)) {
    if (BPMNPreview.isState(s.payload.subState)) {
      const subState = BPMNPreview.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? Ready.state({
            user: s.payload.user,
            organizations: {
              isActive: s.payload.organizations.isActive,
              ids: s.payload.organizations.ids,
              entities: s.payload.organizations.entities,
            },
            orgId: s.payload.orgId,
            openAI: s.payload.openAI,
            subState,
          })
        : s;
    }
    return s;
  }

  if (ZitadelPreview.isActions(a)) {
    if (ZitadelPreview.isState(s.payload.subState)) {
      const subState = ZitadelPreview.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? Ready.state({
            user: s.payload.user,
            organizations: {
              isActive: s.payload.organizations.isActive,
              ids: s.payload.organizations.ids,
              entities: s.payload.organizations.entities,
            },
            orgId: s.payload.orgId,
            openAI: s.payload.openAI,
            subState,
          })
        : s;
    }
    return s;
  }

  if (SandboxPreview.isActions(a)) {
    if (SandboxPreview.isState(s.payload.subState)) {
      const subState = SandboxPreview.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? Ready.state({
            user: s.payload.user,
            organizations: {
              isActive: s.payload.organizations.isActive,
              ids: s.payload.organizations.ids,
              entities: s.payload.organizations.entities,
            },
            orgId: s.payload.orgId,
            openAI: s.payload.openAI,
            subState,
          })
        : s;
    }
    return s;
  }

  if (a.type === "Ready:SignOut") {
    if (Ready.isSigningOut(s.payload.subState)) return s;

    return Ready.state({
      user: s.payload.user,
      organizations: {
        isActive: s.payload.organizations.isActive,
        ids: s.payload.organizations.ids,
        entities: s.payload.organizations.entities,
      },
      orgId: s.payload.orgId,
      openAI: s.payload.openAI,
      subState: Ready.signingOut({}),
    });
  }

  if (a.type === "Ready:SignedOut") {
    if (!Ready.isSigningOut(s.payload.subState)) return s;

    return login({ goTo: { type: "data-types:listing" } });
  }

  if (a.type === "Ready:Reauthorize") {
    if (E.isLeft(s.payload.user)) return s;

    return {
      ...s,
      payload: {
        ...s.payload,
        user: E.left("refresh"),
      },
    };
  }

  if (a.type === "Ready:ReauthorizeFail") {
    if (!E.isLeft(s.payload.user)) return s;

    return login({ goTo: { type: "data-types:listing" } });
  }

  if (a.type === "Ready:ReauthorizeSuccess") {
    if (!E.isLeft(s.payload.user)) return s;

    return {
      ...s,
      payload: {
        ...s.payload,
        user: E.right({
          accessToken: a.payload,
        }),
      },
    };
  }

  if (Actions.isGoto(a)) {
    if (Ready.isSigningOut(s.payload.subState)) return s;

    const getSubState = (
      a: Actions.Goto,
    ): Ready.State["payload"]["subState"] => {
      const getDataManagerState = (
        subState: DataManager.State["payload"]["subState"],
      ): DataManager.State => {
        if (DataManager.isState(s.payload.subState))
          return {
            ...s.payload.subState,
            payload: {
              ...s.payload.subState.payload,
              subState,
            },
          } as DataManager.State;
        return {
          ...DataManager.init(),
          payload: {
            ...DataManager.init().payload,
            subState,
          },
        } as DataManager.State;
      };

      switch (a.type) {
        case "Ready:GoTo:DataTypes:Create":
          return getDataManagerState(DataTypesCreate.init());
        case "Ready:GoTo:DataTypes:Edit":
          return getDataManagerState(DataTypesEdit.init({ id: a.payload }));
        case "Ready:GoTo:DataTypes:Listing":
          return getDataManagerState(DataTypesListing.init());

        case "Ready:GoTo:Customers":
          return getDataManagerState(CustomersListingAll.init());
        case "Ready:GoTo:Customers:Create":
          return getDataManagerState(
            CustomersCreate.init({ dataTypeId: a.payload }),
          );
        case "Ready:GoTo:Customers:Edit":
          return getDataManagerState(
            CustomersEdit.init({ customerId: a.payload }),
          );
        case "Ready:GoTo:Customers:DataType":
          return getDataManagerState(
            CustomersListing.init({
              dataTypeId: a.payload,
              openAI: s.payload.openAI,
            }),
          );

        case "Ready:GoTo:Suppliers":
          return getDataManagerState(SuppliersListingAll.init());
        case "Ready:GoTo:Suppliers:Create":
          return getDataManagerState(
            SuppliersCreate.init({ dataTypeId: a.payload }),
          );
        case "Ready:GoTo:Suppliers:Edit":
          return getDataManagerState(SuppliersEdit.init({ id: a.payload }));
        case "Ready:GoTo:Suppliers:DataType":
          return getDataManagerState(
            SuppliersListing.init({
              dataTypeId: a.payload,
              openAI: s.payload.openAI,
            }),
          );

        case "Ready:GoTo:WarehouseBuilder":
          return BuilderPreview.init();
        case "Ready:GoTo:BPMNBuilder":
          return BPMNPreview.idle({});
        case "Ready:GoTo:ZitadelApp":
          return ZitadelPreview.idle({});
        case "Ready:GoTo:GraphQLSandbox":
          return SandboxPreview.idle({});

        case "Ready:GoTo:Repositories":
          return getDataManagerState(RepositoriesListingAll.init());
        case "Ready:GoTo:Repositories:Create":
          return getDataManagerState(
            RepositoriesCreate.init({ dataTypeId: a.payload }),
          );
        case "Ready:GoTo:Repositories:Edit":
          return getDataManagerState(RepositoriesEdit.init({ id: a.payload }));
        case "Ready:GoTo:Repositories:DataType":
          return getDataManagerState(RepositoriesListing.init(a.payload));

        case "Ready:GoTo:InventoryItems":
          return getDataManagerState(InventoryItemsListingAll.init());
        case "Ready:GoTo:InventoryItems:Create":
          return getDataManagerState(
            InventoryItemsCreate.init({ dataTypeId: a.payload }),
          );
        case "Ready:GoTo:InventoryItems:Edit":
          return getDataManagerState(
            InventoryItemsEdit.init({ id: a.payload }),
          );
        case "Ready:GoTo:InventoryItems:DataType":
          return getDataManagerState(
            InventoryItemsListing.init({
              dataTypeId: a.payload,
              openAI: s.payload.openAI,
            }),
          );

        case "Ready:GoTo:ItemMovements":
          return getDataManagerState(ItemMovementsListingAll.init());
        case "Ready:GoTo:ItemMovements:Create":
          return getDataManagerState(
            ItemMovementsCreate.init({ dataTypeId: a.payload }),
          );
        case "Ready:GoTo:ItemMovements:Edit":
          return getDataManagerState(ItemMovementsEdit.init({ id: a.payload }));
        case "Ready:GoTo:ItemMovements:DataType":
          return getDataManagerState(ItemMovementsListing.init(a.payload));

        case "Ready:GoTo:RepositoryMovements":
          return getDataManagerState(RepositoryMovementsListingAll.init());
        case "Ready:GoTo:RepositoryMovements:Create":
          return getDataManagerState(
            RepositoryMovementsCreate.init({ dataTypeId: a.payload }),
          );
        case "Ready:GoTo:RepositoryMovements:Edit":
          return getDataManagerState(
            RepositoryMovementsEdit.init({ id: a.payload }),
          );
        case "Ready:GoTo:RepositoryMovements:DataType":
          return getDataManagerState(
            RepositoryMovementsListing.init(a.payload),
          );

        case "Ready:GoTo:PickingOrders":
          return getDataManagerState(
            pickingOrderState.subStates.listingAll.init(),
          );
        case "Ready:GoTo:PickingOrders:Create":
          return getDataManagerState(
            pickingOrderState.subStates.create.init({
              dataTypeId: a.payload,
            }),
          );
        case "Ready:GoTo:PickingOrders:Edit":
          return getDataManagerState(
            pickingOrderState.subStates.edit.init({ id: a.payload }),
          );
        case "Ready:GoTo:PickingOrders:DataType":
          return getDataManagerState(
            pickingOrderState.subStates.listing.init(a.payload),
          );

        case "Ready:GoTo:Stocks:Listing":
          return getDataManagerState(StocksListing.init());
        case "Ready:GoTo:Stocks:Single":
          return getDataManagerState(StocksSingle.loading({ id: a.payload }));

        case "Ready:GoTo:Transactions:Listing":
          return getDataManagerState(TransactionsListing.init());
        case "Ready:GoTo:Transactions:Single":
          return getDataManagerState(
            TransactionsSingle.loading({ id: a.payload }),
          );
      }
    };

    return Ready.state({
      ...s.payload,
      organizations: {
        ...s.payload.organizations,
        isActive: false,
      },
      subState: getSubState(a),
    });
  }

  if (a.type === "Ready:ChangeActive:Organizations") {
    return {
      ...s,
      payload: {
        ...s.payload,
        organizations: {
          ...s.payload.organizations,
          isActive: a.payload,
        },
      },
    };
  }

  if (a.type === "Ready:ChangeOrgId:Organizations") {
    return {
      ...s,
      payload: {
        ...s.payload,
        orgId: a.payload,
        subState: DataManager.init(),
      },
    };
  }

  silentUnreachableError(a);
  return s;
}
