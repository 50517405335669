import { Selector, useSelector } from "state-manager";
import {
  Combobox as ComboboxComponent,
  Field,
  Label,
  Option,
} from "ui/components/Combobox";
import { TranslatedStr } from "types/src/TranslatedStr";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as N from "fp-ts/number";
import { Spinner } from "ui/components/Spinner";
import { XIcon } from "icons/X";

export interface SelectorProps<T> {
  validation?: "success" | "warning" | "error";
  value: O.Option<T>;
  options: { value: T; label: TranslatedStr }[];
  isLoading: boolean;
}

export interface SearchComboboxProps<T> {
  onChange: (value: O.Option<T>) => void;
  onSearch: (s: string) => void;
  selector: Selector<SelectorProps<T>>;
  clearable?: boolean;
  getId: (v: T) => string;
  label?: TranslatedStr;
}

export function SearchCombobox<T>({
  selector,
  onChange,
  onSearch,
  clearable,
  getId,
  label,
}: SearchComboboxProps<T>) {
  const options = useSelector(
    flow(selector, (v) => v.options),
    (a, b) => a === b,
  );
  const activeIndex = useSelector(
    flow(
      selector,
      (v) => v.value,
      O.map((v) => options.findIndex((i) => getId(i.value) === getId(v))),
    ),
    O.getEq(N.Eq).equals,
  );
  const validation = useSelector(flow(selector, (v) => v.validation));
  const value = pipe(
    activeIndex,
    O.map((v) => options[v]?.label),
    O.toUndefined,
  );
  const isLoading = useSelector(flow(selector, (v) => v.isLoading));

  return (
    <Field>
      {label && <Label>{label}</Label>}
      <ComboboxComponent
        isAutocomplete
        activeIndex={O.toUndefined(activeIndex)}
        inputValue={value}
        inputProps={{
          onChange: (v) => onSearch(v.target.value),
        }}
        onChange={({ selectionValue, type }) => {
          type === "option:click" &&
            onChange(O.fromNullable(selectionValue as T));
        }}
        validation={validation}
        endIcon={
          isLoading ? (
            <Spinner />
          ) : clearable && O.isSome(activeIndex) ? (
            <XIcon />
          ) : undefined
        }
      >
        {options.map(({ value: v, label }) => (
          <Option key={getId(v)} value={v as object} label={label} />
        ))}
      </ComboboxComponent>
    </Field>
  );
}
