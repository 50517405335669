import { TransactionId } from "types/src/Transactions/Transaction";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { RepositoryId } from "types/src/Repositories/Repository";
import { createState } from "../../../../../../../../generic-states/ItemSearch";

export const transactionSearchState = createState<
  "Transactions:Transaction",
  unknown,
  { id: TransactionId }
>("Transactions:Transaction", (a, b) => a.id === b.id);

export type TransactionSearchState = ReturnType<
  (typeof transactionSearchState)["states"][keyof (typeof transactionSearchState)["states"]]["create"]
>;
export type TransactionSearchActions = ReturnType<
  (typeof transactionSearchState)["actions"][keyof (typeof transactionSearchState)["actions"]]["create"]
>;

export const itemSearchState = createState<
  "Transactions:InventoryItem",
  unknown,
  { id: InventoryItemId }
>("Transactions:InventoryItem", (a, b) => a.id === b.id);

export type ItemSearchState = ReturnType<
  (typeof itemSearchState)["states"][keyof (typeof itemSearchState)["states"]]["create"]
>;
export type ItemSearchActions = ReturnType<
  (typeof itemSearchState)["actions"][keyof (typeof itemSearchState)["actions"]]["create"]
>;

export const repositorySearchState = createState<
  "Transactions:Repository",
  unknown,
  { id: RepositoryId; name: string }
>("Transactions:Repository", (a, b) => a.id === b.id);

export type RepositorySearchState = ReturnType<
  (typeof repositorySearchState)["states"][keyof (typeof repositorySearchState)["states"]]["create"]
>;
export type RepositorySearchActions = ReturnType<
  (typeof repositorySearchState)["actions"][keyof (typeof repositorySearchState)["actions"]]["create"]
>;
