import { Cursor } from "types";
import * as NonEmptyArray from "fp-ts/NonEmptyArray";
import { DataTypeId } from "types/src/DataType/DataType";
import { omitEmpties } from "utils/value";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { _gte, _lte } from "../utils";
import {
  GetInventoryItemsQueryVariables,
  InventoryItemOrderField,
  InventoryItemWhereInput,
} from "../generated/graphql";
import { toApiOrderDirection } from "../transformers/OrderDirection";

export interface GetInventoryItemsWhere {
  createdAt?: [Date | undefined, Date | undefined];
  updatedAt?: [Date | undefined, Date | undefined];
  dataTypes?: NonEmptyArray.NonEmptyArray<DataTypeId>;
  id?: string;
  search?: string;
  orphans?: boolean;
}

export function getInventoryItemsWhereToApi(
  v: GetInventoryItemsWhere,
): InventoryItemWhereInput | undefined {
  const createdAt = pipe(v?.createdAt, O.fromNullable);
  const updatedAt = pipe(v?.updatedAt, O.fromNullable);
  return omitEmpties({
    and: [
      {
        createdAtGTE: pipe(createdAt, _gte),
        updatedAtGTE: pipe(updatedAt, _gte),
      },
      {
        createdAtLTE: pipe(createdAt, _lte),
        updatedAtLTE: pipe(updatedAt, _lte),
      },
    ],
    id: v?.id,
    DataContains: v?.search ? [v.search] : undefined,
    dataTypeIDIn: v?.dataTypes,
    dataTypeIDIsNil: v?.orphans === true ? true : undefined,
    dataTypeIDNotNil: v?.orphans === false ? true : undefined,
    skuContains: v?.search,
  });
}

export interface GetInventoryItemsVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: GetInventoryItemsWhere;
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
}

export function getInventoryItemsVarsWhereToApiInput(
  vars: GetInventoryItemsVars,
): GetInventoryItemsQueryVariables | undefined {
  return omitEmpties({
    first: vars.first,
    last: vars.last,
    after: vars.after,
    before: vars.before,
    where: pipe(
      vars.where,
      O.fromNullable,
      O.map(getInventoryItemsWhereToApi),
      O.toUndefined,
    ),
    orderBy: pipe(
      vars.orderBy,
      O.fromNullable,
      O.map((o) => ({
        direction: toApiOrderDirection(o.direction),
        field: {
          createdAt: InventoryItemOrderField.CreatedAt,
          updatedAt: InventoryItemOrderField.UpdatedAt,
        }[o.by],
      })),
      O.toUndefined,
    ),
  });
}
