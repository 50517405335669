import { ReactNode } from "react";
import {
  Body,
  Chrome,
  Footer,
  Header,
  Nav,
  NavItem as _NavItem,
  SubNav,
} from "@zendeskgarden/react-chrome";
import { INavItemProps } from "@zendeskgarden/react-chrome/dist/typings/types";
import styled from "styled-components";
import { Tooltip } from "@components/Tooltip";

export {
  NavItemIcon,
  NavItemText,
  SubNavItem,
  SubNavItemText,
  CollapsibleSubNavItem,
} from "@zendeskgarden/react-chrome";

export interface PageLayoutProps {
  mainSidebar: ReactNode;
  secondarySidebar?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  className?: string;
}

export function PageLayout({
  mainSidebar,
  secondarySidebar,
  footer,
  header,
  children,
  className,
}: PageLayoutProps) {
  return (
    <Chrome isFluid className={className}>
      <Nav isExpanded={!secondarySidebar}>{mainSidebar}</Nav>
      {secondarySidebar && (
        <SecondarySidebarStyled>{secondarySidebar}</SecondarySidebarStyled>
      )}
      <BodyStyled>
        <HeaderStyled>{header}</HeaderStyled>
        <Content>{children}</Content>
        <FooterStyled>{footer}</FooterStyled>
      </BodyStyled>
    </Chrome>
  );
}

export function NavItem({ children, title, ...props }: INavItemProps) {
  return (
    <Tooltip content={title} placement={"end"} hidden={!title}>
      <_NavItem {...props}>{children}</_NavItem>
    </Tooltip>
  );
}

const BodyStyled = styled(Body)`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  overflow: auto;
  flex: 1;
  padding: ${(p) => p.theme.space.md};
  background: ${(p) => p.theme.palette.grey["100"]};
  display: flex;
`;

const HeaderStyled = styled(Header)`
  justify-content: start;
  padding: ${(p) => p.theme.space.md};

  &:empty {
    display: none;
  }
`;

const FooterStyled = styled(Footer)`
  justify-content: start;
  &:empty {
    display: none;
  }
`;

export const SubNavItemSubmenu = styled.nav.attrs<{ isVisible: boolean }>(
  (p) => ({
    "aria-expanded": p.isVisible,
  }),
)`
  display: none;
  margin-left: ${(p) => p.theme.space.sm};

  &[aria-expanded="true"] {
    display: block;
  }
`;

const SecondarySidebarStyled = styled(SubNav)`
  &:empty {
    display: none;
  }
`;
