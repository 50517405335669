import { RootState, useSelector } from "state-manager";
import { Dispatch, ReactElement, useMemo } from "react";
import * as Listing from "state-manager/states/Ready/states/DataManager/states/Repositories/states/ListingAll";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { useTranslation } from "i18n";
import { FilterInput } from "@Containers/Listing/FilterInput";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Label } from "ui/components/Label";
import { MultiCombobox } from "@Containers/Form/MultiCombobox";
import { DataTypeId } from "types/src/DataType/DataType";
import { Field } from "ui/components/Field";
import { TranslatedStr } from "types/src/TranslatedStr";
import { shallowEqualObjects } from "shallow-equal";
import * as Arr from "fp-ts/Array";
import { Picker } from "@Containers/Form/Picker";
import { FilterDatePicker } from "@Containers/Listing/FilterDatePicker";
import { symmetricTuple } from "types/src/Tuple";
import { addDays, subDays } from "date-fns/fp";

type Filters = Listing.State["payload"]["filters"];

interface DataType {
  id: DataTypeId;
  name: TranslatedStr;
}

export interface AdvancedFiltersFormProps {
  selector$: (s: RootState) => Filters;
  dataTypes$: (s: RootState) => Array<DataType>;
  dispatch: Dispatch<Listing.Actions>;
}

export function AdvancedFiltersForm(p: AdvancedFiltersFormProps): ReactElement {
  const { t } = useTranslation();

  return (
    <FormWrapper>
      <FilterInput
        label={t("Search by id")}
        placeholder={t("Insert repository id")}
        value$={flow(
          p.selector$,
          (v) => v.id,
          O.getOrElseW(() => ""),
        )}
        onChange={flow(Listing.setIdFilter, p.dispatch)}
      />
      <FilterInput
        label={t("Search by name")}
        placeholder={t("Insert repository name")}
        value$={flow(
          p.selector$,
          (v) => v.name,
          O.getOrElseW(() => ""),
        )}
        onChange={flow(Listing.setNameFilter, p.dispatch)}
      />
      <FilterInput
        label={t("Search by fields")}
        placeholder={t("Type content to search")}
        value$={flow(
          p.selector$,
          (v) => v.search,
          O.getOrElseW(() => ""),
        )}
        onChange={flow(Listing.setSearchFilter, p.dispatch)}
      />
      <DataTypes
        dataTypes$={p.dataTypes$}
        selector$={flow(p.selector$, (s) => s.dataTypes)}
        onChange={flow(Listing.setDataTypesFilter, p.dispatch)}
      />
      <Status
        selector$={flow(p.selector$, (v) => v.status)}
        onChange={flow(Listing.setStatusFilter, p.dispatch)}
      />
      <CreatedFrom
        value$={flow(p.selector$, (v) => v.createdAt)}
        onChange={flow(Listing.setCreatedAtFilter, p.dispatch)}
      />
      <CreatedTo
        value$={flow(p.selector$, (v) => v.createdAt)}
        onChange={flow(Listing.setCreatedAtFilter, p.dispatch)}
      />
    </FormWrapper>
  );
}

interface DataTypesProps {
  selector$: (s: RootState) => DataTypeId[];
  dataTypes$: (s: RootState) => Array<DataType>;
  onChange: (v: DataTypeId[]) => void;
}
function DataTypes(p: DataTypesProps) {
  const { t } = useTranslation();
  const dataTypes = useSelector(
    flow(p.dataTypes$, (ds) => ds.map((d) => ({ value: d.id, label: d.name }))),
    Arr.getEq<{ value: DataTypeId; label: TranslatedStr }>({
      equals: shallowEqualObjects,
    }).equals,
  );

  return (
    <Field>
      <Label>{t("DataTypes")}</Label>
      <MultiCombobox<DataTypeId>
        placeholder={t("All DataTypes")}
        value$={p.selector$}
        onChange={p.onChange}
        options={dataTypes}
      />
    </Field>
  );
}

interface StatusProps {
  selector$: (s: RootState) => "active" | "orphan" | "all";
  onChange: (v: "active" | "orphan" | "all") => void;
}

function Status(p: StatusProps) {
  const { t } = useTranslation();
  const options = [
    { value: "active", label: t("Active") } as const,
    { value: "orphan", label: t("Orphan") } as const,
  ];

  return (
    <Field>
      <Label>{t("Status")}</Label>
      <Picker
        value$={p.selector$}
        onChange={(v) => p.onChange(v ?? "all")}
        options={options}
      />
    </Field>
  );
}

interface CreatedFromProps {
  value$: (s: RootState) => Filters["createdAt"];
  onChange: (v: Filters["createdAt"]) => void;
}
function CreatedFrom(p: CreatedFromProps) {
  const { t } = useTranslation();
  const to = useSelector<Date | undefined>(
    flow(
      p.value$,
      O.map((v) => v[1]),
      O.toUndefined,
    ),
    (a, b) => a?.getTime() === b?.getTime(),
  );

  const macValue = useMemo(
    () => pipe(to, O.fromNullable, O.map(subDays(1)), O.toUndefined),
    [to],
  );

  return (
    <FilterDatePicker
      label={t("Created from")}
      value$={flow(
        p.value$,
        O.map((v) => v[0]),
        O.toUndefined,
      )}
      maxValue={macValue}
      onChange={(v) => p.onChange(O.of(symmetricTuple(v, to)))}
    />
  );
}

interface CreatedToProps {
  value$: (s: RootState) => Filters["createdAt"];
  onChange: (v: Filters["createdAt"]) => void;
}
function CreatedTo(p: CreatedToProps) {
  const { t } = useTranslation();
  const from = useSelector<Date | undefined>(
    flow(
      p.value$,
      O.map((v) => v[0]),
      O.toUndefined,
    ),
    (a, b) => a?.getTime() === b?.getTime(),
  );
  const minValue = useMemo(
    () => pipe(from, O.fromNullable, O.map(addDays(1)), O.toUndefined),
    [from],
  );

  return (
    <FilterDatePicker
      label={t("Created until")}
      value$={flow(
        p.value$,
        O.map((v) => v[1]),
        O.toUndefined,
      )}
      onChange={(v) => p.onChange(O.of(symmetricTuple(from, v)))}
      minValue={minValue}
    />
  );
}
