import {
  delay,
  distinctUntilChanged,
  filter,
  first,
  from,
  map,
  merge,
  mergeMap,
  NEVER,
  Observable,
  of,
  pairwise,
  race,
} from "rxjs";
import * as Arr from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { DsError } from "ds";
import { pipe } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { TranslatedStr } from "types/src/TranslatedStr";
import { Epic } from "../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { Item } from "./types/Item";

const diff = Arr.difference<Item>({
  equals: (a, b) => a.id === b.id,
});

export const epic: Epic<Actions.Actions, State.State> = (state$) => {
  const item$ = state$.pipe(
    map((v) => v.payload.items),
    distinctUntilChanged(),
    map((v) => v.filter((v) => v.status === "pending")),
  );
  return merge(
    item$.pipe(first()),
    item$.pipe(
      pairwise(),
      map(([prev, next]) => diff(next, prev)),
    ),
  ).pipe(
    mergeMap((v) => from(v)),
    mergeMap((v) =>
      race(
        item$.pipe(
          map((vs) => vs.filter((v) => v.status === "pending")),
          map((vs) => vs.map((v) => v.id)),
          filter((vs) => !vs.includes(v.id)),
          map(() => null),
        ),
        of(v).pipe(
          delay(Math.max(0, v.expires - Date.now())),
          map(() => Actions.removeNotification(v.id)),
        ),
      ),
    ),
    mergeMap((v) => (v ? of(v) : NEVER)),
  );
};

export const dsErrorNotification =
  <A, B>(handler: (v: E.Either<DsError, A>) => B) =>
  (s$: Observable<E.Either<DsError, A>>): Observable<B | Actions.Actions> => {
    return s$.pipe(
      mergeMap((v) => {
        return merge(
          of(handler(v)),
          pipe(
            v,
            E.map(() => NEVER),
            E.getOrElse((e) => {
              switch (e.code) {
                case "SERVER":
                  return of(
                    Actions.addNotificationWithDesc({
                      type: "error",
                      message: e.payload.message as TranslatedStr,
                      description: {
                        type: "Notifications:code",
                        code: e.payload.data,
                      },
                    }),
                  );
                case "NOT_FOUND":
                case "UNKNOWN":
                case "UNAUTHORIZED":
                  return NEVER;
                default: {
                  silentUnreachableError(e);
                  return NEVER;
                }
              }
            }),
          ),
        );
      }),
    );
  };
