import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { RepositoryId } from "types/src/Repositories/Repository";
import { StockId } from "types/src/Stocks/Stock";
import { createState } from "../../../../../../../../generic-states/ItemSearch";

export const stockSearchState = createState<
  "Stocks:Stock",
  unknown,
  { id: StockId }
>("Stocks:Stock", (a, b) => a.id === b.id);

export type StockSearchState = ReturnType<
  (typeof stockSearchState)["states"][keyof (typeof stockSearchState)["states"]]["create"]
>;
export type StockSearchActions = ReturnType<
  (typeof stockSearchState)["actions"][keyof (typeof stockSearchState)["actions"]]["create"]
>;

export const itemSearchState = createState<
  "Stocks:InventoryItem",
  unknown,
  { id: InventoryItemId }
>("Stocks:InventoryItem", (a, b) => a.id === b.id);

export type ItemSearchState = ReturnType<
  (typeof itemSearchState)["states"][keyof (typeof itemSearchState)["states"]]["create"]
>;
export type ItemSearchActions = ReturnType<
  (typeof itemSearchState)["actions"][keyof (typeof itemSearchState)["actions"]]["create"]
>;

export const repositorySearchState = createState<
  "Stocks:Repository",
  unknown,
  { id: RepositoryId; name: string }
>("Stocks:Repository", (a, b) => a.id === b.id);

export type RepositorySearchState = ReturnType<
  (typeof repositorySearchState)["states"][keyof (typeof repositorySearchState)["states"]]["create"]
>;
export type RepositorySearchActions = ReturnType<
  (typeof repositorySearchState)["actions"][keyof (typeof repositorySearchState)["actions"]]["create"]
>;
