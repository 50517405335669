import { ISODate } from "types/src/date/ISODate";
import { UniqId } from "types/src/UniqId";
import { DataTypeId } from "types/src/DataType/DataType";
import { Cursor } from "types";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import { omitEmpties } from "utils/value";
import { isT, mPipe } from "fp-utilities";
import { CustomerId } from "types/src/Customers/Customer";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import {
  GetPickingOrdersQueryVariables,
  PickingOrderOrderField,
  PickingOrderWhereInput,
} from "../generated/graphql";
import { toApiOrderDirection } from "../transformers/OrderDirection";
import {
  getPickingOrderItemVarsWhereToApiInput,
  PickingOrderItemVarsWhere,
} from "./PickingOrderItems";
import { WhereData, whereEq, WhereEq, whereOrd, WhereOrd } from "./Where";

export interface PickingOrderVarsWhere {
  and?: PickingOrderVarsWhere[];
  not?: PickingOrderVarsWhere;
  or?: PickingOrderVarsWhere[];
  id?: WhereEq<PickingOrderId>;
  createdAt?: WhereOrd<ISODate>;
  createdBy?: WhereEq<UniqId>;
  updatedAt?: WhereOrd<ISODate>;
  updatedBy?: WhereEq<UniqId>;
  tenantId?: WhereEq<UniqId>;
  dataTypeId?: WhereEq<DataTypeId>;
  customerId?: WhereEq<CustomerId>;
  data?: WhereData;
  hasItems?: boolean;
  hasItemsWith?: PickingOrderItemVarsWhere[];
}

export function getPickingOrderVarsWhereToApiInput(
  v: PickingOrderVarsWhere,
): PickingOrderWhereInput | undefined {
  return omitEmpties({
    and: v.and?.map(getPickingOrderVarsWhereToApiInput).filter(isT),
    or: v.or?.map(getPickingOrderVarsWhereToApiInput).filter(isT),
    not: v.not ? getPickingOrderVarsWhereToApiInput(v.not) : undefined,
    hasOrderItems: v.hasItems,
    hasOrderItemsWith: v.hasItemsWith
      ?.map(getPickingOrderItemVarsWhereToApiInput)
      .filter(isT),
    ...mPipe(whereEq("id"))(v.id),
    ...mPipe(whereEq("tenantID"))(v.tenantId),
    ...mPipe(whereEq("createdBy"))(v.createdBy),
    ...mPipe(whereEq("updatedBy"))(v.updatedBy),
    ...mPipe(whereEq("dataTypeID"))(v.dataTypeId),
    ...mPipe(whereEq("customerID"))(v.customerId),
    ...mPipe(whereOrd("createdAt"))(v.createdAt),
    ...mPipe(whereOrd("updatedAt"))(v.updatedAt),
  });
}

export interface GetPickingOrdersVars {
  after?: Cursor;
  first?: number;
  before?: Cursor;
  last?: number;
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
  where?: PickingOrderVarsWhere;
}

export function getPickingOrdersVarsToApiVars(
  vars: GetPickingOrdersVars,
): GetPickingOrdersQueryVariables {
  return (
    omitEmpties({
      first: vars.first,
      last: vars.last,
      after: vars.after,
      before: vars.before,
      where: omitEmpties(
        pipe(
          vars.where,
          O.fromNullable,
          O.map(getPickingOrderVarsWhereToApiInput),
          O.toNullable,
        ),
      ),
      orderBy: pipe(
        vars.orderBy,
        O.fromNullable,
        O.map((v) => ({
          field: pipe(
            v.by,
            (v) =>
              ({
                createdAt: PickingOrderOrderField.CreatedAt,
                updatedAt: PickingOrderOrderField.UpdatedAt,
              })[v],
          ),
          direction: pipe(v.direction, toApiOrderDirection),
        })),
        O.toUndefined,
      ),
    }) ?? {}
  );
}
