import { ReactElement, useCallback, useMemo } from "react";
import { RootState } from "state-manager";
import { flow } from "fp-ts/function";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { GetContructorType } from "types/src/Utils";
import { ListingHeader } from "./ListingHeader";
import { DeleteConfirmation } from "./DeleteConfirmation";
import { Table, TableProps } from "./Table";

const state = DataManager.pickingOrderState.subStates.listing;
const readyOrFetch = {
  ready: state.states.ready,
  fetch: state.states.fetching,
};

type Actions = GetContructorType<typeof state.actions>;
type ReadyOrFetch = GetContructorType<typeof readyOrFetch>;

export interface AllProps {
  selector$: (s: RootState) => ReadyOrFetch;
  dispatch: (a: Actions) => void;
}

export function All({ selector$, dispatch }: AllProps): ReactElement {
  const columns$ = useMemo(() => () => [], [selector$]);
  // @ts-expect-error, fix it later
  const items$: TableProps["items$"] = useMemo(
    () => flow(selector$, (s) => s.payload.items),
    [selector$],
  );
  const orderBy$ = useMemo(
    () => flow(selector$, (s) => s.payload.order),
    [selector$],
  );

  const orderBy = useCallback(
    (v: "createdAt" | "updatedAt") => dispatch(state.actions.orderBy.create(v)),
    [dispatch],
  );
  const onSelect = useCallback(flow(state.actions.select.create, dispatch), [
    dispatch,
  ]);
  const onSelectAll = useCallback(
    flow(state.actions.selectAll.create, dispatch),
    [dispatch],
  );

  const onDelete = useCallback(
    flow(state.actions.removeItem.create, dispatch),
    [dispatch],
  );
  const onDeleteAll = useCallback(
    flow(state.actions.removeBulk.create, dispatch),
    [dispatch],
  );

  return (
    <>
      <ListingWrapper
        header={<ListingHeader selector$={selector$} dispatch={dispatch} />}
      >
        <Table
          columns$={columns$}
          items$={items$}
          orderBy$={orderBy$}
          orderBy={orderBy}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          onDeleteAll={onDeleteAll}
          onDelete={onDelete}
        />
      </ListingWrapper>
      <DeleteConfirmation selector$={selector$} dispatch={dispatch} />
    </>
  );
}
