import { isObject, keys } from "./object";

export function omitEmpties<T extends unknown[]>(v: T): T | undefined;
export function omitEmpties<T>(v: T): Partial<T> | undefined;
export function omitEmpties<T>(v: T): Partial<T> | undefined {
  if (v === undefined) return undefined;

  if (Array.isArray(v)) {
    const r = v.map(omitEmpties).filter((v) => v !== undefined);

    return r.length === 0 ? undefined : (r as unknown as Partial<T>);
  }

  if (isObject(v)) {
    const r = keys(v).reduce((acc, key) => {
      const value = omitEmpties(v[key]);
      if (value !== undefined) {
        // @ts-expect-error, this is fine
        acc[key] = value;
      }
      return acc;
    }, {} as Partial<T>);

    return Object.keys(r).length === 0 ? undefined : r;
  }

  return v;
}

export function isSame<A, B>(
  ps: [(v: A | B) => v is A, (v: A | B) => v is B],
): (a: A | B, b: A | B) => a is typeof b;
export function isSame<A, B, C>(
  ps: [
    (v: A | B | C) => v is A,
    (v: A | B | C) => v is B,
    (v: A | B | C) => v is C,
  ],
): (a: A | B | C, b: A | B | C) => a is typeof b;
export function isSame<A, B, C, D>(
  ps: [
    (v: A | B | C | D) => v is A,
    (v: A | B | C | D) => v is B,
    (v: A | B | C | D) => v is C,
    (v: A | B | C | D) => v is D,
  ],
): (a: A | B | C | D, b: A | B | C | D) => a is typeof b;
export function isSame<A, B, C, D, E>(
  ps: [
    (v: A | B | C | D | E) => v is A,
    (v: A | B | C | D | E) => v is B,
    (v: A | B | C | D | E) => v is C,
    (v: A | B | C | D | E) => v is D,
    (v: A | B | C | D | E) => v is E,
  ],
): (a: A | B | C | D | E, b: A | B | C | D | E) => a is typeof b;
export function isSame<A, B, C, D, E, F>(
  ps: [
    (v: A | B | C | D | E | F) => v is A,
    (v: A | B | C | D | E | F) => v is B,
    (v: A | B | C | D | E | F) => v is C,
    (v: A | B | C | D | E | F) => v is D,
    (v: A | B | C | D | E | F) => v is E,
    (v: A | B | C | D | E | F) => v is F,
  ],
): (a: A | B | C | D | E | F, b: A | B | C | D | E | F) => a is typeof b;
export function isSame<A, B, C, D, E, F, G>(
  ps: [
    (v: A | B | C | D | E | F | G) => v is A,
    (v: A | B | C | D | E | F | G) => v is B,
    (v: A | B | C | D | E | F | G) => v is C,
    (v: A | B | C | D | E | F | G) => v is D,
    (v: A | B | C | D | E | F | G) => v is E,
    (v: A | B | C | D | E | F | G) => v is F,
    (v: A | B | C | D | E | F | G) => v is G,
  ],
): (
  a: A | B | C | D | E | F | G,
  b: A | B | C | D | E | F | G,
) => a is typeof b;
export function isSame<A, B, C, D, E, F, G, H>(
  ps: [
    (v: A | B | C | D | E | F | G | H) => v is A,
    (v: A | B | C | D | E | F | G | H) => v is B,
    (v: A | B | C | D | E | F | G | H) => v is C,
    (v: A | B | C | D | E | F | G | H) => v is D,
    (v: A | B | C | D | E | F | G | H) => v is E,
    (v: A | B | C | D | E | F | G | H) => v is F,
    (v: A | B | C | D | E | F | G | H) => v is G,
    (v: A | B | C | D | E | F | G | H) => v is H,
  ],
): (
  a: A | B | C | D | E | F | G | H,
  b: A | B | C | D | E | F | G | H,
) => a is typeof b;
export function isSame<T>(
  ps: ((v: T) => v is T)[],
): (a: T, b: T) => a is typeof b {
  return (a, b): a is typeof b => ps.some((p) => p(a) && p(b));
}
