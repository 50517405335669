import type { GraphQLFormattedError } from "graphql/index";
import { DsError, serverError } from "../type/DsError";

export function graphQLErrorToDsError(error: GraphQLFormattedError): DsError {
  switch ((error?.extensions?.http as { status?: number })?.status) {
    case 403:
      return {
        code: "UNAUTHORIZED",
      };
    case 404:
      return {
        code: "NOT_FOUND",
      };
    default: {
      return serverError(error.message, JSON.stringify(error));
    }
  }
}
