import { PageInfo } from "types";
import {
  ItemMovement,
  ItemMovementId,
} from "types/src/ItemMovements/ItemMovement";
import { silentUnreachableError } from "utils/exceptions";
import { Option } from "fp-ts/Option";
import { DataType } from "types/src/DataType/DataType";
import { strictGuard } from "utils/strictGuard";

// region Actions
export type Actions =
  | LoadSuccess
  | FetchSuccess
  | LoadFail
  | SetPage
  | OrderBy
  | Select
  | SelectAll
  | RemoveItem
  | RemoveBulk
  | RemoveConfirm
  | RemoveDecline
  | RemoveSuccess
  | RemoveFail
  | ExecuteItem
  | ExecuteBulk
  | ExecuteConfirm
  | ExecuteDecline
  | ExecuteSuccess
  | ExecuteFail
  | SetCreatedAtFilter
  | SetUpdatedAtFilter
  | SetSearchFilter
  | SetExecutedFilter
  | SetIdFilter
  | SubmitFilters
  | OpenAdvancedFilters
  | CloseAdvancedFilters
  | ClearFilters
  | Create;

export const isActions = strictGuard((a: Actions): a is Actions => {
  const type = (a as Actions).type;
  switch (type) {
    case "Ready:DataManager:ItemMovements:Listing:LoadSuccess":
    case "Ready:DataManager:ItemMovements:Listing:FetchSuccess":
    case "Ready:DataManager:ItemMovements:Listing:LoadFail":
    case "Ready:DataManager:ItemMovements:Listing:SetPage":
    case "Ready:DataManager:ItemMovements:Listing:Select":
    case "Ready:DataManager:ItemMovements:Listing:SelectAll":
    case "Ready:DataManager:ItemMovements:Listing:OrderBy":
    case "Ready:DataManager:ItemMovements:Listing:RemoveBulk":
    case "Ready:DataManager:ItemMovements:Listing:RemoveConfirm":
    case "Ready:DataManager:ItemMovements:Listing:RemoveDecline":
    case "Ready:DataManager:ItemMovements:Listing:RemoveFail":
    case "Ready:DataManager:ItemMovements:Listing:RemoveItem":
    case "Ready:DataManager:ItemMovements:Listing:RemoveSuccess":
    case "Ready:DataManager:ItemMovements:Listing:SetIdFilter":
    case "Ready:DataManager:ItemMovements:Listing:SetCreatedAtFilter":
    case "Ready:DataManager:ItemMovements:Listing:SetUpdatedAtFilter":
    case "Ready:DataManager:ItemMovements:Listing:SetSearchFilter":
    case "Ready:DataManager:ItemMovements:Listing:SubmitFilters":
    case "Ready:DataManager:ItemMovements:Listing:CloseAdvancedFilters":
    case "Ready:DataManager:ItemMovements:Listing:OpenAdvancedFilters":
    case "Ready:DataManager:ItemMovements:Listing:ClearFilters":
    case "Ready:DataManager:ItemMovements:Listing:Create":
    case "Ready:DataManager:ItemMovements:Listing:ExecuteBulk":
    case "Ready:DataManager:ItemMovements:Listing:ExecuteConfirm":
    case "Ready:DataManager:ItemMovements:Listing:ExecuteDecline":
    case "Ready:DataManager:ItemMovements:Listing:ExecuteFail":
    case "Ready:DataManager:ItemMovements:Listing:ExecuteItem":
    case "Ready:DataManager:ItemMovements:Listing:ExecuteSuccess":
    case "Ready:DataManager:ItemMovements:Listing:SetExecutedFilter":
      return true;
    default:
      silentUnreachableError(type);
      return false;
  }
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:ItemMovements:Listing:LoadSuccess";
  payload: {
    pageInfo: PageInfo;
    total: number;
    items: ItemMovement[];
    dataType: DataType;
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:ItemMovements:Listing:LoadSuccess",
  payload,
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:ItemMovements:Listing:LoadFail";
  payload: {
    type: undefined;
  };
}

export const loadFail = (payload: LoadFail["payload"]): LoadFail => ({
  type: "Ready:DataManager:ItemMovements:Listing:LoadFail",
  payload,
});
// endregion

// region FetchSuccess
export interface FetchSuccess {
  type: "Ready:DataManager:ItemMovements:Listing:FetchSuccess";
  payload: {
    pageInfo: PageInfo;
    total: number;
    items: ItemMovement[];
  };
}

export const fetchSuccess = (
  payload: FetchSuccess["payload"],
): FetchSuccess => ({
  type: "Ready:DataManager:ItemMovements:Listing:FetchSuccess",
  payload,
});
// endregion

// region SetPage
export interface SetPage {
  type: "Ready:DataManager:ItemMovements:Listing:SetPage";
  payload: "start" | "prev" | "next" | "end";
}

export const setPage = (payload: SetPage["payload"]): SetPage => ({
  type: "Ready:DataManager:ItemMovements:Listing:SetPage",
  payload,
});
// endregion

// region OrderBy
export interface OrderBy {
  type: "Ready:DataManager:ItemMovements:Listing:OrderBy";
  payload: "createdAt" | "updatedAt";
}

export const orderBy = (payload: OrderBy["payload"]): OrderBy => ({
  type: "Ready:DataManager:ItemMovements:Listing:OrderBy",
  payload,
});
// endregion

// region Select
export interface Select {
  type: "Ready:DataManager:ItemMovements:Listing:Select";
  payload: ItemMovementId;
}

export const select = (payload: Select["payload"]): Select => ({
  type: "Ready:DataManager:ItemMovements:Listing:Select",
  payload,
});
// endregion

// region SelectAll
export interface SelectAll {
  type: "Ready:DataManager:ItemMovements:Listing:SelectAll";
}

export const selectAll = (): SelectAll => ({
  type: "Ready:DataManager:ItemMovements:Listing:SelectAll",
});
// endregion

// region RemoveItem
export interface RemoveItem {
  type: "Ready:DataManager:ItemMovements:Listing:RemoveItem";
  payload: ItemMovementId;
}

export const removeItem = (payload: RemoveItem["payload"]): RemoveItem => ({
  type: "Ready:DataManager:ItemMovements:Listing:RemoveItem",
  payload,
});
// endregion

// region RemoveBulk
export interface RemoveBulk {
  type: "Ready:DataManager:ItemMovements:Listing:RemoveBulk";
}

export const removeBulk = (): RemoveBulk => ({
  type: "Ready:DataManager:ItemMovements:Listing:RemoveBulk",
});
// endregion

// region RemoveConfirm
export interface RemoveConfirm {
  type: "Ready:DataManager:ItemMovements:Listing:RemoveConfirm";
}

export const removeConfirm = (): RemoveConfirm => ({
  type: "Ready:DataManager:ItemMovements:Listing:RemoveConfirm",
});
// endregion

// region RemoveDecline
export interface RemoveDecline {
  type: "Ready:DataManager:ItemMovements:Listing:RemoveDecline";
}

export const removeDecline = (): RemoveDecline => ({
  type: "Ready:DataManager:ItemMovements:Listing:RemoveDecline",
});
// endregion

// region RemoveSuccess
export interface RemoveSuccess {
  type: "Ready:DataManager:ItemMovements:Listing:RemoveSuccess";
  payload: ItemMovementId[];
}

export const removeSuccess = (
  payload: RemoveSuccess["payload"],
): RemoveSuccess => ({
  type: "Ready:DataManager:ItemMovements:Listing:RemoveSuccess",
  payload,
});
// endregion

// region RemoveFail
export interface RemoveFail {
  type: "Ready:DataManager:ItemMovements:Listing:RemoveFail";
  payload: ItemMovementId[];
}

export const removeFail = (payload: RemoveFail["payload"]): RemoveFail => ({
  type: "Ready:DataManager:ItemMovements:Listing:RemoveFail",
  payload,
});
// endregion

// region ExecuteItem
export interface ExecuteItem {
  type: "Ready:DataManager:ItemMovements:Listing:ExecuteItem";
  payload: ItemMovementId;
}

export const executeItem = (payload: ExecuteItem["payload"]): ExecuteItem => ({
  type: "Ready:DataManager:ItemMovements:Listing:ExecuteItem",
  payload,
});
// endregion

// region ExecuteBulk
export interface ExecuteBulk {
  type: "Ready:DataManager:ItemMovements:Listing:ExecuteBulk";
}

export const executeBulk = (): ExecuteBulk => ({
  type: "Ready:DataManager:ItemMovements:Listing:ExecuteBulk",
});
// endregion

// region ExecuteConfirm
export interface ExecuteConfirm {
  type: "Ready:DataManager:ItemMovements:Listing:ExecuteConfirm";
}

export const executeConfirm = (): ExecuteConfirm => ({
  type: "Ready:DataManager:ItemMovements:Listing:ExecuteConfirm",
});
// endregion

// region ExecuteDecline
export interface ExecuteDecline {
  type: "Ready:DataManager:ItemMovements:Listing:ExecuteDecline";
}

export const executeDecline = (): ExecuteDecline => ({
  type: "Ready:DataManager:ItemMovements:Listing:ExecuteDecline",
});
// endregion

// region ExecuteSuccess
export interface ExecuteSuccess {
  type: "Ready:DataManager:ItemMovements:Listing:ExecuteSuccess";
  payload: ItemMovementId[];
}

export const executeSuccess = (
  payload: ExecuteSuccess["payload"],
): ExecuteSuccess => ({
  type: "Ready:DataManager:ItemMovements:Listing:ExecuteSuccess",
  payload,
});
// endregion

// region ExecuteFail
export interface ExecuteFail {
  type: "Ready:DataManager:ItemMovements:Listing:ExecuteFail";
  payload: ItemMovementId[];
}

export const executeFail = (payload: ExecuteFail["payload"]): ExecuteFail => ({
  type: "Ready:DataManager:ItemMovements:Listing:ExecuteFail",
  payload,
});
// endregion

// region SetCreatedAtFilter
export interface SetCreatedAtFilter {
  type: "Ready:DataManager:ItemMovements:Listing:SetCreatedAtFilter";
  payload: Option<[Date | undefined, Date | undefined]>;
}

export const setCreatedAtFilter = (
  payload: SetCreatedAtFilter["payload"],
): SetCreatedAtFilter => ({
  type: "Ready:DataManager:ItemMovements:Listing:SetCreatedAtFilter",
  payload,
});
// endregion

// region SetUpdatedAtFilter
export interface SetUpdatedAtFilter {
  type: "Ready:DataManager:ItemMovements:Listing:SetUpdatedAtFilter";
  payload: Option<[Date | undefined, Date | undefined]>;
}

export const setUpdatedAtFilter = (
  payload: SetUpdatedAtFilter["payload"],
): SetUpdatedAtFilter => ({
  type: "Ready:DataManager:ItemMovements:Listing:SetUpdatedAtFilter",
  payload,
});
// endregion

// region SetSearchFilter
export interface SetSearchFilter {
  type: "Ready:DataManager:ItemMovements:Listing:SetSearchFilter";
  payload: string;
}

export const setSearchFilter = (
  payload: SetSearchFilter["payload"],
): SetSearchFilter => ({
  type: "Ready:DataManager:ItemMovements:Listing:SetSearchFilter",
  payload,
});
// endregion

// region SetExecutedFilter
export interface SetExecutedFilter {
  type: "Ready:DataManager:ItemMovements:Listing:SetExecutedFilter";
  payload: "executed" | "un-executed" | "all";
}

export const setExecutedFilter = (
  payload: SetExecutedFilter["payload"],
): SetExecutedFilter => ({
  type: "Ready:DataManager:ItemMovements:Listing:SetExecutedFilter",
  payload,
});
// endregion

// region SetIdFilter
export interface SetIdFilter {
  type: "Ready:DataManager:ItemMovements:Listing:SetIdFilter";
  payload: string;
}

export const setIdFilter = (payload: SetIdFilter["payload"]): SetIdFilter => ({
  type: "Ready:DataManager:ItemMovements:Listing:SetIdFilter",
  payload,
});
// endregion

// region SubmitFilters
export interface SubmitFilters {
  type: "Ready:DataManager:ItemMovements:Listing:SubmitFilters";
}

export const submitFilters = (): SubmitFilters => ({
  type: "Ready:DataManager:ItemMovements:Listing:SubmitFilters",
});
// endregion

// region OpenAdvancedFilters
export interface OpenAdvancedFilters {
  type: "Ready:DataManager:ItemMovements:Listing:OpenAdvancedFilters";
}

export const openAdvancedFilters = (): OpenAdvancedFilters => ({
  type: "Ready:DataManager:ItemMovements:Listing:OpenAdvancedFilters",
});
// endregion

// region CloseAdvancedFilters
export interface CloseAdvancedFilters {
  type: "Ready:DataManager:ItemMovements:Listing:CloseAdvancedFilters";
}

export const closeAdvancedFilters = (): CloseAdvancedFilters => ({
  type: "Ready:DataManager:ItemMovements:Listing:CloseAdvancedFilters",
});
// endregion

// region ClearFilters
export interface ClearFilters {
  type: "Ready:DataManager:ItemMovements:Listing:ClearFilters";
}

export const clearFilters = (): ClearFilters => ({
  type: "Ready:DataManager:ItemMovements:Listing:ClearFilters",
});
// endregion

// region Create
export interface Create {
  type: "Ready:DataManager:ItemMovements:Listing:Create";
}

export const create = (): Create => ({
  type: "Ready:DataManager:ItemMovements:Listing:Create",
});
// endregion
