import { gql } from "@apollo/client";
import {
  ItemMovement,
  ItemMovementId,
} from "types/src/ItemMovements/ItemMovement";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Cursor } from "types";
import { isT } from "fp-utilities";
import * as NonEmptyArray from "fp-ts/NonEmptyArray";
import { DataTypeId } from "types/src/DataType/DataType";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { toApiOrderDirection } from "../../transformers/OrderDirection";
import { _gte, _lte } from "../../utils";
import { itemMovementFragmentToItemMovement } from "../../transformers/ItemMovements";
import { itemMovementFragment } from "../../fragments/ItemMovement";
import { Client, notFoundError, QueryResponse } from "../../index";
import {
  ItemMovementOrderField,
  GetItemMovementsQuery,
  GetItemMovementsQueryVariables,
} from "../../generated/graphql";
import {
  getPaginatedQueryResult,
  PaginatedQueryResponse,
} from "../../type/QueryResponse";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";

export interface GetItemMovementsVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: {
    executed?: boolean;
    createdAt?: [Date | undefined, Date | undefined];
    updatedAt?: [Date | undefined, Date | undefined];
    dataTypes?: NonEmptyArray.NonEmptyArray<DataTypeId>;
    id?: string;
    search?: string;
    orphans?: boolean;
  };
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
}

export function getItemMovements(
  client: Client,
  vars: GetItemMovementsVars,
): Promise<PaginatedQueryResponse<ItemMovement>> {
  const query = gql`
    ${pageInfoFragment}
    ${itemMovementFragment}

    query GetItemMovements(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: ItemMovementWhereInput
      $orderBy: ItemMovementOrder
    ) {
      itemMovements(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...ItemMovementFragment
          }
        }
      }
    }
  `;

  const createdAt = pipe(vars.where?.createdAt, O.fromNullable);
  const updatedAt = pipe(vars.where?.updatedAt, O.fromNullable);

  return client
    .query<GetItemMovementsQuery, GetItemMovementsQueryVariables>({
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: {
          executed: vars.where?.executed,
          and: [
            {
              createdAtGTE: pipe(createdAt, _gte),
              updatedAtGTE: pipe(updatedAt, _gte),
            },
            {
              createdAtLTE: pipe(createdAt, _lte),
              updatedAtLTE: pipe(updatedAt, _lte),
            },
          ],
          id: vars.where?.id,
          DataContains: vars.where?.search ? [vars.where.search] : undefined,
          dataTypeIDIn: vars.where?.dataTypes,
          dataTypeIDIsNil: vars.where?.orphans === true ? true : undefined,
          dataTypeIDNotNil: vars.where?.orphans === false ? true : undefined,
        },
        orderBy: pipe(
          vars.orderBy,
          O.fromNullable,
          O.map((o) => ({
            direction: toApiOrderDirection(o.direction),
            field: {
              createdAt: ItemMovementOrderField.CreatedAt,
              updatedAt: ItemMovementOrderField.UpdatedAt,
            }[o.by],
          })),
          O.toUndefined,
        ),
      }),
    })
    .then(
      flow(
        E.map((v) => v.itemMovements),
        E.map(getPaginatedQueryResult(itemMovementFragmentToItemMovement)),
      ),
    );
}

export type GetItemMovementQueryResult = ItemMovement;
export function getItemMovement(
  client: Client,
  id: ItemMovementId,
): Promise<QueryResponse<GetItemMovementQueryResult>> {
  return getItemMovements(client, { where: { id } }).then(
    flow(
      E.map((r) => r.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
  );
}
