import { gql } from "@apollo/client";
import {
  InventoryItem,
  InventoryItemId,
} from "types/src/InventoryItems/InventoryItem";
import { flow } from "fp-ts/function";
import { isT } from "fp-utilities";
import * as E from "fp-ts/Either";
import { inventoryItemFragmentToInventoryItem } from "../../transformers/InventoryItems";
import { inventoryItemFragment } from "../../fragments/InventoryItem";
import { Client } from "../../index";
import {
  GetInventoryItemsQuery,
  GetInventoryItemsQueryVariables,
} from "../../generated/graphql";
import {
  getPaginatedQueryResult,
  PaginatedQueryResult,
  QueryResponse,
} from "../../type/QueryResponse";
import { notFoundError } from "../../type/DsError";
import {
  GetInventoryItemsVars,
  getInventoryItemsVarsWhereToApiInput,
} from "../../type/InvetoryItems";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";

export type { GetInventoryItemsVars } from "../../type/InvetoryItems";

export type GetInventoryItemsQueryResult = PaginatedQueryResult<InventoryItem>;
export function getInventoryItems(
  client: Client,
  vars: GetInventoryItemsVars,
): Promise<QueryResponse<GetInventoryItemsQueryResult>> {
  const query = gql`
    ${pageInfoFragment}
    ${inventoryItemFragment}

    query GetInventoryItems(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: InventoryItemWhereInput
      $orderBy: InventoryItemOrder
    ) {
      inventoryItems(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...InventoryItemFragment
          }
        }
      }
    }
  `;

  return client
    .query<GetInventoryItemsQuery, GetInventoryItemsQueryVariables>({
      query,
      variables: getInventoryItemsVarsWhereToApiInput(vars),
    })
    .then(
      flow(
        E.map((v) => v.inventoryItems),
        E.map(getPaginatedQueryResult(inventoryItemFragmentToInventoryItem)),
      ),
    );
}

export function getInventoryItem(
  client: Client,
  id: InventoryItemId,
): Promise<QueryResponse<InventoryItem>> {
  return getInventoryItems(client, { where: { id } }).then(
    flow(
      E.map((r) => r.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
  );
}
