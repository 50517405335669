import { Footer as FooterLayout } from "ui/layouts/Edit/Footer";
import { Selector, useSelector } from "state-manager";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/Repositories/states/Edit";
import { flow } from "fp-ts/function";

export interface FooterProps {
  selector: Selector<Edit.State>;
  dispatch: (a: Edit.Actions) => void;
}

export function Footer({ selector, dispatch }: FooterProps) {
  const isSaving = useSelector(flow(selector, Edit.isSaving));

  return (
    <FooterLayout
      submit={{
        disabled: isSaving,
        onClick: () => dispatch(Edit.submit()),
      }}
    />
  );
}
