import * as Listing from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Listing";
import { RootState, useSelector } from "state-manager";
import { ReactElement } from "react";
import { flow } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { DeleteModal } from "ui/layouts/Dialogs/DeleteModal";
import { useTranslation } from "i18n";

export interface DeleteConfirmationProps {
  selector$: (s: RootState) => Listing.State;
  dispatch: (a: Listing.Actions) => void;
}

export function DeleteConfirmation(
  p: DeleteConfirmationProps,
): ReactElement | null {
  const { t } = useTranslation();
  const count = useSelector(
    flow(
      p.selector$,
      O.of,
      O.filter(Listing.isReady),
      O.map(
        (s) =>
          s.payload.items.filter(
            (i) =>
              i.actionState === "removeConfirm" || i.actionState === "removing",
          ).length,
      ),
      O.getOrElseW(() => 0),
    ),
  );
  const isRemoving = useSelector(
    flow(
      p.selector$,
      O.of,
      O.filter(Listing.isReady),
      O.filter((s) =>
        s.payload.items.some((i) => i.actionState === "removing"),
      ),
      O.map(() => true),
      O.getOrElseW(() => false),
    ),
  );
  const isOpen = count > 0;
  const many = count > 1;

  if (!isOpen) return null;

  return (
    <DeleteModal
      title={many ? t("Remove items") : t("Remove item")}
      onConfirm={() => p.dispatch(Listing.removeConfirm())}
      onCancel={() => p.dispatch(Listing.removeDecline())}
      isLoading={isRemoving}
    >
      {t("Are you sure you want to delete this item?")}
    </DeleteModal>
  );
}
