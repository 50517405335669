import { RootState, useSelector } from "state-manager";
import * as State from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Listing";
import { ReactElement } from "react";
import { silentUnreachableError } from "utils/exceptions";
import { TypographyMD } from "ui/components/Typography";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";

export interface HeaderProps {
  selector: (s: RootState) => State.State;
  dispatch: (a: State.Actions) => void;
}

export function Header({ selector }: HeaderProps): ReactElement | null {
  const { t } = useTranslation();
  const r = useSelector(
    flow(selector, getSelector),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "null":
      return null;
    case "paginated":
      return <TypographyMD>{t("Data types")}</TypographyMD>;
  }
}

interface Null {
  type: "null";
}
interface Paginated {
  type: "paginated";
}
type ContentType = Null | Paginated;

function getSelector(s: State.State): ContentType {
  if (State.isLoading(s)) return { type: "null" };
  if (State.isLoadError(s)) return { type: "null" };

  if (State.isReady(s) || State.isFetching(s)) {
    return {
      type: "paginated",
    };
  }

  silentUnreachableError(s);
  return { type: "null" };
}
