import { gql } from "@apollo/client";
import { flow } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { isT } from "fp-utilities";
import {
  PickingOrder,
  PickingOrderId,
} from "types/src/PickingOrder/PickingOrder";
import { Client, notFoundError, QueryResponse } from "../../index";
import {
  GetPickingOrdersQuery,
  GetPickingOrdersQueryVariables,
} from "../../generated/graphql";
import {
  getPaginatedQueryResult,
  PaginatedQueryResponse,
} from "../../type/QueryResponse";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";
import { pickingOrderFragment } from "../../fragments/PickingOrder";
import { pickingOrderFragmentToPickingOrder } from "../../transformers/PickingOrder";
import {
  GetPickingOrdersVars,
  getPickingOrdersVarsToApiVars,
} from "../../type/PickingOrders";

export type { GetStocksVars } from "../../type/Stocks";

export function getPickingOrders(
  client: Client,
  vars: GetPickingOrdersVars,
): Promise<PaginatedQueryResponse<PickingOrder>> {
  const query = gql`
    ${pageInfoFragment}
    ${pickingOrderFragment}

    query GetPickingOrders(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: PickingOrderWhereInput
      $orderBy: PickingOrderOrder
    ) {
      pickingOrders(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...PickingOrderFragment
          }
        }
      }
    }
  `;

  return client
    .query<GetPickingOrdersQuery, GetPickingOrdersQueryVariables>({
      query,
      variables: getPickingOrdersVarsToApiVars(vars),
    })
    .then(
      E.map(
        flow(
          (v) => v.pickingOrders,
          getPaginatedQueryResult(pickingOrderFragmentToPickingOrder),
        ),
      ),
    );
}

export function getPickingOrder(
  client: Client,
  id: PickingOrderId,
): Promise<QueryResponse<PickingOrder>> {
  return getPickingOrders(client, { where: { id: { eq: id } }, first: 1 }).then(
    flow(
      E.map((v) => v.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
  );
}
