import { gql } from "@apollo/client";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import {
  DataType,
  DataTypeEntity,
  DataTypeId,
} from "types/src/DataType/DataType";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { isT } from "fp-utilities";
import { Client, notFoundError } from "../../index";
import {
  CreateDataTypeMutation,
  CreateDataTypeMutationVariables,
  RemoveDataTypeMutation,
  RemoveDataTypeMutationVariables,
  UpdateDataTypeMutation,
  UpdateDataTypeMutationVariables,
} from "../../generated/graphql";
import {
  dataTypeEntityToApiEntity,
  dataTypeFragmentToDataType,
  dataTypeToJsonSchema,
} from "../../transformers/DataType";
import { dataTypeFragment } from "../../fragments/DataType";
import { QueryResponse } from "../../type/QueryResponse";

export const createDataType = (
  client: Client,
  vars: {
    name: string;
    description: string | undefined;
    jsonSchema: DataTypeSchema;
    entity: DataTypeEntity;
    default: boolean;
  },
): Promise<QueryResponse<DataType>> => {
  const mutation = gql`
    ${dataTypeFragment}
    mutation CreateDataType($input: CreateDataTypeInput!) {
      createDataType(input: $input) {
        ...DataTypeFragment
      }
    }
  `;

  return client
    .mutate<CreateDataTypeMutation, CreateDataTypeMutationVariables>({
      mutation,
      variables: {
        input: {
          name: vars.name,
          description: vars.description ?? null,
          jsonSchema: JSON.stringify(dataTypeToJsonSchema(vars.jsonSchema)),
          entity: dataTypeEntityToApiEntity(vars.entity),
          default: vars.default,
        },
      },
    })
    .then(
      flow(
        E.map((v) => v.createDataType),
        E.filterOrElseW(isT, notFoundError),
        E.map(dataTypeFragmentToDataType),
      ),
    );
};

export const updateDataType = (
  client: Client,
  id: DataTypeId,
  vars: {
    name: string;
    description: string | undefined;
    jsonSchema: DataTypeSchema;
    entity: DataTypeEntity;
    default: boolean;
  },
): Promise<QueryResponse<DataType>> => {
  const mutation = gql`
    ${dataTypeFragment}
    mutation UpdateDataType($id: ID!, $input: UpdateDataTypeInput!) {
      updateDataType(id: $id, input: $input) {
        ...DataTypeFragment
      }
    }
  `;

  return client
    .mutate<UpdateDataTypeMutation, UpdateDataTypeMutationVariables>({
      mutation,
      variables: {
        id,
        input: {
          name: vars.name,
          description: vars.description,
          jsonSchema: JSON.stringify(dataTypeToJsonSchema(vars.jsonSchema)),
          entity: dataTypeEntityToApiEntity(vars.entity),
          default: vars.default,
        },
      },
    })
    .then(
      flow(
        E.map((v) => v.updateDataType),
        E.filterOrElseW(isT, notFoundError),
        E.map(dataTypeFragmentToDataType),
      ),
    );
};

export const removeDataType = (
  client: Client,
  id: DataTypeId,
): Promise<QueryResponse<DataTypeId>> => {
  const mutation = gql`
    mutation RemoveDataType($id: ID!) {
      deleteDataType(id: $id) {
        deletedID
      }
    }
  `;

  return client
    .mutate<RemoveDataTypeMutation, RemoveDataTypeMutationVariables>({
      mutation,
      variables: {
        id,
      },
    })
    .then(E.map((node) => node.deleteDataType.deletedID as DataTypeId));
};
