import { gql } from "@apollo/client";

export const inventoryItemFragment = gql`
  fragment InventoryItemFragment on InventoryItem {
    id
    createdAt
    updatedAt
    deletedAt
    inventoryJSONData
    dataTypeID
    sku
  }
`;
