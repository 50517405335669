import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Route } from "@Router/index";
import { JSXElementConstructor } from "react";

export interface LinkProps extends RouterLinkProps {
  to: Route;
}

export const Link: JSXElementConstructor<LinkProps> = RouterLink;
