import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import { DataTypeId } from "types/src/DataType/DataType";

export type Exits<P extends string> = Edit<P> | Create<P>;

// region Edit
export interface Edit<P extends string> {
  type: `${P}:Edit`;
  payload: PickingOrderId;
}

export const edit =
  <P extends string>(p: P) =>
  (payload: Edit<P>["payload"]): Edit<P> => ({
    type: `${p}:Edit`,
    payload,
  });

export const isEdit =
  <P extends string>(p: P) =>
  (s: Exits<P>): s is Edit<P> =>
    s.type === `${p}:Edit`;
// endregion

// region Create
export interface Create<P extends string> {
  type: `${P}:Create`;
  payload: DataTypeId;
}

export const create =
  <P extends string>(p: P) =>
  (payload: Create<P>["payload"]): Create<P> => ({
    type: `${p}:Create`,
    payload,
  });

export const isCreate =
  <P extends string>(p: P) =>
  (s: Exits<P>): s is Create<P> =>
    s.type === `${p}:Create`;
// endregion
