import styled from "styled-components";
import { forwardRef, ReactNode } from "react";

export interface ColumnsProps {
  children?: ReactNode;
}

export const FormColumns = forwardRef<HTMLDivElement, ColumnsProps>(
  (p: ColumnsProps, ref) => {
    return <Container ref={ref}>{p.children}</Container>;
  },
);

const Container = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: minmax(550px, auto) 1fr;
  gap: 1rem;
  min-height: 0;
`;
