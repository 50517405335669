import { DataTypeId } from "types/src/DataType/DataType";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";

export type Exits<P extends string> = List<P>;

// region List
export interface List<P extends string> {
  type: `${P}:List`;
  payload: {
    dataTypeId: DataTypeId;
    id: PickingOrderId;
  };
}

export const list =
  <P extends string>(p: P) =>
  (payload: List<P>["payload"]): List<P> => ({
    type: `${p}:List`,
    payload,
  });

export const isList =
  <P extends string>(p: P) =>
  (s: Exits<P>): s is List<P> =>
    s.type === `${p}:List`;
// endregion
