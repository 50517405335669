import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";

export type Exits<P extends string> = Edit<P>;

// region Edit
export interface Edit<P extends string> {
  type: `${P}:Edit`;
  payload: PickingOrderId;
}

export const edit =
  <P extends string>(p: P) =>
  (payload: Edit<P>["payload"]): Edit<P> => ({
    type: `${p}:Edit`,
    payload,
  });

export const isEdit =
  <P extends string>(p: P) =>
  (s: Exits<P>): s is Edit<P> =>
    s.type === `${p}:Edit`;
// endregion
