import * as NonEmptyArray from "fp-ts/NonEmptyArray";
import { DataTypeId } from "types/src/DataType/DataType";
import { Cursor } from "types";
import { omitEmpties } from "utils/value";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { fromDate } from "types/src/date/ISODate";
import { isT } from "fp-utilities";
import { repositoryTypeToApiResponseType } from "../transformers/Repositories";
import {
  GetRepositoriesQueryVariables,
  RepositoryOrderField,
  RepositoryWhereInput,
} from "../generated/graphql";
import { toApiOrderDirection } from "../transformers/OrderDirection";
import { GetStocksVarsWhere, getStocksVarsWhereToApiInput } from "./Stocks";

export interface RepositoryWhere {
  createdAtGTE?: Date;
  createdAtLTE?: Date;
  updatedAtGTE?: Date;
  updatedAtLTE?: Date;
  dataTypes?: NonEmptyArray.NonEmptyArray<DataTypeId>;
  id?: string;
  name?: string;
  search?: string;
  orphans?: boolean;
  hasRepositoryStocksWith?: GetStocksVarsWhere[];
  virtualRepository?: boolean;
  and?: RepositoryWhere[];
  or?: RepositoryWhere[];
  type?: "static" | "dynamic";
}

export function repositoryWhereToApiWhere(
  v: RepositoryWhere,
): RepositoryWhereInput | undefined {
  return omitEmpties({
    createdAtGTE: pipe(
      v?.createdAtGTE,
      O.fromNullable,
      O.map(fromDate),
      O.toUndefined,
    ),
    updatedAtGTE: pipe(
      v?.updatedAtGTE,
      O.fromNullable,
      O.map(fromDate),
      O.toUndefined,
    ),
    createdAtLTE: pipe(
      v?.createdAtLTE,
      O.fromNullable,
      O.map(fromDate),
      O.toUndefined,
    ),
    updatedAtLTE: pipe(
      v?.updatedAtLTE,
      O.fromNullable,
      O.map(fromDate),
      O.toUndefined,
    ),
    id: v?.id,
    nameContainsFold: v?.name,
    DataContains: v?.search ? [v.search] : undefined,
    dataTypeIDIn: v?.dataTypes,
    dataTypeIDIsNil: v?.orphans === true ? true : undefined,
    dataTypeIDNotNil: v?.orphans === false ? true : undefined,
    hasRepositoryStocksWith: v?.hasRepositoryStocksWith
      ?.map(getStocksVarsWhereToApiInput)
      .filter(isT),
    virtualRepo: v?.virtualRepository,
    type: pipe(
      v.type,
      O.fromNullable,
      O.map(repositoryTypeToApiResponseType),
      O.toUndefined,
    ),
    and: v.and?.map(repositoryWhereToApiWhere).filter(isT),
    or: v.or?.map(repositoryWhereToApiWhere).filter(isT),
  });
}

export interface GetRepositoryVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: RepositoryWhere;
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
}

export function repositoryWhereToApi(
  vars: GetRepositoryVars,
): GetRepositoriesQueryVariables | undefined {
  return omitEmpties({
    first: vars.first,
    last: vars.last,
    after: vars.after,
    before: vars.before,
    where: pipe(
      vars.where,
      O.fromNullable,
      O.map(repositoryWhereToApiWhere),
      O.toUndefined,
    ),
    orderBy: pipe(
      vars.orderBy,
      O.fromNullable,
      O.map((o) => ({
        direction: toApiOrderDirection(o.direction),
        field: {
          createdAt: RepositoryOrderField.CreatedAt,
          updatedAt: RepositoryOrderField.UpdatedAt,
        }[o.by],
      })),
      O.toUndefined,
    ),
  });
}
