// region NoEmptyString
import { none, Option, some } from "fp-ts/Option";
import { Eq } from "fp-ts/Eq";

declare const _noEmptyString: unique symbol;

export type NoEmptyString = string & { [_noEmptyString]: "NoEmptyString" };
// endregion

export function isNoEmptyString(value: string): value is NoEmptyString {
  return value.length > 0;
}

export function fromString(value: string): Option<NoEmptyString> {
  if (!isNoEmptyString(value)) {
    return none;
  }

  return some(value);
}

export const NoEmptyStringEq: Eq<NoEmptyString> = {
  equals: (a, b) => a === b,
};
