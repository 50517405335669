import { PageInfo } from "types";
import { DataType, DataTypeId } from "types/src/DataType/DataType";
import {
  InventoryItem,
  InventoryItemId,
} from "types/src/InventoryItems/InventoryItem";
import { silentUnreachableError } from "utils/exceptions";
import { Option } from "fp-ts/Option";
import { strictGuard } from "utils/strictGuard";

// region Actions
export type Actions =
  | LoadSuccess
  | FetchSuccess
  | LoadFail
  | SetPage
  | OrderBy
  | Select
  | SelectAll
  | RemoveItem
  | RemoveBulk
  | RemoveConfirm
  | RemoveDecline
  | RemoveSuccess
  | RemoveFail
  | SetCreatedAtFilter
  | SetUpdatedAtFilter
  | SetSearchFilter
  | SetIdFilter
  | SetDataTypesFilter
  | SetStatusFilter
  | SubmitFilters
  | OpenAdvancedFilters
  | CloseAdvancedFilters
  | ClearFilters;

export const isActions = strictGuard((a: Actions): a is Actions => {
  switch (a.type) {
    case "Ready:DataManager:InventoryItems:ListingAll:LoadSuccess":
    case "Ready:DataManager:InventoryItems:ListingAll:FetchSuccess":
    case "Ready:DataManager:InventoryItems:ListingAll:LoadFail":
    case "Ready:DataManager:InventoryItems:ListingAll:SetPage":
    case "Ready:DataManager:InventoryItems:ListingAll:Select":
    case "Ready:DataManager:InventoryItems:ListingAll:SelectAll":
    case "Ready:DataManager:InventoryItems:ListingAll:OrderBy":
    case "Ready:DataManager:InventoryItems:ListingAll:RemoveBulk":
    case "Ready:DataManager:InventoryItems:ListingAll:RemoveConfirm":
    case "Ready:DataManager:InventoryItems:ListingAll:RemoveDecline":
    case "Ready:DataManager:InventoryItems:ListingAll:RemoveFail":
    case "Ready:DataManager:InventoryItems:ListingAll:RemoveItem":
    case "Ready:DataManager:InventoryItems:ListingAll:RemoveSuccess":
    case "Ready:DataManager:InventoryItems:ListingAll:SetIdFilter":
    case "Ready:DataManager:InventoryItems:ListingAll:SetCreatedAtFilter":
    case "Ready:DataManager:InventoryItems:ListingAll:SetUpdatedAtFilter":
    case "Ready:DataManager:InventoryItems:ListingAll:SetSearchFilter":
    case "Ready:DataManager:InventoryItems:ListingAll:SubmitFilters":
    case "Ready:DataManager:InventoryItems:ListingAll:CloseAdvancedFilters":
    case "Ready:DataManager:InventoryItems:ListingAll:OpenAdvancedFilters":
    case "Ready:DataManager:InventoryItems:ListingAll:ClearFilters":
    case "Ready:DataManager:InventoryItems:ListingAll:SetDataTypesFilter":
    case "Ready:DataManager:InventoryItems:ListingAll:SetStatusFilter":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:InventoryItems:ListingAll:LoadSuccess";
  payload: {
    pageInfo: PageInfo;
    total: number;
    items: InventoryItem[];
    dataTypes: DataType[];
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:LoadSuccess",
  payload,
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:InventoryItems:ListingAll:LoadFail";
  payload: {
    type: undefined;
  };
}

export const loadFail = (payload: LoadFail["payload"]): LoadFail => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:LoadFail",
  payload,
});
// endregion

// region FetchSuccess
export interface FetchSuccess {
  type: "Ready:DataManager:InventoryItems:ListingAll:FetchSuccess";
  payload: {
    pageInfo: PageInfo;
    total: number;
    items: InventoryItem[];
  };
}

export const fetchSuccess = (
  payload: FetchSuccess["payload"],
): FetchSuccess => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:FetchSuccess",
  payload,
});
// endregion

// region SetPage
export interface SetPage {
  type: "Ready:DataManager:InventoryItems:ListingAll:SetPage";
  payload: "start" | "prev" | "next" | "end";
}

export const setPage = (payload: SetPage["payload"]): SetPage => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:SetPage",
  payload,
});
// endregion

// region OrderBy
export interface OrderBy {
  type: "Ready:DataManager:InventoryItems:ListingAll:OrderBy";
  payload: "createdAt" | "updatedAt";
}

export const orderBy = (payload: OrderBy["payload"]): OrderBy => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:OrderBy",
  payload,
});
// endregion

// region Select
export interface Select {
  type: "Ready:DataManager:InventoryItems:ListingAll:Select";
  payload: InventoryItemId;
}

export const select = (payload: Select["payload"]): Select => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:Select",
  payload,
});
// endregion

// region SelectAll
export interface SelectAll {
  type: "Ready:DataManager:InventoryItems:ListingAll:SelectAll";
}

export const selectAll = (): SelectAll => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:SelectAll",
});
// endregion

// region RemoveItem
export interface RemoveItem {
  type: "Ready:DataManager:InventoryItems:ListingAll:RemoveItem";
  payload: InventoryItemId;
}

export const removeItem = (payload: RemoveItem["payload"]): RemoveItem => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:RemoveItem",
  payload,
});
// endregion

// region RemoveBulk
export interface RemoveBulk {
  type: "Ready:DataManager:InventoryItems:ListingAll:RemoveBulk";
}

export const removeBulk = (): RemoveBulk => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:RemoveBulk",
});
// endregion

// region RemoveConfirm
export interface RemoveConfirm {
  type: "Ready:DataManager:InventoryItems:ListingAll:RemoveConfirm";
}

export const removeConfirm = (): RemoveConfirm => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:RemoveConfirm",
});
// endregion

// region RemoveDecline
export interface RemoveDecline {
  type: "Ready:DataManager:InventoryItems:ListingAll:RemoveDecline";
}

export const removeDecline = (): RemoveDecline => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:RemoveDecline",
});
// endregion

// region RemoveSuccess
export interface RemoveSuccess {
  type: "Ready:DataManager:InventoryItems:ListingAll:RemoveSuccess";
  payload: InventoryItemId[];
}

export const removeSuccess = (
  payload: RemoveSuccess["payload"],
): RemoveSuccess => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:RemoveSuccess",
  payload,
});
// endregion

// region RemoveFail
export interface RemoveFail {
  type: "Ready:DataManager:InventoryItems:ListingAll:RemoveFail";
  payload: InventoryItemId[];
}

export const removeFail = (payload: RemoveFail["payload"]): RemoveFail => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:RemoveFail",
  payload,
});
// endregion

// region SetCreatedAtFilter
export interface SetCreatedAtFilter {
  type: "Ready:DataManager:InventoryItems:ListingAll:SetCreatedAtFilter";
  payload: Option<[Date | undefined, Date | undefined]>;
}

export const setCreatedAtFilter = (
  payload: SetCreatedAtFilter["payload"],
): SetCreatedAtFilter => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:SetCreatedAtFilter",
  payload,
});
// endregion

// region SetUpdatedAtFilter
export interface SetUpdatedAtFilter {
  type: "Ready:DataManager:InventoryItems:ListingAll:SetUpdatedAtFilter";
  payload: Option<[Date | undefined, Date | undefined]>;
}

export const setUpdatedAtFilter = (
  payload: SetUpdatedAtFilter["payload"],
): SetUpdatedAtFilter => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:SetUpdatedAtFilter",
  payload,
});
// endregion

// region SetSearchFilter
export interface SetSearchFilter {
  type: "Ready:DataManager:InventoryItems:ListingAll:SetSearchFilter";
  payload: string;
}

export const setSearchFilter = (
  payload: SetSearchFilter["payload"],
): SetSearchFilter => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:SetSearchFilter",
  payload,
});
// endregion

// region SetIdFilter
export interface SetIdFilter {
  type: "Ready:DataManager:InventoryItems:ListingAll:SetIdFilter";
  payload: string;
}

export const setIdFilter = (payload: SetIdFilter["payload"]): SetIdFilter => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:SetIdFilter",
  payload,
});
// endregion

// region SetDataTypesFilter
export interface SetDataTypesFilter {
  type: "Ready:DataManager:InventoryItems:ListingAll:SetDataTypesFilter";
  payload: DataTypeId[];
}

export const setDataTypesFilter = (
  payload: SetDataTypesFilter["payload"],
): SetDataTypesFilter => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:SetDataTypesFilter",
  payload,
});
// endregion

// region SetStatusFilter
export interface SetStatusFilter {
  type: "Ready:DataManager:InventoryItems:ListingAll:SetStatusFilter";
  payload: "active" | "orphan" | "all";
}

export const setStatusFilter = (
  payload: SetStatusFilter["payload"],
): SetStatusFilter => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:SetStatusFilter",
  payload,
});
// endregion

// region SubmitFilters
export interface SubmitFilters {
  type: "Ready:DataManager:InventoryItems:ListingAll:SubmitFilters";
}

export const submitFilters = (): SubmitFilters => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:SubmitFilters",
});
// endregion

// region OpenAdvancedFilters
export interface OpenAdvancedFilters {
  type: "Ready:DataManager:InventoryItems:ListingAll:OpenAdvancedFilters";
}

export const openAdvancedFilters = (): OpenAdvancedFilters => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:OpenAdvancedFilters",
});
// endregion

// region CloseAdvancedFilters
export interface CloseAdvancedFilters {
  type: "Ready:DataManager:InventoryItems:ListingAll:CloseAdvancedFilters";
}

export const closeAdvancedFilters = (): CloseAdvancedFilters => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:CloseAdvancedFilters",
});
// endregion

// region ClearFilters
export interface ClearFilters {
  type: "Ready:DataManager:InventoryItems:ListingAll:ClearFilters";
}

export const clearFilters = (): ClearFilters => ({
  type: "Ready:DataManager:InventoryItems:ListingAll:ClearFilters",
});
// endregion
