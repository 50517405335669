import { createState } from "../SchemaFields";

export const createSchemaFieldsState = <P extends string>(p: P) =>
  createState(`${p}:Fields`);

export type SchemaFieldsStatesMap<P extends string> = {
  [k in keyof ReturnType<
    typeof createState<`${P}:Fields`>
  >["states"]]: ReturnType<
    ReturnType<typeof createState<`${P}:Fields`>>["states"][k]["create"]
  >;
};

export type SchemaFieldsState<P extends string> =
  SchemaFieldsStatesMap<P>[keyof SchemaFieldsStatesMap<P>];

export type SchemaFieldsActionsMap<P extends string> = {
  [k in keyof ReturnType<
    typeof createState<`${P}:Fields`>
  >["actions"]]: ReturnType<
    ReturnType<typeof createState<`${P}:Fields`>>["actions"][k]["create"]
  >;
};

export type SchemaFieldsAction<P extends string> =
  SchemaFieldsActionsMap<P>[keyof SchemaFieldsActionsMap<P>];
