import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";

export type State = Draft;

// region Draft
export type DraftPayload = DataTypeSchema;

export interface Draft {
  type: "Schema:Draft";
  payload: DraftPayload;
}

export const draft = (payload: Draft["payload"]): Draft => ({
  type: "Schema:Draft",
  payload,
});
// endregion

export const init = (): Draft => draft({});
