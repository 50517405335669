import { OpenAI, PageInfo } from "types";
import { DataType, DataTypeId } from "types/src/DataType/DataType";
import { silentUnreachableError } from "utils/exceptions";
import * as O from "fp-ts/Option";
import { strictGuard } from "utils/strictGuard";
import * as DataGenerator from "../../../../../../../../../generic-states/data-genetator";
import { Item } from "./Item";
import { Filters, FiltersMonoid } from "./Filters";

// region State
export type State = Loading | LoadError | Ready | Fetching;

export const isState = strictGuard((s: State): s is State => {
  if (isLoading(s)) return true;
  if (isLoadError(s)) return true;
  if (isReady(s)) return true;
  if (isFetching(s)) return true;

  silentUnreachableError(s);
  return false;
});
// endregion

// region Loading
export interface LoadingPayload {
  perPage: number;
  id: DataTypeId;
  order: O.Option<{
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  }>;
  openAI: DataGenerator.State;
  filters: Filters;
}

export interface Loading {
  type: "Ready:DataManager:Customers:Listing:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Ready:DataManager:Customers:Listing:Loading",
  payload,
});

export const isLoading = (s: State): s is Loading =>
  s.type === "Ready:DataManager:Customers:Listing:Loading";
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Ready:DataManager:Customers:Listing:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Ready:DataManager:Customers:Listing:LoadError",
  payload,
});

export const isLoadError = (s: State): s is LoadError =>
  s.type === "Ready:DataManager:Customers:Listing:LoadError";
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  pageInfo: PageInfo;
  total: number;
  items: Item[];
  dataType: DataType;
  advancedFiltersState: "open" | "closed";
}

export interface Ready {
  type: "Ready:DataManager:Customers:Listing:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:DataManager:Customers:Listing:Ready",
  payload,
});

export const isReady = (s: State): s is Ready =>
  s.type === "Ready:DataManager:Customers:Listing:Ready";
// endregion

// region Fetching
export interface FetchingPayload extends ReadyPayload {
  page: "current" | "start" | "prev" | "next" | "end";
}

export interface Fetching {
  type: "Ready:DataManager:Customers:Listing:Fetching";
  payload: FetchingPayload;
}

export const fetching = (payload: Fetching["payload"]): Fetching => ({
  type: "Ready:DataManager:Customers:Listing:Fetching",
  payload,
});

export const isFetching = (s: State): s is Fetching =>
  s.type === "Ready:DataManager:Customers:Listing:Fetching";
// endregion

export const init = (v: { dataTypeId: DataTypeId; openAI?: OpenAI }): Loading =>
  loading({
    id: v.dataTypeId,
    openAI: DataGenerator.Init(v.openAI),
    perPage: 50,
    order: O.none,
    filters: FiltersMonoid.empty,
  });
