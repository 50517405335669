import { createState } from "../../../../../../../../generic-states/SchemaFields";

export const schemaFieldsState = createState(
  "Ready:DataManager:ItemMovements:Create:SchemaFields",
);

export type SchemaFieldsActionsMap = {
  [k in keyof typeof schemaFieldsState.actions]: ReturnType<
    (typeof schemaFieldsState.actions)[k]["create"]
  >;
};
export type SchemaFieldsActions =
  SchemaFieldsActionsMap[keyof SchemaFieldsActionsMap];

export type SchemaFieldsStatesMap = {
  [k in keyof typeof schemaFieldsState.states]: ReturnType<
    (typeof schemaFieldsState.states)[k]["create"]
  >;
};
export type SchemaFieldsState =
  SchemaFieldsStatesMap[keyof SchemaFieldsStatesMap];
