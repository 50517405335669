import { PageInfo } from "types";
import { silentUnreachableError } from "utils/exceptions";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { strictGuard } from "utils/strictGuard";
import { Item } from "./Item";

export type Actions =
  | LoadFail
  | LoadSuccess
  | Edit
  | Create
  | Fetch
  | FiltersActions;

type FiltersActions =
  | SetName
  | SetDescription
  | SetEntity
  | SetDefault
  | SetCreatedAt
  | SetUpdatedAt
  | ClearFilters
  | ApplyFilters
  | OpenAdvancedFilters
  | CloseAdvancedFilters;

export const isActions = strictGuard((a: Actions): a is Actions => {
  switch (a.type) {
    case "Ready:DataManager:DataTypes:Listing:LoadFail":
    case "Ready:DataManager:DataTypes:Listing:LoadSuccess":
    case "Ready:DataManager:DataTypes:Listing:Create":
    case "Ready:DataManager:DataTypes:Listing:Fetch":
    case "Ready:DataManager:DataTypes:Listing:Edit":
    case "Ready:DataManager:DataTypes:Listing:CloseAdvancedFilters":
    case "Ready:DataManager:DataTypes:Listing:OpenAdvancedFilters":
    case "Ready:DataManager:DataTypes:Listing:ApplyFilters":
    case "Ready:DataManager:DataTypes:Listing:ClearFilters":
    case "Ready:DataManager:DataTypes:Listing:SetCreatedAt":
    case "Ready:DataManager:DataTypes:Listing:SetDefault":
    case "Ready:DataManager:DataTypes:Listing:SetDescription":
    case "Ready:DataManager:DataTypes:Listing:SetEntity":
    case "Ready:DataManager:DataTypes:Listing:SetName":
    case "Ready:DataManager:DataTypes:Listing:SetUpdatedAt":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:DataTypes:Listing:LoadFail";
}

export const loadFail = (): LoadFail => ({
  type: "Ready:DataManager:DataTypes:Listing:LoadFail",
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:DataTypes:Listing:LoadSuccess";
  payload: {
    total: number;
    items: Item[];
    pageInfo: PageInfo;
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:DataTypes:Listing:LoadSuccess",
  payload,
});
// endregion

// region Fetch
export interface Fetch {
  type: "Ready:DataManager:DataTypes:Listing:Fetch";
  payload: "start" | "prev" | "next" | "end";
}

export const fetch = (payload: Fetch["payload"]): Fetch => ({
  type: "Ready:DataManager:DataTypes:Listing:Fetch",
  payload,
});
// endregion

// region Edit
export interface Edit {
  type: "Ready:DataManager:DataTypes:Listing:Edit";
  payload: DataTypeId;
}

export const edit = (payload: Edit["payload"]): Edit => ({
  type: "Ready:DataManager:DataTypes:Listing:Edit",
  payload,
});
// endregion

// region Create
export interface Create {
  type: "Ready:DataManager:DataTypes:Listing:Create";
}

export const create = (): Create => ({
  type: "Ready:DataManager:DataTypes:Listing:Create",
});
// endregion

// region SetName
export interface SetName {
  type: "Ready:DataManager:DataTypes:Listing:SetName";
  payload: string;
}

export const setName = (payload: SetName["payload"]): SetName => ({
  type: "Ready:DataManager:DataTypes:Listing:SetName",
  payload,
});
// endregion

// region SetDescription
export interface SetDescription {
  type: "Ready:DataManager:DataTypes:Listing:SetDescription";
  payload: string;
}

export const setDescription = (
  payload: SetDescription["payload"],
): SetDescription => ({
  type: "Ready:DataManager:DataTypes:Listing:SetDescription",
  payload,
});
// endregion

// region SetDefault
export interface SetDefault {
  type: "Ready:DataManager:DataTypes:Listing:SetDefault";
  payload: boolean | undefined;
}

export const setDefault = (payload: SetDefault["payload"]): SetDefault => ({
  type: "Ready:DataManager:DataTypes:Listing:SetDefault",
  payload,
});
// endregion

// region SetEntity
export interface SetEntity {
  type: "Ready:DataManager:DataTypes:Listing:SetEntity";
  payload: DataTypeEntity[];
}

export const setEntity = (payload: SetEntity["payload"]): SetEntity => ({
  type: "Ready:DataManager:DataTypes:Listing:SetEntity",
  payload,
});
// endregion

// region SetCreatedAt
export interface SetCreatedAt {
  type: "Ready:DataManager:DataTypes:Listing:SetCreatedAt";
  payload: [Date | undefined, Date | undefined];
}

export const setCreatedAt = (
  payload: SetCreatedAt["payload"],
): SetCreatedAt => ({
  type: "Ready:DataManager:DataTypes:Listing:SetCreatedAt",
  payload,
});
// endregion

// region SetUpdatedAt
export interface SetUpdatedAt {
  type: "Ready:DataManager:DataTypes:Listing:SetUpdatedAt";
  payload: [Date | undefined, Date | undefined];
}

export const setUpdatedAt = (
  payload: SetUpdatedAt["payload"],
): SetUpdatedAt => ({
  type: "Ready:DataManager:DataTypes:Listing:SetUpdatedAt",
  payload,
});
// endregion

// region ClearFilters
export interface ClearFilters {
  type: "Ready:DataManager:DataTypes:Listing:ClearFilters";
}

export const clearFilters = (): ClearFilters => ({
  type: "Ready:DataManager:DataTypes:Listing:ClearFilters",
});
// endregion

// region ApplyFilters
export interface ApplyFilters {
  type: "Ready:DataManager:DataTypes:Listing:ApplyFilters";
}

export const applyFilters = (): ApplyFilters => ({
  type: "Ready:DataManager:DataTypes:Listing:ApplyFilters",
});
// endregion

// region OpenAdvancedFilters
export interface OpenAdvancedFilters {
  type: "Ready:DataManager:DataTypes:Listing:OpenAdvancedFilters";
}

export const openAdvancedFilters = (): OpenAdvancedFilters => ({
  type: "Ready:DataManager:DataTypes:Listing:OpenAdvancedFilters",
});
// endregion

// region CloseAdvancedFilters
export interface CloseAdvancedFilters {
  type: "Ready:DataManager:DataTypes:Listing:CloseAdvancedFilters";
}

export const closeAdvancedFilters = (): CloseAdvancedFilters => ({
  type: "Ready:DataManager:DataTypes:Listing:CloseAdvancedFilters",
});
// endregion
