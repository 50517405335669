import { RootState } from "state-manager";
import { Field } from "ui/components/Field";
import { Label } from "ui/components/Label";
import { Input } from "@Containers/Form/Input";
import { TranslatedStr } from "types/src/TranslatedStr";

interface FilterInputProps {
  value$: (s: RootState) => string;
  onChange: (v: string) => void;
  label: TranslatedStr;
  placeholder?: TranslatedStr;
}
export function FilterInput(p: FilterInputProps) {
  return (
    <Field>
      <Label>{p.label}</Label>
      <Input
        value$={p.value$}
        onChange={p.onChange}
        placeholder={p.placeholder}
      />
    </Field>
  );
}
