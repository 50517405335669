import { FiltersWrapper } from "ui/layouts/Listing/FiltersWrapper";
import { SearchInput } from "@Containers/Form/SearchInput";
import { RootState, useSelector } from "state-manager";
import { Dispatch, ReactElement } from "react";
import { flow } from "fp-ts/function";
import { FiltersButton } from "ui/layouts/Filters/FiltersButton";
import * as O from "fp-ts/Option";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { GetContructorType } from "types/src/Utils";
import { AdvancedFiltersForm } from "./AdvancedFiltersForm";
import { AdvancedFilters } from "./AdvancedFilters";

const state = DataManager.pickingOrderState.subStates.listingAll;
const actions = state.actions;
type Actions = GetContructorType<typeof state.actions>;
type ReadyOrFetching = GetContructorType<
  Pick<typeof state.states, "fetching" | "ready">
>;

export interface FiltersProps {
  selector$: (s: RootState) => ReadyOrFetching;
  dispatch: Dispatch<Actions>;
}

export function ListingHeader(p: FiltersProps): ReactElement {
  const search$ = flow(
    p.selector$,
    (s) => s.payload.filters.search,
    O.getOrElseW(() => ""),
  );
  const isOpen$ = flow(
    p.selector$,
    (s) => s.payload.advancedFiltersState === "open",
  );
  const filters$ = flow(p.selector$, (s) => s.payload.filters);
  const dataTypes$ = flow(p.selector$, (s) => s.payload.dataTypes);

  return (
    <FiltersWrapper>
      <SearchInput
        value$={search$}
        onChange={flow(actions.setSearchFilter.create, p.dispatch)}
      />
      <FiltersTrigger
        isSelected$={flow(
          p.selector$,
          (s) => !state.filters.isEmpty(s.payload.filters),
        )}
        onClear={flow(actions.clearFilters.create, p.dispatch)}
        onClick={flow(actions.openAdvancedFilters.create, p.dispatch)}
      />
      <AdvancedFilters
        open$={isOpen$}
        onClose={flow(actions.closeAdvancedFilters.create, p.dispatch)}
        onClear={flow(actions.clearFilters.create, p.dispatch)}
        onApply={flow(actions.submitFilters.create, p.dispatch)}
      >
        <AdvancedFiltersForm
          dispatch={p.dispatch}
          selector$={filters$}
          dataTypes$={dataTypes$}
        />
      </AdvancedFilters>
    </FiltersWrapper>
  );
}

interface FiltersTriggerProps {
  isSelected$: (s: RootState) => boolean;
  onClear: () => void;
  onClick: () => void;
}
function FiltersTrigger(p: FiltersTriggerProps): ReactElement {
  const isSelected = useSelector(p.isSelected$);

  return (
    <FiltersButton
      isSelected={isSelected}
      onClick={p.onClick}
      onClear={p.onClear}
    />
  );
}
