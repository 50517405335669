import { gql } from "@apollo/client";

export const customerFragment = gql`
  fragment CustomerFragment on Customer {
    id
    createdAt
    updatedAt
    deletedAt
    data
    dataTypeID
  }
`;
