import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError } from "ds";
import { createDataType } from "ds/DataTypes";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { Epic } from "../../../../../../../../types/RootEpic";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$ }) =>
  state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isSaving),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) => {
      const vars: Parameters<typeof createDataType>[1] = {
        name: s.payload.name,
        description: s.payload.description,
        default: s.payload.default,
        entity: s.payload.entity,
        jsonSchema: s.payload.schema.payload,
      };

      return from(createDataType(client, vars)).pipe(
        map(
          flow(
            E.map((r) => Actions.saveSuccess(r.id)),
            E.getOrElse<DsError, Actions.Actions>(Actions.saveFail),
          ),
        ),
        catchError(() => of(Actions.saveFail())),
      );
    }),
  );
