import { fromArray } from "types/src/NoEmptyArr";
import * as Create from "../Create";
import * as Edit from "../Edit";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { isDirty } from "./types/Filters";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): State.State | Create.State | Edit.State {
  switch (a.type) {
    case "Ready:DataManager:DataTypes:Listing:LoadFail": {
      if (s.type === "Ready:DataManager:DataTypes:Listing:Loading")
        return State.loadError(s.payload);
      if (s.type === "Ready:DataManager:DataTypes:Listing:Fetching")
        return State.loadError(s.payload);
      return s;
    }
    case "Ready:DataManager:DataTypes:Listing:LoadSuccess": {
      return s.type === "Ready:DataManager:DataTypes:Listing:Loading" ||
        s.type === "Ready:DataManager:DataTypes:Listing:Fetching"
        ? State.ready({
            ...s.payload,
            items: a.payload.items,
            total: a.payload.total,
            pageInfo: a.payload.pageInfo,
          })
        : s;
    }
    case "Ready:DataManager:DataTypes:Listing:Fetch": {
      return s.type === "Ready:DataManager:DataTypes:Listing:Ready" ||
        s.type === "Ready:DataManager:DataTypes:Listing:Fetching"
        ? State.fetching({ ...s.payload, page: a.payload })
        : s;
    }
    case "Ready:DataManager:DataTypes:Listing:Create":
      return Create.init();
    case "Ready:DataManager:DataTypes:Listing:Edit":
      return Edit.init({ id: a.payload });
    case "Ready:DataManager:DataTypes:Listing:SetName": {
      return s.type === "Ready:DataManager:DataTypes:Listing:Ready" ||
        s.type === "Ready:DataManager:DataTypes:Listing:Fetching"
        ? State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              name: a.payload || undefined,
            },
          })
        : s;
    }
    case "Ready:DataManager:DataTypes:Listing:SetDescription": {
      return s.type === "Ready:DataManager:DataTypes:Listing:Ready" ||
        s.type === "Ready:DataManager:DataTypes:Listing:Fetching"
        ? State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              description: a.payload || undefined,
            },
          })
        : s;
    }
    case "Ready:DataManager:DataTypes:Listing:SetDefault": {
      return s.type === "Ready:DataManager:DataTypes:Listing:Ready" ||
        s.type === "Ready:DataManager:DataTypes:Listing:Fetching"
        ? State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              default: a.payload,
            },
          })
        : s;
    }
    case "Ready:DataManager:DataTypes:Listing:SetEntity": {
      return s.type === "Ready:DataManager:DataTypes:Listing:Ready" ||
        s.type === "Ready:DataManager:DataTypes:Listing:Fetching"
        ? State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              entity: fromArray(a.payload),
            },
          })
        : s;
    }
    case "Ready:DataManager:DataTypes:Listing:SetCreatedAt": {
      return s.type === "Ready:DataManager:DataTypes:Listing:Ready" ||
        s.type === "Ready:DataManager:DataTypes:Listing:Fetching"
        ? State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              createdAt: a.payload,
            },
          })
        : s;
    }
    case "Ready:DataManager:DataTypes:Listing:SetUpdatedAt": {
      return s.type === "Ready:DataManager:DataTypes:Listing:Ready" ||
        s.type === "Ready:DataManager:DataTypes:Listing:Fetching"
        ? State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              updatedAt: a.payload,
            },
          })
        : s;
    }
    case "Ready:DataManager:DataTypes:Listing:ClearFilters": {
      return (s.type === "Ready:DataManager:DataTypes:Listing:Ready" ||
        s.type === "Ready:DataManager:DataTypes:Listing:Fetching") &&
        isDirty(s.payload.filters)
        ? State.fetching({
            ...s.payload,
            page: "current",
            filters: {},
            advancedFiltersOpen: false,
          })
        : s;
    }
    case "Ready:DataManager:DataTypes:Listing:ApplyFilters": {
      return s.type === "Ready:DataManager:DataTypes:Listing:Ready"
        ? State.fetching({
            ...s.payload,
            page: "current",
          })
        : s;
    }
    case "Ready:DataManager:DataTypes:Listing:OpenAdvancedFilters": {
      return !s.payload.advancedFiltersOpen
        ? ({
            ...s,
            payload: {
              ...s.payload,
              advancedFiltersOpen: true,
            },
          } as State.State)
        : s;
    }
    case "Ready:DataManager:DataTypes:Listing:CloseAdvancedFilters": {
      return s.payload.advancedFiltersOpen
        ? ({
            ...s,
            payload: {
              ...s.payload,
              advancedFiltersOpen: false,
            },
          } as State.State)
        : s;
    }
  }
}
