import { Selector, useSelector } from "state-manager";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Edit";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import { SchemaFieldsJsonEditor } from "@Containers/SchemaFieldsJsonEditor";

export interface ContentProps {
  selector: Selector<Edit.State>;
  dispatch: (a: Edit.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const formSelector = useSelector(
    flow(selector, (s) => {
      switch (s.type) {
        case "Ready:DataManager:Suppliers:Edit:Loading":
        case "Ready:DataManager:Suppliers:Edit:LoadError":
          return {
            type: "loading",
          } as const;
        case "Ready:DataManager:Suppliers:Edit:Ready":
        case "Ready:DataManager:Suppliers:Edit:Saving": {
          return {
            type: "ready",
            value$: flow(
              selector,
              (s) => s as Edit.Ready,
              (s) => s.payload.schema.payload,
            ),
          } as const;
        }

        default: {
          silentUnreachableError(s);
          return {
            type: "loading",
          } as const;
        }
      }
    }),
    (a, b) => a.type === b.type,
  );

  switch (formSelector.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <SchemaFieldsJsonEditor
          value$={formSelector.value$}
          onChange={flow(
            Edit.schemaFieldsState.actions.onChange.create,
            dispatch,
          )}
        />
      );
  }
}
