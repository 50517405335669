import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import {
  createPickingOrderState,
  editPickingOrderState,
  listingAllPickingOrderState,
  listingPickingOrderState,
  ListingAllActions,
  ListingActions,
  CreateActions,
  EditActions,
} from "../utils";

export type Actions =
  | CreateActions
  | EditActions
  | ListingAllActions
  | ListingActions;

export const isActions = strictGuard((s: Actions): s is Actions => {
  if (createPickingOrderState.isActions(s)) return true;
  if (listingAllPickingOrderState.isActions(s)) return true;
  if (listingPickingOrderState.isActions(s)) return true;
  if (editPickingOrderState.isActions(s)) return true;

  silentUnreachableError(s);
  return false;
});
