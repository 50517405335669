import { silentUnreachableError } from "utils/exceptions";
import { Repository } from "types/src/Repositories/Repository";
import { RepositoryType } from "types/src/Repositories/RepositoryType";
import { strictGuard } from "utils/strictGuard";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import * as ItemSearch from "../../../../../../../../../generic-states/ItemSearch/types/Actions";
import { SchemaFieldsActions, schemaFieldsState } from "../utils";

// region Actions
export type Actions =
  | LoadSuccess
  | LoadFail
  | SetName
  | SetType
  | Submit
  | SaveError
  | SaveSuccess
  | SchemaFieldsActions
  | ItemSearch.Actions<
      "Ready:DataManager:Repositories:Edit:Parent",
      "unknown",
      Repository | undefined
    >;

export const isActions = strictGuard((action: Actions): action is Actions => {
  const a = action as Actions;

  if (schemaFieldsState.isActions(a)) return true;
  if (ItemSearch.isActions("Ready:DataManager:Repositories:Edit:Parent")(a))
    return true;

  switch (a.type) {
    case "Ready:DataManager:Repositories:Edit:LoadFail":
    case "Ready:DataManager:Repositories:Edit:LoadSuccess":
    case "Ready:DataManager:Repositories:Edit:SetName":
    case "Ready:DataManager:Repositories:Edit:SetType":
    case "Ready:DataManager:Repositories:Edit:SaveError":
    case "Ready:DataManager:Repositories:Edit:SaveSuccess":
    case "Ready:DataManager:Repositories:Edit:Submit":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:Repositories:Edit:LoadFail";
}

export const loadFail = (): LoadFail => ({
  type: "Ready:DataManager:Repositories:Edit:LoadFail",
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:Repositories:Edit:LoadSuccess";
  payload: {
    schema: DataTypeSchema;
    item: Repository;
    repositories: Repository[];
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:Repositories:Edit:LoadSuccess",
  payload,
});
// endregion

// region SetName
export interface SetName {
  type: "Ready:DataManager:Repositories:Edit:SetName";
  payload: string;
}

export const setName = (payload: SetName["payload"]): SetName => ({
  type: "Ready:DataManager:Repositories:Edit:SetName",
  payload,
});
// endregion

// region SetType
export interface SetType {
  type: "Ready:DataManager:Repositories:Edit:SetType";
  payload: RepositoryType;
}

export const setType = (payload: SetType["payload"]): SetType => ({
  type: "Ready:DataManager:Repositories:Edit:SetType",
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: "Ready:DataManager:Repositories:Edit:Submit";
}

export const submit = (): Submit => ({
  type: "Ready:DataManager:Repositories:Edit:Submit",
});
// endregion

// region SaveError
export interface SaveError {
  type: "Ready:DataManager:Repositories:Edit:SaveError";
}

export const saveError = (): SaveError => ({
  type: "Ready:DataManager:Repositories:Edit:SaveError",
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: "Ready:DataManager:Repositories:Edit:SaveSuccess";
  payload: Repository;
}

export const saveSuccess = (payload: SaveSuccess["payload"]): SaveSuccess => ({
  type: "Ready:DataManager:Repositories:Edit:SaveSuccess",
  payload,
});
// endregion
