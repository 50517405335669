import { OpenAI } from "types";
import { DataTypeId } from "types/src/DataType/DataType";
import { CustomFields, CustomFieldsValue } from "./CustomFields";

export type State<T extends CustomFields = CustomFields> =
  | Init
  | Idle<T>
  | Creating<T>
  | Success<T>;

// region Idle
export interface IdlePayload<T extends CustomFields = CustomFields>
  extends OpenAI {
  fieldsSchema: string;
  isOpen: boolean;
  dataTypeId: DataTypeId;
  amount: number;
  responseExample: string;
  templateContext: string;
  data: T;
}

export interface Init {
  type: "DataGenerator:Init";
  payload: OpenAI | undefined;
}

export const Init = (payload: Init["payload"]): Init => ({
  type: "DataGenerator:Init",
  payload,
});

export interface Idle<T extends CustomFields = CustomFields> {
  type: "DataGenerator:Idle";
  payload: IdlePayload<T>;
}

export const idle = <T extends CustomFields>(
  payload: Idle<T>["payload"],
): Idle<T> => ({
  type: "DataGenerator:Idle",
  payload,
});

export const isIdle = <T extends CustomFields = CustomFields>(
  s: State<T>,
): s is Idle<T> => s.type === "DataGenerator:Idle";
// endregion

// region Creating
export interface CreatingPayload<T extends CustomFields>
  extends IdlePayload<T> {
  amount: number;
}

export interface Creating<T extends CustomFields> {
  type: "DataGenerator:Creating";
  payload: CreatingPayload<T>;
}

export const creating = <T extends CustomFields>(
  payload: Creating<T>["payload"],
): Creating<T> => ({
  type: "DataGenerator:Creating",
  payload,
});

export const isCreating = <T extends CustomFields = CustomFields>(
  s: State<T>,
): s is Creating<T> => s.type === "DataGenerator:Creating";
// endregion

// region Success

export const isSuccess = <T extends CustomFields>(
  s: State<T>,
): s is Success<T> => s.type === "DataGenerator:Success";

export interface SuccessPayload<T extends CustomFields> extends IdlePayload<T> {
  resultData: {
    fields: CustomFieldsValue<T>[];
  };
}

export interface Success<T extends CustomFields> {
  type: "DataGenerator:Success";
  payload: SuccessPayload<T>;
}

export const success = <T extends CustomFields>(
  payload: Success<T>["payload"],
): Success<T> => ({
  type: "DataGenerator:Success",
  payload,
});
// endregion
