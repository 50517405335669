import {
  ItemMovement,
  ItemMovementId,
} from "types/src/ItemMovements/ItemMovement";
import { RepositoryId } from "types/src/Repositories/Repository";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";

export interface Item {
  id: ItemMovementId;
  orderId: string;
  collectionId: string;
  from: {
    id: RepositoryId;
    name: string;
  };
  to: {
    id: RepositoryId;
    name: string;
  };
  item: InventoryItemId;
  quantity: number;
  handler: string;
  executed: boolean;

  selected: boolean;
  actionState:
    | "none"
    | "removeConfirm"
    | "removing"
    | "executeConfirm"
    | "executing";
}

export function fromApiItemMovement(i: ItemMovement): Item {
  return {
    id: i.id,
    from: {
      id: i.from.id,
      name: i.from.name,
    },
    to: {
      id: i.to.id,
      name: i.to.name,
    },
    item: i.item.id,
    handler: i.handler,
    orderId: i.orderId,
    collectionId: i.collectionId,
    executed: i.executed,
    quantity: i.quantity,

    selected: false,
    actionState: "none",
  };
}
