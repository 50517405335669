import { Footer as FooterLayout } from "ui/layouts/Create/Footer";
import { RootState, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import * as Create from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Create";

export interface FooterProps {
  selector: (s: RootState) => Create.State;
  dispatch: (a: Create.Actions) => void;
}

export function Footer({ selector, dispatch }: FooterProps) {
  const isSaving = useSelector(flow(selector, Create.isSaving));

  return (
    <FooterLayout
      submit={{
        onClick: () => dispatch(Create.submit()),
        disabled: isSaving,
      }}
    />
  );
}
