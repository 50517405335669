import { NEVER } from "rxjs";
import { Epic } from "../../types/RootEpic";
import { Actions } from "./types/Actions";
import { State } from "./types/State";

export const epic =
  <P extends string>(p: P): Epic<Actions<P>, State<P>> =>
  () => {
    return NEVER;
  };
