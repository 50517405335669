import { silentUnreachableError } from "utils/exceptions";
import * as FormValue from "types/src/FormValue";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { isNoEmptyString } from "types/src/NoEmptyString";
import * as DataGenerator from "../../../../../../../../generic-states/data-genetator";
import * as Listing from "../Listing/types/State";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { schemaFieldsState } from "./utils";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): State.State | Listing.State {
  if (DataGenerator.isActions(a)) {
    if (State.isReady(s) && s.payload.openAI) {
      return State.ready({
        ...s.payload,
        openAI: DataGenerator.reducer(s.payload.openAI, a),
      });
    }

    return s;
  }

  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return State.ready({
        dataTypeId: s.payload.dataTypeId,
        openAI: s.payload.openAI,
        schema: schemaFieldsState.reducer(s.payload.schema, a),
        sku: s.payload.sku,
      });
    }

    return s;
  }

  switch (a.type) {
    case "Ready:DataManager:InventoryItems:Create:LoadFail": {
      if (State.isLoading(s)) {
        return State.loadError({
          dataTypeId: s.payload.dataTypeId,
          openAI: s.payload.openAI,
        });
      }

      return s;
    }
    case "Ready:DataManager:InventoryItems:Create:LoadSuccess": {
      if (State.isLoading(s)) {
        return State.ready({
          dataTypeId: s.payload.dataTypeId,
          openAI: s.payload.openAI,
          sku: FormValue.initial(undefined),
          schema: schemaFieldsState.states.init.create({
            schema: a.payload,
            values: {},
          }),
        });
      }

      return s;
    }
    case "Ready:DataManager:InventoryItems:Create:SetSku": {
      if (State.isReady(s)) {
        return State.ready({
          dataTypeId: s.payload.dataTypeId,
          openAI: s.payload.openAI,
          schema: s.payload.schema,
          sku: pipe(
            a.payload,
            O.fromNullable,
            O.chain(O.fromPredicate(isNoEmptyString)),
            O.map(FormValue.valid),
            O.getOrElseW(() =>
              FormValue.invalid("required" as const, a.payload),
            ),
          ),
        });
      }

      return s;
    }
    case "Ready:DataManager:InventoryItems:Create:Submit": {
      if (State.isReady(s)) {
        if (FormValue.isValid(s.payload.sku)) {
          return State.saving({
            dataTypeId: s.payload.dataTypeId,
            openAI: s.payload.openAI,
            schema: s.payload.schema,
            sku: s.payload.sku,
          });
        } else {
          return State.ready({
            dataTypeId: s.payload.dataTypeId,
            openAI: s.payload.openAI,
            schema: s.payload.schema,
            sku: s.payload.sku,
          });
        }
      }

      return s;
    }
    case "Ready:DataManager:InventoryItems:Create:SaveError": {
      if (State.isSaving(s)) {
        return State.ready({
          dataTypeId: s.payload.dataTypeId,
          openAI: s.payload.openAI,
          schema: s.payload.schema,
          sku: s.payload.sku,
        });
      }

      return s;
    }
    case "Ready:DataManager:InventoryItems:Create:SaveSuccess": {
      if (State.isSaving(s)) {
        return Listing.init({
          dataTypeId: s.payload.dataTypeId,
          openAI: s.payload.openAI?.payload,
        });
      }

      return s;
    }
    default: {
      silentUnreachableError(a);
      return s;
    }
  }
}
