/**
 * A method to prevent type safe unreachable code blocks
 */

export function unreachableError(e: never): void {
  // @ts-expect-error, this is a hack to get the stack trace
  // eslint-disable-next-line no-console
  console.trace(
    "\n------------ Unreachable was reached -------------\n\n",
    // @ts-expect-error, this is a hack to get the stack trace
    typeof window === "undefined"
      ? JSON.stringify(e === undefined ? "undefined" : e, null, 2)
      : e,
    "\n\n--------------------------------------------------",
  );
}

/**
 * Same as `unreachableError`, but without console notification
 */

export function silentUnreachableError<T extends never>(e: T): T {
  return e;
}
