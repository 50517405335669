import { useTranslation } from "i18n";
import { useCallback } from "react";
import { TranslatedStr } from "types/src/TranslatedStr";
import { RepositoryType } from "types/src/Repositories/RepositoryType";

export function useRepositoryTypeTitle(): (t: RepositoryType) => TranslatedStr {
  const { t } = useTranslation();

  return useCallback(
    (v) => {
      switch (v) {
        case "dynamic":
          return t("Dynamic");
        case "static":
          return t("Static");
      }
    },
    [t],
  );
}
