import { useTranslation } from "i18n";
import * as Ready from "state-manager/states/Ready";
import {
  isSigningOut,
  signOut,
  changeOrganizationActive,
} from "state-manager/states/Ready";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import * as BuilderPreview from "state-manager/states/Ready/states/BuilderPreview";
import * as BPMNPreview from "state-manager/states/Ready/states/BPMNPreview";
import * as ZitadelPreview from "state-manager/states/Ready/states/ZitadelPreview";
import * as SandboxPreview from "state-manager/states/Ready/states/SandboxPreview";
import { RootState, useSelector } from "state-manager";
import { NavItem, NavItemIcon, NavItemText } from "ui/layouts/PageLayout";
import { silentUnreachableError } from "utils/exceptions";
import { flow } from "fp-ts/function";
import { AppIcon } from "icons/App";
import { DashboardIcon } from "icons/Dashboard";
import { ZitadelIcon } from "icons/Zitadel";
import { PlatformIcon } from "icons/Platform";
import { OrganizationIcon } from "icons/Organization";
import { ExitIcon } from "icons/Exit";
import { NotesFillIcon } from "icons/NotesFill";

export interface MainSidebarProps {
  selector: (s: RootState) => Ready.State;
  dispatch: (a: Ready.Actions) => void;
}

export function MainSidebar({ selector, dispatch }: MainSidebarProps) {
  const { t } = useTranslation();
  const r = useSelector(
    flow(selector, (s) => {
      if (s.payload.organizations.isActive) return "organizations";
      if (DataManager.isState(s.payload.subState)) return "data-manager";
      if (BuilderPreview.isState(s.payload.subState)) return "builder-preview";
      if (BPMNPreview.isState(s.payload.subState)) return "bpmn-preview";
      if (ZitadelPreview.isState(s.payload.subState)) return "zitadel-preview";
      if (SandboxPreview.isState(s.payload.subState)) return "sandbox-preview";

      if (isSigningOut(s.payload.subState)) return "signOut";

      silentUnreachableError(s.payload.subState);
      return undefined;
    }),
  );

  return (
    <>
      <NavItem
        isCurrent={r === "organizations"}
        title={t("Organizations")}
        onClick={() => dispatch(changeOrganizationActive(true))}
      >
        <NavItemIcon>
          <OrganizationIcon />
        </NavItemIcon>
      </NavItem>

      <NavItem
        isCurrent={r === "data-manager"}
        title={t("Data Manager")}
        onClick={() => dispatch(Ready.goToDataTypesListing())}
      >
        <NavItemIcon>
          <PlatformIcon />
        </NavItemIcon>
        <NavItemText>{t("Data Manager")}</NavItemText>
      </NavItem>

      <NavItem
        isCurrent={r === "builder-preview"}
        title={t("Warehouse builder")}
        onClick={() => dispatch(Ready.goToWarehouseBuilder())}
      >
        <NavItemIcon>
          <AppIcon />
        </NavItemIcon>
        <NavItemText>{t("Warehouse builder")}</NavItemText>
      </NavItem>

      <NavItem
        isCurrent={r === "bpmn-preview"}
        title={t("BPMN Builder")}
        onClick={() => dispatch(Ready.goToBPMNBuilder())}
      >
        <NavItemIcon>
          <DashboardIcon />
        </NavItemIcon>
        <NavItemText>{t("BPMN App")}</NavItemText>
      </NavItem>

      <NavItem
        isCurrent={r === "zitadel-preview"}
        title={t("Zitadel App")}
        onClick={() => dispatch(Ready.goToZitadelApp())}
      >
        <NavItemIcon>
          <ZitadelIcon />
        </NavItemIcon>
        <NavItemText>{t("Zitadel App")}</NavItemText>
      </NavItem>

      <NavItem
        isCurrent={r === "sandbox-preview"}
        title={t("GraphQL Sandbox")}
        onClick={() => dispatch(Ready.goToGraphQLSandbox())}
      >
        <NavItemIcon>
          <NotesFillIcon />
        </NavItemIcon>
        <NavItemText>{t("GraphQL Sandbox")}</NavItemText>
      </NavItem>

      <div style={{ flex: "1", order: 1 }} />

      <NavItem onClick={() => dispatch(signOut())} title={t("Log out")}>
        <NavItemIcon>
          <ExitIcon />
        </NavItemIcon>
        <NavItemText>{t("Log out")}</NavItemText>
      </NavItem>
    </>
  );
}
