import { Selector } from "state-manager";
import * as ZitadelPreview from "state-manager/states/Ready/states/ZitadelPreview";

export interface ContentProps {
  selector: Selector<ZitadelPreview.State>;
  dispatch: (a: ZitadelPreview.Actions) => void;
}

export function Content(p: ContentProps) {
  return (
    <div id="zitadel-app" style={{ flexGrow: 1 }}>
      <iframe
        style={{ width: "100%", height: "100%" }}
        // hardcoded for now till we decide where to store our redux env variables
        src={window.env.zitadelConsole}
      />
    </div>
  );
}
