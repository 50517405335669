// region NewItemId
import { v4 } from "uuid";

declare const _newItemId: unique symbol;

export type NewItemId = string & { [_newItemId]: "NewItemId" };

export const createNewItemId = (): NewItemId =>
  `new-item-id-${v4()}` as NewItemId;

export const isNewItemId = <T extends string>(
  t: T | NewItemId,
): t is NewItemId => t.startsWith("new-item-id-");
// endregion
