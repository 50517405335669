import { Action } from "redux";
import { silentUnreachableError } from "utils/exceptions";
import { CustomFields, CustomFieldsValue } from "./CustomFields";

export function isActions<T extends CustomFields>(
  action: Action,
): action is Actions<T> {
  const a = action as Actions<T>;

  switch (a.type) {
    case "DataGenerator:Create":
    case "DataGenerator:CreateFail":
    case "DataGenerator:CreateSuccess":
    case "DataGenerator:ToggleOpenSidebar":
    case "DataGenerator:ChangeAmount":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
}

export type Actions<T extends CustomFields = CustomFields> =
  | Create
  | CreateSuccess<T>
  | CreateFail
  | ToggleOpenSidebar
  | ChangeAmount;

// region Create
export interface Create {
  type: "DataGenerator:Create";
}

export const create = (): Create => ({
  type: "DataGenerator:Create",
});
// endregion

// region Create
export interface ChangeAmount {
  type: "DataGenerator:ChangeAmount";
  payload: number;
}

export const changeAmount = (
  payload: ChangeAmount["payload"],
): ChangeAmount => ({
  type: "DataGenerator:ChangeAmount",
  payload,
});
// endregion

// region CreateFail
export interface CreateFail {
  type: "DataGenerator:CreateFail";
}

export const createFail = (): CreateFail => ({
  type: "DataGenerator:CreateFail",
});
// endregion

// region CreateSuccess
export interface CreateSuccess<T extends CustomFields> {
  type: "DataGenerator:CreateSuccess";
  payload: {
    fields: CustomFieldsValue<T>[];
  };
}

export const createSuccess = <T extends CustomFields>(
  payload: CreateSuccess<T>["payload"],
): CreateSuccess<T> => ({
  type: "DataGenerator:CreateSuccess",
  payload,
});
// endregion

export interface ToggleOpenSidebar {
  type: "DataGenerator:ToggleOpenSidebar";
}

export const toggleOpenSidebar = (): ToggleOpenSidebar => ({
  type: "DataGenerator:ToggleOpenSidebar",
});
