import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  forkJoin,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  scan,
  skip,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError } from "ds";
import { shallowEqualObjects } from "shallow-equal";
import * as Arr from "fp-ts/Array";
import * as Str from "fp-ts/string";
import { Eq } from "fp-ts/Eq";
import { getRepositories, deleteRepositories } from "ds/Repositories";
import { getDataTypes } from "ds/DataTypes";
import { RepositoryId } from "types/src/Repositories/Repository";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { DataTypeEntity } from "types/src/DataType/DataType";
import { Epic } from "../../../../../../../../types/RootEpic";
import { FiltersEq } from "./types/Filters";
import { getFetchVars } from "./transformers";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$: dep$ }) => {
  const loading$ = state$.pipe(
    filter(State.isLoading),
    map(getFetchVars),
    distinctUntilChanged(shallowEqualObjects),
    withLatestFrom(dep$),
    switchMap(([vars, client]) => {
      return forkJoin({
        items: from(getRepositories(client, vars)),
        dataTypes: from(
          getDataTypes(client, {
            where: {
              entity: [DataTypeEntity.Repository],
            },
          }),
        ),
      }).pipe(
        map(
          flow(
            (vs) => {
              if (E.isLeft(vs.items)) return vs.items;
              if (E.isLeft(vs.dataTypes)) return vs.dataTypes;
              return E.right({
                items: vs.items.right,
                dataTypes: vs.dataTypes.right,
              });
            },
            E.map((r) =>
              Actions.loadSuccess({
                dataTypes: r.dataTypes.items,
                items: r.items.items,
                total: r.items.totalCount,
                pageInfo: r.items.pageInfo,
              }),
            ),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.loadFail({ type: undefined }),
            ),
          ),
        ),
      );
    }),
  );

  const fetch$ = state$.pipe(
    filter(State.isFetching),
    map(getFetchVars),
    distinctUntilChanged(shallowEqualObjects),
    withLatestFrom(dep$),
    switchMap(([vars, client]) => {
      return from(getRepositories(client, vars)).pipe(
        map(
          flow(
            E.map((r) =>
              Actions.fetchSuccess({
                items: r.items,
                total: r.totalCount,
                pageInfo: r.pageInfo,
              }),
            ),
          ),
        ),
      );
    }),
    map(
      E.getOrElse<DsError, Actions.Actions>(() =>
        Actions.loadFail({ type: undefined }),
      ),
    ),
  );

  const remove$ = state$.pipe(
    filter(State.isReady),
    map((s) =>
      s.payload.items
        .filter((i) => i.removeState === "removing")
        .map((i) => i.id),
    ),
    scan(
      ([pendingItems], removingItems) => {
        return [
          removingItems,
          Arr.difference(Str.Eq as Eq<RepositoryId>)(pendingItems)(
            removingItems,
          ),
        ] as [RepositoryId[], RepositoryId[]];
      },
      [[], []] as [RepositoryId[], RepositoryId[]],
    ),
    map(([, toRemove]) => toRemove),
    filter((i) => i.length > 0),
    withLatestFrom(dep$),
    mergeMap(([toRemove, client]) => {
      return from(deleteRepositories(client, toRemove)).pipe(
        map(
          flow(
            E.map(() => Actions.removeSuccess(toRemove)),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.removeFail(toRemove),
            ),
          ),
        ),
        catchError(() => of(Actions.removeFail(toRemove))),
      );
    }),
  );

  const applyFilters$ = state$.pipe(
    filter(State.isReady),
    map((s) => s.payload.filters),
    distinctUntilChanged(FiltersEq.equals),
    skip(1),
    debounceTime(500),
    map(Actions.submitFilters),
  );

  return merge(loading$, fetch$, remove$, applyFilters$);
};
