import { gql } from "@apollo/client";
import { repositoryFragment } from "./Repository";
import { inventoryItemFragment } from "./InventoryItem";

export const transactionFragment = gql`
  ${repositoryFragment}
  ${inventoryItemFragment}

  fragment TransactionFragment on Transaction {
    id
    createdAt
    quantity
    type
    repository {
      ...RepositoryFragment
    }
    item {
      ...InventoryItemFragment
    }
  }
`;
