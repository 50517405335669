import { RootState, useDispatch } from "state-manager";
import * as Listing from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Listing";
import { Filters, FiltersProps } from "@Containers/Listing/Filters";
import { flow } from "fp-ts/function";
import { Input } from "@Containers/Form/Input";
import { Textarea } from "@Containers/Form/Textarea";
import { Field } from "ui/components/Field";
import { Label } from "ui/components/Label";
import { useTranslation } from "i18n";
import { useMemo } from "react";
import { dataTypeEntities, DataTypeEntity } from "types/src/DataType/DataType";
import { useDataTypeEntityTitle } from "@Hooks/useDataTypeEntityTitle";
import { MultiCombobox } from "@Containers/Form/MultiCombobox";
import { Picker } from "@Containers/Form/Picker";

interface ListingHeaderProps {
  selector: (s: RootState) => Listing.Ready | Listing.Fetching;
}
export function ListingHeader(p: ListingHeaderProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selector = flow(
    p.selector,
    (s): ReturnType<FiltersProps["selector"]> => ({
      isOpen: s.payload.advancedFiltersOpen,
      hasFiltersEnabled: Listing.isFilterDirty(s.payload.filters),
      search: s.payload.filters.name ?? "",
    }),
  );
  const entityTitle = useDataTypeEntityTitle();
  const filtersSelector = flow(p.selector, (s) => s.payload.filters);
  const entities = useMemo(() => {
    return dataTypeEntities.map((entity) => ({
      value: entity,
      label: entityTitle(entity),
    }));
  }, [entityTitle]);

  return (
    <Filters
      selector={selector}
      dispatch={{
        onReset: () => dispatch(Listing.clearFilters()),
        onSave: () => dispatch(Listing.applyFilters()),
        closeFilters: () => dispatch(Listing.closeAdvancedFilters()),
        openFilters: () => dispatch(Listing.openAdvancedFilters()),
        setSearch: flow(Listing.setName, dispatch),
      }}
    >
      <Field>
        <Label>{t("Title")}</Label>
        <Input
          placeholder={t("Search by a text in title")}
          value$={flow(filtersSelector, (s) => s.name ?? "")}
          onChange={flow(Listing.setName, dispatch)}
        />
      </Field>
      <Field>
        <Label>{t("Description")}</Label>
        <Textarea
          placeholder={t("Search by a text in description")}
          rows={3}
          value$={flow(filtersSelector, (s) => s.description ?? "")}
          onChange={flow(Listing.setDescription, dispatch)}
        />
      </Field>
      <Field>
        <Label>{t("Default")}</Label>
        <Picker
          value$={flow(filtersSelector, (s) => s.default)}
          onChange={flow(Listing.setDefault, dispatch)}
          options={[
            { value: true, label: t("Default only") },
            { value: false, label: t("No default only") },
          ]}
        />
      </Field>
      <Field>
        <Label>{t("Entity")}</Label>
        <MultiCombobox<DataTypeEntity>
          placeholder={t("All entities")}
          value$={flow(filtersSelector, (s) => s.entity ?? [])}
          onChange={(v) => dispatch(Listing.setEntity(v))}
          options={entities}
        />
      </Field>
    </Filters>
  );
}
