// region Actions
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import { DataType } from "types/src/DataType/DataType";
import {
  Repo,
  RepoWithMeta,
  RequiredFields,
} from "types/src/Repositories/Repository";

export type Actions =
  | LoadSuccess
  | LoadFail
  | CreateRepos
  | CreatedReposSuccess
  | CreatedReposFail
  | DeleteRepos
  | DeleteReposSuccess
  | DeleteReposFail
  | UpdateRepos
  | UpdatedReposSuccess
  | UpdatedReposFail
  | ChangeActiveDataType
  | ChangeActiveDataTypeSuccess
  | ChangeActiveDataTypeFail;

export const isActions = strictGuard((a: Actions): a is Actions => {
  switch (a.type) {
    case "Ready:BuilderPreview:LoadSuccess":
    case "Ready:BuilderPreview:LoadFail":
    case "Ready:BuilderPreview:CreateRepos":
    case "Ready:BuilderPreview:CreatedReposSuccess":
    case "Ready:BuilderPreview:CreatedReposFail":
    case "Ready:BuilderPreview:UpdateRepos":
    case "Ready:BuilderPreview:UpdatedReposSuccess":
    case "Ready:BuilderPreview:UpdatedReposFail":
    case "Ready:BuilderPreview:DeleteRepos":
    case "Ready:BuilderPreview:DeleteReposSuccess":
    case "Ready:BuilderPreview:DeleteReposFail":
    case "Ready:BuilderPreview:ChangeActiveDataType":
    case "Ready:BuilderPreview:ChangeActiveDataTypeSuccess":
    case "Ready:BuilderPreview:ChangeActiveDataTypeFail":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:BuilderPreview:LoadSuccess";
  payload: {
    items: Repo[];
    dataTypes: DataType[];
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:BuilderPreview:LoadSuccess",
  payload,
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:BuilderPreview:LoadFail";
  payload: {
    type: undefined;
  };
}

export const loadFail = (payload: LoadFail["payload"]): LoadFail => ({
  type: "Ready:BuilderPreview:LoadFail",
  payload,
});
// endregion

// region LoadFail
export interface CreateRepos {
  type: "Ready:BuilderPreview:CreateRepos";
  payload: {
    items: RepoWithMeta[];
  };
}

export const createRepos = (payload: CreateRepos["payload"]): CreateRepos => ({
  type: "Ready:BuilderPreview:CreateRepos",
  payload,
});
// endregion

// region LoadFail
export interface CreatedReposSuccess {
  type: "Ready:BuilderPreview:CreatedReposSuccess";
  payload: {
    items: RepoWithMeta[];
    idsBinding: Record<string, Repo["id"]>;
  };
}

export const createdReposSuccess = (
  payload: CreatedReposSuccess["payload"],
): CreatedReposSuccess => ({
  type: "Ready:BuilderPreview:CreatedReposSuccess",
  payload,
});
// endregion

// region LoadFail
export interface CreatedReposFail {
  type: "Ready:BuilderPreview:CreatedReposFail";
  payload: {
    type: undefined;
  };
}

export const createdReposFail = (
  payload: CreatedReposFail["payload"],
): CreatedReposFail => ({
  type: "Ready:BuilderPreview:CreatedReposFail",
  payload,
});
// endregion

export interface DeleteRepos {
  type: "Ready:BuilderPreview:DeleteRepos";
  payload: {
    itemsIds: Repo["id"][];
  };
}

export const deleteRepos = (payload: DeleteRepos["payload"]): DeleteRepos => ({
  type: "Ready:BuilderPreview:DeleteRepos",
  payload,
});

export interface DeleteReposSuccess {
  type: "Ready:BuilderPreview:DeleteReposSuccess";
  payload: undefined;
}

export const deleteReposSuccess = (
  payload: DeleteReposSuccess["payload"],
): DeleteReposSuccess => ({
  type: "Ready:BuilderPreview:DeleteReposSuccess",
  payload,
});

export interface DeleteReposFail {
  type: "Ready:BuilderPreview:DeleteReposFail";
  payload: undefined;
}

export const deleteReposFail = (
  payload: DeleteReposFail["payload"],
): DeleteReposFail => ({
  type: "Ready:BuilderPreview:DeleteReposFail",
  payload,
});

export interface UpdateRepos {
  type: "Ready:BuilderPreview:UpdateRepos";
  payload: {
    items: RequiredFields<Partial<RepoWithMeta>, "id">[];
  };
}

export const updateRepos = (payload: UpdateRepos["payload"]): UpdateRepos => ({
  type: "Ready:BuilderPreview:UpdateRepos",
  payload,
});
// endregion

// region LoadFail
export interface UpdatedReposSuccess {
  type: "Ready:BuilderPreview:UpdatedReposSuccess";
  payload: {
    items: Record<RepoWithMeta["id"], RepoWithMeta>;
  };
}

export const updatedReposSuccess = (
  payload: UpdatedReposSuccess["payload"],
): UpdatedReposSuccess => ({
  type: "Ready:BuilderPreview:UpdatedReposSuccess",
  payload,
});
// endregion

// region LoadFail
export interface UpdatedReposFail {
  type: "Ready:BuilderPreview:UpdatedReposFail";
  payload: {
    type: undefined;
  };
}

export const updatedReposFail = (
  payload: UpdatedReposFail["payload"],
): UpdatedReposFail => ({
  type: "Ready:BuilderPreview:UpdatedReposFail",
  payload,
});

export interface ChangeActiveDataType {
  type: "Ready:BuilderPreview:ChangeActiveDataType";
  payload: DataType["id"];
}

export const changeActiveDataType = (
  payload: ChangeActiveDataType["payload"],
): ChangeActiveDataType => {
  return {
    type: "Ready:BuilderPreview:ChangeActiveDataType",
    payload,
  };
};

export interface ChangeActiveDataTypeSuccess {
  type: "Ready:BuilderPreview:ChangeActiveDataTypeSuccess";
  payload: {
    items: Repo[];
    dataTypes: DataType[];
  };
}

export const changeActiveDataTypeSuccess = (
  payload: ChangeActiveDataTypeSuccess["payload"],
): ChangeActiveDataTypeSuccess => ({
  type: "Ready:BuilderPreview:ChangeActiveDataTypeSuccess",
  payload,
});
// endregion

// region LoadFail
export interface ChangeActiveDataTypeFail {
  type: "Ready:BuilderPreview:ChangeActiveDataTypeFail";
  payload: {
    type: undefined;
  };
}

export const changeActiveDataTypeFail = (
  payload: ChangeActiveDataTypeFail["payload"],
): ChangeActiveDataTypeFail => ({
  type: "Ready:BuilderPreview:ChangeActiveDataTypeFail",
  payload,
});
