import styled, { css } from "styled-components";
import { CursorPagination as C } from "@zendeskgarden/react-pagination";

export { Pagination } from "@zendeskgarden/react-pagination";

const disabled = css`
  &[disabled] {
    pointer-events: none;
  }
`;

export const CursorPagination = C;

CursorPagination.First = styled(C.First)`
  ${disabled}
`;
CursorPagination.Previous = styled(C.Previous)`
  ${disabled}
`;
CursorPagination.Next = styled(C.Next)`
  ${disabled}
`;
CursorPagination.Last = styled(C.Last)`
  ${disabled}
`;
