import { User } from "types";
import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";

export type Actions =
  | UserPreloadFail
  | UserPreloadSuccess
  | SignIn
  | SignInFail
  | SignInSuccess;

export const isAction = strictGuard((a: Actions): a is Actions => {
  switch (a.type) {
    case "Auth:UserPreloadFail":
    case "Auth:UserPreloadSuccess":
    case "Auth:SignIn":
    case "Auth:SignInFail":
    case "Auth:SignInSuccess":
      return true;
    default: {
      silentUnreachableError(a);
      return false;
    }
  }
});

// region UserPreloadFail
export interface UserPreloadFail {
  type: "Auth:UserPreloadFail";
}

export const userPreloadFail = (): UserPreloadFail => ({
  type: "Auth:UserPreloadFail",
});
// endregion

// region UserPreloadSuccess
export interface UserPreloadSuccess {
  type: "Auth:UserPreloadSuccess";
  payload: User;
}

export const userPreloadSuccess = (
  payload: UserPreloadSuccess["payload"],
): UserPreloadSuccess => ({
  type: "Auth:UserPreloadSuccess",
  payload,
});
// endregion

// region SignIn
export interface SignIn {
  type: "Auth:SignIn";
}

export const signIn = (): SignIn => ({
  type: "Auth:SignIn",
});
// endregion

// region SignInFail
export interface SignInFail {
  type: "Auth:SignInFail";
}

export const signInFail = (): SignInFail => ({
  type: "Auth:SignInFail",
});
// endregion

// region SignInSuccess
export interface SignInSuccess {
  type: "Auth:SignInSuccess";
  payload: User;
}

export const signInSuccess = (
  payload: SignInSuccess["payload"],
): SignInSuccess => ({
  type: "Auth:SignInSuccess",
  payload,
});
// endregion
