import { Repository } from "types/src/Repositories/Repository";
import * as SchemaFields from "../../../../../../../../generic-states/SchemaFields";
import { createState } from "../../../../../../../../generic-states/ItemSearch";

export const schemaFieldsState = SchemaFields.createState(
  "Ready:DataManager:Repositories:Create:SchemaFields",
);

export type SchemaFieldsActionsMap = {
  [k in keyof typeof schemaFieldsState.actions]: ReturnType<
    (typeof schemaFieldsState.actions)[k]["create"]
  >;
};
export type SchemaFieldsActions =
  SchemaFieldsActionsMap[keyof SchemaFieldsActionsMap];

export type SchemaFieldsStatesMap = {
  [k in keyof typeof schemaFieldsState.states]: ReturnType<
    (typeof schemaFieldsState.states)[k]["create"]
  >;
};
export type SchemaFieldsState =
  SchemaFieldsStatesMap[keyof SchemaFieldsStatesMap];

export const parentSearchState = createState<
  "Ready:DataManager:Repositories:Create:Parent",
  "unknown",
  Repository | undefined
>("Ready:DataManager:Repositories:Create:Parent", (a, b) => a?.id === b?.id);

export type ParentSearchState = ReturnType<
  (typeof parentSearchState)["states"][keyof (typeof parentSearchState)["states"]]["create"]
>;
export type ParentSearchActions = ReturnType<
  (typeof parentSearchState)["actions"][keyof (typeof parentSearchState)["actions"]]["create"]
>;
