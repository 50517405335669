import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { reducer } from "./reducer";
import { epic } from "./epic";
import { extractFieldsFromSchema } from "./utils";

export function createState<P extends string>(p: P) {
  return {
    isState: State.isState(p),
    states: {
      init: {
        create: State.init(p),
        is: State.isInit(p),
      },
    },
    isActions: Actions.isActions(p),
    actions: {
      onChange: {
        is: Actions.isActions(p),
        create: Actions.onChange(p),
      },
    },
    init: State.init(p),
    reducer: reducer(p),
    epic: epic(p),
    extractFieldsFromSchema: extractFieldsFromSchema(p),
  };
}
