import { Either } from "fp-ts/Either";
import { Cursor } from "types";
import { isT } from "fp-utilities";
import { PageInfoFragmentFragment } from "../generated/graphql";
import { DsError } from "./DsError";

export type QueryResponse<T> = Either<DsError, T>;

// region PaginatedQueryResult
export interface PaginatedQueryResult<T> {
  totalCount: number;
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: Cursor | undefined;
    endCursor: Cursor | undefined;
    prevCursor: Cursor | undefined;
    nextCursor: Cursor | undefined;
  };
  items: T[];
}

type InputData<T> = {
  totalCount: number;
  pageInfo: PageInfoFragmentFragment;
  edges?: Array<{ cursor: Cursor; node?: T | null } | null | undefined> | null;
};

export const getPaginatedQueryResult =
  <A, B>(fn: (a: A) => B) =>
  (data: InputData<A>): PaginatedQueryResult<B> => {
    const edges = data.edges || [];

    return {
      totalCount: data.totalCount,
      pageInfo: {
        hasNextPage: data.pageInfo.hasNextPage,
        hasPreviousPage: data.pageInfo.hasPreviousPage,
        startCursor: data.pageInfo.startCursor ?? undefined,
        endCursor: data.pageInfo.endCursor ?? undefined,
        prevCursor: edges[0]?.cursor ?? undefined,
        nextCursor: edges[edges.length - 1]?.cursor ?? undefined,
      },
      items: edges
        .map((e) => e?.node)
        .filter(isT)
        .map(fn),
    };
  };
// endregion

export type PaginatedQueryResponse<T> = QueryResponse<PaginatedQueryResult<T>>;
