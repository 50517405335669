import { unreachableError } from "utils/exceptions";
import * as O from "fp-ts/Option";
import { fromString } from "types/src/NoEmptyString";
import { pipe } from "fp-ts/function";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { transactionToItem } from "./transformers";
import {
  itemSearchState,
  repositorySearchState,
  transactionSearchState,
} from "./utils";

export function reducer(s: State.State, a: Actions.Actions): State.State {
  if (transactionSearchState.isActions(a)) {
    if (State.isLoaded(s))
      return {
        ...s,
        payload: {
          ...s.payload,
          filters: {
            ...s.payload.filters,
            id: transactionSearchState.reducer(s.payload.filters.id, a),
          },
        },
      } as typeof s;

    return s;
  }
  if (itemSearchState.isActions(a)) {
    if (State.isLoaded(s))
      return {
        ...s,
        payload: {
          ...s.payload,
          filters: {
            ...s.payload.filters,
            item: itemSearchState.reducer(s.payload.filters.item, a),
          },
        },
      } as typeof s;

    return s;
  }
  if (repositorySearchState.isActions(a)) {
    if (State.isLoaded(s))
      return {
        ...s,
        payload: {
          ...s.payload,
          filters: {
            ...s.payload.filters,
            repository: repositorySearchState.reducer(
              s.payload.filters.repository,
              a,
            ),
          },
        },
      } as typeof s;

    return s;
  }

  if (Actions.isLoadFailAction(a)) {
    if (State.isLoading(s)) return State.loadError(s.payload);
    return s;
  }
  if (Actions.isLoadSuccessAction(a)) {
    if (State.isLoading(s))
      return State.ready({
        ...s.payload,
        total: a.payload.totalCount,
        items: a.payload.items.map(transactionToItem),
        pageInfo: a.payload.pageInfo,
        filters: {
          ...s.payload.filters,
          id: transactionSearchState.states.selected.is(s.payload.filters.id)
            ? transactionSearchState.states.selected.create({
                query: s.payload.filters.id.payload.query,
                item: s.payload.filters.id.payload.item,
                items: a.payload.items,
              })
            : transactionSearchState.states.idle.create({
                query: s.payload.filters.id.payload.query,
                items: a.payload.items,
              }),
          item: itemSearchState.states.selected.is(s.payload.filters.item)
            ? itemSearchState.states.selected.create({
                query: s.payload.filters.item.payload.query,
                item: s.payload.filters.item.payload.item,
                items: a.payload.inventoryItems,
              })
            : itemSearchState.states.idle.create({
                query: s.payload.filters.item.payload.query,
                items: a.payload.inventoryItems,
              }),
          repository: repositorySearchState.states.selected.is(
            s.payload.filters.repository,
          )
            ? repositorySearchState.states.selected.create({
                query: s.payload.filters.repository.payload.query,
                item: s.payload.filters.repository.payload.item,
                items: a.payload.repositories,
              })
            : repositorySearchState.states.idle.create({
                query: s.payload.filters.repository.payload.query,
                items: a.payload.repositories,
              }),
        },
      });
    return s;
  }
  if (Actions.isFetchFirstAction(a)) {
    if (State.isReady(s) && s.payload.pageInfo.startCursor)
      return State.fetching({ ...s.payload, page: "start" });
    return s;
  }
  if (Actions.isFetchLastAction(a)) {
    if (State.isReady(s) && s.payload.pageInfo.endCursor)
      return State.fetching({ ...s.payload, page: "end" });
    return s;
  }
  if (Actions.isFetchPrevAction(a)) {
    if (State.isReady(s) && s.payload.pageInfo.prevCursor)
      return State.fetching({ ...s.payload, page: "prev" });
    return s;
  }
  if (Actions.isFetchNextAction(a)) {
    if (State.isReady(s) && s.payload.pageInfo.nextCursor)
      return State.fetching({ ...s.payload, page: "next" });
    return s;
  }
  if (Actions.isFetchFailAction(a)) {
    if (State.isFetching(s)) return State.ready(s.payload);
    return s;
  }
  if (Actions.isFetchSuccessAction(a)) {
    if (State.isFetching(s))
      return State.ready({
        ...s.payload,
        items: a.payload.items.map(transactionToItem),
        pageInfo: a.payload.pageInfo,
      });
    return s;
  }
  if (Actions.isResetFiltersAction(a)) {
    if (State.isLoaded(s))
      return State.fetching({
        ...s.payload,
        page: "start",
        filters: {
          search: undefined,
          id: transactionSearchState.states.idle.create({
            query: O.none,
            items: s.payload.filters.id.payload.items,
          }),
          item: itemSearchState.states.idle.create({
            query: O.none,
            items: s.payload.filters.item.payload.items,
          }),
          repository: repositorySearchState.states.idle.create({
            query: O.none,
            items: s.payload.filters.repository.payload.items,
          }),
        },
      });
    return s;
  }
  if (Actions.isSubmitFiltersAction(a)) {
    if (State.isReady(s)) {
      return State.fetching({
        ...s.payload,
        page: "start",
      });
    }
    return s;
  }
  if (Actions.isSetTypeAction(a)) {
    if (State.isLoaded(s))
      return State.ready({
        ...s.payload,
        filters: {
          ...s.payload.filters,
          type: a.payload,
        },
      });
    return s;
  }
  if (Actions.isSetQuantityAction(a)) {
    if (State.isLoaded(s))
      return State.ready({
        ...s.payload,
        filters: {
          ...s.payload.filters,
          quantity: a.payload,
        },
      });
    return s;
  }
  if (Actions.isSetCreatedAtAction(a)) {
    if (State.isLoaded(s))
      return State.ready({
        ...s.payload,
        filters: {
          ...s.payload.filters,
          createdAt: a.payload,
        },
      });
    return s;
  }
  if (Actions.isSetSearchAction(a)) {
    if (State.isLoaded(s))
      return State.ready({
        ...s.payload,
        filters: {
          ...s.payload.filters,
          search: pipe(a.payload, fromString, O.toUndefined),
        },
      });
    return s;
  }

  unreachableError(a);
  return s;
}
