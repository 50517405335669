import {
  catchError,
  distinctUntilKeyChanged,
  from,
  map,
  NEVER,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { silentUnreachableError } from "utils/exceptions";
import { Client, DsError, isNotFoundError } from "ds";
import { getDataType, removeDataType, updateDataType } from "ds/DataTypes";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { Epic } from "../../../../../../../../types/RootEpic";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$: client$ }) => {
  return state$.pipe(
    distinctUntilKeyChanged("type"),
    withLatestFrom(client$),
    switchMap(([s, client]) => {
      if (State.isLoading(s)) {
        return from(getDataType(client, s.payload.id)).pipe(
          map(
            flow(
              E.map(Actions.loadSuccess),
              E.getOrElse<DsError, Actions.Actions>((e) => {
                if (isNotFoundError(e))
                  return Actions.loadFail({ type: "not-found" });
                return Actions.loadFail({ type: "error" });
              }),
            ),
          ),
          catchError(() => of(Actions.loadFail({ type: "error" }))),
        );
      }

      if (State.isSaving(s)) {
        return from(
          updateDataType(client, s.payload.id, {
            name: s.payload.name,
            description: s.payload.description,
            jsonSchema: s.payload.schema.payload,
            entity: s.payload.entity,
            default: s.payload.default,
          }),
        ).pipe(
          map(
            flow(
              E.map(Actions.saveSuccess),
              E.getOrElse<DsError, Actions.Actions>(Actions.saveFail),
            ),
          ),
          catchError(() => of(Actions.saveFail())),
        );
      }

      if (State.isRemoving(s)) {
        return from(removeDataType(client, s.payload.id)).pipe(
          map(
            flow(
              E.map(Actions.removeSuccess),
              E.getOrElse<DsError, Actions.Actions>(Actions.removeFail),
            ),
          ),
          catchError(() => of(Actions.removeFail())),
        );
      }

      if (State.isRemoveConfirmation(s)) return NEVER;
      if (State.isLoadError(s)) return NEVER;
      if (State.isReady(s)) return NEVER;

      silentUnreachableError(s);
      return NEVER;
    }),
  );
};
