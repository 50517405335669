// region Timestamp
declare const _timestamp: unique symbol;

export type Timestamp = number & { [_timestamp]: "Timestamp" };
// endregion

export const fromDate = (date: Date): Timestamp => date.getTime() as Timestamp;

export const toDate = (timestamp: Timestamp): Date => new Date(timestamp);

export const addSeconds = (seconds: number, timestamp: Timestamp): Timestamp =>
  (timestamp + seconds * 1000) as Timestamp;
