import * as Rx from "rxjs";
import { Client } from "ds";
import { Epic, mergeByGuard } from "../../../../../../types/RootEpic";
import { Actions } from "./types/Actions";
import { State } from "./types/State";
import {
  createPickingOrderState,
  listingAllPickingOrderState,
  listingPickingOrderState,
  editPickingOrderState,
} from "./utils";

export const epic: Epic<
  Actions,
  State,
  { pyckAdminClient$: Rx.Observable<Client> }
> = (s$, deps) => {
  return mergeByGuard([
    [createPickingOrderState.isState, createPickingOrderState.epic],
    [listingAllPickingOrderState.isState, listingAllPickingOrderState.epic],
    [listingPickingOrderState.isState, listingPickingOrderState.epic],
    [editPickingOrderState.isState, editPickingOrderState.epic],
  ])(s$, deps);
};
