import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import * as Create from "../states/Create";
import * as Edit from "../states/Edit";
import * as ListingAll from "../states/ListingAll";
import * as Listing from "../states/Listing";

export type State =
  | ListingAll.State
  | Listing.State
  | Create.State
  | Edit.State;
export const isState = strictGuard((s: State): s is State => {
  const _s = s as State;

  if (ListingAll.isState(_s)) return true;
  if (Listing.isState(_s)) return true;
  if (Create.isState(_s)) return true;
  if (Edit.isState(_s)) return true;

  silentUnreachableError(_s);
  return false;
});
