import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { pipe } from "fp-ts/function";
import * as DsError from "./type/DsError";
import { graphQLErrorToDsError } from "./transformers/Errors";

export function getClient(uri: string, token: string, orgId: string): Client {
  return new _Client(
    new ApolloClient({
      uri,
      cache: new InMemoryCache(),
      headers: { authorization: `Bearer ${token}`, "Pyck-Owner-Id": orgId },
    }),
  );
}

export type QueryFn = <
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TVariables extends Record<string, any> = Record<string, any>,
>(options: {
  query: TypedDocumentNode<T>;
  variables?: TVariables;
}) => Promise<E.Either<DsError.DsError, T>>;

export type MutationFn = <
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TVariables extends Record<string, any> = Record<string, any>,
>(options: {
  mutation: TypedDocumentNode<T>;
  variables?: TVariables;
}) => Promise<E.Either<DsError.DsError, T>>;

export interface Client {
  query: QueryFn;
  mutate: MutationFn;
}

class _Client implements Client {
  constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {}

  async query<
    T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TVariables extends Record<string, any> = Record<string, any>,
  >(options: {
    query: TypedDocumentNode<T>;
    variables?: TVariables;
  }): Promise<E.Either<DsError.DsError, T>> {
    return this.client
      .query({
        ...options,
        errorPolicy: "all",
      })
      .then((res) => {
        if (res.errors) {
          return pipe(
            res.errors[0],
            O.fromNullable,
            O.map(graphQLErrorToDsError),
            O.getOrElse<DsError.DsError>(() => DsError.unknownError()),
            E.left,
          );
        }

        return E.right(res.data);
      });
  }

  async mutate<
    T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TVariables extends Record<string, any> = Record<string, any>,
  >(options: {
    mutation: TypedDocumentNode<T>;
    variables?: TVariables;
  }): Promise<E.Either<DsError.DsError, T>> {
    return this.client
      .mutate({ ...options, errorPolicy: "all" })
      .then((res) => {
        if (res.errors) {
          return pipe(
            res.errors[0],
            O.fromNullable,
            O.map(graphQLErrorToDsError),
            O.getOrElse<DsError.DsError>(() => DsError.unknownError()),
            E.left,
          );
        }

        this.client.cache.reset();
        return E.right(res.data as T);
      });
  }
}

export * from "./type/DsError";
export type { QueryResponse } from "./type/QueryResponse";
