import { GetDataTypesQueryResult, GetDataTypesVars } from "ds/DataTypes";
import { LoadSuccess } from "./types/Actions";
import * as State from "./types/State";

export function dataTypesQueryToLoadSuccess(
  r: GetDataTypesQueryResult,
): LoadSuccess["payload"] {
  return {
    items: r.items,
    total: r.totalCount,
    pageInfo: r.pageInfo,
  };
}

export function getFetchVars(
  s: State.Loading | State.Fetching,
): GetDataTypesVars {
  switch (s.type) {
    case "Ready:DataManager:DataTypes:Listing:Loading":
      return {
        first: s.payload.perPage,
        where: s.payload.filters,
      };
    case "Ready:DataManager:DataTypes:Listing:Fetching": {
      switch (s.payload.page) {
        case "start":
          return {
            first: s.payload.perPage,
            after: s.payload.pageInfo.startCursor,
            where: s.payload.filters,
          };
        case "prev":
          return {
            last: s.payload.perPage,
            before: s.payload.pageInfo.prevCursor,
            where: s.payload.filters,
          };
        case "next":
          return {
            first: s.payload.perPage,
            after: s.payload.pageInfo.nextCursor,
            where: s.payload.filters,
          };
        case "end":
          return {
            last: s.payload.perPage,
            before: s.payload.pageInfo.endCursor,
            where: s.payload.filters,
          };
        case "current":
          return {
            first: s.payload.perPage,
            where: s.payload.filters,
          };
      }
    }
  }
}
