import { ReactElement } from "react";
import { useTranslation } from "i18n";
import { TypographyMD } from "ui/components/Typography";
import { Selector } from "state-manager";
import * as Listing from "state-manager/states/Ready/states/DataManager/states/Transactions/states/Listing";

export interface HeaderProps {
  selector: Selector<Listing.State>;
  dispatch: (a: Listing.Actions) => void;
}

export function Header(p: HeaderProps): ReactElement {
  const { t } = useTranslation();
  return <TypographyMD>{t("Transactions")}</TypographyMD>;
}
