import {
  JsonEditor as UIJsonEditor,
  JsonEditorProps,
} from "ui/components/JsonEditor";
import { Selector, useSelector } from "state-manager";
import { ReactElement } from "react";
import { isDeepEqual, jsonParse } from "utils/object";
import {
  DataTypeSchema,
  DataTypeValue,
} from "types/src/DataType/DataTypeSchema";
import { flow } from "fp-ts/function";
import * as O from "fp-ts/Option";

export interface SchemaFieldsJsonEditorProps {
  value$: Selector<{
    schema: DataTypeSchema;
    values: DataTypeValue | undefined;
  }>;
  onChange: (v: DataTypeValue | undefined) => void;
}

export function SchemaFieldsJsonEditor(
  p: SchemaFieldsJsonEditorProps,
): ReactElement {
  const { schema, value } = useSelector(
    flow(p.value$, (v) => ({
      schema: v.schema,
      value: v.values ? JSON.stringify(v.values, null, 2) : undefined,
    })),
    isDeepEqual,
  );
  return (
    <UIJsonEditor
      schema={schema as JsonEditorProps["schema"]}
      content={value}
      onChange={flow(
        O.fromNullable,
        O.chain(flow(jsonParse, O.fromNullable)),
        O.map((v) => v as DataTypeValue),
        O.toUndefined,
        p.onChange,
      )}
    />
  );
}
