import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { useTranslation } from "i18n";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { GetContructorType, GetGuardType } from "types/src/Utils";
import { PickingOrderItems } from "@Containers/PickingOrderItems";
import { Label } from "ui/components/Label";
import { TranslatedStr } from "types/src/TranslatedStr";
import { ItemSearchInput } from "@Containers/Form/ItemSearchInput";
import { SchemaFieldsJsonEditor } from "@Containers/SchemaFieldsJsonEditor";

const {
  states,
  isActions,
  schemaFieldsState,
  customerSearchState,
  pickingOrderItemsState,
} = DataManager.pickingOrderState.subStates.edit;
type State = GetContructorType<typeof states>;
type Actions = GetGuardType<typeof isActions>;

export interface ContentProps {
  selector: Selector<State>;
  dispatch: (a: Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const { t } = useTranslation();

  const r = useSelector(
    flow(selector, (s) => {
      if (states.loading.is(s) || states.loadError.is(s)) {
        return {
          type: "loading",
        } as const;
      }

      if (states.ready.is(s) || states.saving.is(s)) {
        const schemaSelector = flow(
          selector,
          (a) => a as typeof s,
          (s) => s.payload.fields.payload,
        );
        const customerSelector = flow(
          selector,
          (a) => a as typeof s,
          (s) => s.payload.customer,
        );
        const itemsSelector = flow(
          selector,
          (a) => a as typeof s,
          (s) => s.payload.items,
        );

        return {
          type: "ready",
          schemaSelector,
          customerSelector,
          itemsSelector,
        } as const;
      }

      silentUnreachableError(s);
      return {
        type: "loading",
      } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <FormWrapper>
          <ItemSearchInput
            label={t("Customer")}
            selector={r.customerSelector}
            dispatch={dispatch}
            getLabel={(v) => v.title as TranslatedStr}
            getId={(v) => v.id}
            constructor={customerSearchState}
          />
          <SchemaFieldsJsonEditor
            value$={r.schemaSelector}
            onChange={flow(schemaFieldsState.actions.onChange.create, dispatch)}
          />
          <Label>{t("Order items")}</Label>
          <PickingOrderItems
            constructor={pickingOrderItemsState}
            value$={r.itemsSelector}
            dispatch={dispatch}
          />
        </FormWrapper>
      );
  }
}
