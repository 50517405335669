import { silentUnreachableError } from "utils/exceptions";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import * as Listing from "./states/Listing";
import * as Create from "./states/Create";
import * as Edit from "./states/Edit";


export function reducer(s: State.State, a: Actions.Actions): State.State {
  if (Listing.isState(s)) {
    if (Listing.isActions(a)) {
      return Listing.reducer(s, a);
    }
    return s;
  }

  if (Create.isState(s)) {
    if (Create.isActions(a)) {
      return Create.reducer(s, a);
    }
    return s;
  }

  if (Edit.isState(s)) {
    if (Edit.isActions(a)) {
      return Edit.reducer(s, a);
    }
    return s;
  }

  silentUnreachableError(s);
  return s;
}
