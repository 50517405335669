import { NoEmptyString } from "types/src/NoEmptyString";
import * as O from "fp-ts/Option";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";

export type State<P extends string, E, T> =
  | Idle<P, T>
  | Selected<P, T>
  | Searching<P, T>
  | ItemSearchError<P, E, T>;

export const init =
  <P extends string, T>(p: P) =>
  (): Idle<P, T> =>
    idle(p)({ items: [], query: O.none });

export const isState = <P extends string, E, T>(p: P) =>
  strictGuard((s: State<P, E, T>): s is State<P, E, T> => {
    if (
      isIdle(p)(s) ||
      isSelected(p)(s) ||
      isSearching(p)(s) ||
      isItemSearchError(p)(s)
    )
      return true;

    silentUnreachableError(s);
    return false;
  });

// region Idle
export interface IdlePayload<T> {
  query: O.Option<NoEmptyString>;
  items: T[];
}

export interface Idle<P extends string, T> {
  type: `${P}:Idle`;
  payload: IdlePayload<T>;
}

export const idle =
  <P extends string>(p: P) =>
  <T>(payload: Idle<P, T>["payload"]): Idle<P, T> => ({
    type: `${p}:Idle`,
    payload,
  });

export const isIdle =
  <P extends string>(p: P) =>
  <E, T>(a: State<P, E, T>): a is Idle<P, T> =>
    a.type === `${p}:Idle`;
// endregion

// region Selected
export interface SelectedPayload<T> extends IdlePayload<T> {
  item: T;
}

export interface Selected<P extends string, T> {
  type: `${P}:Selected`;
  payload: SelectedPayload<T>;
}

export const selected =
  <P extends string>(p: P) =>
  <T>(payload: Selected<P, T>["payload"]): Selected<P, T> => ({
    type: `${p}:Selected`,
    payload,
  });

export const isSelected =
  <P extends string>(p: P) =>
  <E, T>(a: State<P, E, T>): a is Selected<P, T> =>
    a.type === `${p}:Selected`;
// endregion

// region Searching
export interface SearchingPayload<T> extends IdlePayload<T> {
  query: O.Some<NoEmptyString>;
}

export interface Searching<P extends string, T> {
  type: `${P}:Searching`;
  payload: SearchingPayload<T>;
}

export const searching =
  <P extends string>(p: P) =>
  <T>(payload: Searching<P, T>["payload"]): Searching<P, T> => ({
    type: `${p}:Searching`,
    payload,
  });

export const isSearching =
  <P extends string>(p: P) =>
  <E, T>(a: State<P, E, T>): a is Searching<P, T> =>
    a.type === `${p}:Searching`;
// endregion

// region ItemSearchError
export interface ItemSearchErrorPayload<E, T> extends SearchingPayload<T> {
  error: E;
}

export interface ItemSearchError<P extends string, E, T> {
  type: `${P}Error`;
  payload: ItemSearchErrorPayload<E, T>;
}

export const itemSearchError =
  <P extends string>(p: P) =>
  <E, T>(
    payload: ItemSearchError<P, E, T>["payload"],
  ): ItemSearchError<P, E, T> => ({
    type: `${p}Error`,
    payload,
  });

export const isItemSearchError =
  <P extends string>(p: P) =>
  <E, T>(a: State<P, E, T>): a is ItemSearchError<P, E, T> =>
    a.type === `${p}Error`;
// endregion
