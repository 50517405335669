import { gql } from "@apollo/client";
import { DataTypeId } from "types/src/DataType/DataType";
import {
  ItemMovementId,
  ItemMovement,
} from "types/src/ItemMovements/ItemMovement";
import { RepositoryId } from "types/src/Repositories/Repository";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { NoEmptyString } from "types/src/NoEmptyString";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";

import { DataTypeValue } from "types/src/DataType/DataTypeSchema";
import { Client, QueryResponse } from "../../index";
import {
  CreateItemMovementMutation,
  CreateItemMovementMutationVariables,
  UpdateItemMovementMutation,
  UpdateItemMovementMutationVariables,
} from "../../generated/graphql";
import { getItemMovement } from "./queries";

export const createItemMovement = (
  client: Client,
  vars: {
    dataTypeId: DataTypeId;
    fields: DataTypeValue;
    itemId: InventoryItemId;
    fromId: RepositoryId;
    toId: RepositoryId;
    quantity: number;
    handler: NoEmptyString;
  },
): Promise<QueryResponse<ItemMovement>> => {
  const mutation = gql`
    mutation CreateItemMovement($input: CreateItemMovementInput!) {
      createInventoryItemMovement(input: $input) {
        inventoryItemMovement {
          id
        }
      }
    }
  `;

  return client
    .mutate<CreateItemMovementMutation, CreateItemMovementMutationVariables>({
      mutation,
      variables: {
        input: {
          data: vars.fields,
          dataTypeID: vars.dataTypeId,
          itemID: vars.itemId,
          fromID: vars.fromId,
          toID: vars.toId,
          quantity: vars.quantity,
          handler: vars.handler,
        },
      },
    })
    .then(
      flow(
        E.map((v) =>
          getItemMovement(
            client,
            v.createInventoryItemMovement?.inventoryItemMovement
              ?.id as ItemMovementId,
          ),
        ),
        E.getOrElseW(() => Promise.reject("Error creating item movement")),
      ),
    );
};

export const updateItemMovement = (
  client: Client,
  vars: {
    id: ItemMovementId;
    dataTypeId?: DataTypeId;
    fields?: Record<string, unknown>;
    itemId?: InventoryItemId;
    fromId?: RepositoryId;
    toId?: RepositoryId;
    quantity?: number;
    handler?: NoEmptyString;
    isExecuted?: boolean;
  },
): Promise<QueryResponse<ItemMovement>> => {
  const mutation = gql`
    mutation UpdateItemMovement($id: ID!, $input: UpdateItemMovementInput!) {
      updateInventoryItemMovement(id: $id, input: $input) {
        inventoryItemMovement {
          id
        }
      }
    }
  `;

  return client
    .mutate<UpdateItemMovementMutation, UpdateItemMovementMutationVariables>({
      mutation,
      variables: {
        id: vars.id,
        input: {
          data: vars.fields,
          dataTypeID: vars.dataTypeId,
          itemID: vars.itemId,
          fromID: vars.fromId,
          toID: vars.toId,
          quantity: vars.quantity,
          handler: vars.handler,
          executed: vars.isExecuted,
        },
      },
    })
    .then(
      flow(
        E.map((v) =>
          getItemMovement(
            client,
            v.updateInventoryItemMovement?.inventoryItemMovement
              ?.id as ItemMovementId,
          ),
        ),
        E.getOrElseW(() => Promise.reject("Error creating item movement")),
      ),
    );
};

export const deleteItemMovements = (
  client: Client,
  ids: ItemMovementId[],
): Promise<QueryResponse<void>> => {
  const template = `
    data_itemMovement_id: deleteInventoryItemMovement(id: "[ITEM_MOVEMENT_ID]") {
      deletedID
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template
      .replace("[ITEM_MOVEMENT_ID]", id)
      .replace("data_itemMovement_id", `data_itemMovement_${i}`),
  );
  const query = `
        mutation DeleteItemMovements {
            ${fakeMutation.join("\n")}
        }
      `;

  return client.mutate({
    mutation: gql(query),
  });
};

export const executeItemMovements = (
  client: Client,
  ids: ItemMovementId[],
): Promise<QueryResponse<void>> => {
  const template = `
    data_itemMovement_id: executeInventoryItemMovement(id: "[ITEM_MOVEMENT_ID]") {
      inventoryItemMovement {
          id
      }
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template
      .replace("[ITEM_MOVEMENT_ID]", id)
      .replace("data_itemMovement_id", `data_itemMovement_${i}`),
  );
  const query = `
        mutation ExecuteItemMovements {
            ${fakeMutation.join("\n")}
        }
      `;

  return client.mutate({
    mutation: gql(query),
  });
};
