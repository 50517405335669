import { ReactElement, ReactNode } from "react";
import {
  Body,
  Close,
  FooterItem,
  Header,
  Modal,
} from "@zendeskgarden/react-modals";
import { useTranslation } from "i18n";
import { Footer } from "@components/Modal";
import { Button } from "@components/Button";

export interface ExecuteModalProps {
  title: ReactNode;
  children?: ReactNode;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  isLoading?: boolean;
}

export function ExecuteModal(p: ExecuteModalProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Modal onClose={p.onCancel}>
      <Header tag="h2" isDanger>
        {p.title}
      </Header>
      <Body>{p.children}</Body>
      <Footer>
        <FooterItem>
          <Button onClick={p.onCancel} isBasic>
            {t("Cancel")}
          </Button>
        </FooterItem>
        <FooterItem>
          <Button
            isPrimary
            isDanger
            onClick={p.onConfirm}
            disabled={p.isLoading}
          >
            {t("Execute")}
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal" />
    </Modal>
  );
}
