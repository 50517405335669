export function isOneOf<A extends T, B extends T, T = A | B>(
  guards: [(v: T) => v is A, (v: T) => v is B],
): (v: T) => v is A | B;
export function isOneOf<A extends T, B extends T, C extends T, T = A | B | C>(
  guards: [(v: T) => v is A, (v: T) => v is B, (v: T) => v is C],
): (v: T) => v is A | B | C;
export function isOneOf<
  A extends T,
  B extends T,
  C extends T,
  D extends T,
  T = A | B | C | D,
>(
  guards: [
    (v: T) => v is A,
    (v: T) => v is B,
    (v: T) => v is C,
    (v: T) => v is D,
  ],
): (v: T) => v is A | B | C | D;
export function isOneOf<
  A extends T,
  B extends T,
  C extends T,
  D extends T,
  E extends T,
  T = A | B | C | D | E,
>(
  guards: [
    (v: T) => v is A,
    (v: T) => v is B,
    (v: T) => v is C,
    (v: T) => v is D,
    (v: T) => v is E,
  ],
): (v: T) => v is A | B | C | D | E;
export function isOneOf<
  A extends T,
  B extends T,
  C extends T,
  D extends T,
  E extends T,
  F extends T,
  T = A | B | C | D | E | F,
>(
  guards: [
    (v: T) => v is A,
    (v: T) => v is B,
    (v: T) => v is C,
    (v: T) => v is D,
    (v: T) => v is E,
    (v: T) => v is F,
  ],
): (v: T) => v is A | B | C | D | E | F;
export function isOneOf<
  A extends T,
  B extends T,
  C extends T,
  D extends T,
  E extends T,
  F extends T,
  G extends T,
  T = A | B | C | D | E | F | G,
>(
  guards: [
    (v: T) => v is A,
    (v: T) => v is B,
    (v: T) => v is C,
    (v: T) => v is D,
    (v: T) => v is E,
    (v: T) => v is F,
    (v: T) => v is G,
  ],
): (v: T) => v is A | B | C | D | E | F | G;
export function isOneOf(
  guards: Array<(v: unknown) => boolean>,
): (v: unknown) => boolean {
  return (v) => guards.some((type) => type(v));
}
