import { Transaction } from "types/src/Transactions/Transaction";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";

export type Actions = FetchSuccessAction | FetchErrorAction;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (isFetchSuccessAction(a)) return true;
  if (isFetchErrorAction(a)) return true;

  silentUnreachableError(a);
  return false;
});

// region FetchSuccessAction
export interface FetchSuccessAction {
  type: "Ready:DataManager:Transactions:Single:FetchSuccessAction";
  payload: Transaction;
}

export const fetchSuccessAction = (
  payload: FetchSuccessAction["payload"],
): FetchSuccessAction => ({
  type: "Ready:DataManager:Transactions:Single:FetchSuccessAction",
  payload,
});

export const isFetchSuccessAction = (s: Actions): s is FetchSuccessAction =>
  s.type === "Ready:DataManager:Transactions:Single:FetchSuccessAction";
// endregion

// region FetchErrorAction
export interface FetchErrorAction {
  type: "Ready:DataManager:Transactions:Single:FetchErrorAction";
}

export const fetchErrorAction = (): FetchErrorAction => ({
  type: "Ready:DataManager:Transactions:Single:FetchErrorAction",
});

export const isFetchErrorAction = (s: Actions): s is FetchErrorAction =>
  s.type === "Ready:DataManager:Transactions:Single:FetchErrorAction";
// endregion
