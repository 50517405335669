import { TranslatedStr } from "types/src/TranslatedStr";
import { Close, Notification, Title } from "ui/components/Toast";
import { useTranslation } from "i18n";
import { useMemo } from "react";

export interface NotificationItemProps {
  type: "success" | "info" | "warning" | "error";
  message: TranslatedStr;
  content:
    | { type: "none" }
    | { type: "text"; text: TranslatedStr }
    | { type: "code"; code: string };
  onLock: () => void;
  onUnlock: () => void;
  onRemove: () => void;
}

export function NotificationItem(p: NotificationItemProps) {
  const { t } = useTranslation();

  const title = useMemo(() => {
    switch (p.type) {
      case "success":
        return t("Success");
      case "info":
        return t("Info");
      case "warning":
        return t("Warning");
      case "error":
        return t("An error occurred");
    }
  }, [p.type]);

  return (
    <Notification
      type={p.type}
      style={{ maxWidth: 450 }}
      onMouseEnter={p.onLock}
      onMouseLeave={p.onUnlock}
    >
      <Title>{title}</Title>
      {p.message}
      <Close aria-label="Close" onClick={p.onRemove} />
    </Notification>
  );
}
