import { PageInfo } from "types";
import { DataType } from "types/src/DataType/DataType";
import { silentUnreachableError } from "utils/exceptions";
import * as O from "fp-ts/Option";
import { strictGuard } from "utils/strictGuard";
import { Item } from "./Item";
import { Filters, FiltersMonoid } from "./Filters";

// region State
export type State<P extends string> =
  | Loading<P>
  | LoadError<P>
  | Ready<P>
  | Fetching<P>;

export const isState = <P extends string>(p: P) => {
  const _isLoading = isLoading(p);
  const _isLoadError = isLoadError(p);
  const _isReady = isReady(p);
  const _isFetching = isFetching(p);

  return strictGuard((s: State<P>): s is State<P> => {
    if (_isLoading(s)) return true;
    if (_isLoadError(s)) return true;
    if (_isReady(s)) return true;
    if (_isFetching(s)) return true;

    silentUnreachableError(s);
    return false;
  });
};
// endregion

// region Loading
export interface LoadingPayload {
  perPage: number;
  order: O.Option<{
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  }>;
  filters: Filters;
}

export interface Loading<P extends string> {
  type: `${P}:Loading`;
  payload: LoadingPayload;
}

export const loading =
  <P extends string>(p: P) =>
  (payload: Loading<P>["payload"]): Loading<P> => ({
    type: `${p}:Loading`,
    payload,
  });

export const isLoading =
  <P extends string>(p: P) =>
  (s: State<P>): s is Loading<P> =>
    s.type === `${p}:Loading`;
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError<P extends string> {
  type: `${P}:LoadError`;
  payload: LoadErrorPayload;
}

export const loadError =
  <P extends string>(p: P) =>
  (payload: LoadError<P>["payload"]): LoadError<P> => ({
    type: `${p}:LoadError`,
    payload,
  });

export const isLoadError =
  <P extends string>(p: P) =>
  (s: State<P>): s is LoadError<P> =>
    s.type === `${p}:LoadError`;
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  pageInfo: PageInfo;
  total: number;
  items: Item[];
  dataTypes: DataType[];
  advancedFiltersState: "open" | "closed";
}

export interface Ready<P extends string> {
  type: `${P}:Ready`;
  payload: ReadyPayload;
}

export const ready =
  <P extends string>(p: P) =>
  (payload: Ready<P>["payload"]): Ready<P> => ({
    type: `${p}:Ready`,
    payload,
  });

export const isReady =
  <P extends string>(p: P) =>
  (s: State<P>): s is Ready<P> =>
    s.type === `${p}:Ready`;
// endregion

// region Fetching
export interface FetchingPayload extends ReadyPayload {
  page: "current" | "start" | "prev" | "next" | "end";
}

export interface Fetching<P extends string> {
  type: `${P}:Fetching`;
  payload: FetchingPayload;
}

export const fetching =
  <P extends string>(p: P) =>
  (payload: Fetching<P>["payload"]): Fetching<P> => ({
    type: `${p}:Fetching`,
    payload,
  });

export const isFetching =
  <P extends string>(p: P) =>
  (s: State<P>): s is Fetching<P> =>
    s.type === `${p}:Fetching`;
// endregion

export const init =
  <P extends string>(p: P) =>
  () =>
    loading(p)({
      perPage: 20,
      filters: FiltersMonoid.empty,
      order: O.none,
    });
