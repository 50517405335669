import { gql } from "@apollo/client";
import { Supplier, SupplierId } from "types/src/Supplier/Supplier";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Cursor } from "types";
import { isT } from "fp-utilities";
import * as NonEmptyArray from "fp-ts/NonEmptyArray";
import { DataTypeId } from "types/src/DataType/DataType";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { toApiOrderDirection } from "../../transformers/OrderDirection";
import { _gte, _lte } from "../../utils";
import { supplierFragmentToSupplier } from "../../transformers/Suppliers";
import { Client } from "../../index";
import {
  GetSupplierQuery,
  GetSupplierQueryVariables,
  GetSuppliersQuery,
  GetSuppliersQueryVariables,
  SupplierOrderField,
} from "../../generated/graphql";
import { supplierFragment } from "../../fragments/Supplier";
import {
  getPaginatedQueryResult,
  PaginatedQueryResponse,
  QueryResponse,
} from "../../type/QueryResponse";
import { notFoundError } from "../../type/DsError";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";

export interface GetSuppliersVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: {
    createdAt?: [Date | undefined, Date | undefined];
    updatedAt?: [Date | undefined, Date | undefined];
    dataTypes?: NonEmptyArray.NonEmptyArray<DataTypeId>;
    id?: string;
    search?: string;
    orphans?: boolean;
  };
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
}
export function getSuppliers(
  client: Client,
  vars: GetSuppliersVars,
): Promise<PaginatedQueryResponse<Supplier>> {
  const query = gql`
    ${pageInfoFragment}
    ${supplierFragment}

    query GetSuppliers(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: SupplierWhereInput
      $orderBy: SupplierOrder
    ) {
      suppliers(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...SupplierFragment
          }
        }
      }
    }
  `;

  const createdAt = pipe(vars.where?.createdAt, O.fromNullable);
  const updatedAt = pipe(vars.where?.updatedAt, O.fromNullable);

  return client
    .query<GetSuppliersQuery, GetSuppliersQueryVariables>({
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: {
          and: [
            {
              createdAtGTE: pipe(createdAt, _gte),
              updatedAtGTE: pipe(updatedAt, _gte),
            },
            {
              createdAtLTE: pipe(createdAt, _lte),
              updatedAtLTE: pipe(updatedAt, _lte),
            },
          ],
          id: vars.where?.id,
          DataContains: vars.where?.search ? [vars.where.search] : undefined,
          dataTypeIDIn: vars.where?.dataTypes,
          dataTypeIDIsNil: vars.where?.orphans === true ? true : undefined,
          dataTypeIDNotNil: vars.where?.orphans === false ? true : undefined,
        },
        orderBy: pipe(
          vars.orderBy,
          O.fromNullable,
          O.map((o) => ({
            direction: toApiOrderDirection(o.direction),
            field: {
              createdAt: SupplierOrderField.CreatedAt,
              updatedAt: SupplierOrderField.UpdatedAt,
            }[o.by],
          })),
          O.toUndefined,
        ),
      }),
    })
    .then(
      flow(
        E.map((r) => r.suppliers),
        E.map(getPaginatedQueryResult(supplierFragmentToSupplier)),
      ),
    );
}

export type GetSupplierQueryResult = Supplier;
export function getSupplier(
  client: Client,
  id: SupplierId,
): Promise<QueryResponse<GetSupplierQueryResult>> {
  const query = gql`
    ${supplierFragment}

    query GetSupplier($id: ID!) {
      suppliers(where: { id: $id }, first: 1) {
        edges {
          node {
            ...SupplierFragment
          }
        }
      }
    }
  `;

  return client
    .query<GetSupplierQuery, GetSupplierQueryVariables>({
      query,
      variables: {
        id,
      },
    })
    .then(
      flow(
        E.map((r) => r.suppliers.edges?.[0]?.node),
        E.filterOrElseW(isT, notFoundError),
        E.map(supplierFragmentToSupplier),
      ),
    );
}
