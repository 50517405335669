import { Selector, useSelector } from "state-manager";
import * as Ready from "state-manager/states/Ready";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import * as BuilderPreview from "state-manager/states/Ready/states/BuilderPreview";
import * as BPMNPreview from "state-manager/states/Ready/states/BPMNPreview";
import * as ZitadelPreview from "state-manager/states/Ready/states/ZitadelPreview";
import { flow } from "fp-ts/function";
import * as SandboxPreview from "state-manager/states/Ready/states/SandboxPreview";

import { ReactElement } from "react";
import { silentUnreachableError } from "utils/exceptions";
import * as DataManagerContainer from "./Ready/DataManager";
import * as OrganizationsContainer from "./Ready/Organizations";

export interface SecondarySidebarProps {
  selector: Selector<Ready.State>;
  dispatch: (a: Ready.Actions) => void;
}

export function SecondarySidebar({
  selector,
  dispatch,
}: SecondarySidebarProps): ReactElement | null {
  const r = useSelector(
    flow(selector, (_s) => {
      const s = _s.payload.subState;
      if (_s.payload.organizations.isActive) {
        return {
          type: "organizations",
          selector: flow(selector, (ss) => ss.payload),
        } as const;
      }
      if (Ready.isSigningOut(s))
        return {
          type: "signing-out",
        } as const;

      if (DataManager.isState(s))
        return {
          type: "data-manager",
          selector: flow(selector, (ss) => ss.payload.subState as typeof s),
        } as const;

      if (BuilderPreview.isState(s)) {
        return {
          type: "builder-preview",
          selector: flow(selector, (ss) => ss.payload.subState as typeof s),
        } as const;
      }

      if (BPMNPreview.isState(s)) {
        return {
          type: "bpmn-preview",
          selector: flow(selector, (ss) => ss.payload.subState as typeof s),
        } as const;
      }

      if (ZitadelPreview.isState(s)) {
        return {
          type: "zitadel-preview",
          selector: flow(selector, (ss) => ss.payload.subState as typeof s),
        } as const;
      }

      if (SandboxPreview.isState(s)) {
        return {
          type: "sandbox-preview",
          selector: flow(selector, (ss) => ss.payload.subState as typeof s),
        } as const;
      }

      silentUnreachableError(s);
      return {
        type: "unknown",
      } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "organizations": {
      return (
        <OrganizationsContainer.Sidebar
          selector={r.selector}
          dispatch={dispatch}
        />
      );
    }
    case "data-manager":
      return (
        <DataManagerContainer.Sidebar
          selector={r.selector}
          dispatch={dispatch}
        />
      );
    case "builder-preview":
    case "bpmn-preview":
    case "zitadel-preview":
    case "sandbox-preview":
    case "signing-out":
    case "unknown":
      return null;
  }
}
