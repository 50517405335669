import { RootState, useSelector } from "state-manager";
import { Dispatch, ReactElement, useMemo } from "react";
import * as Listing from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Listing";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { useTranslation } from "i18n";
import { FilterInput } from "@Containers/Listing/FilterInput";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { FilterDatePicker } from "@Containers/Listing/FilterDatePicker";
import { symmetricTuple } from "types/src/Tuple";
import { addDays, subDays } from "date-fns/fp";
import { Field } from "ui/components/Field";
import { Label } from "ui/components/Label";
import { Picker } from "@Containers/Form/Picker";

type Filters = Listing.Ready["payload"]["filters"];

export interface AdvancedFiltersFormProps {
  selector$: (s: RootState) => Filters;
  dispatch: Dispatch<Listing.Actions>;
}

export function AdvancedFiltersForm(p: AdvancedFiltersFormProps): ReactElement {
  const { t } = useTranslation();

  return (
    <FormWrapper>
      <FilterInput
        label={t("Search by id")}
        placeholder={t("Insert item id")}
        value$={flow(
          p.selector$,
          (v) => v.id,
          O.getOrElseW(() => ""),
        )}
        onChange={flow(Listing.setIdFilter, p.dispatch)}
      />
      <FilterInput
        label={t("Search by fields")}
        placeholder={t("Type content to search")}
        value$={flow(
          p.selector$,
          (v) => v.search,
          O.getOrElseW(() => ""),
        )}
        onChange={flow(Listing.setSearchFilter, p.dispatch)}
      />
      <Executed
        selector$={flow(p.selector$, (v) => v.executed)}
        onChange={flow(Listing.setExecutedFilter, p.dispatch)}
      />
      <CreatedFrom
        value$={flow(p.selector$, (v) => v.createdAt)}
        onChange={flow(Listing.setCreatedAtFilter, p.dispatch)}
      />
      <CreatedTo
        value$={flow(p.selector$, (v) => v.createdAt)}
        onChange={flow(Listing.setCreatedAtFilter, p.dispatch)}
      />
    </FormWrapper>
  );
}

interface ExecutedProps {
  selector$: (s: RootState) => "executed" | "un-executed" | "all";
  onChange: (v: "executed" | "un-executed" | "all") => void;
}
function Executed(p: ExecutedProps) {
  const { t } = useTranslation();
  const options = [
    { value: "executed", label: t("Executed") } as const,
    { value: "un-executed", label: t("Not executed") } as const,
  ];

  return (
    <Field>
      <Label>{t("Executed")}</Label>
      <Picker
        value$={p.selector$}
        onChange={(v) => p.onChange(v ?? "all")}
        options={options}
      />
    </Field>
  );
}

interface CreatedFromProps {
  value$: (s: RootState) => Filters["createdAt"];
  onChange: (v: Filters["createdAt"]) => void;
}
function CreatedFrom(p: CreatedFromProps) {
  const { t } = useTranslation();
  const to = useSelector<Date | undefined>(
    flow(
      p.value$,
      O.map((v) => v[1]),
      O.toUndefined,
    ),
    (a, b) => a?.getTime() === b?.getTime(),
  );

  const macValue = useMemo(
    () => pipe(to, O.fromNullable, O.map(subDays(1)), O.toUndefined),
    [to],
  );

  return (
    <FilterDatePicker
      label={t("Created from")}
      value$={flow(
        p.value$,
        O.map((v) => v[0]),
        O.toUndefined,
      )}
      maxValue={macValue}
      onChange={(v) => p.onChange(O.of(symmetricTuple(v, to)))}
    />
  );
}

interface CreatedToProps {
  value$: (s: RootState) => Filters["createdAt"];
  onChange: (v: Filters["createdAt"]) => void;
}
function CreatedTo(p: CreatedToProps) {
  const { t } = useTranslation();
  const from = useSelector<Date | undefined>(
    flow(
      p.value$,
      O.map((v) => v[0]),
      O.toUndefined,
    ),
    (a, b) => a?.getTime() === b?.getTime(),
  );
  const minValue = useMemo(
    () => pipe(from, O.fromNullable, O.map(addDays(1)), O.toUndefined),
    [from],
  );

  return (
    <FilterDatePicker
      label={t("Created until")}
      value$={flow(
        p.value$,
        O.map((v) => v[1]),
        O.toUndefined,
      )}
      onChange={(v) => p.onChange(O.of(symmetricTuple(from, v)))}
      minValue={minValue}
    />
  );
}
