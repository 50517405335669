import { filter, merge, Observable } from "rxjs";
import { Client } from "ds";
import { Epic } from "../../../../../../types/RootEpic";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import * as Listing from "./states/Listing";
import * as Create from "./states/Create";
import * as Edit from "./states/Edit";

type E = Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
>;

export const epic: E = (state$, ds$) => {
  const listing$ = Listing.epic(state$.pipe(filter(Listing.isState)), ds$);
  const create$ = Create.epic(state$.pipe(filter(Create.isState)), ds$);
  const edit$ = Edit.epic(state$.pipe(filter(Edit.isState)), ds$);
  return merge(listing$, create$, edit$);
};
