import { Customer } from "types/src/Customers/Customer";
import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import * as DataGenerator from "../../../../../../../../../generic-states/data-genetator";
import { SchemaFieldsActions, schemaFieldsState } from "../utils";

// region Actions
export type Actions =
  | LoadSuccess
  | LoadFail
  | Submit
  | SaveError
  | SaveSuccess
  | SchemaFieldsActions
  | DataGenerator.Actions;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (schemaFieldsState.isActions(a) || DataGenerator.isActions(a)) return true;

  switch (a.type) {
    case "Ready:DataManager:Customers:Create:LoadFail":
    case "Ready:DataManager:Customers:Create:LoadSuccess":
    case "Ready:DataManager:Customers:Create:SaveError":
    case "Ready:DataManager:Customers:Create:SaveSuccess":
    case "Ready:DataManager:Customers:Create:Submit":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:Customers:Create:LoadFail";
}

export const loadFail = (): LoadFail => ({
  type: "Ready:DataManager:Customers:Create:LoadFail",
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:Customers:Create:LoadSuccess";
  payload: DataTypeSchema;
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:Customers:Create:LoadSuccess",
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: "Ready:DataManager:Customers:Create:Submit";
}

export const submit = (): Submit => ({
  type: "Ready:DataManager:Customers:Create:Submit",
});
// endregion

// region SaveError
export interface SaveError {
  type: "Ready:DataManager:Customers:Create:SaveError";
}

export const saveError = (): SaveError => ({
  type: "Ready:DataManager:Customers:Create:SaveError",
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: "Ready:DataManager:Customers:Create:SaveSuccess";
  payload: Customer;
}

export const saveSuccess = (payload: SaveSuccess["payload"]): SaveSuccess => ({
  type: "Ready:DataManager:Customers:Create:SaveSuccess",
  payload,
});
// endregion
