import { shallowEqualObjects } from "shallow-equal";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  merge,
  NEVER,
  Observable,
  of,
  skip,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError } from "ds";
import { getDataTypes } from "ds/DataTypes";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { Epic } from "../../../../../../../../types/RootEpic";
import { dataTypesQueryToLoadSuccess, getFetchVars } from "./transformers";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

type Ep = Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
>;
export const epic: Ep = (state$, { pyckAdminClient$ }) => {
  const applyFilters$ = state$.pipe(
    skip(1),
    filter(State.isReady),
    map((s) => s.payload.filters),
    distinctUntilChanged(shallowEqualObjects),
    debounceTime(500),
    map(Actions.applyFilters),
  );

  const main$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) => {
      switch (s.type) {
        case "Ready:DataManager:DataTypes:Listing:LoadError":
        case "Ready:DataManager:DataTypes:Listing:Ready":
          return NEVER;
        case "Ready:DataManager:DataTypes:Listing:Loading":
        case "Ready:DataManager:DataTypes:Listing:Fetching": {
          return from(getDataTypes(client, getFetchVars(s))).pipe(
            map(
              flow(
                E.map(flow(dataTypesQueryToLoadSuccess, Actions.loadSuccess)),
                E.getOrElse<DsError, Actions.Actions>(Actions.loadFail),
              ),
            ),
            catchError(() => of(Actions.loadFail())),
          );
        }
      }
    }),
  );

  return merge(main$, applyFilters$);
};
