import * as Timestamp from "types/src/date/Timestamp";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

export function reducer(s: State.State, a: Actions.Actions): State.State {
  switch (a.type) {
    case "Notifications:AddNotification": {
      const counter = s.payload.counter + 1;
      return {
        ...s,
        payload: {
          ...s.payload,
          counter,
          items: [
            ...s.payload.items,
            {
              id: counter,
              message: a.payload.message,
              type: a.payload.type,
              status: "pending",
              content: { type: "none" },
              createdAt: Timestamp.fromDate(new Date()),
              expires: Timestamp.addSeconds(7, Timestamp.fromDate(new Date())),
            },
          ],
        },
      };
    }
    case "Notifications:AddNotificationWithDesc": {
      const counter = s.payload.counter + 1;
      return {
        ...s,
        payload: {
          ...s.payload,
          counter,
          items: [
            ...s.payload.items,
            {
              id: counter,
              message: a.payload.message,
              type: a.payload.type,
              status: "pending",
              content:
                a.payload.description.type === "Notifications:text"
                  ? {
                      type: "text",
                      text: a.payload.description.text,
                    }
                  : {
                      type: "code",
                      code: a.payload.description.code,
                    },
              expires: Timestamp.addSeconds(5, Timestamp.fromDate(new Date())),
              createdAt: Timestamp.fromDate(new Date()),
            },
          ],
        },
      };
    }
    case "Notifications:RemoveNotification": {
      return {
        ...s,
        payload: {
          ...s.payload,
          items: s.payload.items.filter((item) => item.id !== a.payload),
        },
      };
    }
    case "Notifications:ClearNotifications": {
      return {
        ...s,
        payload: {
          ...s.payload,
          items: [],
        },
      };
    }
    case "Notifications:Lock": {
      return {
        ...s,
        payload: {
          ...s.payload,
          items: s.payload.items.map((item) =>
            item.id === a.payload && item.status === "pending"
              ? { ...item, status: "locked" }
              : item,
          ),
        },
      };
    }
    case "Notifications:Unlock": {
      return {
        ...s,
        payload: {
          ...s.payload,
          items: s.payload.items.map((item) =>
            item.id === a.payload && item.status === "locked"
              ? {
                  ...item,
                  status: "pending",
                  expires:
                    item.expires - item.createdAt < 1000
                      ? Timestamp.addSeconds(3, Timestamp.fromDate(new Date()))
                      : item.expires,
                }
              : item,
          ),
        },
      };
    }
  }
}
