import { DataTypeEntity } from "types/src/DataType/DataType";

export interface Filters {
  name?: string;
  description?: string;
  entity?: [DataTypeEntity, ...DataTypeEntity[]];
  default?: boolean;
  createdAt?: [Date | undefined, Date | undefined];
  updatedAt?: [Date | undefined, Date | undefined];
}

export function isDirty(filters: Filters): boolean {
  return Object.values(filters).some((value) => value !== undefined);
}
