import { gql } from "@apollo/client";
import { DataTypeId } from "types/src/DataType/DataType";
import { RepositoryId } from "types/src/Repositories/Repository";
import { NoEmptyString } from "types/src/NoEmptyString";
import * as E from "fp-ts/Either";

import {
  RepositoryMovement,
  RepositoryMovementId,
} from "types/src/RepositoryMovements/RepositoryMovement";
import { flow } from "fp-ts/function";
import { DataTypeValue } from "types/src/DataType/DataTypeSchema";
import { Client, QueryResponse } from "../../index";
import {
  CreateRepositoryMovementMutation,
  CreateRepositoryMovementMutationVariables,
  UpdateRepositoryMovementMutation,
  UpdateRepositoryMovementMutationVariables,
} from "../../generated/graphql";
import { getRepositoryMovement } from "./queries";

export const createRepositoryMovement = (
  client: Client,
  vars: {
    dataTypeId: DataTypeId;
    fields: DataTypeValue;
    repositoryId: RepositoryId;
    fromId: RepositoryId;
    toId: RepositoryId;
    handler: NoEmptyString;
  },
): Promise<QueryResponse<RepositoryMovement>> => {
  const mutation = gql`
    mutation CreateRepositoryMovement($input: CreateRepositoryMovementInput!) {
      createInventoryRepositoryMovement(input: $input) {
        inventoryRepositoryMovement {
          id
        }
      }
    }
  `;

  return client
    .mutate<
      CreateRepositoryMovementMutation,
      CreateRepositoryMovementMutationVariables
    >({
      mutation,
      variables: {
        input: {
          data: vars.fields,
          dataTypeID: vars.dataTypeId,
          repositoryID: vars.repositoryId,
          fromID: vars.fromId,
          toID: vars.toId,
          handler: vars.handler,
        },
      },
    })
    .then(
      flow(
        E.map((v) =>
          getRepositoryMovement(
            client,
            v.createInventoryRepositoryMovement?.inventoryRepositoryMovement
              ?.id as RepositoryMovementId,
          ),
        ),
        E.getOrElseW(() => Promise.reject("Error creating item movement")),
      ),
    );
};

export const updateRepositoryMovement = (
  client: Client,
  vars: {
    id: RepositoryMovementId;
    dataTypeId?: DataTypeId;
    fields?: DataTypeValue;
    repositoryId?: RepositoryId;
    fromId?: RepositoryId;
    toId?: RepositoryId;
    quantity?: number;
    handler?: NoEmptyString;
    isExecuted?: boolean;
  },
): Promise<QueryResponse<RepositoryMovement>> => {
  const mutation = gql`
    mutation UpdateRepositoryMovement(
      $id: ID!
      $input: UpdateRepositoryMovementInput!
    ) {
      updateInventoryRepositoryMovement(id: $id, input: $input) {
        inventoryRepositoryMovement {
          id
        }
      }
    }
  `;

  return client
    .mutate<
      UpdateRepositoryMovementMutation,
      UpdateRepositoryMovementMutationVariables
    >({
      mutation,
      variables: {
        id: vars.id,
        input: {
          data: vars.fields,
          dataTypeID: vars.dataTypeId,
          repositoryID: vars.repositoryId,
          fromID: vars.fromId,
          toID: vars.toId,
          handler: vars.handler,
          executed: vars.isExecuted,
        },
      },
    })
    .then(
      flow(
        E.map((v) =>
          getRepositoryMovement(
            client,
            v.updateInventoryRepositoryMovement?.inventoryRepositoryMovement
              ?.id as RepositoryMovementId,
          ),
        ),
        E.getOrElseW(() => Promise.reject("Error creating item movement")),
      ),
    );
};

export const deleteRepositoryMovements = (
  client: Client,
  ids: RepositoryMovementId[],
): Promise<QueryResponse<void>> => {
  const template = `
    data_repositoryMovement_id: deleteInventoryRepositoryMovement(id: "[REPOSITORY_MOVEMENT_ID]") {
      deletedID
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template
      .replace("[REPOSITORY_MOVEMENT_ID]", id)
      .replace("data_repositoryMovement_id", `data_repositoryMovement_${i}`),
  );
  const query = `
        mutation DeleteRepositoryMovements {
            ${fakeMutation.join("\n")}
        }
      `;

  return client.mutate({
    mutation: gql(query),
  });
};

export const executeRepositoryMovements = (
  client: Client,
  ids: RepositoryMovementId[],
): Promise<QueryResponse<void>> => {
  const template = `
    data_repositoryMovement_id: executeInventoryRepositoryMovement(id: "[REPOSITORY_MOVEMENT_ID]") {
      inventoryRepositoryMovement {
          id
      }
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template
      .replace("[REPOSITORY_MOVEMENT_ID]", id)
      .replace("data_repositoryMovement_id", `data_repositoryMovement_${i}`),
  );
  const query = `
        mutation ExecuteRepositoryMovements {
            ${fakeMutation.join("\n")}
        }
      `;

  return client.mutate({
    mutation: gql(query),
  });
};
