import { silentUnreachableError } from "utils/exceptions";
import { PageInfo } from "types";
import { strictGuard } from "utils/strictGuard";
import { Filters } from "./Filters";
import { Item } from "./Item";

export type State = Loading | LoadError | Ready | Fetching;

export const isState = strictGuard((state: State): state is State => {
  switch (state.type) {
    case "Ready:DataManager:DataTypes:Listing:Loading":
    case "Ready:DataManager:DataTypes:Listing:LoadError":
    case "Ready:DataManager:DataTypes:Listing:Fetching":
    case "Ready:DataManager:DataTypes:Listing:Ready":
      return true;
    default:
      silentUnreachableError(state);
      return false;
  }
});

// region Loading
export interface LoadingPayload {
  perPage: number;
  filters: Filters;
  advancedFiltersOpen: boolean;
}

export interface Loading {
  type: "Ready:DataManager:DataTypes:Listing:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Ready:DataManager:DataTypes:Listing:Loading",
  payload,
});

export const isLoading = (state: State): state is Loading =>
  state.type === "Ready:DataManager:DataTypes:Listing:Loading";
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Ready:DataManager:DataTypes:Listing:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Ready:DataManager:DataTypes:Listing:LoadError",
  payload,
});

export const isLoadError = (state: State): state is LoadError => {
  return state.type === "Ready:DataManager:DataTypes:Listing:LoadError";
};
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  pageInfo: PageInfo;
  total: number;
  items: Item[];
}

export interface Ready {
  type: "Ready:DataManager:DataTypes:Listing:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:DataManager:DataTypes:Listing:Ready",
  payload,
});

export const isReady = (state: State): state is Ready => {
  return state.type === "Ready:DataManager:DataTypes:Listing:Ready";
};
// endregion

// region Fetching
export interface FetchingPayload extends ReadyPayload {
  page: "current" | "start" | "prev" | "next" | "end";
}

export interface Fetching {
  type: "Ready:DataManager:DataTypes:Listing:Fetching";
  payload: FetchingPayload;
}

export const fetching = (payload: Fetching["payload"]): Fetching => ({
  type: "Ready:DataManager:DataTypes:Listing:Fetching",
  payload,
});

export const isFetching = (state: State): state is Fetching => {
  return state.type === "Ready:DataManager:DataTypes:Listing:Fetching";
};
// endregion

export const init = () =>
  loading({
    perPage: 15,
    filters: {},
    advancedFiltersOpen: false,
  });
