import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import * as ListingAll from "../states/ListingAll";
import * as Listing from "../states/Listing";
import * as Create from "../states/Create";
import * as Edit from "../states/Edit";

export type Actions =
  | ListingAll.Actions
  | Listing.Actions
  | Create.Actions
  | Edit.Actions;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (ListingAll.isActions(a)) return true;
  if (Listing.isActions(a)) return true;
  if (Create.isActions(a)) return true;
  if (Edit.isActions(a)) return true;

  silentUnreachableError(a);
  return false;
});
