import { Selector, useSelector } from "state-manager";
import { useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";

import { AltairSandbox } from "ui/layouts/AltairSandbox";
import { flow } from "fp-ts/lib/function";
import { UserAccessToken } from "types";

const initialQuery = `fragment DataTypeFragment on DataType {\n  id\n  name\n  description\n  jsonSchema\n  entity\n  default\n  jsonSchema\n  createdAt\n  updatedAt\n  deletedAt\n  __typename\n}\n\nquery GetDataTypes($first: Int, $last: Int, $after: Cursor, $before: Cursor, $where: DataTypeWhereInput) {\n  dataTypes(\n    first: $first\n    last: $last\n    after: $after\n    before: $before\n    where: $where\n  ) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n      __typename\n    }\n    edges {\n      cursor\n      node {\n        ...DataTypeFragment\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n}`;

const DB_NAME = "AltairDB";

export interface ContentProps {
  selector: Selector<UserAccessToken | undefined>;
}

export function Content(p: ContentProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const accessToken = useSelector(flow(p.selector, (s) => s));

  useEffect(() => {
    const elem = iframeRef.current;
    if (!elem) return;

    indexedDB.deleteDatabase(DB_NAME);
    elem.addEventListener("load", () => {
      elem.contentWindow?.AltairGraphQL.init({
        baseURL: window.env.clientUri,
        endpointURL: window.env.clientUri,
        initialQuery: initialQuery,
        initialHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    });
  }, [accessToken]);

  const content = <AltairSandbox key={accessToken} />;

  return (
    <iframe
      ref={iframeRef}
      style={{ flex: 1 }}
      srcDoc={ReactDOMServer.renderToString(content)}
    />
  );
}
