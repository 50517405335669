import { gql } from "@apollo/client";
import { DataTypeId } from "types/src/DataType/DataType";
import { Supplier, SupplierId } from "types/src/Supplier/Supplier";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { isT } from "fp-utilities";
import { print } from "graphql";
import { DataTypeValue } from "types/src/DataType/DataTypeSchema";
import { supplierFragment } from "../../fragments/Supplier";
import {
  CreateSupplierMutation,
  CreateSupplierMutationVariables,
  UpdateSupplierMutation,
  UpdateSupplierMutationVariables,
} from "../../generated/graphql";
import { Client, DsError, notFoundError } from "../../index";
import { supplierFragmentToSupplier } from "../../transformers/Suppliers";
import { QueryResponse } from "../../type/QueryResponse";

export const createSupplier = (
  client: Client,
  vars: {
    dataTypeId: DataTypeId;
    fields: DataTypeValue;
  },
): Promise<QueryResponse<Supplier>> => {
  const mutation = gql`
    ${supplierFragment}
    mutation CreateSupplier($input: CreateSupplierInput!) {
      createSupplier(input: $input) {
        ...SupplierFragment
      }
    }
  `;

  return client
    .mutate<CreateSupplierMutation, CreateSupplierMutationVariables>({
      mutation,
      variables: {
        input: {
          data: vars.fields,
          dataTypeID: vars.dataTypeId,
        },
      },
    })
    .then(
      flow(
        E.map((v) => v.createSupplier),
        E.filterOrElseW(isT, notFoundError),
        E.map(supplierFragmentToSupplier),
      ),
    );
};

export const createSuppliers = async (
  client: Client,
  vars: {
    dataTypeId: DataTypeId;
    fields: DataTypeValue[];
  },
): Promise<E.Either<DsError, void>> => {
  const template = `
    data_supplier_id: createSupplier(input: [SUPPLIER_INPUT]) {
      ...SupplierFragment
    }
  `;

  const fakeMutations: string[] = [];
  const fakeTypes: string[] = [];
  const fakeVariables: Record<
    string,
    {
      data: DataTypeValue;
      dataTypeID: DataTypeId;
    }
  > = {};
  vars.fields.forEach((fields, i) => {
    const name = `data_supplier_input_${i}`;

    fakeMutations.push(
      template
        .replace("[SUPPLIER_INPUT]", `$${name}`)
        .replace("data_supplier_id", `data_supplier_${i}`),
    );

    fakeVariables[name] = {
      data: { fields },
      dataTypeID: vars.dataTypeId,
    };

    fakeTypes.push(`$${name}: CreateSupplierInput!`);
  });

  const query = `
    mutation CreateSuppliers(${fakeTypes.join(", ")}) {
        ${fakeMutations.join("\n")}
    }
  `;

  return client
    .mutate({
      mutation: gql(`${print(supplierFragment)}${query}`),
      variables: fakeVariables,
    })
    .then(E.map(() => undefined));
};

export const updateSupplier = (
  client: Client,
  vars: {
    id: SupplierId;
    dataTypeId: DataTypeId;
    fields: DataTypeValue;
  },
): Promise<QueryResponse<Supplier>> => {
  const mutation = gql`
    ${supplierFragment}
    mutation UpdateSupplier($id: ID!, $input: UpdateSupplierInput!) {
      updateSupplier(id: $id, input: $input) {
        ...SupplierFragment
      }
    }
  `;

  return client
    .mutate<UpdateSupplierMutation, UpdateSupplierMutationVariables>({
      mutation,
      variables: {
        id: vars.id,
        input: {
          dataTypeID: vars.dataTypeId,
          data: vars.fields,
        },
      },
    })
    .then(
      flow(
        E.map((v) => v.updateSupplier),
        E.filterOrElseW(isT, notFoundError),
        E.map(supplierFragmentToSupplier),
      ),
    );
};

export const deleteSuppliers = (
  client: Client,
  ids: SupplierId[],
): Promise<QueryResponse<void>> => {
  const template = `
    data_supplier_id: deleteSupplier(id: "[SUPPLIER_ID]") {
      deletedID
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template
      .replace("[SUPPLIER_ID]", id)
      .replace("data_supplier_id", `data_supplier_${i}`),
  );
  const query = `
      mutation DeleteSuppliers {
            ${fakeMutation.join("\n")}
        }
      `;

  return client
    .mutate({
      mutation: gql(query),
    })
    .then(E.map(() => undefined));
};
