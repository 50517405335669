import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import * as Listing from "../states/Listing";
import * as Create from "../states/Create";
import * as Edit from "../states/Edit";

export type State = Listing.State | Create.State | Edit.State;

export const isState = strictGuard((state: State): state is State => {
  const s = state as State;

  if (Listing.isState(s)) return true;
  if (Create.isState(s)) return true;
  if (Edit.isState(s)) return true;

  silentUnreachableError(s);
  return false;
});

export const initListing = Listing.init;
export const initCreate = Create.init;
export const initEdit = Edit.init;
