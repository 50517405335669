import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import * as Schema from "../../../../../../../../../generic-states/Schema";

export type State =
  | Loading
  | LoadError
  | Ready
  | Saving
  | RemoveConfirmation
  | Removing;

export const isState = strictGuard((s: State): s is State => {
  switch (s.type) {
    case "Ready:DataManager:DataTypes:Edit:Loading":
    case "Ready:DataManager:DataTypes:Edit:LoadError":
    case "Ready:DataManager:DataTypes:Edit:Ready":
    case "Ready:DataManager:DataTypes:Edit:Saving":
    case "Ready:DataManager:DataTypes:Edit:RemoveConfirmation":
    case "Ready:DataManager:DataTypes:Edit:Removing":
      return true;
    default:
      silentUnreachableError(s);
      return false;
  }
});

// region Loading
export interface LoadingPayload {
  id: DataTypeId;
}

export interface Loading {
  type: "Ready:DataManager:DataTypes:Edit:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Ready:DataManager:DataTypes:Edit:Loading",
  payload,
});

export const isLoading = (s: State): s is Loading =>
  s.type === "Ready:DataManager:DataTypes:Edit:Loading";
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Ready:DataManager:DataTypes:Edit:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Ready:DataManager:DataTypes:Edit:LoadError",
  payload,
});

export const isLoadError = (s: State): s is LoadError =>
  s.type === "Ready:DataManager:DataTypes:Edit:LoadError";
// endregion

// region Ready
export interface ReadyPayload {
  id: DataTypeId;
  name: string;
  description: string | undefined;
  default: boolean;
  entity: DataTypeEntity;
  schema: Schema.State;
}

export interface Ready {
  type: "Ready:DataManager:DataTypes:Edit:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:DataManager:DataTypes:Edit:Ready",
  payload,
});

export const isReady = (s: State): s is Ready =>
  s.type === "Ready:DataManager:DataTypes:Edit:Ready";
// endregion

// region Saving
export interface SavingPayload extends ReadyPayload {}

export interface Saving {
  type: "Ready:DataManager:DataTypes:Edit:Saving";
  payload: SavingPayload;
}

export const saving = (payload: Saving["payload"]): Saving => ({
  type: "Ready:DataManager:DataTypes:Edit:Saving",
  payload,
});

export const isSaving = (s: State): s is Saving =>
  s.type === "Ready:DataManager:DataTypes:Edit:Saving";
// endregion

// region RemoveConfirmation
export interface RemoveConfirmationPayload extends ReadyPayload {}

export interface RemoveConfirmation {
  type: "Ready:DataManager:DataTypes:Edit:RemoveConfirmation";
  payload: RemoveConfirmationPayload;
}

export const removeConfirmation = (
  payload: RemoveConfirmation["payload"],
): RemoveConfirmation => ({
  type: "Ready:DataManager:DataTypes:Edit:RemoveConfirmation",
  payload,
});

export const isRemoveConfirmation = (s: State): s is RemoveConfirmation => {
  return s.type === "Ready:DataManager:DataTypes:Edit:RemoveConfirmation";
};
// endregion

// region Removing
export interface RemovingPayload extends RemoveConfirmationPayload {}

export interface Removing {
  type: "Ready:DataManager:DataTypes:Edit:Removing";
  payload: RemovingPayload;
}

export const removing = (payload: Removing["payload"]): Removing => ({
  type: "Ready:DataManager:DataTypes:Edit:Removing",
  payload,
});

export const isRemoving = (s: State): s is Removing => {
  return s.type === "Ready:DataManager:DataTypes:Edit:Removing";
};
// endregion

export const init = loading;
