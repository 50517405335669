import { gql } from "@apollo/client";
import { DataTypeId } from "types/src/DataType/DataType";
import {
  InventoryItemId,
  InventoryItem,
} from "types/src/InventoryItems/InventoryItem";
import { NoEmptyString } from "types/src/NoEmptyString";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { isT } from "fp-utilities";
import { print } from "graphql";
import { DataTypeValue } from "types/src/DataType/DataTypeSchema";
import { inventoryItemFragment } from "../../fragments/InventoryItem";

import { Client, DsError, notFoundError } from "../../index";
import { inventoryItemFragmentToInventoryItem } from "../../transformers/InventoryItems";
import {
  CreateInventoryItemMutation,
  CreateInventoryItemMutationVariables,
  UpdateInventoryItemMutation,
  UpdateInventoryItemMutationVariables,
} from "../../generated/graphql";
import { QueryResponse } from "../../type/QueryResponse";

export const createInventoryItem = (
  client: Client,
  vars: {
    dataTypeId: DataTypeId;
    fields: DataTypeValue;
    sku: NoEmptyString;
  },
): Promise<QueryResponse<InventoryItem>> => {
  const mutation = gql`
    ${inventoryItemFragment}
    mutation CreateInventoryItem($input: CreateInventoryItemInput!) {
      createInventoryItem(input: $input) {
        inventoryItem {
          ...InventoryItemFragment
        }
      }
    }
  `;

  return client
    .mutate<CreateInventoryItemMutation, CreateInventoryItemMutationVariables>({
      mutation,
      variables: {
        input: {
          inventoryJSONData: vars.fields,
          sku: vars.sku,
          dataTypeID: vars.dataTypeId,
        },
      },
    })
    .then(
      flow(
        E.map((v) => v.createInventoryItem?.inventoryItem),
        E.filterOrElseW(isT, notFoundError),
        E.map(inventoryItemFragmentToInventoryItem),
      ),
    );
};

export const createInventoryItems = async (
  client: Client,
  vars: {
    dataTypeId: DataTypeId;
    fields: DataTypeValue[];
    skuArr: NoEmptyString[];
  },
): Promise<E.Either<DsError, void>> => {
  const template = `
    data_intentory_item_id: createInventoryItem(input: [INVENTORY_ITEM_INPUT]) {
        inventoryItem {
          ...InventoryItemFragment
        }
    }
  `;

  const fakeMutations: string[] = [];
  const fakeTypes: string[] = [];
  const fakeVariables: Record<
    string,
    {
      inventoryJSONData: DataTypeValue;
      sku: NoEmptyString;
      dataTypeID: DataTypeId;
    }
  > = {};
  vars.fields.forEach((fields, i) => {
    const name = `data_inventory_item_input_${i}`;

    fakeMutations.push(
      template
        .replace("[INVENTORY_ITEM_INPUT]", `$${name}`)
        .replace("data_intentory_item_id", `data_inventory_item_${i}`),
    );

    fakeVariables[name] = {
      inventoryJSONData: { fields },
      sku: vars.skuArr[i] as NoEmptyString,
      dataTypeID: vars.dataTypeId,
    };

    fakeTypes.push(`$${name}: CreateInventoryItemInput!`);
  });

  const query = `
    ${inventoryItemFragment}
    mutation CreateInventoryItems(${fakeTypes.join(", ")}) {
        ${fakeMutations.join("\n")}
    }
  `;

  return client
    .mutate({
      mutation: gql(`${print(inventoryItemFragment)}${query}`),
      variables: fakeVariables,
    })
    .then(E.map(() => undefined));
};

export const updateInventoryItem = (
  client: Client,
  vars: {
    id: InventoryItemId;
    dataTypeId: DataTypeId;
    fields: DataTypeValue;
    sku?: NoEmptyString;
  },
): Promise<QueryResponse<InventoryItem>> => {
  const mutation = gql`
    ${inventoryItemFragment}
    mutation UpdateInventoryItem($id: ID!, $input: UpdateInventoryItemInput!) {
      updateInventoryItem(id: $id, input: $input) {
        inventoryItem {
          ...InventoryItemFragment
        }
      }
    }
  `;

  return client
    .mutate<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>({
      mutation,
      variables: {
        id: vars.id,
        input: {
          dataTypeID: vars.dataTypeId,
          inventoryJSONData: vars.fields,
          sku: vars.sku,
        },
      },
    })
    .then(
      flow(
        E.map((v) => v.updateInventoryItem?.inventoryItem),
        E.filterOrElseW(isT, notFoundError),
        E.map(inventoryItemFragmentToInventoryItem),
      ),
    );
};

export const deleteInventoryItems = (
  client: Client,
  ids: InventoryItemId[],
): Promise<QueryResponse<void>> => {
  const template = `
    data_inventoryItem_id: deleteInventoryItem(id: "[INVENTORY_ITEM_ID]") {
      deletedID
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template
      .replace("[INVENTORY_ITEM_ID]", id)
      .replace("data_inventoryItem_id", `data_inventoryItem_${i}`),
  );
  const query = `
        mutation DeleteInventoryItems {
            ${fakeMutation.join("\n")}
        }
      `;

  return client
    .mutate({
      mutation: gql(query),
    })
    .then(E.map(() => undefined));
};
