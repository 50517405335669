// region Actions
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";

export interface Actions {
  type: "Ready:BPMNPreview:Actions";
}

export const actions = (): Actions => ({
  type: "Ready:BPMNPreview:Actions",
});

export const isActions = strictGuard((a: Actions): a is Actions => {
  switch (a.type) {
    case "Ready:BPMNPreview:Actions":
      return true;
    default:
      silentUnreachableError(a.type);
      return false;
  }
});
// endregion
