import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  merge,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError } from "ds";
import { identity } from "fp-ts/function";
import { createInventoryItem } from "ds/InventoryItems";
import { getDataType } from "ds/DataTypes";
import * as E from "fp-ts/Either";
import { extractFieldsFromSchema } from "../../../../../../../../generic-states/SchemaFields/utils";
import { Epic } from "../../../../../../../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { schemaFieldsState } from "./utils";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$ }) => {
  const fieldsSchema$ = schemaFieldsState.epic(
    state$.pipe(
      filter(State.isLoaded),
      map((s) => s.payload.schema),
    ),
    pyckAdminClient$,
  );

  const loading$ = state$.pipe(
    filter(State.isLoading),
    map((s) => s.payload.dataTypeId),
    distinctUntilChanged(),
    withLatestFrom(pyckAdminClient$),
    switchMap(([dataTypeId, client]) =>
      from(getDataType(client, dataTypeId)).pipe(
        map(E.mapLeft(Actions.loadFail)),
        map(E.chain(E.fromNullable(Actions.loadFail()))),
        map(E.filterOrElse((v) => v.entity === "item", Actions.loadFail)),
        map(E.map((dataType) => dataType.schema)),
        map(E.map(Actions.loadSuccess)),
        map(E.getOrElse<Actions.Actions, Actions.Actions>(identity)),
      ),
    ),
  );

  const create$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isSaving),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) =>
      from(
        createInventoryItem(client, {
          dataTypeId: s.dataTypeId,
          fields: extractFieldsFromSchema(
            "Ready:DataManager:InventoryItems:Create",
          )(s.schema.payload.values),
          sku: s.sku.value,
        }),
      ).pipe(
        map(E.map(Actions.saveSuccess)),
        map(E.getOrElse<DsError, Actions.Actions>(Actions.saveError)),
        catchError(() => of(Actions.saveError())),
      ),
    ),
  );

  return merge(loading$, create$, fieldsSchema$);
};
