import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import * as SchemaActions from "../../../../../../../../../generic-states/Schema/types/Actions";

export type Actions = FormActions | SchemaActions.Actions;

export const isActions = strictGuard((action: Actions): action is Actions => {
  return isFormActions(action) || SchemaActions.isActions(action);
});

function isFormActions(action: Actions): action is FormActions {
  const a = action as FormActions;

  switch (a.type) {
    case "Ready:DataManager:DataTypes:Create:SetName":
    case "Ready:DataManager:DataTypes:Create:SetDescription":
    case "Ready:DataManager:DataTypes:Create:SetDefault":
    case "Ready:DataManager:DataTypes:Create:SetEntity":
    case "Ready:DataManager:DataTypes:Create:SaveFail":
    case "Ready:DataManager:DataTypes:Create:SaveSuccess":
    case "Ready:DataManager:DataTypes:Create:Submit":
    case "Ready:DataManager:DataTypes:Create:ResetSchema":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
}
type FormActions =
  | SetName
  | SetDescription
  | SetDefault
  | SetEntity
  | ResetSchema
  | Submit
  | SaveSuccess
  | SaveFail;

// region SetName
export interface SetName {
  type: "Ready:DataManager:DataTypes:Create:SetName";
  payload: string;
}

export const setName = (payload: SetName["payload"]): SetName => ({
  type: "Ready:DataManager:DataTypes:Create:SetName",
  payload,
});
// endregion

// region SetDescription
export interface SetDescription {
  type: "Ready:DataManager:DataTypes:Create:SetDescription";
  payload: string;
}

export const setDescription = (
  payload: SetDescription["payload"],
): SetDescription => ({
  type: "Ready:DataManager:DataTypes:Create:SetDescription",
  payload,
});
// endregion

// region SetDefault
export interface SetDefault {
  type: "Ready:DataManager:DataTypes:Create:SetDefault";
  payload: boolean;
}

export const setDefault = (payload: SetDefault["payload"]): SetDefault => ({
  type: "Ready:DataManager:DataTypes:Create:SetDefault",
  payload,
});
// endregion

// region SetEntity
export interface SetEntity {
  type: "Ready:DataManager:DataTypes:Create:SetEntity";
  payload: DataTypeEntity;
}

export const setEntity = (payload: SetEntity["payload"]): SetEntity => ({
  type: "Ready:DataManager:DataTypes:Create:SetEntity",
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: "Ready:DataManager:DataTypes:Create:Submit";
}

export const submit = (): Submit => ({
  type: "Ready:DataManager:DataTypes:Create:Submit",
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: "Ready:DataManager:DataTypes:Create:SaveSuccess";
  payload: DataTypeId;
}

export const saveSuccess = (payload: SaveSuccess["payload"]): SaveSuccess => ({
  type: "Ready:DataManager:DataTypes:Create:SaveSuccess",
  payload,
});
// endregion

// region SaveFail
export interface SaveFail {
  type: "Ready:DataManager:DataTypes:Create:SaveFail";
}

export const saveFail = (): SaveFail => ({
  type: "Ready:DataManager:DataTypes:Create:SaveFail",
});
// endregion

// region ResetSchema
export interface ResetSchema {
  type: "Ready:DataManager:DataTypes:Create:ResetSchema";
  payload: DataTypeSchema;
}

export const resetSchema = (payload: ResetSchema["payload"]): ResetSchema => ({
  type: "Ready:DataManager:DataTypes:Create:ResetSchema",
  payload,
});

export const isResetSchema = (s: Actions): s is ResetSchema =>
  s.type === "Ready:DataManager:DataTypes:Create:ResetSchema";
// endregion
