import * as O from "fp-ts/Option";
import { fromDate, ISODate } from "types/src/date/ISODate";
import { flow } from "fp-ts/function";

export const _gte: (
  v: O.Option<[Date | undefined, Date | undefined]>,
) => ISODate | undefined = flow(
  O.chain((v) => O.fromNullable(v[0])),
  O.map(fromDate),
  O.toUndefined,
);

export const _lte: (
  v: O.Option<[Date | undefined, Date | undefined]>,
) => ISODate | undefined = flow(
  O.chain((v) => O.fromNullable(v[1])),
  O.map(fromDate),
  O.toUndefined,
);
