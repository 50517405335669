import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  forkJoin,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  scan,
  skip,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError } from "ds";
import { createCustomers, deleteCustomers, getCustomers } from "ds/Customers";
import { CustomerId } from "types/src/Customers/Customer";
import { Client as OpenAIClient } from "open-ai-ds";
import * as Arr from "fp-ts/Array";
import * as Str from "fp-ts/string";
import { Eq } from "fp-ts/Eq";
import { getDataType } from "ds/DataTypes";
import { flow } from "fp-ts/function";
import * as E from "fp-ts/Either";
import * as OpenAIState from "../../../../../../../../generic-states/data-genetator";
import { Epic } from "../../../../../../../../types/RootEpic";
import { FiltersEq } from "./types/Filters";
import { getFetchVars } from "./transformers";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export const epic: Epic<
  Actions.Actions,
  State.State,
  {
    pyckAdminClient$: Observable<Client>;
    openAIClient$: Observable<OpenAIClient>;
  }
> = (state$, { pyckAdminClient$, openAIClient$ }) => {
  const loading$ = state$.pipe(
    filter(State.isLoading),
    map((s) => ({
      customer: getFetchVars(s),
      dataType: s.payload.id,
    })),
    withLatestFrom(pyckAdminClient$),
    switchMap(([{ customer, dataType }, client]) => {
      return forkJoin({
        items: from(getCustomers(client, customer)),
        dataType: from(getDataType(client, dataType)),
      }).pipe(
        map(
          flow(
            (vs) => {
              if (E.isLeft(vs.dataType)) return vs.dataType;
              if (E.isLeft(vs.items)) return vs.items;
              return E.right({
                dataType: vs.dataType.right,
                items: vs.items.right,
              });
            },
            E.map((r) =>
              Actions.loadSuccess({
                dataType: r.dataType,
                items: r.items.items,
                total: r.items.totalCount,
                pageInfo: r.items.pageInfo,
              }),
            ),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.loadFail({ type: undefined }),
            ),
          ),
        ),
        catchError(() => of(Actions.loadFail({ type: undefined }))),
      );
    }),
  );

  const fetch$ = state$.pipe(
    filter(State.isFetching),
    map(getFetchVars),
    withLatestFrom(pyckAdminClient$),
    switchMap(([vars, client]) => {
      return from(getCustomers(client, vars)).pipe(
        map(
          flow(
            E.map((r) =>
              Actions.fetchSuccess({
                items: r.items,
                total: r.totalCount,
                pageInfo: r.pageInfo,
              }),
            ),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.loadFail({ type: undefined }),
            ),
          ),
        ),
        catchError(() => of(Actions.loadFail({ type: undefined }))),
      );
    }),
  );

  const remove$ = state$.pipe(
    filter(State.isReady),
    map((s) =>
      s.payload.items
        .filter((i) => i.removeState === "removing")
        .map((i) => i.id),
    ),
    scan(
      ([pendingItems], removingItems) => {
        return [
          removingItems,
          Arr.difference(Str.Eq as Eq<CustomerId>)(pendingItems)(removingItems),
        ] as [CustomerId[], CustomerId[]];
      },
      [[], []] as [CustomerId[], CustomerId[]],
    ),
    map(([, toRemove]) => toRemove),
    filter((i) => i.length > 0),
    withLatestFrom(pyckAdminClient$),
    mergeMap(([toRemove, client]) => {
      return from(deleteCustomers(client, toRemove)).pipe(
        map(
          flow(
            E.map(() => Actions.removeSuccess(toRemove)),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.removeFail(toRemove),
            ),
          ),
        ),
        catchError(() => of(Actions.removeFail(toRemove))),
      );
    }),
  );

  const openAIEpic$ = OpenAIState.epic(
    state$.pipe(
      filter(State.isReady),
      map((s) => s.payload.openAI),
    ),
    { pyckAdminClient$, openAIClient$ },
  );

  const openAICreate$ = state$.pipe(
    map((s) => s.payload.openAI),
    filter(OpenAIState.isSuccess),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) => {
      return from(
        createCustomers(client, {
          dataTypeId: s.payload.dataTypeId,
          fields: s.payload.resultData.fields,
        }),
      ).pipe(map(Actions.generateSuccess));
    }),
  );

  const applyFilters$ = state$.pipe(
    filter(State.isReady),
    map((s) => s.payload.filters),
    distinctUntilChanged(FiltersEq.equals),
    skip(1),
    debounceTime(500),
    map(Actions.submitFilters),
  );

  return merge(
    loading$,
    fetch$,
    remove$,
    openAIEpic$,
    openAICreate$,
    applyFilters$,
  );
};
