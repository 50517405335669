import { ReactElement } from "react";
import { Selector, useDispatch, useSelector } from "state-manager";
import { useTranslation } from "i18n";
import { flow, pipe } from "fp-ts/function";
import { Button } from "ui/components/Button";
import * as DataGenerator from "state-manager/generic-states/data-genetator";
import { AdvancedFilters as FiltersSidebar } from "ui/layouts/Filters/AdvancedFilters";
import { TypographyMD } from "ui/components/Typography";
import { Field as UIField } from "ui/components/Field";
import * as O from "fp-ts/Option";
import { Label } from "ui/components/Label";
import { Number } from "@Containers/Form/Number";
import { isOneOf } from "utils/isOneOf";

export interface FooterProps {
  selector: Selector<DataGenerator.State>;
}

export function FieldsGenerator({
  selector,
}: FooterProps): ReactElement | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openAIIsAvailable = useSelector(
    flow(selector, (s: DataGenerator.State) => {
      return s.payload?.apiKey && s.payload?.orgKey;
    }),
  );

  const openAIShowSidebar = useSelector(
    flow(selector, (s) =>
      pipe(
        O.of(s),
        O.filter(isOneOf([DataGenerator.isIdle, DataGenerator.isCreating])),
        O.map((s) => s.payload.isOpen),
        O.getOrElseW(() => false),
      ),
    ),
  );

  const openAIILoading = useSelector(
    flow(selector, (s: DataGenerator.State) => {
      return s.type === "DataGenerator:Creating";
    }),
  );

  if (!openAIIsAvailable) {
    return null;
  }

  return (
    <>
      <Button
        isPrimary
        disabled={openAIILoading}
        onClick={() => dispatch(DataGenerator.toggleOpenSidebar())}
      >
        {openAIILoading ? t("Loading") : t("Generate data")}
      </Button>
      <FiltersSidebar
        isOpen={openAIShowSidebar}
        headerText={t("Data Generator")}
        onClose={() => dispatch(DataGenerator.toggleOpenSidebar())}
        onApply={() => dispatch(DataGenerator.create())}
        onClear={() => dispatch(DataGenerator.changeAmount(1))}
      >
        <TypographyMD>
          {t(
            "Welcome to the Data Generator powered by ChatGPT! Once you click 'Apply', the system will create dummy data based on your specifications.",
          )}
        </TypographyMD>

        <UIField>
          <Label>{t("Amount")}</Label>
          <Number
            placeholder={t("Insert amount of items")}
            value$={pipe(
              flow(selector, (s) =>
                pipe(
                  O.of(s),
                  O.filter(
                    isOneOf([DataGenerator.isIdle, DataGenerator.isCreating]),
                  ),
                  O.map((s) => s.payload.amount),
                  O.getOrElseW(() => 1),
                ),
              ),
            )}
            onChange={(v) => v && dispatch(DataGenerator.changeAmount(v))}
          />
        </UIField>
      </FiltersSidebar>
    </>
  );
}
