import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";

export type State = Idle;

export const isState = strictGuard((s: State): s is State => {
  if (isIdle(s)) {
    return true;
  }

  silentUnreachableError(s);
  return false;
});

// region Idle
export interface IdlePayload {}

export interface Idle {
  type: "Ready:SandboxPreview:Idle";
  payload: IdlePayload;
}

export const idle = (payload: Idle["payload"]): Idle => ({
  type: "Ready:SandboxPreview:Idle",
  payload,
});

export const isIdle = (a: State): a is Idle => {
  return a.type === "Ready:SandboxPreview:Idle";
};
// endregion
