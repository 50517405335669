import * as Listing from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Listing";
import { RootState, useSelector } from "state-manager";
import { ReactElement } from "react";
import { flow } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { useTranslation } from "i18n";
import { ExecuteModal } from "ui/layouts/Dialogs/ExecuteModal";

export interface ExecuteConfirmationProps {
  selector$: (s: RootState) => Listing.State;
  dispatch: (a: Listing.Actions) => void;
}

export function ExecuteConfirmation(
  p: ExecuteConfirmationProps,
): ReactElement | null {
  const { t } = useTranslation();
  const count = useSelector(
    flow(
      p.selector$,
      O.of,
      O.filter(Listing.isReady),
      O.map(
        (s) =>
          s.payload.items.filter((i) =>
            ["executeConfirm", "executing"].includes(i.actionState),
          ).length,
      ),
      O.getOrElseW(() => 0),
    ),
  );
  const isLoading = useSelector(
    flow(
      p.selector$,
      O.of,
      O.filter(Listing.isReady),
      O.filter((s) =>
        s.payload.items.some((i) => i.actionState === "executing"),
      ),
      O.map(() => true),
      O.getOrElseW(() => false),
    ),
  );
  const isOpen = count > 0;
  const many = count > 1;

  if (!isOpen) return null;

  return (
    <ExecuteModal
      title={many ? t("Execute items") : t("Execute item")}
      onConfirm={() => p.dispatch(Listing.executeConfirm())}
      onCancel={() => p.dispatch(Listing.executeDecline())}
      isLoading={isLoading}
    >
      {t("Are you sure to execute? This cannot be undone!")}
    </ExecuteModal>
  );
}
