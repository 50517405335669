import { gql } from "@apollo/client";

export const supplierFragment = gql`
  fragment SupplierFragment on Supplier {
    id
    createdAt
    updatedAt
    deletedAt
    data
    dataTypeID
  }
`;
