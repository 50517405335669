import { Checkbox } from "@zendeskgarden/react-forms";
import { forwardRef, ReactElement } from "react";
import { Cell } from "@components/Table";
import { Field } from "@components/Field";
import { Label } from "@components/Label";

export interface CheckHeadCellProps {
  value: "checked" | "unchecked" | "indeterminate";
  onChange: () => void;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
}

export const CheckHeadCell = forwardRef<
  HTMLTableCellElement,
  CheckHeadCellProps
>(({ onChange, value, readonly, disabled }, ref): ReactElement => {
  return (
    <Cell isMinimum ref={ref}>
      <Field>
        <Checkbox
          readOnly={readonly}
          disabled={disabled}
          checked={value === "checked"}
          indeterminate={value === "indeterminate"}
          onChange={onChange}
        >
          <Label hidden>+</Label>
        </Checkbox>
      </Field>
    </Cell>
  );
});
