import { silentUnreachableError } from "utils/exceptions";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { isNoEmptyString } from "types/src/NoEmptyString";
import { isOneOf } from "utils/isOneOf";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export const createReducer =
  <P extends string, E, T>(p: P, eq: (a: T, b: T) => boolean) =>
  (
    s: State.State<P, E, T>,
    a: Actions.Actions<P, E, T>,
  ): State.State<P, E, T> => {
    if (Actions.isClear(p)(a))
      return State.idle(p)({ query: O.none, items: s.payload.items });

    if (Actions.isSetQuery(p)(a)) {
      return State.idle(p)({
        query: pipe(a.payload, O.fromPredicate(isNoEmptyString)),
        items: s.payload.items,
      });
    }

    if (Actions.isSubmitQuery(p)(a)) {
      return pipe(
        s,
        O.fromPredicate(State.isIdle(p)),
        O.map((s) => s.payload.query),
        O.filter(O.isSome),
        O.map((query) => State.searching(p)({ query, items: s.payload.items })),
        O.getOrElse(() => s),
      );
    }

    if (Actions.isSearchError(p)(a)) {
      return pipe(
        s,
        O.fromPredicate(State.isSearching(p)),
        O.map((s) => s.payload.query),
        O.map((query) =>
          State.itemSearchError(p)({
            query,
            error: a.payload,
            items: s.payload.items,
          }),
        ),
        O.getOrElse(() => s),
      );
    }

    if (Actions.isSearchSuccess(p)(a)) {
      return pipe(
        s,
        O.fromPredicate(isOneOf([State.isSearching(p), State.isIdle(p)])),
        O.map((query) =>
          State.idle(p)({
            query: query.payload.query,
            items: a.payload,
          }),
        ),
        O.getOrElse(() => s),
      );
    }

    if (Actions.isSelectItem(p)(a)) {
      return pipe(
        O.some(s),
        O.filter((s) => s.payload.items.some((item) => eq(item, a.payload))),
        O.map((s) =>
          State.selected(p)({
            ...s.payload,
            item: s.payload.items.find((item) => eq(item, a.payload))!,
          }),
        ),
        O.getOrElse(() => s),
      );
    }

    silentUnreachableError(a);
    return s;
  };
