import { gql } from "@apollo/client";
import {
  DataType,
  DataTypeEntity,
  DataTypeId,
} from "types/src/DataType/DataType";
import { isT } from "fp-utilities";
import { Cursor } from "types";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as Arr from "fp-ts/Array";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { _gte, _lte } from "../../utils";
import { dataTypeFragment } from "../../fragments/DataType";
import {
  dataTypeEntityToApiEntity,
  dataTypeFragmentToDataType,
} from "../../transformers/DataType";
import { Client } from "../../index";
import {
  DataTypeWhereInput,
  GetDataTypesQuery,
  GetDataTypesQueryVariables,
} from "../../generated/graphql";
import {
  getPaginatedQueryResult,
  PaginatedQueryResult,
  QueryResponse,
} from "../../type/QueryResponse";
import { notFoundError } from "../../type/DsError";
import { WhereEq } from "../../type/Where";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";

export interface GetDataTypesVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: {
    and?: Array<GetDataTypesVars["where"]>;
    or?: Array<GetDataTypesVars["where"]>;
    id?: WhereEq<DataTypeId>;
    name?: string;
    description?: string;
    entity?: DataTypeEntity[];
    default?: boolean;
    createdAt?: [Date | undefined, Date | undefined];
    updatedAt?: [Date | undefined, Date | undefined];
  };
}
export type GetDataTypesQueryResult = PaginatedQueryResult<DataType>;
export function getDataTypes(
  client: Client,
  vars: GetDataTypesVars,
): Promise<QueryResponse<GetDataTypesQueryResult>> {
  const query = gql`
    ${pageInfoFragment}
    ${dataTypeFragment}

    query GetDataTypes(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: DataTypeWhereInput
    ) {
      dataTypes(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...DataTypeFragment
          }
        }
      }
    }
  `;

  return client
    .query<GetDataTypesQuery, GetDataTypesQueryVariables>({
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: createWhere(vars.where),
      }),
    })
    .then(
      flow(
        E.map((v) => v.dataTypes),
        E.map(getPaginatedQueryResult(dataTypeFragmentToDataType)),
      ),
    );
}

export function getDataType(
  client: Client,
  id: DataTypeId,
): Promise<QueryResponse<DataType>> {
  return getDataTypes(client, { where: { and: [{ id: { eq: id } }] } }).then(
    flow(
      E.map((v) => v.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
  );
}

function createWhere(
  where: GetDataTypesVars["where"],
): DataTypeWhereInput | undefined {
  if (!where) {
    return undefined;
  }

  const createdAt = pipe(where?.createdAt, O.fromNullable);
  const updatedAt = pipe(where?.updatedAt, O.fromNullable);
  const name = where?.name || undefined;
  const description = where?.description || undefined;

  return {
    or: pipe(
      where?.or,
      O.fromNullable,
      O.map(flow(Arr.map(createWhere), Arr.filter(isT))),
      O.toUndefined,
    ),
    and: pipe(
      where?.and,
      O.fromNullable,
      O.map(flow(Arr.map(createWhere), Arr.filter(isT))),
      O.toUndefined,
    ),

    id: where.id?.eq,
    idNEQ: where.id?.neq,
    idIn: where.id?.in,
    idNotIn: where.id?.notIn,

    createdAtGTE: pipe(createdAt, _gte),
    createdAtLTE: pipe(createdAt, _lte),

    updatedAtGTE: pipe(updatedAt, _gte),
    updatedAtLTE: pipe(updatedAt, _lte),

    nameContainsFold: name,
    descriptionContainsFold: description,
    default: where?.default,
    entityIn: pipe(
      where?.entity,
      O.fromNullable,
      O.map(Arr.map(dataTypeEntityToApiEntity)),
      O.toUndefined,
    ),
  };
}
