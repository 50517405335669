import { RootState, useSelector } from "state-manager";
import {
  DatePickerInput as Component,
  DatePickerProps,
} from "ui/components/DatePicker";

export interface InputProps extends Omit<DatePickerProps, "value"> {
  value$: (s: RootState) => Date | undefined;
  placeholder?: string;
  validation?: "success" | "warning" | "error";
}

export function DatePickerInput({ value$, ...props }: InputProps) {
  const value = useSelector(value$, (a, b) => a?.getTime() === b?.getTime());

  return <Component {...props} value={value} />;
}
