import * as StateModule from "./types/State";
import * as ActionsModule from "./types/Actions";
import {
  createPickingOrderState,
  editPickingOrderState,
  listingAllPickingOrderState,
  listingPickingOrderState,
} from "./utils";
import { reducer } from "./reducer";
import { epic } from "./epic";

export function createState() {
  return {
    isState: StateModule.isState,
    isActions: ActionsModule.isActions,
    reducer: reducer,
    epic: epic,
    subStates: {
      create: createPickingOrderState,
      edit: editPickingOrderState,
      listingAll: listingAllPickingOrderState,
      listing: listingPickingOrderState,
    },
  };
}

export type State = StateModule.State;
export type Actions = ActionsModule.Actions;
